export enum FlowStepReplyButtonType {
  REPLY = "reply",
  CTA_URL = "cta_url",
  MULTIPLE_ATTACHMENT = "multipleAttachment",
}
export enum AddressMessageFieldType {
  NAME = "name",
  PHONE_NUMBER = "phone_number",
  IN_PIN_CODE = "in_pin_code",
  HOUSE_NUMBER = "house_number",
  FLOOR_NUMBER = "floor_number",
  TOWER_NUMBER = "tower_number",
  BUILDING_NAME = "building_name",
  ADDRESS = "address",
  LANDMARK_AREA = "landmark_area",
  CITY = "city",
  STATE = "state",
}

export const OptionalRepromptFlowSteps = [
  "list",
  "reply",
  "dynamicList",
  "dynamicReply",
  "whatsappFlow",
  "product",
  "dynamicProduct",
  "orderPaymentMessage",
];

export const FlowStepDisplayType = {
  initiation: "Initiation",
  text: "Text",
  delay: "Delay",
  reply: "Card",
  card: "Card",
  list: "List",
  whatsappFlow: "Form",
  orderPaymentMessage: "Order Details",
  orderStatus: "Order Status",
  question: "Question",
  product: "Product",
  location: "Location",
  mcq: "Quick Reply",
  carousels: "Carousel",
  contact: "Contact",
  image: "Image",
  audio: "Audio",
  video: "Video",
  pdf: "PDF",
  doc: "Document",
  ppt: "PPT",
  xls: "Excel",
  dynamicList: "iList",
  dynamicReply: "iReply",
  dynamicMedia: "iMedia",
  dynamicProduct: "iProduct",
  dynamicMcq: "iQR",
  dynamicLocation: "iLocation",
  dynamicCarousel: "iCarousel",
  locationRequest: "Location Request",
  addressMessage: "Address Message",
};
