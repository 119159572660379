import { environment } from "../../environments/environment";

export class Urls {
  static baseUrl = environment.apiUrl + "/";
  static readonly platformApisUrl = Urls.baseUrl + "v2/";
  static nestMonstruoBaseUrl = environment.nestMonstruo + "/";
  static login = Urls.baseUrl + "users/login";
  static logout = Urls.baseUrl + "auth/users/logout";
  static changePassword = Urls.baseUrl + "auth/users/changePassword";
  static usersAgentsList = Urls.baseUrl + "auth/users/agentList/";
  static usersProfile = Urls.baseUrl + "auth/users/";
  static readonly notificationSettings = `${Urls.usersProfile}notification-settings/`;
  static forgotPassword = Urls.baseUrl + "users/forgotPassword";
  static reserPassword = Urls.baseUrl + "users/changeForgotPassword";
  static getresetUrl = Urls.baseUrl + "users/verifyResetToken/";
  static tags = Urls.baseUrl + "tag/";
  static flexible_indicator = Urls.baseUrl + "fi/";
  static flexible_indicator_delete = Urls.flexible_indicator + "remove/";
  static flow_builder = Urls.baseUrl + "flowbuilder/";
  static floW_messages = Urls.baseUrl + "flowbuilder/messages";
  static flow_dialog = Urls.flow_builder + "flowDialogs";
  static intent = Urls.flow_builder + "intents";

  static flow_builder_step = Urls.flow_builder + "step/";
  static org = Urls.baseUrl + "org/";
  static botList = Urls.baseUrl + "bots/";
  static rules = Urls.baseUrl + "rules/";
  static get_apis = Urls.baseUrl + "apitool/";
  static get_api_tool = Urls.baseUrl + "apitool/tool-req";
  static soap = Urls.baseUrl + "apitool/soap_client/";
  static soap_result = Urls.get_apis + "soap_result/";
  static soap_save = Urls.get_apis + "soap_save/";
  static users = Urls.baseUrl + "user-profiling";
  static botSettings = Urls.baseUrl + "botsetting";
  static intent_save = Urls.baseUrl + "df-intents";
  static languages = Urls.baseUrl + "df-intents/supported-lang/";
  static entity = Urls.baseUrl + "df-entity/";
  static folder_create = Urls.baseUrl + "intent-folder";
  static intentFolder = Urls.baseUrl + "intent-folder/";
  static handoff = Urls.baseUrl + "handOff/";
  static bdk_common = Urls.baseUrl + "bdk/";
  static analytics = Urls.baseUrl + "analytics/";
  static apiAnalytics = "/api-analytics";
  static flowFolder = Urls.baseUrl + "flowFolder/";
  static untrained_intents = Urls.baseUrl + "df-training/";
  static bot = Urls.baseUrl + "bot/";
  static knowledge_base = Urls.baseUrl + "knowledge-base/";
  static utterance = Urls.baseUrl + "utterance/";
  static growthTool = Urls.baseUrl + "growth-tool/";
  static keywords = Urls.baseUrl + "keywords/";
  static whatsapp_widget = Urls.baseUrl + "whatsapp-widget/";
  static useCase = Urls.baseUrl + "bot/";
  static preview_link = Urls.baseUrl + "botPreviewLink/";

  // TO--DO
  static fileUpload = Urls.baseUrl + "files/";
  static settings = Urls.baseUrl + "bot-settings/";
  static platform = Urls.baseUrl + "platform/";

  static form = Urls.baseUrl + "form/";

  static webhooks = "/webhook-trigger/";

  static handOff = "/handoff/";
  static messageConfigration = `${Urls.handOff}setting/messages/`;
  static handoffAction = `${Urls.handOff}actions/`;
  static handoffShortcuts = `${Urls.handOff}shortcuts/`;

  static campaign = "/campaign/";
  static campaignList = `${Urls.campaign}list`;
  static campaignReport = `${Urls.campaign}download-campaign-cdr/`;

  static instagram = "/instagram/";
  static instagramConfigration = `${Urls.instagram}settings`;

  static flowBuilder = "/flow-builder/";

  static authUser = Urls.baseUrl + "auth/users";
  static integration = "/integrations/";
  static revoke = `${Urls.integration}google/revoke`;
  static sheet = `${Urls.integration}google/sheet/`;
  static calendar = `${Urls.integration}google/calendar/`;
  static zohoCRM = `${Urls.integration}zoho-crm/`;

  static preview = `${Urls.baseUrl}saas/preview/`;
  static previewAuth = `${Urls.preview}auth`;
  static growthToolUserDetail = Urls.baseUrl + "growthToolUserDetail/";
  static template = `${Urls.baseUrl}template-messages`;

  /**
   * Nest Monstruo related api urls
   */
  static handoffNestMonstruo = `${Urls.nestMonstruoBaseUrl}handoff/`;
  static links = `${Urls.nestMonstruoBaseUrl}link`;
  static ruleNestMonstruo = `${Urls.nestMonstruoBaseUrl}scheduleMessage/`;
  static userProfiling = `${Urls.nestMonstruoBaseUrl}user-profiling`;
  static ikitCampaign = `${Urls.nestMonstruoBaseUrl}campaign/`;

  /**
   * Web Widget Url
   */
  static webWidgetUrl = environment.webWidgetUrl;

  static channelSettings = `${Urls.baseUrl}channelSettings/`;

  /**
   * file export url
   */
  static fileExportUrl = `${Urls.baseUrl}file-exports/`;

  /**
   * auditor url
   */
  static readonly auditLogUrl = `${Urls.baseUrl}auditlog`;

  /** Message Templates Url */
  static messageUrl = `${Urls.baseUrl}messages-template/`;

  /** User's notfications url */
  static userNotificationUrl = `${Urls.baseUrl}user-notification`;

  /** Razor pay webhook url */
  static razorpayWebhookUrl = `${Urls.nestMonstruoBaseUrl}saas/razor-pay/payment-status`;

  /**
   * shopify url
   */
  static shopifyUrl = `/integrations/shopify`;

  static whatsappFlow = `${Urls.baseUrl}whatsapp-flow`;

  static agentCalling = `${Urls.nestMonstruoBaseUrl}agent-calling`;

  /**
   * Platform Apis Urls
   */
  static whatsappFlowV2 = `${Urls.platformApisUrl}whatsapp-flow`;
}
