export enum ButtonType {
  CUSTOM = "CUSTOM",
  URL = "URL",
  PHONE_NUMBER = "PHONE_NUMBER",
  FLOW = "FLOW",
  COPY_CODE = "COPY_CODE",
}

export enum CarouselButtonType {
  QUICK_REPLY = "QUICK_REPLY",
  URL = "URL",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export enum InteractiveButtonType {
  NONE = "NONE",
  QUICK_REPLY = "QUICK_REPLY",
  CALL_TO_ACTION = "CALL_TO_ACTION",
  OTP = "OTP",
}

export enum CampaignCarouselButtonType {
  QUICK_REPLY = "quickReply",
  URL = "url",
}

export enum CampaignInteractiveButtonType {
  NONE = "none",
  QUICK_REPLY = "quickReply",
  CALL_TO_ACTION = "callToAction",
}

export enum CampaignButtonType {
  CUSTOM = "custom",
  URL = "url",
  FLOW = "flow",
  COPY_CODE = "copyCode",
}
export enum carouselTemplateHeaderType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum TemplateStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PAUSED = "PAUSED",
}

interface CommonVisitWebsite {
  URLType: "STATIC" | "DYNAMIC";
  url: string;
  example?: string[];
}

interface CommonPhoneNumber {
  country: string;
  phoneNumber: string;
}

export interface Button {
  text: string;
  subType?: ButtonType;
  phone_number?: CommonPhoneNumber;
  visitWebsite?: CommonVisitWebsite;
  flowData?: {
    flowId: string;
    navigate_screen: string;
    flowName: string;
    preview?: string;
  };
  copyCode?: {
    example: string;
  };
}
export interface CarouselButton {
  text: string;
  type?: CarouselButtonType;
  phone_number?: CommonPhoneNumber;
  visitWebsite?: CommonVisitWebsite;
}

interface CommonBody {
  text: string;
  example?: { body_text: [string[]] };
  add_security_recommendation?: boolean;
}
export interface CarouselCard {
  header: {
    format: carouselTemplateHeaderType;
    example: {
      header_handle: string[];
    };
  };
  body: CommonBody;
  buttons: CarouselButton[];
}

export interface Interactive {
  type: InteractiveButtonType;
  buttons: Button[];
}

export interface LanguageTemplate {
  _id?: string;
  code: string;
  status?: string;
  header?: {
    format: "NONE" | "TEXT" | "MEDIA";
    text: string;
    mediaType?: "NONE" | "IMAGE" | "VIDEO" | "DOCUMENT" | "LOCATION";
    example?: { header_text?: string[]; header_handle?: string[] };
  };
  body?: CommonBody;
  footer?: {
    text: string;
    code_expiration_minutes?: code_expiration_minutes;
  };
  carousel?: CarouselCard[];
  interactive?: Interactive[];
  // ONLY FOR FRONTEND KEYS
  headerMediaToken?: string;
  isSend?: boolean;
  isValid?: boolean;
  message?: string;
}

export interface TemplateListObject {
  _id: string;
  category: "MARKETING" | "UTILITY" | "AUTHENTICATION";

  name: string;
  language: LanguageTemplate;
  qualityRating: string;
  templateId: string;
  updatedAt: Date;
}

export interface TemplateObject {
  category: "NONE" | "MARKETING" | "UTILITY" | "AUTHENTICATION";
  name: string;
  languages: LanguageTemplate[];
  allowCategoryChange?: boolean;
  rejectedReason: string;
  channel: string;
  lastUpdatedTime?: any;
}

export enum TemplateAction {
  edit = "EDIT",
  create = "CREATE",
  view = "VIEW",
}

export enum templateInsightsButtonType {
  QUICK_REPLY = "Quick reply",
  URL = "Website click",
}

export enum templateInsightsCategoryType {
  marketing = "MARKETING",
  utility = "UTILITY",
}

export interface code_expiration_minutes {
  time?: number;
  isEnabled?: boolean;
}
