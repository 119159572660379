import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { Urls } from "../utils/urls";
import { IAPIResponse } from "../utils/model/common";
import { IInstagramSettings } from "../utils/model/bot";
import { USER_ROLE } from "../utils/consts/user-role.consts";

@Injectable()
export class BotService {
  private subject = new Subject<any>();
  public isBotPreviewOpened = new Subject<boolean>();
  public iconChangeSubject = new Subject<void>();

  readOnlyTypeRoles: Array<string> = [USER_ROLE.BOT_REVIEWER, USER_ROLE.BOT_SUPERVISOR, USER_ROLE.PARTNER, USER_ROLE.CST];
  partnerOrCST: Array<string> = [USER_ROLE.PARTNER, USER_ROLE.CST];
  reviewerOrSupervisor: Array<string> = [USER_ROLE.BOT_REVIEWER, USER_ROLE.BOT_SUPERVISOR];
  public botIdOfCurrentSession: string = "";
  public orgIdOfCurrentSession: string = "";

  constructor(private http: HttpClient) {
    this.botIdOfCurrentSession = localStorage.getItem("selectedBot") || "";
    this.orgIdOfCurrentSession = localStorage.getItem("organizationId") || "";
  }

  getUpdateForBotSelected(): Observable<any> {
    return this.subject.asObservable();
  }

  sendUpdate(message) {
    this.subject.next(message);
  }

  saveBot(id, name, logo?) {
    if (name) {
      localStorage.setItem("selectedBotName", name);
    }
    if (logo) {
      localStorage.setItem("selectedBotPanelLogo", logo);
    }
    if (id) {
      localStorage.setItem("selectedBot", id);
      this.botIdOfCurrentSession = id;
      this.sendUpdate(true);
    }
  }

  getBot(): string {
    return this.botIdOfCurrentSession;
  }

  clearCurrentSession() {
    localStorage.clear();
    this.botIdOfCurrentSession = "";
    this.orgIdOfCurrentSession = "";
  }

  getName(): string {
    if (localStorage.getItem("selectedBotName")) {
      return localStorage.getItem("selectedBotName");
    } else {
      return "";
    }
  }

  getPanelLogo(): string {
    return localStorage.getItem("selectedBotName") ? localStorage.getItem("selectedBotPanelLogo") : "";
  }

  saveRole(role) {
    if (role) {
      localStorage.setItem("currentRole", role);
    }
  }

  getRole() {
    return localStorage.getItem("currentRole");
  }

  isReviewer(): boolean {
    if (localStorage.getItem("currentRole")) {
      return localStorage.getItem("currentRole") === "Bot Reviewer";
    } else {
      return false;
    }
  }

  isBotSupervisor(): boolean {
    if (localStorage.getItem("currentRole")) {
      return localStorage.getItem("currentRole") === "Bot Supervisor";
    } else {
      return false;
    }
  }

  checkAllAccess() {
    return localStorage.getItem("currentRole") && !this.readOnlyTypeRoles.includes(localStorage.getItem("currentRole"));
  }

  checkPartnerOrCST() {
    return localStorage.getItem("currentRole") && this.partnerOrCST.includes(localStorage.getItem("currentRole"));
  }

  updateSettings(data) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.botSettings + "/" + this.getBot(), data).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.botSettings + "/" + this.getBot()).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setOrganization(organizationId) {
    localStorage.setItem("organizationId", organizationId);
    this.orgIdOfCurrentSession = organizationId;
  }

  getSelectedOrganization() {
    return this.orgIdOfCurrentSession;
  }

  createLink(reqBodyLinkObejct) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.preview_link + this.getBot(), reqBodyLinkObejct).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getLink() {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.preview_link + this.getBot()).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  deleteLink(linkId) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.preview_link + linkId).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  authoriseToken(token) {
    return new Promise((resolve, reject) => {
      return this.http
        .get(`${Urls.preview_link}auth?botId=${this.getBot()}`, {
          headers: new HttpHeaders({ Authorization: token }),
        })
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  authoriseBotPreview(reqBodyAuthorizationObject) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.preview_link, reqBodyAuthorizationObject).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateInstagramSettings(data) {
    return new Promise((resolve, reject) => {
      this.http.put(`${Urls.bot}${this.getBot()}${Urls.instagramConfigration}?`, data).subscribe(
        res => {
          resolve(res as IAPIResponse<{ _id: string }>);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getInstagramSettings(): Promise<IAPIResponse<IInstagramSettings>> {
    return new Promise((resolve, reject) => {
      this.http.get(`${Urls.bot}${this.getBot()}${Urls.instagramConfigration}`).subscribe(
        res => {
          resolve(res as IAPIResponse<IInstagramSettings>);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getGeneralSetting(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.botSettings}/${this.getBot()}/general`).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateGeneralSetting(data) {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.botSettings}/${this.getBot()}/general`, data).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  deleteSessionTimeoutLanguage(languageCode) {
    return new Promise((resolve, reject) => {
      return this.http.delete(`${Urls.botSettings}/${this.getBot()}/general/session?language=${languageCode}`).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getWhatsappSettings() {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.botSettings}/${this.getBot()}/whatsappSettings`).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  addOrUpdateDefaultOrderFlow(data) {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.botSettings}/${this.getBot()}/whatsappSettings/defaultOrderFlow`, data).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  deleteDefaultOrderFlow() {
    return new Promise((resolve, reject) => {
      return this.http.delete(`${Urls.botSettings}/${this.getBot()}/whatsappSettings/defaultOrderFlow`).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  changeCampaignWidgetEnabledStatus(data) {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.botSettings}/${this.getBot()}/whatsappSettings/campaignWidget/status`, data).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  addOrUpdateOrderPaymentSettings(data) {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.botSettings}/${this.getBot()}/whatsappSettings/order-payment-setting`, data).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  changeExcludeOptoutUserStatus(data) {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.botSettings}/${this.getBot()}/whatsappSettings/optout-setting`, data).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
