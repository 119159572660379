import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";

import { routes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { LoginComponent } from "./common-components/login/login.component";
import { AuthService } from "./services/auth.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthGuard } from "./utils/guards/auth.guard";
import { TokenInterceptor } from "./utils/interceptor/token.interceptor";
import { SettingService } from "./services/setting.service";
import { Router, RouterModule } from "@angular/router";
import { SharedModule } from "./utils/shared.module";
import "hammerjs";
import { UserTranscriptComponent } from "./user-transcript/user-transcript.component";
import { UsersService } from "./services/users.service";
import { NotifyService } from "./services/noyify.service";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TagsService } from "./services/tags.service";
import { UsecaseMappingService } from "./services/usecaseMapping.service";
import { BotService } from "./services/bot.service";
import { ExcelService } from "./services/excel-file.service";
import { MomentDateModule } from "@angular/material-moment-adapter";
import { CommonService } from "./services/common.service";
import { InsufficientAccessComponent } from "./common-components/insufficient-access/insufficient-access.component";
import * as Sentry from "@sentry/angular-ivy";
import { PreviewSocketService } from "./services/preview-socket.service";
import { GlobalErrorHandler } from "./utils/error-handler";

@NgModule({
  declarations: [AppComponent, LoginComponent, UserTranscriptComponent, InsufficientAccessComponent],
  imports: [
    InfiniteScrollModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    SharedModule,
    NgxMaterialTimepickerModule,
    MomentDateModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    UsersService,
    NotifyService,
    SettingService,
    CommonService,
    TagsService,
    UsecaseMappingService,
    BotService,
    ExcelService,
    PreviewSocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
