import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { createCipher, createDecipher } from "crypto-browserify";
import { Subject } from "rxjs";

import { BotService } from "./bot.service";
import { Urls } from "../utils/urls";

declare const k7Mgq_Hqtm0oxzr28Z0M4x;
declare const aHYyRq7X6_zwesymsgdvaX;
declare const pa09cI4v_MTgxh6QlJTJpH;
declare const bUfQWgskElADr7lQvyy_rR;

@Injectable()
export class AuthService {
  remember;
  email;
  isAdminLogin = false;
  aEZHoOWuYV = [k7Mgq_Hqtm0oxzr28Z0M4x, aHYyRq7X6_zwesymsgdvaX, pa09cI4v_MTgxh6QlJTJpH, bUfQWgskElADr7lQvyy_rR];

  userDetailsChange = new Subject();

  constructor(
    private http: HttpClient,
    private _botService: BotService
  ) {
    this.remember = localStorage.getItem("remember") || false;
  }

  login(data) {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.login, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setRememberMe(val) {
    this.remember = val;
    localStorage.setItem("remember", this.remember);
  }

  getRememberMe() {
    return localStorage.getItem("remember");
  }

  plIA_HvZ6zV(p1s9HWotZufVowtIp_VTug) {
    localStorage.setItem("_g;:.abr-.rmmk;", this.aEZHoOWuYV[0](p1s9HWotZufVowtIp_VTug, createCipher));
  }

  iKk1k_4KxG8Q37tkFfM46() {
    if (localStorage.getItem("_g;:.abr-.rmmk;")) {
      return this.aEZHoOWuYV[1](localStorage.getItem("_g;:.abr-.rmmk;"), createDecipher);
    }
    return null;
  }

  setUser(k6msEE3_cFDY1cHu5cW0IO) {
    localStorage.setItem("93-411_251,visitto:bsb", this.aEZHoOWuYV[2](k6msEE3_cFDY1cHu5cW0IO, createCipher));
  }

  getUser() {
    if (localStorage.getItem("93-411_251,visitto:bsb")) {
      const aJCz_L7AiI1 = this.aEZHoOWuYV[3](localStorage.getItem("93-411_251,visitto:bsb"), createDecipher);
      if (typeof aJCz_L7AiI1 === "object") {
        return aJCz_L7AiI1;
      }
      this.logout().catch(() => {});
    }
    return null;
  }

  logout() {
    const fcmToken = {
      fcmToken: localStorage.getItem("fcmToken"),
    };
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.logout, fcmToken, {
          headers: { Authorization: this.iKk1k_4KxG8Q37tkFfM46() },
        })
        .subscribe(
          res => {
            this._botService.clearCurrentSession();
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  forgotPassword(data) {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.forgotPassword, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  forgotPasswordProfile() {
    return new Promise((resolve, reject) => {
      this.http.post(`${Urls.usersProfile}forgotPassword`, {}).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  resetPassword(data, token) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.reserPassword, data, {
          headers: new HttpHeaders().append("authorization", token),
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
            console.error(err);
          }
        );
    });
  }

  getUserBots(botId?): any {
    let url;
    if (botId) {
      url = `${Urls.baseUrl}auth/users/botAccess/${botId}`;
    } else {
      url = `${Urls.baseUrl}auth/users/botAccess`;
    }
    return new Promise((resolve, reject) => {
      return this.http.get(url).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getAvailableCredit(botId?) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.usersProfile}credit-info/${botId}`).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  resetGetCall(token) {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.getresetUrl + token).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setforgotPassword(data) {
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.changePassword, data, {
          headers: new HttpHeaders().append("authorization", this.iKk1k_4KxG8Q37tkFfM46()),
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getUserDetailsById(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.usersProfile}${id}`, { params: { botId: this._botService.getBot() } }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateUserDetailsById(data, id) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.usersProfile + id, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  checkUserDetailChange(userId) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.usersProfile + "detail/" + userId).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getTwoFactorAuthCode(userId): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.usersProfile + "register2FA", { userId }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  verify2FA(mfaCode): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.usersProfile + "verify2FA", { mfaCode }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  disable2FA(mfaCode): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.usersProfile + "disable2FA", { mfaCode }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  sendPasswordSetLink(user) {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.usersProfile}send-password-mail`, user).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  verifyPasswordSetToken(token): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.baseUrl}users/verify-password-token/${token}`).subscribe(
        (data: any) => {
          localStorage.setItem("token", data.message);
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setUserPassword(user, token) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(`${Urls.baseUrl}users/set-password`, user, { headers: new HttpHeaders().append("authorization", token) })
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
