import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-messenger-card",
  templateUrl: "./messenger-card.component.html",
  styleUrls: ["../messenger.common-bot.component.scss"],
})
export class MessengerCardComponent {
  @Input() cardDetails: any;
  @Output() buttonClickedEmitter = new EventEmitter<any>();
  buttonClicked(value) {
    if (value.type === "openUrl") {
      window.open(value.value, "_blank");
    } else if (value.type === "openApp") {
      window.open(value.value, "_blank");
    } else {
      this.buttonClickedEmitter.emit(value);
    }
  }
}
