<div class="content-align-col" *ngIf="selectedUser !== undefined && selectedUser">
  <div class="t-flex t-items-center" *ngIf="parentComponent !== 'handoff'">
    <div class="user-detail-parent-left">
      <img
        class="profile-pic"
        [src]="selectedUser && selectedUser.profile ? selectedUser.profile : '../../../../assets/images/demo_person.jpg'" />
    </div>
    <div class="t-pl-5 header-text-user t-flex">
      <h2 class="t-m-0 ellipsis">{{ userName }}</h2>
      <div class="social-platform">
        <img
          [src]="commonService.getChannelIcon(selectedUser.botChannel)"
          [matTooltip]="commonService.getChannelNames()[selectedUser.botChannel]" />
      </div>
    </div>
  </div>
  <div class="content-align-col">
    <h4 class="header">Profile Stats</h4>
    <div class="profile-stat-main">
      <div
        class="fields-main"
        [ngClass]="{ 'fields-main--handoff input input-like-box input-like-box-gray': parentComponent === 'handoff' }">
        <span class="left-text">First Interacted :</span>
        <span class="right-text">
          {{ selectedUser.firstSeen ? (selectedUser.firstSeen | date: "medium") : "Currently not available" }}
        </span>
      </div>
      <div
        class="fields-main input input-like-box input-like-box-gray"
        [ngClass]="{ 'fields-main--handoff input input-like-box input-like-box-gray': parentComponent === 'handoff' }">
        <span class="left-text">Last Interacted :</span>
        <span class="right-text">
          {{ selectedUser.lastSeen ? (selectedUser.lastSeen | date: "medium") : "Currently not available" }}
        </span>
      </div>
      <!-- <div
        class="fields-main t-flex-col t-mb-2.5 t-mr-2.5"
        [ngClass]="{
          'fields-main--handoff input input-like-box input-like-box-gray': parentComponent === 'handoff'
        }"
      >
        <span class="left-text">Last 90 days :</span>
        <span class="right-text "> {{ selectedUser.visit }} visits , {{ selectedUser.action }} actions </span>
      </div> -->
    </div>
  </div>
  <hr />
  <div class="content-align-col">
    <h4 class="header">{{ type | titlecase }} Tags</h4>
    <div class="content-align-row t-items-center t-flex-wrap">
      <ng-container *ngIf="selectedUser && selectedUser.tags">
        <ng-container *ngFor="let tag of selectedUser.tags; index as tIndex">
          <mat-chip-list class="tag-chip-list" *ngIf="tag.type === type" [ngClass]="{ disable_touch: !isTagRemovable }">
            <mat-chip class="tag-chip" [removable]="isTagRemovable" (removed)="onTagSelect(true, tIndex)">
              {{ tag.tag }}
              <mat-icon matChipRemove *ngIf="isTagRemovable && !isPartnerOrCST">clear</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </ng-container>
      </ng-container>

      <div class="button-wrapper t-min-w-[116px]">
        <button
          *ngIf="parentComponent !== 'transcript' && !isPartnerOrCST"
          mat-flat-button
          class="btn primary-btn hover-effect btn-with-icon-left"
          color="primary"
          (click)="openDialog(selectTag, 'actionTag', 'addTag')"
          [disabled]="allowsTags.length === 0 && !isAllAccess"
          [matTooltip]="allowsTags.length === 0 && !isAllAccess ? 'No tags are available. Contact your admin for assistance' : ''"
          matTooltipPosition="above">
          <mat-icon class="t-mr-[5px] t-w-[20px] t-h-[20px] t-text-[20px]">add</mat-icon>
          Add Tag
        </button>
      </div>
    </div>
    <hr />
  </div>

  <div class="content-align-col" *ngIf="otherSelectedTags.length !== 0">
    <h4 class="header">{{ (type === tagTypes.AGENT ? tagTypes.USER : tagTypes.AGENT) | titlecase }} Tags</h4>
    <mat-chip-list class="tag-chip-list" *ngIf="otherSelectedTags">
      <ng-container>
        <div class="content-align-row t-items-center t-flex-wrap">
          <ng-container *ngFor="let tag of otherSelectedTags; index as tIndex">
            <mat-chip *ngIf="tag.type !== type" [ngClass]="{ disable_touch: !isTagRemovable }" class="tag-chip" [removable]="false">
              {{ tag.tag }}
            </mat-chip>
          </ng-container>
        </div>
      </ng-container>
    </mat-chip-list>
    <hr />
  </div>

  <div class="content-align-col" *ngIf="userFi && userFi.length > 0">
    <h4 class="header">User Fields</h4>
    <div
      class="fields-main t-flex t-flex-wrap t-flex-row"
      [ngClass]="{
        'fields-main--handoff input input-like-box input-like-box-gray': parentComponent === 'handoff',
      }">
      <ng-container *ngFor="let fi of userFi">
        <div
          class="t-flex t-flex-wrap t-w-[50%] transcript-user-details"
          [ngClass]="{ 'fields-main--handoff': parentComponent === 'handoff' }"
          *ngIf="fi?.name === 'transcriptUrl' && parentComponent != 'transcript'">
          <div class="left-text">{{ fi?.name | titlecase }}</div>
          <div class="t-mx-[5px] colon-text">:</div>
          <a href="{{ fi.value }}" target="_blank" class="right-text" style="color: var(--primaryColor) !important">Click here</a>
        </div>
        <div
          class="t-flex t-flex-wrap t-w-[50%] transcript-user-details"
          [ngClass]="{ 'fields-main--handoff': parentComponent === 'handoff' }"
          *ngIf="fi?.name !== 'transcriptUrl'">
          <div class="left-text">{{ fi?.name | titlecase }}</div>
          <div class="t-mx-[5px] colon-text">:</div>
          <div class="right-text">{{ fi?.value }}</div>
        </div>
      </ng-container>
    </div>
    <hr *ngIf="userFi && userFi.length > 0" />
  </div>

  <div class="content-align-col" *ngIf="nlpFi && nlpFi.length > 0">
    <h4 class="header">NLP Fields</h4>
    <div
      class="fields-main t-flex t-flex-wrap"
      [ngClass]="{
        'fields-main--handoff input input-like-box input-like-box-gray': parentComponent === 'handoff',
      }">
      <div
        class="t-flex t-flex-wrap t-w-[50%] transcript-user-details"
        [ngClass]="{
          'fields-main--handoff input input-like-box input-like-box-gray': parentComponent === 'handoff',
        }"
        *ngFor="let fi of nlpFi">
        <div class="left-text">{{ fi?.name | titlecase }}</div>
        <div class="t-mx-[5px] colon-text">:</div>
        <div class="right-text">{{ fi?.value }}</div>
      </div>
    </div>
    <hr *ngIf="nlpFi && nlpFi.length > 0" />
  </div>

  <div class="content-align-col" *ngIf="otherFi && otherFi.length > 0">
    <h4 class="header">Other Fields</h4>
    <div
      class="fields-main t-flex t-flex-wrap"
      [ngClass]="{
        'input input-like-box input-like-box-gray': parentComponent === 'handoff',
      }">
      <div
        class="t-flex t-flex-wrap t-w-[50%] transcript-user-details"
        [ngClass]="{ 'fields-main--handoff': parentComponent === 'handoff' }"
        *ngFor="let fi of otherFi">
        <div class="left-text">{{ fi?.name | titlecase }}</div>
        <div class="t-mx-[5px] colon-text">:</div>
        <div class="right-text">{{ fi?.value }}</div>
      </div>
    </div>
  </div>
  <hr *ngIf="otherFi && otherFi.length > 0" />
</div>
<!--Select Tag-->
<ng-template #selectTag>
  <div class="content-align-col">
    <div class="input-wrapper">
      <label>Tag</label>
      <app-tag-select
        class="input input-gray"
        [placeholder]="'Select Tag'"
        [isAllowCreateNewTag]="isAllAccess"
        [selectedTag]="selectedTag"
        [allowTagTypes]="allowTagTypes"
        [allTags]="allowsTags"
        (addNewTag)="openDialog(createTags, 'createTag')"
        (tagSelectChange)="selectedTag = $event.value">
      </app-tag-select>
    </div>
    <div class="button-wrapper">
      <button
        mat-flat-button
        color="primary"
        class="btn primary-btn hover-effect t-ml-auto"
        [disabled]="!selectedTag"
        (click)="onTagSelect(false)">
        Save
      </button>
    </div>
  </div>
</ng-template>
<!--Select tag END-->
<!--Create New User field-->
<ng-template #createTags>
  <div class="modal-body">
    <div class="content-align-col">
      <div class="input-wrapper">
        <label>Name</label>
        <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
          <input
            matInput
            [(ngModel)]="tagName"
            autocomplete="off"
            placeholder="Enter Tag Name"
            (keypress)="commonService.restrictSpecialCharacter($event, 'Tag name')" />
        </mat-form-field>
      </div>
      <div class="input-wrapper">
        <label>Description</label>
        <mat-form-field floatLabel="never" appearance="fill" class="input textarea textarea-gray t-p-0">
          <textarea class="textarea t-p-0" [(ngModel)]="description" matInput placeholder="Description" rows="5"></textarea>
        </mat-form-field>
      </div>
      <div class="button-wrappaer">
        <button
          *ngIf="isAllAccess"
          mat-flat-button
          color="primary"
          [disabled]="!tagName"
          class="btn primary-btn hover-effect t-ml-auto"
          (click)="createTag()">
          Create
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!--Create New User field-->
