<div>
  <div class="preview-part">
    <!-- MAIN CHAT PART | START -->
    <div class="messenger-chat t-w-full" id="conversation">
      <ng-container *ngIf="messages && messages.length">
        <ng-container *ngFor="let message of messages; trackBy: trackById">
          <ng-container *ngIf="message.from === 'bot'">
            <div class="messener-text-component" *ngIf="message.type === 'text'">
              <app-messenger-text [text]="message.text"></app-messenger-text>
            </div>
            <div class="messener-text-component" *ngIf="message.type === 'datePickerLink'">
              <app-messenger-text [url]="message.url" [isLink]="true"></app-messenger-text>
            </div>
            <div class="messener-card-component" *ngIf="message.type === 'card'">
              <app-messenger-card [cardDetails]="message.card" (buttonClickedEmitter)="buttonClicked($event)"></app-messenger-card>
            </div>
            <div class="messener-carousal-component" *ngIf="message.type === 'carousel'">
              <app-messenger-carousal
                [carouselDetails]="message.carousel"
                (buttonClickedEmitter)="buttonClicked($event)"></app-messenger-carousal>
            </div>
            <div class="messener-video-component" *ngIf="message.type === 'video'">
              <app-messenger-video [url]="message.url"></app-messenger-video>
            </div>
            <div class="messener-audio-component" *ngIf="message.type === 'audio'">
              <app-messenger-audio [url]="message.url"></app-messenger-audio>
            </div>
            <div class="messener-image-component" *ngIf="message.type === 'image'">
              <app-messenger-image [url]="message.url"></app-messenger-image>
            </div>
            <div class="messener-pdf-component" *ngIf="message.type === 'file'">
              <app-messenger-pdf [url]="message.url"></app-messenger-pdf>
            </div>
          </ng-container>
          <ng-container *ngIf="message.from === 'user'">
            <div class="messener-user-chat-component">
              <app-messenger-user-chat [text]="message.text"></app-messenger-user-chat>
            </div>
          </ng-container>
        </ng-container>
        <app-messenger-typing *ngIf="isShowTyping"></app-messenger-typing>
      </ng-container>
      <ng-container *ngIf="messages && messages.length === 0">
        <div class="start-conversation">
          <p>When you send a message, {{ botName }} will see your public info.</p>
        </div>
      </ng-container>
    </div>
    <!-- MAIN CHAT PART | END -->
    <div class="quick-reply" *ngIf="qrObject.isShow">
      <app-messenger-quick-reply [qrDetails]="qrObject" (buttonClickedEmitter)="buttonClicked($event)"></app-messenger-quick-reply>
    </div>
  </div>
  <div class="message-part">
    <div class="t-flex t-items-center t-justify-center">
      <input
        #newMessengerMessage
        type="text"
        class="input"
        placeholder="Type message"
        (keyup.enter)="sendMessageInMessenger(newMessengerMessage.value, newMessengerMessage)" />
      <mat-icon class="material-icons-outlined send" (click)="sendMessageInMessenger(newMessengerMessage.value, newMessengerMessage)"
        >send</mat-icon
      >
    </div>
  </div>
</div>
