import { Component, Input } from "@angular/core";

@Component({
  selector: "app-messenger-audio",
  templateUrl: "./messenger-audio.component.html",
  styleUrls: ["../messenger.common-bot.component.scss"],
})
export class MessengerAudioComponent {
  @Input() url: string;
}
