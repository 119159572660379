import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { UuidGenerator } from "../../../bot-builder/bot-helper-classes/UUID.generator";
import { CHANNEL_NAMES } from "../../../utils/model/channels";
import { PreviewSocketService } from "../../../services/preview-socket.service";
import { CommonService } from "../../../services/common.service";

@Component({
  selector: "app-messenger-preview",
  templateUrl: "./messenger-preview.component.html",
  styleUrls: ["./messenger-preview.component.scss"],
})
export class MessengerPreviewComponent implements OnInit, OnDestroy {
  componentName = "messenger-preview";

  @Input() botId: string;
  @Input() userDetails;
  @Input() botName: string;
  @Input() selectedChannel;
  @Input() previewAuth;

  @Output() changepreviewUserDynamicData = new EventEmitter<any>();

  directLine;
  fromId = "";
  messages = [];
  UUID = new UuidGenerator();
  isConversationStarted = false;
  isShowTyping = false;
  firstMessageSent = false;
  qrObject = {
    isShow: false,
    actions: [],
  };
  accessToken = "";
  private unsubscribe: Subject<void> = new Subject();
  constructor(
    private previewSocketService: PreviewSocketService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getPreviewAccessToken().catch(() => {});
  }

  async getPreviewAccessToken() {
    try {
      const response = await this.previewSocketService.generateAccessTokenForPreviewChannel(this.botId, this.previewAuth.appSecret);
      this.accessToken = response.accessToken;
    } catch (err) {
      this.commonService.errorHandler(err, `${this.componentName}:getPreviewAccessToken`, true);
    }
  }

  setNewFromId() {
    const chars = "0123456789";
    const stringLength = 10;
    let randomstring = "";
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    this.fromId = `preview-${this.selectedChannel}-${randomstring}`;
  }

  initializePreviewSocket(message) {
    this.firstMessageSent = true;
    this.setNewFromId();
    this.previewSocketService.previewSocketCityConnection(this.botId, this.fromId, this.accessToken, this.selectedChannel);
    this.previewSocketService.botOutputEvent();

    let customField: any = {};
    if (this.selectedChannel === CHANNEL_NAMES.FACEBOOK) {
      customField = {
        timezone: new Date().getTimezoneOffset() / -60,
        gender: "male",
        locale: navigator.language,
      };
      if (this.userDetails !== null) {
        customField = {
          name: `${this.userDetails.name.first} ${this.userDetails.name.last}`,
          firstName: this.userDetails.name.first,
          lastName: this.userDetails.name.last,
          profile_pic: this.userDetails.profileImage,
          ...customField,
        };
      }
    } else if (this.selectedChannel === CHANNEL_NAMES.INSTAGRAM) {
      customField = {
        name: `${this.userDetails.name.first} ${this.userDetails.name.last}`,
        profile_pic: this.userDetails.profileImage,
      };
    }
    const sendMessageObject = {
      text: message,
      data: {
        _default: {
          type: "text",
          text: message,
        },
      },
      extra: {
        customFields: customField,
      },
    };
    this.sendMessageToBot(sendMessageObject, true);
  }

  sendMessageToBot(object, isFirst?: boolean) {
    this.previewSocketService.sendUserMessageToBot(object);
    if (isFirst) {
      this.activityMonitor();
    }
  }

  activityMonitor() {
    this.isConversationStarted = true;
    this.previewSocketService
      .botOutputListener()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(activity => {
        this.qrObject.isShow = false;
        activity = JSON.parse(activity);
        this.isShowTyping = false;
        if (activity._default) {
          switch (activity._default.type) {
            case "text":
              if (activity._default.text?.buttons) {
                const textWithButton = {
                  content: {
                    title: activity._default.text.text,
                    buttons: activity._default.text.buttons,
                  },
                };
                this.addCardInBot(textWithButton, "bot");
              } else {
                this.addTextMessageInBot(activity._default.text.text, "bot");
              }
              break;
            case "question":
              this.addTextMessageInBot(activity._default.question.text, "bot");
              break;
            case "mcq":
              this.addTextMessageInBot(activity._default.mcq.text, "bot");
              if (activity._default.mcq.choices?.length > 0) {
                this.qrObject = {
                  isShow: true,
                  actions: activity._default.mcq.choices,
                };
                setTimeout(() => {
                  this.changeQrSate(true);
                }, 100);
              }
              break;
            case "media":
              if (
                activity._default.media.mediaType === "image" ||
                activity._default.media.mediaType === "video" ||
                activity._default.media.mediaType === "audio"
              ) {
                this.addMediaInBot(
                  activity._default.media.link,
                  "bot",
                  activity._default.media.isCaptionAttached ? activity._default.media.caption : "",
                  activity._default.media.mediaType
                );
              } else if (activity._default.media.mediaType === "pdf") {
                this.addDocumentsInBot(activity._default.media.link, "bot", activity._default.media.caption, "pdf");
              }
              break;
            case "card":
              let cardObjcet = {
                content: {
                  title: activity._default.card.title,
                  subTitle: activity._default.card.subTitle,
                  images: [{ url: activity._default.card.image }],
                  buttons: activity._default.card.buttons,
                },
              };
              this.addCardInBot(cardObjcet, "bot");
              break;
            case "carousels":
              this.addCarouselInBot(activity._default.carousels, "bot");
              break;
            case "event":
              if (activity._default.event.type === "apiCall") {
                this.isShowTyping = true;
                this.scrollConversation();
              }
              this.changepreviewUserDynamicData.emit(activity._default.event);
              break;
            // need to handle event here
            case "delay":
              this.isShowTyping = true;
              this.scrollConversation();
              break;
            // if (activity.attachment && activity.attachment.type === "template") {
            //   this.addTextMessageInBot(activity.attachment.payload.text, "bot");
            //   this.addDatePickerLink(activity.attachment.payload.buttons[0].url, "bot");
            // }
          }
        }
      });
  }

  changeQrSate(isShow) {
    if (isShow) {
      const boxHeight = document.getElementsByClassName("quick-reply")[0].clientHeight;
      const con = document.getElementById("conversation");
      const h = "calc(89% - " + boxHeight + "px)";
      con.style.height = h;
      this.scrollConversation();
    } else {
      document.getElementById("conversation").style.height = "89%";
    }
  }

  addDatePickerLink(url: string, from: string) {
    this.messages.push({
      from,
      type: "datePickerLink",
      url,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addCarouselInBot(carousel: any, from: string) {
    this.messages.push({
      from,
      type: "carousel",
      carousel,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addCardInBot(card: any, from: string) {
    this.messages.push({
      from,
      type: "card",
      card,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addTextMessageInBot(text: string, from: string) {
    this.messages.push({
      from,
      type: "text",
      text,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addDocumentsInBot(url: string, from: string, name: string, docType: string) {
    this.messages.push({
      from,
      type: "file",
      docType,
      url,
      name,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addMediaInBot(url: string, from: string, caption: string, type: string) {
    this.messages.push({
      from,
      type,
      url,
      caption,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  scrollConversation() {
    const box = document.getElementById("conversation");
    setTimeout(() => {
      box.scrollTop = box.scrollHeight;
    }, 100);
  }

  sendMessageInMessenger(message: string, elementRef: HTMLInputElement) {
    if (!this.firstMessageSent && message.trim()) {
      this.initializePreviewSocket(message);
      this.addTextMessageInBot(message.trim(), "user");
      elementRef.value = "";
    } else if (message.trim()) {
      const sendMessageObject = {
        text: message,
        data: {
          _default: {
            type: "text",
            text: message,
          },
        },
      };
      this.addTextMessageInBot(message.trim(), "user");
      elementRef.value = "";
      this.sendMessageToBot(sendMessageObject);
    }
  }

  trackById(index: number, message: any): string {
    return message._id;
  }

  buttonClicked(object) {
    this.changeQrSate(false);
    this.qrObject.isShow = false;
    this.addTextMessageInBot(object.title ? object.title : object.value, "user");
    const sendMessageObject = {
      text: object.value,
      data: {
        _default: {
          type: "text",
          text: object.value,
        },
      },
      extra: {
        payload: {
          title: object.title ? object.title : object.value,
        },
      },
    };
    this.sendMessageToBot(sendMessageObject);
  }

  clearChat() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isConversationStarted = false;
    this.directLine = null;
    const box = document.getElementById("conversation");
    if (box) {
      this.messages = [];
    }
    const container = document.getElementById("facebook_bot");
    if (container) {
      container.style.display = "none";
    }
  }

  ngOnDestroy() {
    this.clearChat();
    this.previewSocketService.removeAllListener();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
