import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import { CHANNEL_DISPLAY_NAMES, CHANNEL_NAMES } from "../utils/model/channels";
const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
declare const ExcelJS: any;

@Injectable()
export class ExcelService {
  generateExcel({
    data,
    startDate,
    endDate,
    graphFor,
    channelData,
    graphType,
    extra,
  }: {
    data: any;
    startDate: any;
    endDate: any;
    graphFor: any;
    channelData: any;
    graphType: any;
    extra?: any;
  }) {
    const workbook = new ExcelJS.Workbook();
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const worksheet = workbook.addWorksheet(graphFor);
    worksheet.addRow([`Analytics for: ${graphFor}`]);
    worksheet.addRow([`Start Date: ${startDateMoment.format("DD-MM-YY")}`]);
    worksheet.addRow([`End Date: ${endDateMoment.format("DD-MM-YY")}`]);
    worksheet.addRow([]);
    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A1").font = {
      bold: true,
      size: 14,
    };
    worksheet.getCell("A2").font = {
      bold: true,
      size: 12,
    };
    worksheet.getCell("A3").font = {
      bold: true,
      size: 12,
    };
    switch (graphType) {
      case "newUsers": {
        this.addDataForNewUsers({ data, worksheet });
        break;
      }
      case "totalUsers":
        this.addDataForTotalUsers({ data, worksheet });
        break;
      case "conversationRate":
        this.addDataForConversationRate({ data, worksheet });
        break;
      case "hitRate":
        this.addDataForHitRate({ data, worksheet });
        break;
      case "trendingIntents":
        this.addDataTrendingIntents({ data, worksheet });
        break;
      case "unansweredQuestions":
        this.addDataUnansweredQuestions({ data, worksheet, channelData });
        break;
      case "agentWorkload":
        this.addDataAgentWorkload({ data, worksheet, extra });
        break;
      case "handoffRequest":
        this.addDataHandOfRequest({ data, worksheet, channelData, extra });
        break;
      case "mostCommunicateHours":
        this.addDataForMostCommHours({ data, worksheet, channelData });
        break;
      case "userVSagentInitiated":
        this.addDataUserVsAgent({ data, worksheet, channelData });
        break;
      case "totalHandoffRequest":
        this.addDataTotalHandoffReq({ data, worksheet });
        break;
      case "botUsage":
        this.addDataForBotUsage({ data, worksheet });
        break;
      case "averageInteractionTime":
      case "averageResponseTime":
      case "firstResponseTime":
        this.addDataForHandlingTime({
          type: graphType,
          data,
          worksheet,
          extra,
        });
        break;
      case "agentStatus": {
        this.addDataForAgentStatus({
          data,
          worksheet,
        });
        break;
      }
      case "missedEngagement": {
        this.addDataForDayWiseFormat({ data, worksheet, graphType, name: "Missed Engagement" });
        break;
      }
      case "missedHandoffRequest": {
        this.addDataForDayWiseFormat({ data, worksheet, graphType, name: "Missed Handoff Request" });
        break;
      }
    }
    try {
      workbook.xlsx.writeBuffer().then(buffer => {
        // tslint:disable-next-line:no-shadowed-variable
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(
          data,
          graphFor + "-" + startDateMoment.format("DD-MM-YY") + "-to-" + endDateMoment.format("DD-MM-YY") + EXCEL_EXTENSION
        );
      });
    } catch (e) {
      console.log(e);
    }
  }

  addDataForNewUsers = ({ data, worksheet }) => {
    let channelColumnName = {
      whatsapp: "New users on Whatsapp",
      facebook: "New users on Facebook",
      whatsappBusiness: "New users on Whatsapp Business",
      whatsappNetCore: "New users on Whatsapp NetCore",
      webchat: "New users on Webchat",
      telegram: "New users on Telegram",
      [CHANNEL_NAMES.RCS_BUSINESS_MESSAGES]: "New users on " + CHANNEL_DISPLAY_NAMES.RCS_BUSINESS_MESSAGES,
      [CHANNEL_NAMES.INSTAGRAM]: "New users on " + CHANNEL_DISPLAY_NAMES.INSTAGRAM,
      [CHANNEL_NAMES.WHATSAPP_KALEYRA]: "New users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_KALEYRA,
      [CHANNEL_NAMES.SOCKET_IO]: "New users on " + CHANNEL_DISPLAY_NAMES.SOCKET_IO,
      [CHANNEL_NAMES.WHATSAPP_AIRTEL]: "New users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_AIRTEL,
      [CHANNEL_NAMES.WHATSAPP_ACL]: "New users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_ACL,
      [CHANNEL_NAMES.WHATSAPP_CLOUD]: "New users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_CLOUD,
      [CHANNEL_NAMES.WHATSAPP_INFOBIP]: "New users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_INFOBIP,
      [CHANNEL_NAMES.WHATSAPP_BSP]: "New users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_BSP,
    };
    let channelsTitle = [];
    if (data.platforms) {
      data.platforms.forEach(platform => {
        channelsTitle.push(channelColumnName[platform]);
      });
    }
    let columns = ["Sr. no.", "Date", "Total new users", ...channelsTitle];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 40;
    worksheet.getColumn(7).width = 40;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.newUsers.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = moment(row.formattedDate).format("DD-MM-YY") || "-";
      getRowInsert.getCell(3).value = row.totalUsersByDay || "-";
      let columnNo = 4;
      data.platforms.forEach(platform => {
        getRowInsert.getCell(columnNo).value = row.totalByChannels[platform];
        columnNo++;
      });
      newRowNumber++;
    });
  };

  addDataForTotalUsers = ({ data, worksheet }) => {
    let channelColumnName = {
      whatsapp: "Users on Whatsapp",
      facebook: "Users on Facebook",
      whatsappBusiness: "Users on Whatsapp Business",
      whatsappNetCore: "Users on Whatsapp NetCore",
      webchat: "Users on Webchat",
      telegram: "Users on Telegram",
      [CHANNEL_NAMES.RCS_BUSINESS_MESSAGES]: "Users on " + CHANNEL_DISPLAY_NAMES.RCS_BUSINESS_MESSAGES,
      [CHANNEL_NAMES.INSTAGRAM]: "Users on " + CHANNEL_DISPLAY_NAMES.INSTAGRAM,
      [CHANNEL_NAMES.SOCKET_IO]: "Users on " + CHANNEL_DISPLAY_NAMES.SOCKET_IO,
      [CHANNEL_NAMES.WHATSAPP_KALEYRA]: "Users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_KALEYRA,
      [CHANNEL_NAMES.WHATSAPP_AIRTEL]: "Users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_AIRTEL,
      [CHANNEL_NAMES.WHATSAPP_ACL]: "Users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_ACL,
      [CHANNEL_NAMES.WHATSAPP_CLOUD]: "Users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_CLOUD,
      [CHANNEL_NAMES.WHATSAPP_INFOBIP]: "Users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_INFOBIP,
      [CHANNEL_NAMES.WHATSAPP_BSP]: "Users on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_BSP,
    };
    let channelsTitle = [];
    if (data.platforms) {
      data.platforms.forEach(platform => {
        channelsTitle.push(channelColumnName[platform]);
      });
    }
    let columns = ["Sr. no.", "Date", "Total users", ...channelsTitle];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 40;
    worksheet.getColumn(7).width = 40;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.totalUsers.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = moment(row.formattedDate).format("DD-MM-YY") || "-";
      getRowInsert.getCell(3).value = row.totalUsersByDay || "-";
      let columnNo = 4;
      data.platforms.forEach(platform => {
        getRowInsert.getCell(columnNo).value = row.totalByChannels[platform];
        columnNo++;
      });
      newRowNumber++;
    });
  };

  addDataForConversationRate = ({ data, worksheet }) => {
    let tagTitles = [];
    if (data.analytics.conversationRate.selectedUsecaseMapping) {
      tagTitles.push(data.analytics.conversationRate.selectedUsecaseMapping.startingTag.tag);
      tagTitles.push(data.analytics.conversationRate.selectedUsecaseMapping.endingTag.tag);
    }
    let columns = ["Sr. no.", "Date", "Usecase", ...tagTitles, "Usecase success rate", "Value created"];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 40;
    worksheet.getColumn(7).width = 40;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.conversationRate.conversationRate.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = moment(row.formattedDate).format("DD-MM-YY") || "-";
      getRowInsert.getCell(3).value = data.analytics.conversationRate.selectedUsecaseMapping.name || "-";
      getRowInsert.getCell(4).value = row.startedTags;
      getRowInsert.getCell(5).value = row.completedTags;
      getRowInsert.getCell(6).value = row.percentage;
      getRowInsert.getCell(7).value = row.totalValue;

      newRowNumber++;
    });
  };

  addDataForBotUsage = ({ data, worksheet }) => {
    const botUsageMapping = {
      users: "Total Users",
      moMessage: "Mobile Originated",
      mtMessage: "Mobile Terminated",
      marketingMessages: "Marketing Conversations",
      utilityMessages: "Utility Conversations",
      authenticationMessages: "Authentication Conversations",
      authenticationILDOMessages: "Authentication ILDO Conversations",
      serviceMessages: "Service Conversations",
      marketingMessagesInternational: "International Marketing Conversations",
      utilityMessagesInternational: "International Utility Conversations",
      authenticationMessagesInternational: "International Authentication Conversations",
      serviceMessagesInternational: "International Service Conversations",
    };
    const columns = ["Sr. no."];
    Object.keys(botUsageMapping).forEach(botUsageObject => {
      if (data.analytics.botUsage.hasOwnProperty(botUsageObject)) {
        columns.push(botUsageMapping[botUsageObject]);
      }
    });
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 50;
    worksheet.getColumn(6).width = 50;
    worksheet.getColumn(7).width = 50;
    worksheet.getColumn(8).width = 50;
    worksheet.getColumn(9).width = 50;
    worksheet.getColumn(10).width = 50;
    worksheet.getColumn(11).width = 50;
    worksheet.getColumn(12).width = 50;
    worksheet.getColumn(13).width = 50;

    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    getRowInsert = worksheet.getRow(newRowNumber);
    getRowInsert.getCell(1).value = 1;
    Object.keys(data.analytics.botUsage).forEach(botUsageObject => {
      const botUsageIndex = Object.keys(botUsageMapping).findIndex(botUsage => botUsage === botUsageObject);
      if (botUsageIndex !== -1) {
        getRowInsert.getCell(botUsageIndex + 2).value = data.analytics.botUsage[botUsageObject] || "-";
      }
    });
    newRowNumber++;
  };

  addDataForHitRate = ({ data, worksheet }) => {
    let columns = ["Sr. no.", "Date", "No. of hits", "No. of times intent detected", "Hit ratio"];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 40;
    worksheet.getColumn(7).width = 40;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.hitRate.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = row.formattedDate || "-";
      getRowInsert.getCell(3).value = row.totalHits;
      getRowInsert.getCell(4).value = row.totalIntentsDetected;
      getRowInsert.getCell(5).value = row.percentage;

      newRowNumber++;
    });
  };

  addDataTrendingIntents = ({ data, worksheet }) => {
    let columns = ["Sr. no.", "Intent Name", "Intent Count"];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.trendingIntents.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = row.intentName;
      getRowInsert.getCell(3).value = row.numberOfHits;
      newRowNumber++;
    });
  };

  addDataUnansweredQuestions = ({ data, worksheet, channelData }) => {
    let columns = ["Sr. no.", "Date", "Channel", "Training phrase"];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 50;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    let dateStr = "";
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.unansweredQuestions.forEach((row, rowIndex) => {
      let channels = "";
      row.channel.forEach(ch => {
        channels = ch + ",";
      });
      dateStr = moment(row.createdAt).format("YYYY-MM-DD");
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = dateStr || "-";
      getRowInsert.getCell(3).value = channels || "-";
      getRowInsert.getCell(4).value = row.query;
      newRowNumber++;
    });
  };

  addDataAgentWorkload = ({ data, worksheet, extra }) => {
    let columns = [
      "Sr. no.",
      "Agent",
      "Channel",
      "Requests accepted",
      "Requests resolved",
      "Requests Transferred",
      "Ratings Achieved (out of 5)",
    ];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    if (extra.agentEmail === "All Agents") {
      data.analytics.agentWorkload?.agentsData?.forEach((row, rowIndex) => {
        getRowInsert = worksheet.getRow(newRowNumber);
        getRowInsert.getCell(1).value = rowIndex + 1 || "-";
        getRowInsert.getCell(2).value = row.email || "-";
        getRowInsert.getCell(3).value = extra.channel || "-";
        getRowInsert.getCell(4).value = row.numberOfRequestAccepted || 0;
        getRowInsert.getCell(5).value = row.numberOfRequestResolved || 0;
        getRowInsert.getCell(6).value = row.numberOfRequestTransfer || 0;
        getRowInsert.getCell(7).value = row.stars || 0;
        newRowNumber++;
      });
    } else {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = 1 || "-";
      getRowInsert.getCell(2).value = extra.agentEmail || "-";
      getRowInsert.getCell(3).value = extra.channel || "-";
      getRowInsert.getCell(4).value = data.analytics.agentWorkload.numberOfRequestAccepted;
      getRowInsert.getCell(5).value = data.analytics.agentWorkload.numberOfRequestResolved;
      getRowInsert.getCell(6).value = data.analytics.agentWorkload.numberOfRequestTransfer;
      getRowInsert.getCell(7).value = data.analytics.agentWorkload.averageStars;
    }
  };

  addDataHandOfRequest = ({ data, worksheet, channelData, extra }) => {
    let columns = [
      "Sr. no.",
      "Channel",
      "Requests in working hours",
      "Requests in non-working hours",
      "Requests on holidays",
      "Requests when all agent were offline",
    ];
    if (extra.isMaxActiveChat) {
      columns.push("Requests when all agents were busy");
    }
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:G1");
    worksheet.mergeCells("A2:G2");
    worksheet.mergeCells("A3:G3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 40;
    if (extra.isMaxActiveChat) {
      worksheet.getColumn(7).width = 40;
    }
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    getRowInsert = worksheet.getRow(newRowNumber);
    getRowInsert.getCell(1).value = 1 || "-";
    getRowInsert.getCell(2).value = channelData || "-";
    getRowInsert.getCell(3).value = data.analytics.handoffRequest.inWorkingHours;
    getRowInsert.getCell(4).value = data.analytics.handoffRequest.outOfWokringHours;
    getRowInsert.getCell(5).value = data.analytics.handoffRequest.onHoliday;
    getRowInsert.getCell(6).value = data.analytics.handoffRequest.allAgentOffline;
    if (extra.isMaxActiveChat) {
      getRowInsert.getCell(7).value = data.analytics.handoffRequest.allAgentBusy;
    }
  };

  addDataForMostCommHours = ({ data, worksheet, channelData }) => {
    // tslint:disable-next-line:max-line-length
    worksheet.addRow([
      "Sr. no",
      "Channel",
      "Day",
      "Users interacted between 00:00 - 01:00",
      "Users interacted between 01:00 - 02:00",
      "Users interacted between 02:00 - 03:00",
      "Users interacted between 03:00 - 04:00",
      "Users interacted between 04:00 - 05:00",
      "Users interacted between 05:00 - 06:00",
      "Users interacted between 06:00 - 07:00",
      "Users interacted between 07:00 - 08:00",
      "Users interacted between 08:00 - 09:00",
      "Users interacted between 09:00 - 10:00",
      "Users interacted between 10:00 - 11:00",
      "Users interacted between 11:00 - 12:00",
      "Users interacted between 12:00 - 13:00",
      "Users interacted between 13:00 - 14:00",
      "Users interacted between 14:00 - 15:00",
      "Users interacted between 15:00 - 16:00",
      "Users interacted between 16:00 - 17:00",
      "Users interacted between 17:00 - 18:00",
      "Users interacted between 18:00 - 19:00",
      "Users interacted between 19:00 - 20:00",
      "Users interacted between 20:00 - 21:00",
      "Users interacted between 21:00 - 22:00",
      "Users interacted between 22:00 - 23:00",
      "Users interacted between 23:00 - 00:00",
    ]);
    worksheet.mergeCells("A1:I1");
    worksheet.mergeCells("A2:I2");
    worksheet.mergeCells("A3:I3");

    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 20;
    worksheet.getColumn(18).width = 20;
    worksheet.getColumn(19).width = 20;
    worksheet.getColumn(20).width = 20;
    worksheet.getColumn(21).width = 20;
    worksheet.getColumn(22).width = 20;
    worksheet.getColumn(23).width = 20;
    worksheet.getColumn(24).width = 20;
    worksheet.getColumn(25).width = 20;
    worksheet.getColumn(26).width = 20;
    worksheet.getColumn(27).width = 20;
    worksheet.getColumn(28).width = 20;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    let finalData = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    };
    data.analytics.mostCommunicateHours.forEach(hours => {
      hours.forEach(day => {
        switch (day.day) {
          case "0":
            finalData["Sunday"].push(day.count);
            break;
          case "1":
            finalData["Monday"].push(day.count);
            break;
          case "2":
            finalData["Tuesday"].push(day.count);
            break;
          case "3":
            finalData["Wednesday"].push(day.count);
            break;
          case "4":
            finalData["Thursday"].push(day.count);
            break;
          case "5":
            finalData["Friday"].push(day.count);
            break;
          case "6":
            finalData["Saturday"].push(day.count);
            break;
        }
      });
    });
    let rowIndex = 1;
    for (let day of Object.keys(finalData)) {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex;
      getRowInsert.getCell(2).value = channelData;
      getRowInsert.getCell(3).value = day;
      let cellcount = 4;
      finalData[day].forEach(count => {
        getRowInsert.getCell(cellcount).value = count;
        cellcount++;
      });
      rowIndex++;
      newRowNumber++;
    }
  };

  addDataUserVsAgent = ({ data, worksheet, channelData }) => {
    let columns = [
      "Sr. no.",
      "Channel",
      "Name of agent",
      "Email of agent",
      "Total handoff ",
      "Handoff initiated by user",
      "Handoff initiated by agent",
    ];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.userVSagentInitiated.agentVsUserInitiated.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = channelData || "-";
      getRowInsert.getCell(3).value = row.agentName;
      getRowInsert.getCell(4).value = row.agentEmail;
      getRowInsert.getCell(5).value = row.total;
      getRowInsert.getCell(6).value = row.initiatedByUser;
      getRowInsert.getCell(7).value = row.initiatedByAgent;

      newRowNumber++;
    });
  };

  addDataTotalHandoffReq = ({ data, worksheet }) => {
    let channelColumnName = {
      whatsapp: "Requests on Whatsapp",
      facebook: "Requests on Facebook",
      whatsappBusiness: "Requests on Whatsapp Business",
      whatsappNetCore: "Requests on Whatsapp NetCore",
      webchat: "Requests on Webchat",
      telegram: "Requests on Telegram",
      [CHANNEL_NAMES.RCS_BUSINESS_MESSAGES]: "Requests on " + CHANNEL_DISPLAY_NAMES.RCS_BUSINESS_MESSAGES,
      [CHANNEL_NAMES.INSTAGRAM]: "Requests on " + CHANNEL_DISPLAY_NAMES.INSTAGRAM,
      [CHANNEL_NAMES.SOCKET_IO]: "Requests on " + CHANNEL_DISPLAY_NAMES.SOCKET_IO,
      [CHANNEL_NAMES.WHATSAPP_KALEYRA]: "Requests on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_KALEYRA,
      [CHANNEL_NAMES.WHATSAPP_AIRTEL]: "Requests on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_AIRTEL,
      [CHANNEL_NAMES.WHATSAPP_ACL]: "Requests on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_ACL,
      [CHANNEL_NAMES.WHATSAPP_CLOUD]: "Requests on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_CLOUD,
      [CHANNEL_NAMES.WHATSAPP_INFOBIP]: "Requests on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_INFOBIP,
      [CHANNEL_NAMES.WHATSAPP_BSP]: "Requests on " + CHANNEL_DISPLAY_NAMES.WHATSAPP_BSP,
    };
    let channelsTitle = [];
    if (data.platforms) {
      data.platforms.forEach(platform => {
        channelsTitle.push(channelColumnName[platform]);
      });
    }
    let columns = ["Sr. no.", "Date", "Total  handoff requests", ...channelsTitle];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 40;
    worksheet.getColumn(7).width = 40;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.totalHandoffRequest.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = moment(row.formattedDate).format("DD-MM-YY") || "-";
      getRowInsert.getCell(3).value = row.totalUsersByDay;
      let columnNo = 4;
      data.platforms.forEach(platform => {
        getRowInsert.getCell(columnNo).value = row.totalByChannels[platform];
        columnNo++;
      });
      newRowNumber++;
    });
  };

  addDataForHandlingTime = ({ type, data, worksheet, extra }) => {
    const columns = [
      "Sr. no.",
      "Agent",
      "Channel",
      type === "averageResponseTime"
        ? "Average response time"
        : type === "averageInteractionTime"
          ? "Average interaction Time"
          : "Average first response Time",
      type === "averageResponseTime" || type === "firstResponseTime" ? "Best day to chat" : "Best day to interact",
      "Monday (in minutes)",
      "Tuesday (in minutes)",
      "Wednesday (in minutes)",
      "Thursday (in minutes)",
      "Friday (in minutes)",
      "Saturday (in minutes)",
      "Sunday (in minutes)",
    ];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:L1");
    worksheet.mergeCells("A2:L2");
    worksheet.mergeCells("A3:L3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 30;
    worksheet.getColumn(10).width = 30;
    worksheet.getColumn(11).width = 30;
    worksheet.getColumn(12).width = 30;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    getRowInsert = worksheet.getRow(newRowNumber);
    getRowInsert.getCell(1).value = 1 || "-";
    getRowInsert.getCell(2).value = extra.agentEmail || "-";
    getRowInsert.getCell(3).value = extra.channel || "-";
    getRowInsert.getCell(4).value = data.analytics[type].averageValues.averageSeconds;
    getRowInsert.getCell(5).value = data.analytics[type].averageValues.dayOfWeek || "-";
    getRowInsert.getCell(6).value = 0;
    getRowInsert.getCell(7).value = 0;
    getRowInsert.getCell(8).value = 0;
    getRowInsert.getCell(9).value = 0;
    getRowInsert.getCell(10).value = 0;
    getRowInsert.getCell(11).value = 0;
    getRowInsert.getCell(12).value = 0;
    data.analytics[type].graphData.forEach(graph => {
      switch (parseInt(graph.dayOfWeek.toString(), 10)) {
        case 1: {
          getRowInsert.getCell(12).value = (graph.averageSeconds / 60).toFixed(1);
          break;
        }
        case 2: {
          getRowInsert.getCell(6).value = (graph.averageSeconds / 60).toFixed(1);
          break;
        }
        case 3: {
          getRowInsert.getCell(7).value = (graph.averageSeconds / 60).toFixed(1);
          break;
        }
        case 4: {
          getRowInsert.getCell(8).value = (graph.averageSeconds / 60).toFixed(1);
          break;
        }
        case 5: {
          getRowInsert.getCell(9).value = (graph.averageSeconds / 60).toFixed(1);
          break;
        }
        case 6: {
          getRowInsert.getCell(10).value = (graph.averageSeconds / 60).toFixed(1);
          break;
        }
        case 7: {
          getRowInsert.getCell(11).value = (graph.averageSeconds / 60).toFixed(1);
          break;
        }
      }
    });
    getRowInsert.eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "left" };
    });
  };

  addDataForAgentStatus = ({ data, worksheet }) => {
    const columns = ["Sr. no.", "Name ", "Status", "Email", "Role", "Queue", "Talking", "Department"];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:H1");
    worksheet.mergeCells("A2:H2");
    worksheet.mergeCells("A3:H3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 30;
    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics.agentStatus.forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = row.name || "-";
      getRowInsert.getCell(3).value = row.status || "-";
      getRowInsert.getCell(4).value = row.email || "-";
      getRowInsert.getCell(5).value = row.role || "-";
      getRowInsert.getCell(6).value = row.queue;
      getRowInsert.getCell(7).value = row.talking;
      getRowInsert.getCell(8).value = row.departmentName || "-";
      getRowInsert.eachCell(cell => {
        cell.alignment = { vertical: "middle", horizontal: "left" };
      });
      newRowNumber++;
    });
  };

  addDataForDayWiseFormat = ({ data, worksheet, name, graphType }) => {
    let channelColumnName = {
      whatsapp: `${name} on Whatsapp`,
      facebook: `${name} on Facebook`,
      whatsappBusiness: `${name}on Whatsapp Business`,
      whatsappNetCore: `${name}on Whatsapp NetCore`,
      webchat: `${name}on Webchat`,
      telegram: `${name}on Telegram`,
      [CHANNEL_NAMES.RCS_BUSINESS_MESSAGES]: `${name}on ${CHANNEL_DISPLAY_NAMES.RCS_BUSINESS_MESSAGES}`,
      [CHANNEL_NAMES.INSTAGRAM]: `${name}on ${CHANNEL_DISPLAY_NAMES.INSTAGRAM}`,
      [CHANNEL_NAMES.SOCKET_IO]: `${name}on ${CHANNEL_DISPLAY_NAMES.SOCKET_IO}`,
      [CHANNEL_NAMES.WHATSAPP_KALEYRA]: `${name}on ${CHANNEL_DISPLAY_NAMES.WHATSAPP_KALEYRA}`,
      [CHANNEL_NAMES.WHATSAPP_AIRTEL]: `${name}on ${CHANNEL_DISPLAY_NAMES.WHATSAPP_AIRTEL}`,
      [CHANNEL_NAMES.WHATSAPP_ACL]: `${name}on ${CHANNEL_DISPLAY_NAMES.WHATSAPP_ACL}`,
      [CHANNEL_NAMES.WHATSAPP_CLOUD]: `${name}on ${CHANNEL_DISPLAY_NAMES.WHATSAPP_CLOUD}`,
      [CHANNEL_NAMES.WHATSAPP_INFOBIP]: `${name}on ${CHANNEL_DISPLAY_NAMES.WHATSAPP_INFOBIP}`,
      [CHANNEL_NAMES.WHATSAPP_BSP]: `${name}on ${CHANNEL_DISPLAY_NAMES.WHATSAPP_BSP}`,
    };
    let channelsTitle = [];
    if (data.platforms) {
      data.platforms.forEach(platform => {
        channelsTitle.push(channelColumnName[platform]);
      });
    }
    let columns = ["Sr. no.", "Date", `Total ${name}`, ...channelsTitle];
    worksheet.addRow(columns);
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 40;
    let columnWidthCount = 4;

    channelsTitle.forEach(res => {
      worksheet.getColumn(columnWidthCount).width = 40;
      columnWidthCount += 1;
    });

    worksheet.getRow(5).eachCell(cell => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "FFFFFFFF" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF666666" },
      };
    });
    const lastRow = worksheet.lastRow;
    let newRowNumber = lastRow.number + 1;
    let getRowInsert;
    data.analytics[graphType].forEach((row, rowIndex) => {
      getRowInsert = worksheet.getRow(newRowNumber);
      getRowInsert.getCell(1).value = rowIndex + 1 || "-";
      getRowInsert.getCell(2).value = moment(row.formattedDate).format("DD-MM-YY") || "-";
      getRowInsert.getCell(3).value = row.totalUsersByDay || "-";
      let columnNo = 4;
      data.platforms.forEach(platform => {
        getRowInsert.getCell(columnNo).value = row.totalByChannels[platform];
        columnNo++;
      });
      newRowNumber++;
    });
  };
}
