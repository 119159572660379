import { Component, Input } from "@angular/core";

@Component({
  selector: "app-messenger-text",
  templateUrl: "./messenger-text.component.html",
  styleUrls: ["../messenger.common-bot.component.scss"],
})
export class MessengerTextComponent {
  @Input() text: string;
  @Input() isLink: string;
  @Input() url: string;
}
