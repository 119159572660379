import { Component, Input } from "@angular/core";

@Component({
  selector: "app-messenger-user-chat",
  templateUrl: "./messenger-user-chat.component.html",
  styleUrls: ["./messenger-user-chat.component.scss"],
})
export class MessengerUserChatComponent {
  @Input() text: string;
}
