<app-messenger-icon></app-messenger-icon>
<section class="messenger-pdf">
  <div class="messenger-pdf-inner">
    <a [href]="url" target="_blank" download="" class="btn-bot">
      <div class="pdf t-flex">
        <span class="material-icons"> picture_as_pdf </span>
        <p>File</p>
      </div></a
    >
  </div>
</section>
