export enum USER_ROLE {
  SA = "SA",
  ORGANIZATION_MANAGER = "Organization Manager",
  ORGANIZATION_ADMIN = "Organization Admin",
  PARTNER = "Partner",
  BOT_ADMIN = "Bot Admin",
  CST = "CST",
  BOT_DEVELOPER = "Bot Developer",
  BOT_REVIEWER = "Bot Reviewer",
  BOT_SUPERVISOR = "Bot Supervisor",
}
