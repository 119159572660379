import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";

import { BotService } from "../../services/bot.service";
import { SettingService } from "../../services/setting.service";
import { AuthService } from "./../../services/auth.service";
import { NotifyService } from "./../../services/noyify.service";
import { CHANNEL_DISPLAY_NAMES, CHANNEL_NAMES, ChannelsData } from "./../../utils/model/channels";
import { Urls } from "./../../utils/urls";
import * as moment from "moment";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "app-bot-preview",
  templateUrl: "./bot-preview.component.html",
  styleUrls: ["./bot-preview.component.scss"],
})
export class BotPreviewComponent implements OnInit {
  componentName = "bot-preview";

  @ViewChild("linkExpired", { static: true })
  linkExpiredTemplateRef: TemplateRef<any>;
  @ViewChild("addPassword", { static: true })
  addPasswordTemplateRef: TemplateRef<any>;

  orgIcon: string;
  botId: string;
  botIcon: string;
  botName: string;
  botChannels = [];
  selectedChannel: string;
  channelName: string;

  isPanelUser = false;
  isAuthorised = false;
  userDetails = null;

  isLinkSidebarOpen = false;
  isLinkValid = true;
  isLinkExpired = false;
  linkId: string;
  generatedLink: string;
  linkPassword: string;
  linkExpireTime: string;
  primaryColor = ChannelsData.webchatColor;
  primaryBackground = ChannelsData.webchatBackground;
  dialogRef;
  password;
  CHANNEL_DISPLAY_NAMES = CHANNEL_DISPLAY_NAMES;
  previewAuth;
  webChatAuth;
  previewUserDynamicData = {
    customFields: [],
    previousFlow: null,
    currentFlow: null,
    isRunningHandoff: "No",
    lastIntent: null,
    lastEntities: [],
    tags: [],
  };

  constructor(
    readonly activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _authService: AuthService,
    private _botService: BotService,
    private _notificationService: NotifyService,
    private _settingService: SettingService,
    private router: Router,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.selectedChannel = this.activeRoute.snapshot.queryParams.channel ? this.activeRoute.snapshot.queryParams.channel : null;
    if (location.href.includes("dashboard")) {
      this._botService.isBotPreviewOpened.next(true);
      this.isPanelUser = true;
      this.orgIcon = this._botService.getPanelLogo();
      this.botId = this._botService.getBot();
      this.botIcon = localStorage.getItem("BotIcon");
      this.botName = this._botService.getName();
      this.userDetails = this._authService.getUser();
      this.previewAuth = this._settingService.preview;
      this.webChatAuth = this._settingService.webChat;
      this.getExistingLink();
      this.setChannels(this._settingService.EnableChannels);
    } else {
      this.isPanelUser = false;
      this.authoriseToken(this.activeRoute.snapshot.params.id);
    }
    const _this = this;
    window.addEventListener("message", function (event) {
      if (event.data.type === "previewUserDynamicData") {
        _this.changePreviewUserDynamicData(event.data.previewUserDynamicData);
      }
    });
  }

  setChannels(channels) {
    if (channels.includes(ChannelsData.webchat)) {
      this.botChannels.push({
        name: "Web Widget",
        value: ChannelsData.webchat,
        image: "wechat.svg",
        isActive: false,
        primaryColor: ChannelsData.webchatColor,
        primaryBackground: ChannelsData.webchatBackground,
      });
    }
    if (channels.includes(ChannelsData.whatsapp)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: ChannelsData.whatsapp,
        image: "whatsapp.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }
    if (channels.includes(ChannelsData.whatsappBusiness)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: ChannelsData.whatsappBusiness,
        image: "whatsappBusiness.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }
    if (channels.includes(ChannelsData.whatsappNetCore)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: ChannelsData.whatsappNetCore,
        image: "whatsappNetCore.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }
    if (channels.includes(ChannelsData.facebook)) {
      this.botChannels.push({
        name: "Messenger",
        value: ChannelsData.facebook,
        image: "facebook.svg",
        isActive: false,
        primaryColor: ChannelsData.facebookColor,
        primaryBackground: ChannelsData.facebookBackground,
      });
    }
    if (channels.includes(CHANNEL_NAMES.INSTAGRAM)) {
      this.botChannels.push({
        name: "Instagram",
        value: CHANNEL_NAMES.INSTAGRAM,
        image: "instagram.svg",
        isActive: false,
        primaryColor: ChannelsData.facebookColor,
        primaryBackground: ChannelsData.facebookBackground,
      });
    }

    if (channels.includes(CHANNEL_NAMES.WHATSAPP_KALEYRA)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: CHANNEL_NAMES.WHATSAPP_KALEYRA,
        image: "whatsappNetCore.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }

    if (channels.includes(CHANNEL_NAMES.WHATSAPP_AIRTEL)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: CHANNEL_NAMES.WHATSAPP_AIRTEL,
        image: "whatsappNetCore.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }

    if (channels.includes(CHANNEL_NAMES.WHATSAPP_ACL)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: CHANNEL_NAMES.WHATSAPP_ACL,
        image: "whatsappNetCore.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }

    if (channels.includes(CHANNEL_NAMES.WHATSAPP_CLOUD)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: CHANNEL_NAMES.WHATSAPP_CLOUD,
        image: "whatsappNetCore.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }

    if (channels.includes(CHANNEL_NAMES.WHATSAPP_INFOBIP)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: CHANNEL_NAMES.WHATSAPP_INFOBIP,
        image: "whatsappNetCore.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }

    if (channels.includes(CHANNEL_NAMES.WHATSAPP_BSP)) {
      this.botChannels.push({
        name: "WhatsApp",
        value: CHANNEL_NAMES.WHATSAPP_BSP,
        image: "whatsappNetCore.svg",
        isActive: false,
        primaryColor: ChannelsData.whatsappColor,
        primaryBackground: ChannelsData.whatsappBackground,
      });
    }

    if (this.selectedChannel && channels.includes(this.selectedChannel)) {
      const index = this.botChannels.findIndex(channel => channel.value === this.selectedChannel);
      if (index !== -1) {
        this.setCurrentChannel(index, true);
      } else if (this.botChannels.length) {
        this.setCurrentChannel(0, true);
      }
    } else if (this.botChannels.length) {
      this.setCurrentChannel(0, true);
    }
  }

  setCurrentChannel(channelIndex: number, isFirstTime?: boolean) {
    if (!isFirstTime) {
      this.botChannels.forEach(ele => {
        ele.isActive = false;
      });
      this.previewUserDynamicData.customFields = [];
      this.previewUserDynamicData.previousFlow = null;
      this.previewUserDynamicData.currentFlow = null;
      this.previewUserDynamicData.isRunningHandoff = "No";
      this.previewUserDynamicData.lastIntent = null;
      this.previewUserDynamicData.lastEntities = [];
      this.previewUserDynamicData.tags = [];
      this.channelName = null;
    }
    this.botChannels[channelIndex].isActive = true;
    this.selectedChannel = this.botChannels[channelIndex].value;

    setTimeout(() => {
      this.channelName = this.botChannels[channelIndex].name;
    }, 10);

    this.primaryColor = this.botChannels[channelIndex].primaryColor;
    this.primaryBackground = this.botChannels[channelIndex].primaryBackground;
    this.checkWidget(this.selectedChannel);
    this.router.navigate([], {
      queryParams: {
        channel: this.selectedChannel,
      },
    });
  }

  checkWidget(channel) {
    if (channel === "webchat") {
      window["isPreview"] = true;
      window["customTokens"] = { botId: this.botId, appSecret: this.previewAuth.appSecret };
      let script = document.createElement("script");
      // script.src = Urls.webWidgetUrl + "/webchat/v2/webChat.js";
      script.src = Urls.webWidgetUrl + "/webChat.js";
      setTimeout(() => {
        document.getElementById("bot_container").appendChild(script);
      }, 100);
    }
  }

  changePreviewUserDynamicData(eventData) {
    switch (eventData.type) {
      case "setCustomField":
        for (let [key, value] of Object.entries(eventData.customFields)) {
          const date = moment(value);
          if (date.toISOString() === value) {
            value = date.format("MMM DD, YYYY, h:mm:ss a");
          }
          const customFieldIndex = this.previewUserDynamicData.customFields.findIndex(field => field.name === key);
          customFieldIndex === -1
            ? this.previewUserDynamicData.customFields.push({
                name: key,
                value,
              })
            : (this.previewUserDynamicData.customFields[customFieldIndex].value = value);
        }
        break;
      case "removeCustomField":
        for (var cfIndex = 0; cfIndex < this.previewUserDynamicData.customFields.length; cfIndex++) {
          const field = this.previewUserDynamicData.customFields[cfIndex];
          eventData.fields.forEach(fieldName => {
            if (field.name === fieldName) {
              this.previewUserDynamicData.customFields.splice(cfIndex, 1);
              cfIndex--;
              return;
            }
          });
        }
        break;
      case "executeFlow":
        if (this.previewUserDynamicData.currentFlow !== null) {
          this.previewUserDynamicData.previousFlow = this.previewUserDynamicData.currentFlow;
        }
        this.previewUserDynamicData.currentFlow = eventData.flowName;
        break;
      case "handoffStatus":
        this.previewUserDynamicData.isRunningHandoff = eventData.value === "On" ? "Yes" : "No";
        break;
      case "nlpData":
        this.previewUserDynamicData.lastIntent = eventData.value.lastIntent;
        this.previewUserDynamicData.lastEntities = eventData.value.entites ? eventData.value.entites : [];
        break;
      case "setTag":
        if (!this.previewUserDynamicData.tags.includes(eventData.tag)) {
          this.previewUserDynamicData.tags.push(eventData.tag);
        }
        break;
      case "removeTag":
        const tagIndex = this.previewUserDynamicData.tags.findIndex(tag => tag === eventData.tag);
        if (tagIndex !== -1) {
          this.previewUserDynamicData.tags.splice(tagIndex, 1);
        }
        break;
    }
  }

  async getExistingLink() {
    try {
      const linkResponse = await this._botService.getLink();
      this.linkId = linkResponse["data"]._id;
      this.generatedLink = linkResponse["data"].link;
      this.linkExpireTime = linkResponse["data"].expiredAt;
      this.isLinkExpired = linkResponse["data"].isLinkExpired;
    } catch (err) {
      this.commonService.errorHandler(err, `${this.componentName}:getExistingLink`, false);
    }
  }

  async authoriseToken(token) {
    try {
      const response = await this._botService.authoriseToken(token);
      this.previewAuth = response["data"].previewAuth;
      this.webChatAuth = response["data"].webChatAuth;
      this.isLinkValid = true;
      this.isLinkExpired = false;
      this.linkId = response["data"].linkId;
      this.dialogRef = this.dialog.open(this.addPasswordTemplateRef, {
        width: "770px",
        panelClass: "preview-modal",
      });
    } catch (error) {
      this.commonService.errorHandler(error, `${this.componentName}:authoriseToken`, false);
      this.isLinkValid = false;
      this.isLinkExpired = error.error?.error_code === "BOT_PREVIEW_LINK_EXPIRED";
      this.dialogRef = this.dialog.open(this.linkExpiredTemplateRef, {
        width: "770px",
        panelClass: "preview-modal",
      });
    }
  }

  async authoriseBotPreview() {
    if (!this.password) {
      this._notificationService.sendNotification("error", "Please enter the password");
      return;
    }
    const reqBodyObject = {
      linkId: this.linkId,
      password: this.password,
    };
    try {
      const response = await this._botService.authoriseBotPreview(reqBodyObject);
      if (response["data"].isVerified) {
        this.isAuthorised = true;
        this.orgIcon = response["data"].orgLogo;
        this.botId = response["data"].botId;
        this.botIcon = response["data"].botImage;
        this.botName = response["data"].name;
        this.setChannels(response["data"].platforms);
        this.dialogRef.close();
      } else {
        this._notificationService.sendNotification("error", "Incorrect password");
      }
    } catch (error) {
      this.commonService.errorHandler(error, `${this.componentName}:authoriseBotPreview`, true);
      this.isAuthorised = false;
    }
  }

  async createLink() {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!this.linkPassword.match(passwordRegex)) {
      this._notificationService.sendNotification("info", "Enter strong password");
      return;
    }
    const reqBody = {
      password: this.linkPassword,
    };
    try {
      const linkResponse = await this._botService.createLink(reqBody);
      this.linkId = linkResponse["data"]._id;
      this.generatedLink = linkResponse["data"].link;
      this.linkExpireTime = linkResponse["data"].expiredAt;
      this.isLinkExpired = new Date().toISOString() > this.linkExpireTime;
    } catch (error) {
      this.commonService.errorHandler(error, `${this.componentName}:createLink`, true);
    }
  }

  copyLink() {
    const textElement = document.createElement("textarea");
    textElement.value = this.generatedLink;
    document.body.appendChild(textElement);
    textElement.select();
    document.execCommand("copy");
    document.body.removeChild(textElement);
    this._notificationService.sendNotification("success", "Link copied!");
  }

  async deleteLink() {
    try {
      await this._botService.deleteLink(this.linkId);
      this.linkId = null;
      this.generatedLink = null;
    } catch (error) {
      this.commonService.errorHandler(error, `${this.componentName}:deleteLink`, true);
    }
  }
}
