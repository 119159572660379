<section class="bot-preview" *ngIf="isAuthorised || isPanelUser">
  <div class="bot-preview-inner">
    <!-- LEFT PART (ORG-LOGO, BOT-DETAILS & CUSTOM-FIELDS) | START -->
    <div class="left-part">
      <div class="logo">
        <img [src]="orgIcon" class="img-fluid" alt="" height="45" />
      </div>
      <div class="bot-name">
        <div class="icon">
          <img [src]="botIcon" alt="" width="60" />
        </div>
        <p class="bot-title" [matTooltip]="botName" [ngStyle]="{ color: primaryColor }">
          {{ botName }}
        </p>
      </div>
      <div class="custom-field">
        <p class="bot-title" [ngStyle]="{ color: primaryColor }">Custom Field</p>
        <div class="scroll-custom-field">
          <div class="bot-details-group" *ngFor="let field of previewUserDynamicData.customFields">
            <label>{{ field.name }}:</label>
            <p>{{ field.value }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- LEFT PART (ORG-LOGO, BOT-DETAILS & CUSTOM-FIELDS) | END -->

    <!-- CENTER PART (CHANNELS-LIST & CHAT) | START -->
    <div class="center-part">
      <div class="channels-list">
        <div
          class="bot-list"
          *ngFor="let channel of botChannels; let index = index"
          [ngStyle]="{
            background: channel.isActive ? channel.primaryBackground : '#F5F8FA',
          }"
          (click)="setCurrentChannel(index)">
          <div class="icon-title-wrapper">
            <div class="icon">
              <img [src]="'assets/images/channels/' + channel.image" alt="" width="15" [ngClass]="{ active: channel.isActive }" />
            </div>
            <p class="title" [ngClass]="{ white: channel.isActive }">{{ channel.name }}</p>
          </div>
        </div>
      </div>
      <ng-container *ngIf="channelName === 'Web Widget' && selectedChannel === 'webchat'">
        <div class="preview-part" id="bot_container"></div>
      </ng-container>
      <app-whatsapp-preview
        *ngIf="channelName === 'WhatsApp'"
        [botId]="botId"
        [selectedChannel]="selectedChannel"
        [previewAuth]="previewAuth"
        (changepreviewUserDynamicData)="changePreviewUserDynamicData($event)">
      </app-whatsapp-preview>
      <app-messenger-preview
        *ngIf="channelName === 'Messenger' || channelName === CHANNEL_DISPLAY_NAMES.INSTAGRAM"
        [botId]="botId"
        [botName]="botName"
        [previewAuth]="previewAuth"
        [userDetails]="userDetails"
        [selectedChannel]="selectedChannel"
        (changepreviewUserDynamicData)="changePreviewUserDynamicData($event)">
      </app-messenger-preview>
    </div>
    <!-- CENTER PART (CHANNELS-LIST & CHAT) | END -->

    <!-- RIGHT PART (SHARE-LINK, FLOW, INTENT-ENTITIES & TAG-SET) | START -->
    <div class="right-part">
      <button *ngIf="isPanelUser" class="share-preview" [ngStyle]="{ background: primaryBackground }" (click)="isLinkSidebarOpen = true">
        <div class="t-flex t-items-center t-justify-center">
          <mat-icon class="material-icons-outlined">share</mat-icon>
          <p class="t-ml-2.5">Share Preview</p>
        </div>
      </button>
      <div class="right-part-bot-details" [ngStyle]="{ 'margin-top': !isPanelUser ? '57px' : '' }">
        <ng-container *ngIf="previewUserDynamicData.previousFlow !== null">
          <p class="name"><strong>Previous Flow</strong></p>
          <div class="message t-mb-2.5">
            <mat-icon class="material-icons-outlined">device_hub</mat-icon>
            <p class="t-ml-2.5">{{ previewUserDynamicData.previousFlow }}</p>
          </div>
        </ng-container>
        <ng-container>
          <p class="name"><strong>Current Flow</strong></p>
          <div *ngIf="previewUserDynamicData.currentFlow !== null" class="message">
            <mat-icon class="material-icons-outlined">device_hub</mat-icon>
            <p class="t-ml-2.5">{{ previewUserDynamicData.currentFlow }}</p>
          </div>
        </ng-container>
      </div>
      <div class="right-part-bot-details">
        <div class="handover">
          <p class="name">Agent Handover</p>
          <p class="ml-auto" [ngStyle]="{ color: primaryColor }">
            {{ previewUserDynamicData.isRunningHandoff }}
          </p>
        </div>
      </div>
      <div *ngIf="previewUserDynamicData.lastIntent !== null" class="right-part-bot-details">
        <div class="handover label">
          <p class="name"><strong>Last Intent</strong></p>
          <div class="message t-flex t-items-center t-mb-2.5">
            <mat-icon class="material-icons-outlined">device_hub</mat-icon>
            <p class="t-ml-2.5">{{ previewUserDynamicData.lastIntent }}</p>
          </div>
          <p class="name">Last Entities</p>
          <div class="entities-scroll">
            <span *ngFor="let entity of previewUserDynamicData.lastEntities" [ngStyle]="{ background: primaryColor }">{{ entity }}</span>
          </div>
        </div>
      </div>
      <div class="right-part-bot-details" *ngIf="previewUserDynamicData.tags.length">
        <div class="handover label">
          <p class="name">Tag Set</p>
          <div class="entities-scroll">
            <span *ngFor="let tag of previewUserDynamicData.tags" [ngStyle]="{ background: primaryColor }">{{ tag }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- RIGHT PART (SHARE-LINK, FLOW, INTENT-ENTITIES & TAG-SET) | END -->
  </div>
</section>

<!-- PASSWORD DIALOG | START -->
<ng-template #addPassword>
  <ng-container *ngIf="!isPanelUser && !isAuthorised">
    <div class="modal-header">
      <p>Bot Preview</p>
    </div>
    <div class="modal-body">
      <div class="content">
        <div class="input-group">
          <label>Please enter the password shared with you</label>
          <div class="with-icon">
            <input type="password" placeholder="Password" class="input" [(ngModel)]="password" (keyup.enter)="authoriseBotPreview()" />
          </div>
        </div>
      </div>
      <div class="t-flex t-items-center t-justify-center">
        <button mat-flat-button class="primary-btn btn" color="primary" (click)="authoriseBotPreview()">View</button>
      </div>
    </div>
  </ng-container>
</ng-template>
<!-- PASSWORD DIALOG | END -->

<!-- EXPIRED/INVALID LINK DIALOG | START -->
<ng-template #linkExpired>
  <ng-container *ngIf="!isPanelUser && !isLinkValid">
    <div class="modal-header">
      <p>Link {{ isLinkExpired ? "expired" : "is not valid" }}</p>
    </div>
    <div class="modal-body">
      <div class="content">
        <div class="input-group">
          <label>Link that has been shared with you {{ isLinkExpired ? "was expired" : "is not valid" }}</label>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
<!-- EXPIRED/INVALID LINK DIALOG | END -->

<!-- SHARE LINK CONTAINER | START -->
<div class="sharelink-container" [ngClass]="{ sharelink_animation: isLinkSidebarOpen }">
  <div class="text-center sharelink-heading" [ngStyle]="{ background: primaryBackground }">
    <div class="t-flex t-items-center t-justify-between">
      <div class="t-flex t-items-center">
        <mat-icon class="material-icons t-mr-[5px]"> share </mat-icon>
        Share Preview
      </div>
      <div class="close-icon t-cursor-pointer t-outline-none" (click)="isLinkSidebarOpen = false">
        <mat-icon class="material-icons"> cancel </mat-icon>
      </div>
    </div>
  </div>
  <div class="sharelink-inner" [ngClass]="{ whatsapp: channelName === 'WhatsApp' }">
    <div class="input-group">
      <ng-container *ngIf="!generatedLink">
        <label>Link Password</label>
        <div class="with-icon">
          <input
            type="password"
            placeholder="Password"
            class="link-password input"
            [(ngModel)]="linkPassword"
            (keyup.enter)="createLink()" />
        </div>
        <small class="t-block">At least 8 characters</small>
        <small class="t-block">One each of : A-Z, a-z, 0-9</small>
        <div class="t-flex t-items-center t-mt-5">
          <button mat-flat-button color="primary" class="btn primary-btn hover-effect" (click)="createLink()">Create</button>
          <small class="t-block t-ml-2.5">Link will be expire in 7days</small>
        </div>
      </ng-container>
      <div class="copy-link" *ngIf="generatedLink">
        <small>Link {{ isLinkExpired ? "was expired" : "will be expire" }} at {{ linkExpireTime | date: "medium" }}</small>
        <mat-icon class="material-icons t-cursor-pointer t-outline-none" color="primary" (click)="deleteLink()"> delete </mat-icon>
        <div class="with-icon" [matTooltip]="generatedLink">
          <input type="text" class="input" [(ngModel)]="generatedLink" placeholder="Link" readonly />
          <mat-icon class="material-icons" color="primary" (click)="copyLink()"> content_copy </mat-icon>
        </div>
        <div class="t-flex t-items-center t-mt-2.5" *ngIf="isLinkExpired">
          <button mat-flat-button color="primary" class="primary-btn btn" (click)="deleteLink(); generatedLink = null">
            Create new link
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- SHARE LINK CONTAINER | END -->
