import { CHANNEL_NAMES } from "../model/channels";

export enum MEDIA_TYPES {
  AUDIO = "audio",
  IMAGE = "image",
  VIDEO = "video",
  DOC = "doc",
  PDF = "pdf",
  PPT = "ppt",
  XLS = "xls",
}

export const VIDEO_TYPES = [
  "application/mp4",
  "application/x-troff-msvideo",
  "video/avi",
  "video/mp4",
  "video/mpeg",
  "video/msvideo",
  "video/ogg",
  "video/webm",
  "video/x-mpeg",
  "video/x-ms-wmv",
  "video/x-msvideo",
  "video/x-matroska",
];

const AUDIO_TYPES = ["audio/mpeg", "audio/mpeg3", "audio/ogg", "audio/wav", "audio/webm", "audio/x-mpeg-3", "audio/x-wav"];

export const DOC_TYPE = [
  "application/doc",
  "application/ms-doc",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const EXCEL_TYPE = [
  "application/excel",
  "application/vnd.ms-excel",
  "application/x-excel",
  "application/x-msexcel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const CSV_TYPE = ["text/csv"];

export const PPT_TYPE = [
  "application/mspowerpoint",
  "application/powerpoint",
  "application/vnd.ms-powerpoint",
  "application/x-mspowerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const PDF_TYPE = ["application/pdf"];
const DOCUMENT_TYPES = [...DOC_TYPE, ...EXCEL_TYPE, ...PPT_TYPE, ...PDF_TYPE];

const IMAGE_TYPES = ["image/jpeg", "image/pjpeg", "image/png"];

export const ChannelFileTypeValidation = {
  [CHANNEL_NAMES.FACEBOOK]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.INSTAGRAM]: [...IMAGE_TYPES],
  [CHANNEL_NAMES.SOCKET_IO]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.WEBCHAT]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.TELEGRAM]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.WHATSAPP_KALEYRA]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.WHATSAPP]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.WHATSAPP_NET_CORE]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.WHATSAPP_BUSINESS]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.WHATSAPP_AIRTEL]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES, ...CSV_TYPE],
  [CHANNEL_NAMES.WHATSAPP_ACL]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.WHATSAPP_CLOUD]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES, ...CSV_TYPE],
  [CHANNEL_NAMES.WHATSAPP_INFOBIP]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES],
  [CHANNEL_NAMES.WHATSAPP_BSP]: [...IMAGE_TYPES, ...AUDIO_TYPES, ...DOCUMENT_TYPES, ...VIDEO_TYPES, ...CSV_TYPE],
};

export const MIME_TYPE_MAPPING = {
  image: IMAGE_TYPES,
  video: VIDEO_TYPES,
  audio: AUDIO_TYPES,
  pdf: PDF_TYPE,
  ppt: PPT_TYPE,
  doc: DOC_TYPE,
  xls: EXCEL_TYPE,
  csv: CSV_TYPE,
};

export function getCampaignChannelMediaValidations(channel) {
  switch (channel) {
    case CHANNEL_NAMES.WHATSAPP_BUSINESS:
    case CHANNEL_NAMES.WHATSAPP_CLOUD:
      return {
        image: {
          contentType: ["image/*"],
          size: 5,
        },
        video: {
          contentType: ["video/*"],
          size: 16,
        },
        document: {
          contentType: ["application/pdf"],
          size: 100,
        },
      };
    case CHANNEL_NAMES.WHATSAPP_BSP:
    case CHANNEL_NAMES.WHATSAPP_INFOBIP:
      return {
        image: {
          contentType: ["image/jpeg", "image/png"],
          extensions: [".jpg", ".jpeg", ".png"],
          note: "Upload/Add JPG , JPEG or PNG File (Max Size - 5MB)",
          maxExtensionSize: 4,
          size: 5,
        },
        video: {
          contentType: ["video/mp4", "video/3gp"],
          extensions: [".mp4", ".3gp"],
          note: "Upload/Add MP4 or 3GP File (Max Size - 16MB)",
          maxExtensionSize: 3,
          size: 16,
        },
        document: {
          contentType: ["application/pdf"],
          extensions: [".pdf"],
          maxExtensionSize: 3,
          size: 100,
        },
      };
    case CHANNEL_NAMES.WHATSAPP_NET_CORE:
      return {
        image: {
          contentType: ["image/*"],
          size: 5,
        },
        video: {
          contentType: ["video/*"],
          size: 16,
        },
        document: {
          contentType: [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
          size: 16,
        },
      };
  }
}

export function getMediaTypeFromMimeType(mimeType: string): string {
  if (DOC_TYPE.includes(mimeType)) {
    return MEDIA_TYPES.DOC;
  }
  if (EXCEL_TYPE.includes(mimeType) || CSV_TYPE.includes(mimeType)) {
    return MEDIA_TYPES.XLS;
  }
  if (PPT_TYPE.includes(mimeType)) {
    return MEDIA_TYPES.PPT;
  }
  if (PDF_TYPE.includes(mimeType)) {
    return MEDIA_TYPES.PDF;
  }
  if (IMAGE_TYPES.includes(mimeType)) {
    return MEDIA_TYPES.IMAGE;
  }
  if (AUDIO_TYPES.includes(mimeType)) {
    return MEDIA_TYPES.AUDIO;
  }
  if (VIDEO_TYPES.includes(mimeType)) {
    return MEDIA_TYPES.VIDEO;
  }
  throw new Error(`Unknown file type: ${mimeType}`);
}
