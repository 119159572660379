import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { Urls } from "../utils/urls";
import { CommonService } from "./common.service";
import { CarouselButtonType } from "../utils/model/template";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  public hideTemplateInteractiveButtonPreview = new Subject<boolean>();
  public changeLanguageForPreviewBody = new Subject<boolean>();
  public carouselCurrentIndexSubject = new Subject<void>();
  URLPattern = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  URLRegex = new RegExp(this.URLPattern);
  carouselButtonIconMap = {
    [CarouselButtonType.QUICK_REPLY]: "reply",
    [CarouselButtonType.URL]: "open_in_new",
    [CarouselButtonType.PHONE_NUMBER]: "call",
  };

  displayCategories = {
    MARKETING: "Marketing",
    UTILITY: "Utility",
    AUTHENTICATION: "Authentication",
  };

  templateAllowedLanguages = {
    af: "Afrikaans",
    sq: "Albanian",
    ar: "Arabic",
    az: "Azerbaijani",
    bn: "Bengali",
    bg: "Bulgarian",
    ca: "Catalan",
    zh_CN: "Chinese (CHN)",
    zh_HK: "Chinese (HKG)",
    zh_TW: "Chinese (TAI)",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    en: "English",
    en_GB: "English (UK)",
    en_US: "English (US)",
    et: "Estonian",
    fil: "Filipino",
    fi: "Finnish",
    fr: "French",
    de: "German",
    el: "Greek",
    gu: "Gujarati",
    ha: "Hausa",
    he: "Hebrew",
    hi: "Hindi",
    hu: "Hungarian",
    id: "Indonesian",
    ga: "Irish",
    it: "Italian",
    ja: "Japanese",
    kn: "Kannada",
    kk: "Kazakh",
    ko: "Korean",
    lo: "Lao",
    lv: "Latvian",
    lt: "Lithuanian",
    mk: "Macedonian",
    ms: "Malay",
    ml: "Malayalam",
    mr: "Marathi",
    nb: "Norwegian",
    fa: "Persian",
    pl: "Polish",
    pt_BR: "Portuguese (BR)",
    pt_PT: "Portuguese (POR)",
    pa: "Punjabi",
    ro: "Romanian",
    ru: "Russian",
    sr: "Serbian",
    sk: "Slovak",
    sl: "Slovenian",
    es: "Spanish",
    es_AR: "Spanish (ARG)",
    es_ES: "Spanish (SPA)",
    es_MX: "Spanish (MEX)",
    sw: "Swahili",
    sv: "Swedish",
    ta: "Tamil",
    te: "Telugu",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    ur: "Urdu",
    uz: "Uzbek",
    vi: "Vietnamese",
    zu: "Zulu",
  };

  countryListWithCode = {
    AF: { phone_code: 93, country_name: "Afghanistan" },
    AL: { phone_code: 355, country_name: "Albania" },
    DZ: { phone_code: 213, country_name: "Algeria" },
    AS: { phone_code: 1, country_name: "American Samoa" },
    AD: { phone_code: 376, country_name: "Andorra" },
    AO: { phone_code: 244, country_name: "Angola" },
    AI: { phone_code: 1, country_name: "Anguilla" },
    AG: { phone_code: 1, country_name: "Antigua" },
    AR: { phone_code: 54, country_name: "Argentina" },
    AM: { phone_code: 374, country_name: "Armenia" },
    AW: { phone_code: 297, country_name: "Aruba" },
    AU: { phone_code: 61, country_name: "Australia" },
    AT: { phone_code: 43, country_name: "Austria" },
    AZ: { phone_code: 994, country_name: "Azerbaijan" },
    BH: { phone_code: 973, country_name: "Bahrain" },
    BD: { phone_code: 880, country_name: "Bangladesh" },
    BB: { phone_code: 1, country_name: "Barbados" },
    BY: { phone_code: 375, country_name: "Belarus" },
    BE: { phone_code: 32, country_name: "Belgium" },
    BZ: { phone_code: 501, country_name: "Belize" },
    BJ: { phone_code: 229, country_name: "Benin" },
    BM: { phone_code: 1, country_name: "Bermuda" },
    BT: { phone_code: 975, country_name: "Bhutan" },
    BO: { phone_code: 591, country_name: "Bolivia" },
    BQ: { phone_code: 599, country_name: "Bonaire, Sint Eustatius and Saba" },
    BA: { phone_code: 387, country_name: "Bosnia and Herzegovina" },
    BW: { phone_code: 267, country_name: "Botswana" },
    BR: { phone_code: 55, country_name: "Brazil" },
    IO: { phone_code: 246, country_name: "British Indian Ocean Territory" },
    VG: { phone_code: 1, country_name: "British Virgin Islands" },
    BN: { phone_code: 673, country_name: "Brunei" },
    BG: { phone_code: 359, country_name: "Bulgaria" },
    BF: { phone_code: 226, country_name: "Burkina Faso" },
    BI: { phone_code: 257, country_name: "Burundi" },
    KH: { phone_code: 855, country_name: "Cambodia" },
    CM: { phone_code: 237, country_name: "Cameroon" },
    CA: { phone_code: 1, country_name: "Canada" },
    CV: { phone_code: 238, country_name: "Cape Verde" },
    KY: { phone_code: 1, country_name: "Cayman Islands" },
    CI: { phone_code: 225, country_name: "Côte d'Ivoire" },
    CF: { phone_code: 236, country_name: "Central African Republic" },
    TD: { phone_code: 235, country_name: "Chad" },
    CL: { phone_code: 56, country_name: "Chile" },
    CN: { phone_code: 86, country_name: "China" },
    CO: { phone_code: 57, country_name: "Colombia" },
    KM: { phone_code: 269, country_name: "Comoros" },
    CK: { phone_code: 682, country_name: "Cook Islands" },
    CR: { phone_code: 506, country_name: "Costa Rica" },
    HR: { phone_code: 385, country_name: "Croatia" },
    CU: { phone_code: 53, country_name: "Cuba" },
    CW: { phone_code: 599, country_name: "Curaçao" },
    CY: { phone_code: 357, country_name: "Cyprus" },
    CZ: { phone_code: 420, country_name: "Czech Republic" },
    CD: { phone_code: 243, country_name: "Democratic Republic of the Congo" },
    DK: { phone_code: 45, country_name: "Denmark" },
    DJ: { phone_code: 253, country_name: "Djibouti" },
    DM: { phone_code: 1, country_name: "Dominica" },
    DO: { phone_code: 1, country_name: "Dominican Republic" },
    EC: { phone_code: 593, country_name: "Ecuador" },
    EG: { phone_code: 20, country_name: "Egypt" },
    SV: { phone_code: 503, country_name: "El Salvador" },
    GQ: { phone_code: 240, country_name: "Equatorial Guinea" },
    ER: { phone_code: 291, country_name: "Eritrea" },
    EE: { phone_code: 372, country_name: "Estonia" },
    ET: { phone_code: 251, country_name: "Ethiopia" },
    FK: { phone_code: 500, country_name: "Falkland Islands" },
    FO: { phone_code: 298, country_name: "Faroe Islands" },
    FM: { phone_code: 691, country_name: "Federated States of Micronesia" },
    FJ: { phone_code: 679, country_name: "Fiji" },
    FI: { phone_code: 358, country_name: "Finland" },
    FR: { phone_code: 33, country_name: "France" },
    GF: { phone_code: 594, country_name: "French Guiana" },
    PF: { phone_code: 689, country_name: "French Polynesia" },
    GA: { phone_code: 241, country_name: "Gabon" },
    GE: { phone_code: 995, country_name: "Georgia" },
    DE: { phone_code: 49, country_name: "Germany" },
    GH: { phone_code: 233, country_name: "Ghana" },
    GI: { phone_code: 350, country_name: "Gibraltar" },
    GR: { phone_code: 30, country_name: "Greece" },
    GL: { phone_code: 299, country_name: "Greenland" },
    GD: { phone_code: 1, country_name: "Grenada" },
    GP: { phone_code: 590, country_name: "Guadeloupe" },
    GU: { phone_code: 1, country_name: "Guam" },
    GT: { phone_code: 502, country_name: "Guatemala" },
    GG: { phone_code: 44, country_name: "Guernsey" },
    GN: { phone_code: 224, country_name: "Guinea" },
    GW: { phone_code: 245, country_name: "Guinea-Bissau" },
    GY: { phone_code: 592, country_name: "Guyana" },
    HT: { phone_code: 509, country_name: "Haiti" },
    HN: { phone_code: 504, country_name: "Honduras" },
    HK: { phone_code: 852, country_name: "Hong Kong" },
    HU: { phone_code: 36, country_name: "Hungary" },
    IS: { phone_code: 354, country_name: "Iceland" },
    IN: { phone_code: 91, country_name: "India" },
    ID: { phone_code: 62, country_name: "Indonesia" },
    IR: { phone_code: 98, country_name: "Iran" },
    IQ: { phone_code: 964, country_name: "Iraq" },
    IE: { phone_code: 353, country_name: "Ireland" },
    IM: { phone_code: 44, country_name: "Isle Of Man" },
    IL: { phone_code: 972, country_name: "Israel" },
    IT: { phone_code: 39, country_name: "Italy" },
    JM: { phone_code: 1, country_name: "Jamaica" },
    JP: { phone_code: 81, country_name: "Japan" },
    JE: { phone_code: 44, country_name: "Jersey" },
    JO: { phone_code: 962, country_name: "Jordan" },
    KZ: { phone_code: 7, country_name: "Kazakhstan" },
    KE: { phone_code: 254, country_name: "Kenya" },
    KI: { phone_code: 686, country_name: "Kiribati" },
    XK: { phone_code: 383, country_name: "Kosovo" },
    KW: { phone_code: 965, country_name: "Kuwait" },
    KG: { phone_code: 996, country_name: "Kyrgyzstan" },
    LA: { phone_code: 856, country_name: "Laos" },
    LV: { phone_code: 371, country_name: "Latvia" },
    LB: { phone_code: 961, country_name: "Lebanon" },
    LS: { phone_code: 266, country_name: "Lesotho" },
    LR: { phone_code: 231, country_name: "Liberia" },
    LY: { phone_code: 218, country_name: "Libya" },
    LI: { phone_code: 423, country_name: "Liechtenstein" },
    LT: { phone_code: 370, country_name: "Lithuania" },
    LU: { phone_code: 352, country_name: "Luxembourg" },
    MO: { phone_code: 853, country_name: "Macau" },
    MK: { phone_code: 389, country_name: "Macedonia" },
    MG: { phone_code: 261, country_name: "Madagascar" },
    MW: { phone_code: 265, country_name: "Malawi" },
    MY: { phone_code: 60, country_name: "Malaysia" },
    MV: { phone_code: 960, country_name: "Maldives" },
    ML: { phone_code: 223, country_name: "Mali" },
    MT: { phone_code: 356, country_name: "Malta" },
    MH: { phone_code: 692, country_name: "Marshall Islands" },
    MQ: { phone_code: 596, country_name: "Martinique" },
    MR: { phone_code: 222, country_name: "Mauritania" },
    MU: { phone_code: 230, country_name: "Mauritius" },
    YT: { phone_code: 262, country_name: "Mayotte" },
    MX: { phone_code: 52, country_name: "Mexico" },
    MD: { phone_code: 373, country_name: "Moldova" },
    MC: { phone_code: 377, country_name: "Monaco" },
    MN: { phone_code: 976, country_name: "Mongolia" },
    ME: { phone_code: 382, country_name: "Montenegro" },
    MS: { phone_code: 1, country_name: "Montserrat" },
    MA: { phone_code: 212, country_name: "Morocco" },
    MZ: { phone_code: 258, country_name: "Mozambique" },
    MM: { phone_code: 95, country_name: "Myanmar" },
    NA: { phone_code: 264, country_name: "Namibia" },
    NR: { phone_code: 674, country_name: "Nauru" },
    NP: { phone_code: 977, country_name: "Nepal" },
    NL: { phone_code: 31, country_name: "Netherlands" },
    NC: { phone_code: 687, country_name: "New Caledonia" },
    NZ: { phone_code: 64, country_name: "New Zealand" },
    NI: { phone_code: 505, country_name: "Nicaragua" },
    NE: { phone_code: 227, country_name: "Niger" },
    NG: { phone_code: 234, country_name: "Nigeria" },
    NU: { phone_code: 683, country_name: "Niue" },
    NF: { phone_code: 672, country_name: "Norfolk Island" },
    KP: { phone_code: 850, country_name: "North Korea" },
    MP: { phone_code: 1, country_name: "Northern Mariana Islands" },
    NO: { phone_code: 47, country_name: "Norway" },
    OM: { phone_code: 968, country_name: "Oman" },
    PK: { phone_code: 92, country_name: "Pakistan" },
    PW: { phone_code: 680, country_name: "Palau" },
    PS: { phone_code: 970, country_name: "Palestine" },
    PA: { phone_code: 507, country_name: "Panama" },
    PG: { phone_code: 675, country_name: "Papua New Guinea" },
    PY: { phone_code: 595, country_name: "Paraguay" },
    PE: { phone_code: 51, country_name: "Peru" },
    PH: { phone_code: 63, country_name: "Philippines" },
    PL: { phone_code: 48, country_name: "Poland" },
    PT: { phone_code: 351, country_name: "Portugal" },
    PR: { phone_code: 1, country_name: "Puerto Rico" },
    QA: { phone_code: 974, country_name: "Qatar" },
    RE: { phone_code: 262, country_name: "Réunion" },
    CG: { phone_code: 242, country_name: "Republic of the Congo" },
    RO: { phone_code: 40, country_name: "Romania" },
    RU: { phone_code: 7, country_name: "Russia" },
    RW: { phone_code: 250, country_name: "Rwanda" },
    BL: { phone_code: 590, country_name: "Saint Barthélemy" },
    SH: { phone_code: 290, country_name: "Saint Helena" },
    KN: { phone_code: 1, country_name: "Saint Kitts and Nevis" },
    MF: { phone_code: 590, country_name: "Saint Martin" },
    PM: { phone_code: 508, country_name: "Saint Pierre and Miquelon" },
    VC: { phone_code: 1, country_name: "Saint Vincent and the Grenadines" },
    WS: { phone_code: 685, country_name: "Samoa" },
    SM: { phone_code: 378, country_name: "San Marino" },
    ST: { phone_code: 239, country_name: "Sao Tome and Principe" },
    SA: { phone_code: 966, country_name: "Saudi Arabia" },
    SN: { phone_code: 221, country_name: "Senegal" },
    RS: { phone_code: 381, country_name: "Serbia" },
    SC: { phone_code: 248, country_name: "Seychelles" },
    SL: { phone_code: 232, country_name: "Sierra Leone" },
    SG: { phone_code: 65, country_name: "Singapore" },
    SX: { phone_code: 1, country_name: "Sint Maarten" },
    SK: { phone_code: 421, country_name: "Slovakia" },
    SI: { phone_code: 386, country_name: "Slovenia" },
    SB: { phone_code: 677, country_name: "Solomon Islands" },
    SO: { phone_code: 252, country_name: "Somalia" },
    ZA: { phone_code: 27, country_name: "South Africa" },
    KR: { phone_code: 82, country_name: "South Korea" },
    SS: { phone_code: 211, country_name: "South Sudan" },
    ES: { phone_code: 34, country_name: "Spain" },
    LK: { phone_code: 94, country_name: "Sri Lanka" },
    LC: { phone_code: 1, country_name: "St. Lucia" },
    SD: { phone_code: 249, country_name: "Sudan" },
    SR: { phone_code: 597, country_name: "Suriname" },
    SZ: { phone_code: 268, country_name: "Swaziland" },
    SE: { phone_code: 46, country_name: "Sweden" },
    CH: { phone_code: 41, country_name: "Switzerland" },
    SY: { phone_code: 963, country_name: "Syria" },
    TW: { phone_code: 886, country_name: "Taiwan" },
    TJ: { phone_code: 992, country_name: "Tajikistan" },
    TZ: { phone_code: 255, country_name: "Tanzania" },
    TH: { phone_code: 66, country_name: "Thailand" },
    BS: { phone_code: 1, country_name: "The Bahamas" },
    GM: { phone_code: 220, country_name: "The Gambia" },
    TL: { phone_code: 670, country_name: "Timor-Leste" },
    TG: { phone_code: 228, country_name: "Togo" },
    TK: { phone_code: 690, country_name: "Tokelau" },
    TO: { phone_code: 676, country_name: "Tonga" },
    TT: { phone_code: 1, country_name: "Trinidad and Tobago" },
    TN: { phone_code: 216, country_name: "Tunisia" },
    TR: { phone_code: 90, country_name: "Turkey" },
    TM: { phone_code: 993, country_name: "Turkmenistan" },
    TC: { phone_code: 1, country_name: "Turks and Caicos Islands" },
    TV: { phone_code: 688, country_name: "Tuvalu" },
    UG: { phone_code: 256, country_name: "Uganda" },
    UA: { phone_code: 380, country_name: "Ukraine" },
    AE: { phone_code: 971, country_name: "United Arab Emirates" },
    GB: { phone_code: 44, country_name: "United Kingdom" },
    US: { phone_code: 1, country_name: "United States" },
    UY: { phone_code: 598, country_name: "Uruguay" },
    VI: { phone_code: 1, country_name: "US Virgin Islands" },
    UZ: { phone_code: 998, country_name: "Uzbekistan" },
    VU: { phone_code: 678, country_name: "Vanuatu" },
    VA: { phone_code: 39, country_name: "Vatican City" },
    VE: { phone_code: 58, country_name: "Venezuela" },
    VN: { phone_code: 84, country_name: "Vietnam" },
    WF: { phone_code: 681, country_name: "Wallis and Futuna" },
    EH: { phone_code: 212, country_name: "Western Sahara" },
    YE: { phone_code: 967, country_name: "Yemen" },
    ZM: { phone_code: 260, country_name: "Zambia" },
    ZW: { phone_code: 263, country_name: "Zimbabwe" },
  };

  constructor(
    private readonly http: HttpClient,
    public readonly commonService: CommonService
  ) {}

  getAllTemplates(botId, queryParams) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.template}/${botId}${queryParams}`).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getAllTemplatesWithBasicDetails(botId, selectedChannel) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.template}/${botId}/templates-name?channel=${selectedChannel}`).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getPaginatedTemplates(botId, queryParams) {
    const httpParams = this.commonService.httpParamsPatcher(queryParams);
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.template}/${botId}/local`, { params: httpParams }).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getTemplateById(botId, templateId, selectedChannel, isAuth) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.template}/${botId}?id=${templateId}&channel=${selectedChannel}&isAuth=${isAuth}`).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  checkOptInStatus(botId) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.template}/${botId}/insights/status`).subscribe({
        next: res => {
          resolve(res);
        },
        error: err => {
          reject(err);
        },
      });
    });
  }

  optInUser(botId) {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.template}/${botId}/insights/optin`, {}).subscribe({
        next: res => {
          resolve(res);
        },
        error: err => {
          reject(err);
        },
      });
    });
  }

  getTemplateInsights(botId, startDate, endDate, templateId) {
    return new Promise((resolve, reject) => {
      return this.http
        .get(`${Urls.template}/${botId}/insights?startDate=${startDate}&endDate=${endDate}&templateId=${templateId}`)
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: err => {
            reject(err);
          },
        });
    });
  }

  getAffectedModulesForATemplate(botId, templateId) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.template}/${botId}/affected-modules?templateId=${templateId}`).subscribe({
        next: res => {
          resolve(res);
        },
        error: err => {
          reject(err);
        },
      });
    });
  }

  addNewTemplate(botId, reqBodyObject) {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.template}/${botId}`, reqBodyObject).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  refreshTemplateStatus(botId, selectedChannel) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.template}/${botId}/refresh?channel=${selectedChannel}`).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  editTemplate(templateId, botId, reqBodyObject) {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.template}/${botId}/${templateId}`, reqBodyObject).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteTemplate(botId, templateName, selectedChannel, _id) {
    return new Promise((resolve, reject) => {
      return this.http.delete(`${Urls.template}/${botId}?name=${templateName}&channel=${selectedChannel}&id=${_id}`).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  uploadSampleFile(botId, fileData, selectedChannel, mediaType): Observable<any> {
    return this.http.post(`${Urls.template}/${botId}/upload?channel=${selectedChannel}&type=${mediaType}`, fileData, {
      reportProgress: true,
      observe: "events",
    });
  }

  getAuthenticationBody(botId, queryParams): Observable<any> {
    const httpParams = this.commonService.httpParamsPatcher(queryParams);
    return this.http.get(`${Urls.template}/${botId}/preview`, { params: httpParams });
  }
}
