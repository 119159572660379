import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { Observable, Subject } from "rxjs";

import { environment } from "../../environments/environment";
import { Urls } from "../utils/urls";

@Injectable({
  providedIn: "root",
})
export class PreviewSocketService {
  private botOutput = new Subject<any>();
  previewSocketCity: Socket;

  constructor(private http: HttpClient) {}

  previewSocketCityConnection(botId, userId, socketToken, channelId) {
    const config = {
      url: environment.previewSocketCityUrl + "/" + botId,
      options: {
        path: "/socket/api",
        transports: ["polling", "websocket"],
        auth: {
          token: socketToken,
          userId: userId,
          channelId: channelId,
          isPreviewUser: true,
        },
        withCredentials: true,
      },
    };
    this.previewSocketCity = new Socket(config);
    this.previewSocketCity.connect();
  }

  botOutputListener(): Observable<any> {
    return this.botOutput.asObservable();
  }

  botOutputEvent() {
    this.previewSocketCity.on("bot:output", event => {
      this.botOutput.next(event);
    });
  }

  sendUserMessageToBot(data) {
    this.previewSocketCity.emit("user:input", data);
  }

  removeAllListener() {
    this.previewSocketCity?.removeAllListeners("bot:output");
  }

  generateAccessTokenForPreviewChannel(appId, appSecret): Promise<{ accessToken: string; expiresAt: string }> {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.previewAuth, { appId, appSecret }).subscribe(
        res => {
          resolve(res as { accessToken: string; expiresAt: string });
        },
        err => {
          reject(err);
        }
      );
    });
  }

  uploadPreviewMedia(body, botId, token): Observable<any> {
    return this.http.post(`${Urls.nestMonstruoBaseUrl}socket/${botId}/preview/attachment`, body, {
      reportProgress: true,
      observe: "events",
      headers: {
        authorization: token,
      },
    });
  }
}
