import { Injectable } from "@angular/core";
import { Urls } from "../utils/urls";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./common.service";

@Injectable()
export class TagsService {
  filterTagType = {
    isUserType: true,
    isAgentType: true,
  };
  constructor(
    private http: HttpClient,
    public commonService: CommonService
  ) {}
  getAllTagsList(id, type?): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.tags}${id}?type=${type || ""}`).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  editTag(id, object, tagId) {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.tags}${id}/update/${tagId}`, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  addTag(id, object) {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.tags}${id}`, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  deleteTag(botId, tagId, isDelete) {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.tags}delete/?dryRun=${isDelete}`, { tagsToDelete: tagId }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getTagsPaginated(id, page, search, limit, type?) {
    const queryObj = { botId: id, page, limit, filter_param: encodeURIComponent(search?.trim() ?? ""), type };
    const httpParams = this.commonService.httpParamsPatcher(queryObj);
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.tags}`, { params: httpParams }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  assignTags(id, object) {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.tags}${id}`, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
