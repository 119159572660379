<div class="main-screen">
  <h1 class="user-profile-title">User Transcript</h1>
  <div class="t-flex" style="height: 100%" *ngIf="!isLoading">
    <div class="t-w-[50%] t-p-5 user-profile-left">
      <app-user-profiling [selectedUser]="data.selectedUser" [parentComponent]="'transcript'"></app-user-profiling>
    </div>
    <div class="t-w-[50%] user-profile-right">
      <div style="text-align: center" class="t-text-base t-font-medium">
        <div class="t-mt-[5px] t-mb-[5px] conversation-header">Conversation</div>
      </div>
      <hr />
      <div
        class="chat-container"
        id="chat-area"
        [ngClass]="{ hideChat: !isScrollDone }"
        infiniteScroll
        [scrollWindow]="false"
        [infiniteScrollDistance]="1"
        [infiniteScrollUpDistance]="1"
        [fromRoot]="true"
        (scroll)="onScroll()"
        (scrolledUp)="onScrollUp()">
        <app-conversation
          *ngIf="data"
          [data]="data.selectedUser.transcript"
          [transcriptDataNewBatch]="transcriptDataNewBatch"
          [parentComponent]="'user-transcript'"
          (dateLabel)="changeLabel($event)"></app-conversation>
      </div>
    </div>
  </div>
</div>
<div style="height: 100vh; width: 100vw" *ngIf="isLoading" class="t-flex t-items-center t-justify-center">
  <app-loader class="t-max-w-full t-w-full"></app-loader>
</div>
