import { NgModule } from "@angular/core";
import { CarouselModule } from "ngx-owl-carousel-o";

import { ConversationComponent } from "../common-components/conversation/conversation.component";
import { CustomAccordionComponent } from "../common-components/custom-accordion/custom-accordion.component";
import { FlowSelectionComponent } from "../common-components/flow-selection/flow-selection.component";
import { NavigationComponent } from "../common-components/navigation/navigation.component";
import { PaginationComponent } from "../common-components/pagination/pagination.component";
import { SearchComponent } from "../common-components/search/search.component";
import { TableComponent } from "../common-components/table/table.component";
import { MessengerAudioComponent } from "../dashboard/conversation-component/messenger/messenger-audio/messenger-audio.component";
import { MessengerCardComponent } from "../dashboard/conversation-component/messenger/messenger-card/messenger-card.component";
import { MessengerCarousalComponent } from "../dashboard/conversation-component/messenger/messenger-carousal/messenger-carousal.component";
import { MessengerIconComponent } from "../dashboard/conversation-component/messenger/messenger-icon/messenger-icon.component";
import { MessengerImageComponent } from "../dashboard/conversation-component/messenger/messenger-image/messenger-image.component";
import { MessengerTypingComponent } from "../dashboard/conversation-component/messenger/messenger-typing/messenger-typing.component";
import { UserProfilingComponent } from "../dashboard/customers/user-profiling/user-profiling.component";
import { BotPreviewComponent } from "./../common-components/bot-preview/bot-preview.component";
import { MessengerPdfComponent } from "./../dashboard/conversation-component/messenger/messenger-pdf/messenger-pdf.component";
import { MessengerQuickReplyComponent } from "./../dashboard/conversation-component/messenger/messenger-quick-reply/messenger-quick-reply.component";
import { MessengerTextComponent } from "./../dashboard/conversation-component/messenger/messenger-text/messenger-text.component";
import { MessengerUserChatComponent } from "./../dashboard/conversation-component/messenger/messenger-user-chat/messenger-user-chat.component";
import { MessengerVideoComponent } from "./../dashboard/conversation-component/messenger/messenger-video/messenger-video.component";
import { MessengerPreviewComponent } from "./../dashboard/preview/messenger-preview/messenger-preview.component";
import { WhatsappPreviewComponent } from "./../dashboard/preview/whatsapp-preview/whatsapp-preview.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { TagSearchPipe } from "../utils/pipe/tag-search.pipe";
import { CustomVariableSelectComponent } from "../common-components/custom-variable-select/custom-variable-select.component";
import { TagSelectComponent } from "../common-components/tag-select/tag-select.component";
import { NgxPopperModule } from "ngx-popper";
import { AnalyticsCommonModule } from "./analytics-common.module";
import { MessageSelectionComponent } from "../common-components/message-selection/message-selection.component";
import { MessagePreviewComponent } from "../common-components/message-preview/message-preview.component";
import { PaginationSkipLimitComponent } from "../common-components/pagination-skip-limit/pagination-skip-limit.component";
import { WhatsappFormFilterPipe } from "./pipe/whatsapp-form-filter.pipe";
import { CounterCharactersPipe } from "./pipe/counter.pipe";
import { CountrySearchPipe } from "./pipe/country-search.pipe";
import { SliderNavigationComponent } from "../common-components/slider-navtigation/slider-navigation.component";
import { PreviewTemplateComponent } from "../dashboard/template/preview-template/preview-template.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CapitalizePipe } from "./pipe/capitalize.pipe";
import { DragScrollModule } from "ngx-drag-scroll";
import { ClickOutsideDirective } from "./click-outside.directive";
import { IndianCurrencyPipe } from "./pipe/indian-currency.pipe";
import { TooltipListPipe } from "./pipe/tooltip-array-to-list.pipe";
import { EllipsisTooltipDirective } from "./ellipsis-tooltip.directive";
import { TemplateListDropdownComponent } from "../common-components/template-list-dropdown/template-list-dropdown.component";
import { AutoFocusDirective } from "./auto-focus.directive";
import { CustomAndroidDatePickerComponent } from "../common-components/custom-android-date-picker/custom-android-date-picker.component";

@NgModule({
  declarations: [
    NavigationComponent,
    ConversationComponent,
    CustomVariableSelectComponent,
    TagSelectComponent,
    PaginationComponent,
    TableComponent,
    SearchComponent,
    FlowSelectionComponent,
    BotPreviewComponent,
    MessengerPreviewComponent,
    WhatsappPreviewComponent,
    MessengerTextComponent,
    MessengerCardComponent,
    MessengerCarousalComponent,
    MessengerIconComponent,
    MessengerVideoComponent,
    MessengerAudioComponent,
    MessengerImageComponent,
    MessengerPdfComponent,
    MessengerQuickReplyComponent,
    MessengerUserChatComponent,
    MessengerTypingComponent,
    UserProfilingComponent,
    TagSearchPipe,
    MessageSelectionComponent,
    MessagePreviewComponent,
    PaginationSkipLimitComponent,
    CountrySearchPipe,
    CapitalizePipe,
    WhatsappFormFilterPipe,
    CounterCharactersPipe,
    CustomAccordionComponent,
    CustomAndroidDatePickerComponent,
    SliderNavigationComponent,
    PreviewTemplateComponent,
    ClickOutsideDirective,
    PreviewTemplateComponent,
    IndianCurrencyPipe,
    TooltipListPipe,
    TemplateListDropdownComponent,
    EllipsisTooltipDirective,
    AutoFocusDirective,
  ],
  imports: [
    CarouselModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AnalyticsCommonModule,
    NgxPopperModule,
    PdfViewerModule,
    ScrollingModule,
    DragScrollModule,
  ],
  exports: [
    NavigationComponent,
    ConversationComponent,
    CustomVariableSelectComponent,
    TagSelectComponent,
    PaginationComponent,
    TableComponent,
    SearchComponent,
    FlowSelectionComponent,
    BotPreviewComponent,
    MessengerPreviewComponent,
    WhatsappPreviewComponent,
    MessengerTextComponent,
    MessengerCardComponent,
    MessengerCarousalComponent,
    MessengerIconComponent,
    MessengerVideoComponent,
    MessengerAudioComponent,
    MessengerImageComponent,
    MessengerPdfComponent,
    MessengerQuickReplyComponent,
    MessengerUserChatComponent,
    MessengerTypingComponent,
    UserProfilingComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TagSearchPipe,
    NgxPopperModule,
    AnalyticsCommonModule,
    MessageSelectionComponent,
    MessagePreviewComponent,
    PaginationSkipLimitComponent,
    CountrySearchPipe,
    CapitalizePipe,
    CounterCharactersPipe,
    CustomAccordionComponent,
    CustomAndroidDatePickerComponent,
    SliderNavigationComponent,
    PreviewTemplateComponent,
    PdfViewerModule,
    ScrollingModule,
    DragScrollModule,
    ClickOutsideDirective,
    IndianCurrencyPipe,
    TooltipListPipe,
    WhatsappFormFilterPipe,
    TemplateListDropdownComponent,
    EllipsisTooltipDirective,
    AutoFocusDirective,
  ],
})
export class SharedModule {}
