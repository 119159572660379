import { Component, Inject, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifyService } from "../../services/noyify.service";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SettingService } from "../../services/setting.service";
import { environment } from "../../../environments/environment";
import { CommonService } from "src/app/services/common.service";
import { DOCUMENT } from "@angular/common";
import { BotService } from "src/app/services/bot.service";

declare const grecaptcha: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  componentName = "login";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _notificationService: NotifyService,
    private _authService: AuthService,
    private settingService: SettingService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private commonService: CommonService,
    private renderer: Renderer2,
    private _botService: BotService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  languages = [
    { name: "English", value: "en" },
    { name: "English", value: "en" },
  ];
  loginForm: UntypedFormGroup;
  code;
  forgetPassForm: UntypedFormGroup;
  resetPassForm: UntypedFormGroup;
  status = "login";
  returnUrl;
  remember;
  id;
  dialogRef;
  welcomeImage = null;
  isLoading = true;
  privacyAndPolicy = this.settingService.privacyAndPolicy;
  termAndCondition = this.settingService.termAndCondition;
  envServerName = environment.envServerName;
  device = "webApp";
  siteKey = environment.captchaSiteKey;
  buttonLoading;

  @ViewChild("templateRef", { static: false }) templateRef: TemplateRef<any>;

  ngOnInit() {
    this.loadRecaptchaScript();
    if (this.route.snapshot.params.id) {
      this.checkAuthentication();
    } else if (this._authService.iKk1k_4KxG8Q37tkFfM46() && this._authService.getUser()) {
      this.router.navigate(["dashboard"]);
    } else {
      this._botService.clearCurrentSession();
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    }

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(this._authService.email ? this._authService.email : "", [Validators.required]),
      password: new UntypedFormControl("", [Validators.required]),
    });
    this.forgetPassForm = new UntypedFormGroup({
      email: new UntypedFormControl(this._authService.email ? this._authService.email : "", [Validators.required, Validators.email]),
    });
    this.resetPassForm = new UntypedFormGroup({
      password: new UntypedFormControl("", [Validators.required]),
      confirmPassword: new UntypedFormControl("", [Validators.required]),
    });
    if (this.envServerName !== "airtel" && this.router.url === "/forgot") {
      this.status = "forgot";
    }
    if (this.router.url === "/two-factor") {
      this.status = "MFA";
    }
    this.settingService.whiteLabelDataEvent.subscribe(successFlag => {
      if (successFlag) {
        this.getWelcomeImage().catch(() => {});
      }
    });
    this.getWelcomeImage().catch(() => {});
  }

  private loadRecaptchaScript(): void {
    const script = this.renderer.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(this.document.head, script);
  }

  checkAuthentication() {
    this.id = this.route.snapshot.params.id;
    if (this.route.snapshot.routeConfig.path.split("/")[0] === "reset") {
      this.status = "reset";
      this.verifyResetToken();
    } else if (this.route.snapshot.routeConfig.path.split("/")[0] === "set-password") {
      this.status = "set-password";
      this.verifyPasswordSetToken();
    } else {
      this.router.navigate(["/", "login"]);
    }
  }

  verifyResetToken() {
    this._authService
      .resetGetCall(this.id)
      .then(() => {})
      .catch(() => {
        this._notificationService.sendNotification("error", "Invalid Token");
        this.router.navigate(["/", "login"]);
      });
  }

  verifyPasswordSetToken() {
    this._authService
      .verifyPasswordSetToken(this.id)
      .then(() => {})
      .catch(() => {
        this._notificationService.sendNotification("error", "Invalid Token");
        this.router.navigate(["/", "login"]);
      });
  }

  getCaptcha(type) {
    grecaptcha.ready(async () => {
      grecaptcha
        .execute(this.siteKey)
        .then(token => {
          if (type === "login") {
            this.login(token);
          } else if (type === "sendMail") {
            this.sendMail(token);
          } else if (type === "resetPass") {
            this.resetPass(token);
          } else if (type === "verifyMFA") {
            this.verifyMFA(token);
          }
        })
        .catch(err => {
          this._notificationService.sendNotification("error", "Something went wrong. Please try again.");
        });
    });
  }

  async getWelcomeImage(): Promise<void> {
    if (this.settingService.whiteLabelData?.data?.assets?.welcomeImage) {
      this.welcomeImage = this.settingService.whiteLabelData.data.assets.welcomeImage;
    }
    if (this.settingService.whiteLabelData?.data?.urls?.privacyAndPolicy) {
      this.privacyAndPolicy = this.settingService.whiteLabelData.data.urls.privacyAndPolicy;
    }
    if (this.settingService.whiteLabelData?.data?.urls?.termAndCondition) {
      this.termAndCondition = this.settingService.whiteLabelData.data.urls.termAndCondition;
    }
    setTimeout(
      () => {
        this.isLoading = false;
      },
      this.settingService.whiteLabelData ? 0 : 1000
    );
  }

  doLogin() {
    if (!this.loginForm.valid) {
      this._notificationService.sendNotification("error", "Please enter all the required details.");
      return;
    }
    if (!this.buttonLoading) {
      this.buttonLoading = true;
      if (this.siteKey?.trim()) {
        this.getCaptcha("login");
      } else {
        this.login(undefined);
      }
    }
  }

  login(recaptchaToken) {
    const encryptedPassword = this.commonService.encryptPasswordWithPublicKey(this.loginForm.value.password);
    const data = {
      userId: this.loginForm.value.email.toLowerCase(),
      password: encryptedPassword,
      device: this.device,
      recaptchaToken,
    };
    this._authService
      .login(data)
      .then((res: any) => {
        this.buttonLoading = false;
        if (res.mfaEnabled) {
          this.status = "MFA";
        } else {
          if (res?.user?.allowedBotServices && res.user.allowedBotServices > 0) {
            this._authService.plIA_HvZ6zV(res.token);
            this._authService.setUser(res.user);
            this.router.navigate(["dashboard"]);
          } else {
            this._notificationService.sendNotification(
              "error",
              "You have insufficient access or your account has been terminated. Contact your RM for more information."
            );
          }
        }
      })
      .catch(err => {
        this.buttonLoading = false;
        this.commonService.errorHandler(err, `${this.componentName}:doLogin`, true);
      });
  }

  toggleRemember(event) {
    this.remember = event.checked;
    this._authService.setRememberMe(this.remember);
  }

  onSendMail() {
    if (!this.forgetPassForm.valid) {
      this._notificationService.sendNotification("error", "Please Enter a valid Email.");
      return;
    }
    if (!this.buttonLoading) {
      this.buttonLoading = true;
      if (this.siteKey?.trim()) {
        this.getCaptcha("sendMail");
      } else {
        this.sendMail(undefined);
      }
    }
  }

  sendMail(recaptchaToken) {
    this._authService
      .forgotPassword({
        device: this.device,
        recaptchaToken,
        ...this.forgetPassForm.value,
      })
      .then(response => {
        this.buttonLoading = false;
        this.snackBar.open("The password reset link will be sent to your registered email address!", "", {
          horizontalPosition: "right",
          duration: 3000,
          panelClass: ["snack-success"],
        });
        this.router.navigate([""]);
      })
      .catch(error => {
        this.buttonLoading = false;
        if (error.error?.error === "Not Found" && error.error?.statusCode === 404) {
          this.snackBar.open("The password reset link will be sent to your registered email address", "", {
            horizontalPosition: "right",
            duration: 3000,
            panelClass: ["snack-success"],
          });
          this.router.navigate([""]);
        } else {
          let message = this.commonService.errorHandler(error, `${this.componentName}:forgotPassword`, false);
          this.snackBar.open(message, "", {
            horizontalPosition: "right",
            duration: 3000,
            panelClass: ["snack-error"],
          });
        }
      });
  }

  onResetPass() {
    if (!(this.resetPassForm.value.password.trim() && this.resetPassForm.value.confirmPassword.trim())) {
      this._notificationService.sendNotification("error", "Please enter proper password.");
      return;
    }
    if (this.resetPassForm.value.password !== this.resetPassForm.value.confirmPassword) {
      this._notificationService.sendNotification("error", "Confirm Password does not matches.");
      return;
    }
    if (!this.buttonLoading) {
      this.buttonLoading = true;
      if (this.siteKey?.trim()) {
        this.getCaptcha("resetPass");
      } else {
        this.resetPass(undefined);
      }
    }
  }

  resetPass(recaptchaToken) {
    const encryptedPassword = this.commonService.encryptPasswordWithPublicKey(this.resetPassForm.value.password.trim());
    const data = {
      password: encryptedPassword,
      device: this.device,
      recaptchaToken,
    };

    if (this.status === "set-password") {
      this._authService
        .setUserPassword(data, this.id)
        .then(() => {
          this.buttonLoading = false;
          this.showSuccessMessage("The password is set successfully.");
          this._botService.clearCurrentSession();
          this.router.navigate(["login"]);
          this.resetPassForm.reset();
        })
        .catch(error => {
          this.buttonLoading = false;
          this.commonService.errorHandler(error, `${this.componentName}:resetPass`, true);
        });
    } else {
      this._authService
        .resetPassword(data, this.id)
        .then(response => {
          this.buttonLoading = false;
          this.showSuccessMessage("The password is reset successfully.");
          this._botService.clearCurrentSession();
          this.router.navigate(["login"]);
          this.resetPassForm.reset();
        })
        .catch(error => {
          this.buttonLoading = false;
          this.commonService.errorHandler(error, `${this.componentName}:resetPass`, true);
        });
    }
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, "", {
      horizontalPosition: "right",
      duration: 3000,
      panelClass: ["snack-success"],
    });
  }

  checkForEmail(form) {
    if (form === "forgot") {
      if (this.loginForm.controls["email"].value.trim()) {
        this._authService.email = this.loginForm.controls["email"].value.trim();
      }
    }
    if (form === "login") {
      if (this.forgetPassForm.controls["email"].value.trim()) {
        this._authService.email = this.forgetPassForm.controls["email"].value.trim();
      }
    }
  }

  openModel() {
    this.openDialog(this.templateRef);
  }

  openDialog(templateRef) {
    this.dialogRef = this.dialog.open(templateRef, {
      width: "400px",
    });
  }
  async doVerifyMFA() {
    if (!this.code?.trim()) {
      this._notificationService.sendNotification("error", "Please enter 2FA code");
      return;
    }
    if (!this.buttonLoading) {
      this.buttonLoading = true;
      if (this.siteKey?.trim()) {
        this.getCaptcha("verifyMFA");
      } else {
        this.verifyMFA(undefined);
      }
    }
  }

  async verifyMFA(recaptchaToken) {
    try {
      const encryptedPassword = this.commonService.encryptPasswordWithPublicKey(this.loginForm.value.password);
      const data = {
        userId: this.loginForm.value.email.toLowerCase(),
        password: encryptedPassword,
        mfaCode: this.code,
        device: this.device,
        recaptchaToken,
      };
      const response: any = await this._authService.login(data);
      this.buttonLoading = false;
      if (response?.user?.allowedBotServices && response.user.allowedBotServices > 0) {
        this._authService.plIA_HvZ6zV(response.token);
        this._authService.setUser(response.user);
        this.router.navigate(["dashboard"]).catch(() => {});
      } else {
        this._notificationService.sendNotification(
          "error",
          "You have insufficient access or your account has been terminated. Contact your RM for more information."
        );
      }
    } catch (err) {
      this.buttonLoading = false;
      this.commonService.errorHandler(err, `${this.componentName}:doVerifyMFA`, false);
      this.loginForm.reset();
      this.code = "";
      this.status = "login";
      this._notificationService.sendNotification("error", "Your authentication information is incorrect. Please try again.");
    }
  }
  ngOnDestroy(): void {
    const recaptchaElems = document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
  }
}
