<app-messenger-icon></app-messenger-icon>
<section class="messenger-video">
  <div class="messenger-video-inner">
    <video controls class="t-w-full">
      <source [src]="url" type="video/mp4" />
      <source [src]="url" type="video/webm" />
      <source [src]="url" type="video/ogg" />
    </video>
  </div>
</section>
