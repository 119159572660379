export const environment = {
  production: true,
  themeEnabled: false,
  apiUrl: "https://api.staging.omni.tatatelebusiness.com",
  nestMonstruo: "https://ikit.staging.omni.tatatelebusiness.com",
  socketCityUrl: "https://socket.staging.omni.tatatelebusiness.com/agents",
  previewSocketCityUrl: "https://socket.staging.omni.tatatelebusiness.com/channel",
  frontImageAssetsUrl: "https://staging.omni.tatatelebusiness.com/assets/images/",
  baseUrl: "https://staging.omni.tatatelebusiness.com",
  webWidgetUrl: "https://static.staging.omni.tatatelebusiness.com",
  ichnaeaUrl: "https://stg.ttl.trke.me/",
  bspUrl: "https://wb.staging.omni.tatatelebusiness.com",
  publicKey:
    "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQ0lqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FnOEFNSUlDQ2dLQ0FnRUF3Z0p5Mm4wTmozektVVml1WkVnMwpKMnZDMWt3eElvcmhFbElYSHB2M0grK0xTYkgwV0ozd1pNRS9STThRTnVQS3BuK2VxdEhIc2NudWdPK0xvMk5RCmZ3TlE3cHBlUFVoL2tEZnErb3RlYlVZN2Fad1ZsQSsxd1UxNjl5UEQwdGlMMnhxZ1FsakhheXBGWjNjekd5TVcKSWVxWDRndm1ZWG1DczBFeHlwKzR3ajI3UEhXMjBFZmhxdnUwOFp3SFl2ME84ZXcyTFl3LzFEellRUWRrajE5VwplOFRpY1gwb3NJL081RXZxWERreHlNdUxTTWhiK2tVajNYZE9kZ2xyS1JtMGp4YUwrd0RVTVg0dDNsQnJsZFI0CitvaUcwOHBvc2NTL3lwU3RBQS9TaGowaDBoc2JlbzhTdG1KbDBRdHhDd1krRlBFRkRtUU5PRzVoTkNKbkJpREMKcUM4MlZtT0lUdGNGTjBzeWZsT25XeU9rS0sxNnlLcXFmUWhSd1hycjRqTHhxRlJiOTN1c3Z6QTFzUTY0TVplLwoxbWYraTFQS0NsVmViSGdsWW1tbG5xR3VwWFNjcWNtN3JXM2VxakxnQXVYUHFMTjlkZGtDOUZucDRpVmhFSFYxCjdLWHFnUkZkUzE5VUJCMFNFeXQxN2dZMVhjWkIwREhmay9xS2M3ZnpiNWkwSjBGQkVLQVBrT3pUR3FQZVFDUC8KVElhTnk5S1dLR3gvSmZXWkJoQ2RBWXdRRlZUNHdIYU5iWGtNc1gwSlZwZHQ4WDBEMU1NUitxOGVQdlBKTTNrQgpVUkNXdEFwd1FZZGJiMzMvcGFwcDNmcDVBbzBGV2RTUWh3aVFlNHpYdTdmTUZ5NGVFQm9YcHRHU25aL3M5RFhJCjB4N2VkNTkwOUZwNmhIY3Q4WWFBTkhrQ0F3RUFBUT09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==",
  captchaSiteKey: "6Le062EpAAAAAGYx01eTOTEBI70KUbA0J276hVOp",
  ttbsAppId: "471260084522176",
  ttbsConfigurationId: "1405782263669247",
  channelTechPartner: {
    ttbs: { solutionId: "" },
    ttns: { solutionId: "" },
  },
  ttnsAppId: "758841176307108",
  ttnsConfigurationId: "1250251546393475",
  googleAuth: {
    clientId: "251796247959-s8ma9esu3o07n3uvch33c3a17icnpbbk.apps.googleusercontent.com",
  },
  enableChannels: ["WORKFLOW", "TELEGRAM", "WHATSAPP_BSP", "FACEBOOK", "WEBCHAT", "INSTAGRAM"],
  settingMenuPosition: {
    analytics: 1,
    campaign: 2,
    schedule_message: 3,
    template: 4,
    instagram: 5,
    flow_builder: 6,
    user_profiling: 7,
    live_chat: 8,
    integration: 9,
    automation: 10,
    fields: 11,
    nlp: 12,
    settings: 13,
    bot_preview: 14,
    profile: 15,
  },
  envServerName: "ttl",
  // SENTRY CONFIGS LIKE ENABLE/DISABLE, DSN, REPLY-LIMITS ETC.
  isSentry: true,
  sentryConfigs: {
    dsn: "https://96bce90031b8a2cbd991bc4fb2407dd8@o4504735106531328.ingest.us.sentry.io/4507741737189376",
    environment: "TTL-Staging",
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    release: "SENTRY_RELEASE",
  },
  platformConfig: {
    colors: {
      primaryColor: "#f56d23",
      secondaryColor: "#002f51",
      mailTextColor: "#ffffff",
      titleAndLeftSidebarColor: "#002f51",
    },
    accountUsageAnalyticsColor: {
      marketing: "#F06B23",
      utility: "#8F3606",
      authentication: "#E7D4C9",
      authenticationILDO: "#de8e5f",
      service: "#F99E6C",
      mo: "#9a4f26",
      mt: "#BF7E5B",
    },
    assets: {
      favicon: "https://dmmc0pcxko2os.cloudfront.net/platform/image_1666957052380_favicon.ico",
      welcomeImage: "https://dmmc0pcxko2os.cloudfront.net/platform/image_1666957056095_banner.png",
      logo: "https://dmmc0pcxko2os.cloudfront.net/platform/image_1666957048111_email_template.png",
    },
    urls: {
      termAndCondition: "https://www.tatatelebusiness.com/terms-and-conditions/",
      privacyAndPolicy: "https://www.tatatelebusiness.com/privacy-policy/",
    },
    name: "Smartflo",
    appPanelUrl: "https://staging.omni.tatatelebusiness.com",
    useDefaultFacebookApp: true,
    firebaseConfig: {
      apiKey: "AIzaSyBAhG4kgmmlDTGzgIAuML7BR7fV3Ia-zxI",
      authDomain: "ttl-stg.firebaseapp.com",
      databaseURL: "https://ttl-stg-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "ttl-stg",
      storageBucket: "ttl-stg.appspot.com",
      messagingSenderId: "251796247959",
      appId: "1:251796247959:web:f75895acd273f7d15660d2",
    },
  },
  productLiftConfig: {
    productLiftKey: "eb56430a-1844-4074-9873-8d3265633ea6",
    productLiftScriptUrl: "https://omni4049.productlift.dev/widgets_sdk",
  },
  integrations: {
    webEngage: true,
    moEngage: true,
    zohoDesk: true,
    shopifyV2: false,
  },
  productFruitsWorkSpace: "ihdO4UOqsTIq561G",
};
