export const countryListWithFagAndName = [
  {
    label: "English",
    value: "en",
    flag: "assets/images/united-kingdom.svg",
  },
  {
    label: "English - Australia",
    value: "en-AU",
    flag: "assets/images/australia.svg",
  },
  {
    label: "English - Canada",
    value: "en-CA",
    flag: "assets/images/canada.svg",
  },
  {
    label: "English - United Kingdom",
    value: "en-GB",
    flag: "assets/images/united-kingdom.svg",
  },
  {
    label: "English - India",
    value: "en-IN",
    flag: "assets/images/india.svg",
  },
  {
    label: "English - US",
    value: "en-US",
    flag: "assets/images/united-states.svg",
  },
  { label: "Hindi", value: "hi", flag: "assets/images/india.svg" },
  { label: "Tamil", value: "ta", flag: "assets/images/india.svg" },
  { label: "Telugu", value: "te", flag: "assets/images/india.svg" },
  { label: "Marathi", value: "mr", flag: "assets/images/india.svg" },
  { label: "Punjabi", value: "pa", flag: "assets/images/india.svg" },
  { label: "Arabic", value: "ar", flag: "assets/images/flag/arab.svg" },
  { label: "Dutch", value: "nl", flag: "assets/images/netherlands.svg" },
  { label: "Danish", value: "da", flag: "assets/images/denmark.svg" },
  { label: "French", value: "fr", flag: "assets/images/france.svg" },
  {
    label: "French - Canada",
    value: "fr-CA",
    flag: "assets/images/canada.svg",
  },
  {
    label: "French - France",
    value: "fr-FR",
    flag: "assets/images/france.svg",
  },
  { label: "German", value: "de", flag: "assets/images/germany.svg" },
  { label: "Indonesian", value: "id", flag: "assets/images/indonesia.svg" },
  { label: "Italian", value: "it", flag: "assets/images/italy.svg" },
  { label: "Japanese", value: "ja", flag: "assets/images/japan.svg" },
  { label: "Korean", value: "ko", flag: "assets/images/south-korea.svg" },
  { label: "Norwegian", value: "no", flag: "assets/images/norway.svg" },
  { label: "Polish", value: "pl", flag: "assets/images/poland.svg" },
  {
    label: "Portuguese - Brazil",
    value: "pt-BR",
    flag: "assets/images/brazil.svg",
  },
  {
    label: "Portuguese - Portugal",
    value: "pt",
    flag: "assets/images/portugal.svg",
  },
  { label: "Russian", value: "ru", flag: "assets/images/russia.svg" },
  { label: "Spanish", value: "es", flag: "assets/images/spain.svg" },
  {
    label: "Spanish - Latin America",
    value: "es-419",
    flag: "assets/images/united-states.svg",
  },
  {
    label: "Spanish - Spain",
    value: "es-ES",
    flag: "assets/images/spain.svg",
  },
  { label: "Swedish", value: "sv", flag: "assets/images/sweden.svg" },
  { label: "Thai", value: "th", flag: "assets/images/thailand.svg" },
  { label: "Turkish", value: "tr", flag: "assets/images/turkey.svg" },
  { label: "Ukrainian", value: "uk", flag: "assets/images/ukraine.svg" },
  {
    label: "Chinese - Cantonese",
    value: "zh-HK",
    flag: "assets/images/china.svg",
  },
  {
    label: "Chinese - Simplified",
    value: "zh-CN",
    flag: "assets/images/china.svg",
  },
  {
    label: "Chinese - Traditional",
    value: "zh-TW",
    flag: "assets/images/china.svg",
  },
];
