import { AfterViewInit, Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from "@angular/router";

import { environment } from "src/environments/environment";
import { CommonService } from "./services/common.service";
import { SettingService } from "./services/setting.service";
import { Colors } from "./utils/model/platform";

declare var $;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit {
  componentName = "app";
  loading;
  productLiftScriptElement: HTMLScriptElement;
  envServerName = environment.envServerName;

  constructor(
    private router: Router,
    private readonly _commonService: CommonService,
    private titleService: Title,
    private settingService: SettingService
  ) {
    if (this.envServerName === "chatomate" || this.envServerName === "ttl") {
      this.productLiftScriptElement = document.createElement("script");
      this.productLiftScriptElement.src = environment.productLiftConfig?.productLiftScriptUrl;
      document.body.appendChild(this.productLiftScriptElement);
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url === "/dashboard") {
          this.loading = true;
        }
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        if (event.url === "/dashboard") {
          this.loading = false;
        }
      }
    });
    this.initPlatformConfigs()
      .then()
      .catch(() => {});
  }

  public async initPlatformConfigs() {
    if (environment.envServerName === "chatomate" && location.origin !== environment.platformConfig.appPanelUrl) {
      try {
        const response = await this.settingService.getPlatformDetail(location.origin);
        const title = response?.data?.name ? response.data.name : this.settingService.platformTitle;
        const favicon = response?.data?.assets?.favicon ? response.data.assets.favicon : "favicon.ico";
        this.setTitle(title, favicon);
        if (response?.data?.colors) {
          this.setTheme(response.data.colors);
        }
      } catch (err) {
        this._commonService.errorHandler(err, `${this.componentName}:initPlatformConfigs`, false);
      }
    } else {
      this.settingService.whiteLabelData = { data: environment.platformConfig };
      this.setTitle(environment.platformConfig?.name, environment.platformConfig?.assets?.favicon);
      this.setTheme(environment.platformConfig?.colors);
      this.settingService.whiteLabelDataEvent.next(true);
    }
  }

  public setTitle(newTitle: string, favicon) {
    this.titleService.setTitle(newTitle);
    localStorage.setItem("platformTitle", newTitle);
    let link;
    link = document.getElementById("icon");
    link.rel = "icon";
    link.href = favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  setTheme(colors: Colors) {
    if (colors) {
      const root = $(":root");
      root.css("--primaryColor", colors.primaryColor);
      root.css("--secondaryColor", colors.secondaryColor);
      root.css("--titleAndLeftSidebarColor", colors.titleAndLeftSidebarColor);
      root.css("--loaderColor", colors.primaryColor);
      for (let i = 1; i < 8; i++) {
        root.css(`--primaryColorShade${i}`, colors.primaryColor + i * 14);
      }
    }
  }
}
