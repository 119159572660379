import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from "src/environments/environment";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  sentryErrorHandler: ErrorHandler;

  constructor() {
    if (environment.isSentry) {
      this.sentryErrorHandler = Sentry.createErrorHandler({
        showDialog: false,
      });
    }
  }

  handleError(error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      if (confirm(`New Version Available! \nHey there! We've just released a new update for our app. Please reload your screen once.`)) {
        window.location.reload();
      }
    } else {
      if (environment.isSentry) {
        this.sentryErrorHandler.handleError(error);
      }
    }
  }
}
