import { Component, Input } from "@angular/core";

@Component({
  selector: "app-messenger-pdf",
  templateUrl: "./messenger-pdf.component.html",
  styleUrls: ["../messenger.common-bot.component.scss"],
})
export class MessengerPdfComponent {
  @Input() url: string;
  @Input() name: string;
}
