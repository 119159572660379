<div class="login-container">
  <div class="login-background" *ngIf="!isLoading">
    <div class="login-form">
      <div class="login-form-wrapper">
        <!--Login Form-->
        <ng-container *ngIf="envServerName !== 'ttl'">
          <div class="login-form-left-background" *ngIf="status === 'login'">
            <div>
              <h3 class="color-primary t-font-medium t-text-2xl">Welcome to your account</h3>
            </div>
            <form [formGroup]="loginForm" class="t-pt-5">
              <div class="t-mb-2.5">
                <mat-form-field appearance="fill" floatLabel="never" class="input input-gray input-bottom-line">
                  <input
                    matInput
                    id="chatomate-login-email-input"
                    autocomplete="off"
                    type="email"
                    placeholder="Email address"
                    formControlName="email" />
                </mat-form-field>
              </div>
              <div class="t-mb-2.5">
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray input-bottom-line">
                  <input
                    matInput
                    id="chatomate-login-password"
                    autocomplete="off"
                    type="password"
                    placeholder="Password"
                    formControlName="password" />
                </mat-form-field>
              </div>
              <div class="t-flex sm:t-flex-row t-flex-col-reverse t-justify-between sm:t-items-center t-items-start t-mb-5 t-gap-2.5">
                <mat-checkbox color="primary" class="checkbox t-text-base t-font-medium t-flex-1" (change)="toggleRemember($event)"
                  >Remember Me
                </mat-checkbox>
                <div *ngIf="envServerName !== 'airtel'" class="t-flex t-justify-end t-flex-wrap sm:t-p-0 t-pl-2.5 t-flex-1">
                  <div class="forgot-password">
                    <a
                      class="t-text-base t-font-medium t-underline t-cursor-pointer t-outline-none"
                      routerLink="/forgot"
                      (click)="checkForEmail('forgot')"
                      >Forgot Password?</a
                    >
                  </div>
                </div>
              </div>
              <button type="submit" id="chatomate-login-button" class="btn primary-btn" mat-flat-button color="primary" (click)="doLogin()">
                <span *ngIf="buttonLoading" class="t-flex t-justify-center"
                  ><mat-spinner diameter="20" class="mat-spinner-color"></mat-spinner
                ></span>
                <span *ngIf="!buttonLoading">Login</span>
              </button>
              <div style="display: none">
                <button id="ModelOpen" (click)="openModel()"></button>
              </div>
            </form>
            <div class="footer-main">
              <div
                class="footer t-absolute t-left-0 t-flex t-justify-center t-w-full t-text-sm t-bottom-2.5"
                id="chatomate-login-term&conditions-link">
                <a [href]="termAndCondition" target="_blank">Term & Conditions</a><span class="t-my-0 t-mx-1">|</span>
                <a [href]="privacyAndPolicy" target="_blank">Privacy Policy</a>
              </div>
            </div>
          </div>
          <!--MFA-->
          <div class="login-form-left-background t-gap-[20px]" *ngIf="status === 'MFA'">
            <div>
              <h3 class="authenticate">Authenticate Your Account</h3>
            </div>
            <div class="mfa-container">
              <mat-form-field appearance="fill" floatLabel="never" class="input input-gray input-bottom-line">
                <input
                  matInput
                  placeholder="Enter 2FA Code"
                  autocomplete="off"
                  maxlength="6"
                  type="text"
                  [(ngModel)]="code"
                  (keydown.enter)="doVerifyMFA()"
                  NumberDirective
                  onkeydown="javascript: return event.keyCode === 8 ||event.keyCode === 46 || event.keyCode === 38 ||event.keyCode === 40|| event.keyCode === 37 ||event.keyCode === 39 ? true : !isNaN(Number(event.key));" />
              </mat-form-field>
            </div>
            <div class="t-flex t-flex-col t-flex-wrap t-content-start t-justify-start t-items-start t-gap-[20px]">
              <button
                type="submit"
                id="chatomate-mfa-submit-button"
                class="btn primary-btn hover-effect"
                mat-flat-button
                color="primary"
                (click)="doVerifyMFA()">
                <span *ngIf="buttonLoading" class="t-flex t-justify-center"
                  ><mat-spinner diameter="20" class="mat-spinner-color"> </mat-spinner
                ></span>
                <span *ngIf="!buttonLoading">Submit</span>
              </button>
              <div class="width-fit-content">
                <a class="cancel-mfa-text text-underline" routerLink="/login" (click)="status = 'login'">Cancel</a>
              </div>
            </div>
            <div>
              <div
                class="footer t-absolute t-left-0 t-flex t-justify-center t-w-full t-text-sm t-bottom-2.5"
                id="chatomate-mfa-term&conditions-link">
                <a [href]="termAndCondition" target="_blank">Term & Conditions </a><span>|</span>
                <a [href]="privacyAndPolicy" target="_blank">Privacy Policy</a>
              </div>
            </div>
          </div>
          <!--Forget Password Form-->
          <div *ngIf="envServerName !== 'airtel' && status === 'forgot'" class="login-form-left-background">
            <div>
              <h3 class="color-primary t-text-2xl t-font-medium t-mb-5">Don't remember your password?</h3>
            </div>
            <h4 class="t-mb-5 t-font-medium t-mb-5">Please enter your email address to reset your password.</h4>
            <form [formGroup]="forgetPassForm">
              <div class="login-form-input t-mb-2.5">
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray input-bottom-line">
                  <input
                    matInput
                    id="chatomate-forgot-email-input"
                    autocomplete="off"
                    type="email"
                    formControlName="email"
                    placeholder="Email address" />
                </mat-form-field>
              </div>
              <div class="t-flex text-underline t-underline t-flex-wrap t-justify-end t-mb-2.5">
                <div class="go-to-login" id="chatomate-go-login-link">
                  <a
                    class="t-text-base t-font-medium t-underline t-cursor-pointer t-outline-none"
                    routerLink="/login"
                    (click)="checkForEmail('login')"
                    >Go To Login</a
                  >
                </div>
              </div>
              <button
                id="chatomate-sendmail-button"
                class="btn primary-btn hover-effect"
                mat-flat-button
                color="primary"
                (click)="onSendMail()">
                <span *ngIf="buttonLoading" class="t-flex t-justify-center"
                  ><mat-spinner diameter="20" class="mat-spinner-color"></mat-spinner
                ></span>
                <span *ngIf="!buttonLoading"> Send Mail</span>
              </button>
            </form>
            <div>
              <div
                class="footer t-absolute t-left-0 t-flex t-justify-center t-w-full t-text-sm t-bottom-2.5"
                id="chatomate-forgot-term&conditions-link">
                <a [href]="termAndCondition" target="_blank">Term & Conditions</a><span class="t-my-0 t-mx-1">|</span>
                <a [href]="privacyAndPolicy" target="_blank">Privacy Policy</a>
              </div>
            </div>
          </div>
          <!--Change password Form-->
          <div class="login-form-left-background" *ngIf="status === 'reset' || status === 'set-password'">
            <div>
              <h3 class="heading-4 color-primary t-font-medium">
                {{ status === "set-password" ? "Set your password" : "Reset your password" }}
              </h3>
            </div>
            <form [formGroup]="resetPassForm" class="t-pt-5">
              <div>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray input-bottom-line">
                  <input
                    matInput
                    id="chatomate-reset-old-password"
                    autocomplete="off"
                    type="password"
                    formControlName="password"
                    placeholder="Password" />
                </mat-form-field>
              </div>
              <div class="mt-10">
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray input-bottom-line">
                  <input
                    matInput
                    id="chatomate-reset-confirmPassword"
                    autocomplete="off"
                    type="password"
                    formControlName="confirmPassword"
                    placeholder="Confirm password" />
                </mat-form-field>
              </div>
              <div class="t-mt-2.5">
                <button class="btn primary-btn hover-effect" mat-flat-button color="primary" (click)="onResetPass()">
                  <span *ngIf="buttonLoading" class="t-flex t-justify-center"
                    ><mat-spinner diameter="20" class="mat-spinner-color"></mat-spinner
                  ></span>
                  <span *ngIf="!buttonLoading"> {{ status === "set-password" ? "Set Password" : "Reset Password" }}</span>
                </button>
              </div>
            </form>
            <div class="footer-main">
              <div class="footer" id="chatomate-reset-term&conditions-link">
                <a [href]="termAndCondition" target="_blank">Term & Conditions </a><span>|</span>
                <a [href]="privacyAndPolicy" target="_blank">Privacy Policy</a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="envServerName === 'ttl'">
          <div class="ttl-login-form-left-background" *ngIf="status === 'login'">
            <div class="TTLlogo">
              <img id="login-ttl-logo" src="assets/images/tata-tele-logo.png" alt="TTLlogo" />
            </div>
            <div class="ttl-login-form">
              <span class="heading-4 t-font-semibold t-text-2xl font-ttl"> Sign in to your account </span>

              <form [formGroup]="loginForm" class="t-pt-5">
                <div>
                  <mat-form-field appearance="fill" floatLabel="never" class="input input-gray input-bottom-line">
                    <input
                      matInput
                      id="ttl-login-email-input"
                      autocomplete="off"
                      type="email"
                      placeholder="Email address"
                      formControlName="email" />
                  </mat-form-field>
                </div>
                <div class="t-mt-2.5">
                  <mat-form-field floatLabel="never" appearance="fill" class="input input-gray input-bottom-line">
                    <input
                      matInput
                      id="ttl-login-password"
                      autocomplete="off"
                      type="password"
                      placeholder="Password"
                      formControlName="password" />
                  </mat-form-field>
                </div>
                <div class="t-mt-5">
                  <button
                    type="submit"
                    id="ttl-login-button"
                    class="login-btn btn primary-btn hover-effect"
                    mat-flat-button
                    color="primary"
                    (click)="doLogin()">
                    <span *ngIf="buttonLoading" class="t-flex t-justify-center"
                      ><mat-spinner diameter="20" class="mat-spinner-color"></mat-spinner
                    ></span>
                    <span *ngIf="!buttonLoading">Login</span>
                  </button>
                </div>
                <div class="t-flex t-justify-between t-items-center t-mt-[15px] t-flex-wrap">
                  <div>
                    <mat-checkbox id="keep-me-login-checkbox" class="checkbox checkbox-secondary" (change)="toggleRemember($event)"
                      >Keep me logged in</mat-checkbox
                    >
                  </div>
                  <div class="forgot-password">
                    <a
                      class="font-ttl t-font-semibold text-underline t-cursor-pointer t-outline-none"
                      id="forgot-password-link"
                      routerLink="/forgot"
                      (click)="checkForEmail('forgot')"
                      >Forgot your password?</a
                    >
                  </div>
                </div>
                <div class="t-hidden">
                  <button id="ModelOpen" (click)="openModel()"></button>
                </div>
              </form>
            </div>
            <div class="footer-main">
              <div class="footer" id="ttl-login-term&conditions-link">
                <a [href]="termAndCondition" target="_blank">Term & Conditions</a><span>|</span>
                <a [href]="privacyAndPolicy" target="_blank">Privacy Policy</a>
              </div>
            </div>
          </div>
          <div *ngIf="status === 'forgot'" class="ttl-login-form-left-background">
            <div class="TTLlogo">
              <img id="forgot-ttl-logo" src="assets/images/tata-tele-logo.png" alt="TTLlogo" />
            </div>
            <div class="ttl-login-form">
              <span class="heading-4 t-font-semibold t-text-2xl font-ttl t-mb-[40px]"> Don't remember your password? </span>
              <span class="t-font-medium align-span"> Please enter your email address to reset your password. </span>
              <form [formGroup]="forgetPassForm" class="t-pt-[5px] t-w-full">
                <div class="login-form-input">
                  <mat-form-field floatLabel="never" appearance="fill" class="input input-gray input-bottom-line">
                    <input
                      matInput
                      id="ttl-forgot-email-input"
                      autocomplete="off"
                      type="email"
                      formControlName="email"
                      placeholder="Email address" />
                  </mat-form-field>
                </div>
                <div class="t-flex text-underline t-mt-2.5 t-flex-wrap t-justify-between">
                  <div class="go-to-login">
                    <a
                      class="t-text-base t-font-medium t-underline t-cursor-pointer t-outline-none"
                      routerLink="/login"
                      (click)="checkForEmail('login')"
                      >Go To Login</a
                    >
                  </div>
                </div>
                <div class="t-mt-2.5">
                  <button
                    id="ttl-sendmail-button"
                    class="btn primary-btn hover-effect login-btn"
                    mat-flat-button
                    color="primary"
                    (click)="onSendMail()">
                    <span *ngIf="buttonLoading" class="t-flex t-justify-center"
                      ><mat-spinner diameter="20" class="mat-spinner-color"></mat-spinner
                    ></span>
                    <span *ngIf="!buttonLoading">Send Mail</span>
                  </button>
                </div>
              </form>
            </div>
            <div>
              <div class="footer-main">
                <div class="footer" id="ttl-forgot-term&conditions-link">
                  <a [href]="termAndCondition" target="_blank">Term & Conditions</a><span>|</span>
                  <a [href]="privacyAndPolicy" target="_blank">Privacy Policy</a>
                </div>
              </div>
            </div>
          </div>
          <div class="ttl-login-form-left-background" *ngIf="status === 'reset' || status === 'set-password'">
            <img id="reset-ttl-logo" src="assets/images/tata-tele-logo.png" alt="TTLlogo" />
            <div class="ttl-login-form">
              <span class="heading-4 t-font-semibold t-text-2xl font-ttl">
                {{ status === "set-password" ? "Set your password" : "Reset your password" }}
              </span>

              <form [formGroup]="resetPassForm" class="t-pt-5">
                <div>
                  <mat-form-field floatLabel="never" appearance="fill" class="input input-gray input-bottom-line">
                    <input
                      matInput
                      id="ttl-reset-old-password"
                      autocomplete="off"
                      type="password"
                      formControlName="password"
                      placeholder="Password" />
                  </mat-form-field>
                </div>
                <div class="mt-10">
                  <mat-form-field floatLabel="never" appearance="fill" class="w-100 input__label--disabled accent">
                    <input
                      matInput
                      id="ttl-reset-confirmPassword"
                      autocomplete="off"
                      type="password"
                      formControlName="confirmPassword"
                      placeholder="Confirm password" />
                  </mat-form-field>
                </div>
                <div class="t-mt-2.5">
                  <button class="btn primary-btn hover-effect login-btn" mat-flat-button color="primary" (click)="onResetPass()">
                    <span *ngIf="buttonLoading" class="t-flex t-justify-center"
                      ><mat-spinner diameter="20" class="mat-spinner-color"></mat-spinner
                    ></span>
                    <span *ngIf="!buttonLoading"> {{ status === "set-password" ? "Set Password" : "Reset Password" }}</span>
                  </button>
                </div>
              </form>
            </div>
            <div class="footer-main">
              <div class="footer" id="ttl-reset-term&conditions-link">
                <a [href]="termAndCondition" target="_blank">Term & Conditions </a><span>|</span>
                <a [href]="privacyAndPolicy" target="_blank">Privacy Policy</a>
              </div>
            </div>
          </div>
          <!--MFA-->
          <div class="ttl-login-form-left-background" *ngIf="status === 'MFA'">
            <div class="TTLlogo">
              <img id="2fa-ttl-logo" src="assets/images/tata-tele-logo.png" alt="TTLlogo" />
            </div>
            <div class="ttl-login-form">
              <div>
                <span class="heading-4 t-font-semibold t-text-2xl font-ttl"> Authenticate Your Account </span>
              </div>
              <div class="mfa-container t-w-full t-pt-5">
                <mat-form-field appearance="fill" floatLabel="never" class="input input-gray input-bottom-line">
                  <input
                    matInput
                    placeholder="Enter 2FA Code"
                    autocomplete="off"
                    maxlength="6"
                    type="text"
                    [(ngModel)]="code"
                    (keydown.enter)="doVerifyMFA()"
                    NumberDirective
                    onkeydown="javascript: return event.keyCode === 8 ||event.keyCode === 46 || event.keyCode === 38 ||event.keyCode === 40|| event.keyCode === 37 ||event.keyCode === 39 ? true : !isNaN(Number(event.key));" />
                </mat-form-field>
              </div>
              <div class="t-mt-5 t-w-full content-align-col">
                <button
                  type="submit"
                  id="ttl-mfa-submit-button"
                  class="btn primary-btn hover-effect login-btn"
                  mat-flat-button
                  color="primary"
                  (click)="doVerifyMFA()">
                  <span *ngIf="buttonLoading" class="d-flex just-center"
                    ><mat-spinner diameter="20" class="mat-spinner-color"></mat-spinner
                  ></span>
                  <span *ngIf="!buttonLoading">Submit</span>
                </button>
                <div class="width-fit-content">
                  <a id="ttl-mfa-cancel-button" class="font-ttl" routerLink="/login" (click)="status = 'login'; getCaptcha()">Cancel</a>
                </div>
              </div>
            </div>
            <div class="footer-main">
              <div class="footer" id="ttl-mfa-term&conditions-link">
                <a [href]="termAndCondition" target="_blank">Term & Conditions </a><span>|</span>
                <a [href]="privacyAndPolicy" target="_blank">Privacy Policy</a>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="welcomeImage" class="login-form-right-background">
          <div class="image-container t-flex-1">
            <img class="bot-image t-w-full t-h-full t-object-cover" id="login-image" [src]="welcomeImage" alt="welcome image" />
          </div>
        </div>
        <div *ngIf="!welcomeImage" class="login-form-right-background background-primary">
          <div class="image-container">
            <img class="bot-image" id="netcore-login-image" src="assets/images/kevit_chatbot.svg" alt="welcome image" />
          </div>
          <div>
            <h1 class="font-white welcome-text">
              <span class="heading-3 font-white t-font-bold">Cognitive Automation</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="loader">
    <app-loader [isMainLoader]="true"></app-loader>
  </div>
</div>
