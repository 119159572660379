import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import anchorme from "anchorme";

import { FlowBuilderService } from "src/app/services/flowbuilder.service";
import {
  DOC_TYPE,
  EXCEL_TYPE,
  MEDIA_TYPES,
  PPT_TYPE,
  VIDEO_TYPES,
  MIME_TYPE_MAPPING,
} from "src/app/utils/consts/channelSupportedMediaTypes";
import { CommonService } from "../../services/common.service";
import { base64Image } from "../../utils/consts/base64Image.const";
import { DialogComponent } from "../dialog/dialog.component";
import { FlowStepReplyButtonType, AddressMessageFieldType } from "../../utils/consts/flow-step.const";
import { CHANNEL_NAMES } from "../../../app/utils/model/channels";
import { TemplateService } from "../../services/template.service";

@Component({
  selector: "app-conversation",
  templateUrl: "./conversation.component.html",
  styleUrls: ["./conversation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationComponent implements OnInit, OnChanges {
  componentName = "conversation";

  @Input() data;
  @Input() transcriptDataNewBatch;
  @Input() channel;
  @Input() tags;
  @Output() dateLabel = new EventEmitter();
  currentImage;
  dates = [];
  currentDate = new Date();
  transcriptArr = [];
  @Input() parentComponent;
  isShowSticky = true;
  dateLabels = [];
  botIcon;
  addressMessageOBJ = {
    [AddressMessageFieldType.NAME]: "",
    [AddressMessageFieldType.PHONE_NUMBER]: "",
    [AddressMessageFieldType.IN_PIN_CODE]: "",
    [AddressMessageFieldType.HOUSE_NUMBER]: "",
    [AddressMessageFieldType.FLOOR_NUMBER]: "",
    [AddressMessageFieldType.TOWER_NUMBER]: "",
    [AddressMessageFieldType.BUILDING_NAME]: "",
    [AddressMessageFieldType.ADDRESS]: "",
    [AddressMessageFieldType.LANDMARK_AREA]: "",
    [AddressMessageFieldType.CITY]: "",
    [AddressMessageFieldType.STATE]: "",
  };
  addressMessageFields = [
    { label: "PIN Code", value: AddressMessageFieldType.IN_PIN_CODE },
    { label: "House Number", value: AddressMessageFieldType.HOUSE_NUMBER },
    { label: "Floor Number", value: AddressMessageFieldType.FLOOR_NUMBER },
    { label: "Tower Number", value: AddressMessageFieldType.TOWER_NUMBER },
    { label: "Building Name", value: AddressMessageFieldType.BUILDING_NAME },
    { label: "Address", value: AddressMessageFieldType.ADDRESS },
    { label: "Landmark/Area", value: AddressMessageFieldType.LANDMARK_AREA },
    { label: "City", value: AddressMessageFieldType.CITY },
    { label: "State", value: AddressMessageFieldType.STATE },
  ];
  showSavedAddresses: boolean = false;
  saved_addresses = [];
  profilePic = base64Image.demoPersonImage;
  agentPic = base64Image.agentMessageImage;
  listData;
  catalogueType;
  productData;
  EXCEL_TYPE = EXCEL_TYPE;
  DOC_TYPE = DOC_TYPE;
  VIDEO_TYPES = VIDEO_TYPES;
  PPT_TYPE = PPT_TYPE;
  ALLOWED_TYPES = [
    ...MIME_TYPE_MAPPING.image,
    ...MIME_TYPE_MAPPING.audio,
    ...MIME_TYPE_MAPPING.csv,
    ...MIME_TYPE_MAPPING.doc,
    ...MIME_TYPE_MAPPING.pdf,
    ...MIME_TYPE_MAPPING.ppt,
    ...MIME_TYPE_MAPPING.video,
    ...MIME_TYPE_MAPPING.xls,
  ];
  sourceMapping = {
    flow: "Flow",
    userProfile: "User Profile",
    liveChat: "Live Chat",
  };
  flowListMapping = {};
  workflowChannelFlowListMapping = {};
  tagMapping = {};
  tagActionMapping = {
    "tagAdded.notExisted": "Tag added: ",
    "tagAdded.existed": "Tag already added: ",
    "tagRemoved.existed": "Tag removed: ",
    "tagRemoved.notExisted": "Tag removed: ",
  };
  paymentStatusMapping = {
    success: { message: "Payment Done", icon: "check_circle" },
    failed: { message: "Payment Failed", icon: "cancel" },
  };
  WhatsappFlowDetails;
  orderPaymentData;
  orderStatusesObj = this.commonService.orderStatuses?.reduce((result, status) => {
    result[status.value] = status.displayValue;
    return result;
  }, {});
  FlowStepReplyButtonType = FlowStepReplyButtonType;
  dialogRef;
  CHANNEL_NAMES = CHANNEL_NAMES;
  MEDIA_TYPES = MEDIA_TYPES;
  tagTypeMapping = {
    userTag: "User",
    agentTag: "Agent",
  };

  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public readonly commonService: CommonService,
    public readonly _templateService: TemplateService,
    private readonly flowBuilder: FlowBuilderService,
    private detector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getBotIcon();
    this.getChannelSpecificFlows().catch(() => {});
    this.createTagMap();
    if (this.channel === CHANNEL_NAMES.WHATSAPP_BSP) {
      this.getWorkflowChannelFlows();
    }
  }

  async getChannelSpecificFlows() {
    try {
      const flowList = await this.flowBuilder.getChannelFlowList(null, this.channel, "flows");
      flowList.forEach(flow => {
        this.flowListMapping[flow._id] = flow.flowName;
      });
      this.detector.detectChanges();
    } catch (err) {
      this.commonService.errorHandler(err, `${this.componentName}:getChannelSpecificFlows`, false);
      this.detector.detectChanges();
    }
  }

  async getWorkflowChannelFlows() {
    try {
      const flowList = await this.flowBuilder.getChannelFlowList(null, CHANNEL_NAMES.WORKFLOW, "flows");
      flowList.forEach(flow => {
        this.workflowChannelFlowListMapping[flow._id] = flow.flowName;
      });
      this.detector.detectChanges();
    } catch (err) {
      this.commonService.errorHandler(err, `${this.componentName}:getWorkflowChannelFlows`, false);
      this.detector.detectChanges();
    }
  }

  getConversationDate() {
    let tempArr = JSON.parse(JSON.stringify(this.transcriptDataNewBatch));
    let datePushCount = 0;
    let pointer = 1;
    if (this.transcriptDataNewBatch.length || this.transcriptDataNewBatch.length === 0) {
      this.transcriptDataNewBatch.forEach((conversation, index) => {
        if (conversation) {
          if (new Date(this.transcriptArr[1]?.createdAt)?.toDateString() === new Date(conversation.createdAt)?.toDateString() && pointer) {
            this.transcriptArr.shift();
            --pointer;
          }
          if (
            index === 0 ||
            new Date(conversation.createdAt).toDateString() !== new Date(this.transcriptDataNewBatch[index - 1]?.createdAt).toDateString()
          ) {
            const dateLabel =
              new Date(conversation.createdAt).toDateString() === this.currentDate.toDateString()
                ? "Today"
                : new Date(conversation.createdAt);
            tempArr.splice(index + datePushCount, 0, { date: dateLabel, _id: "date" + Date.now(), type: "conversationDate" });
            this.dateLabels.push(dateLabel);
            datePushCount++;
          }

          if (conversation.type === "productMulti") {
            conversation.productMulti["noOfMultiProducts"] = conversation.productMulti?.action?.sections?.flatMap(
              ({ items }) => items
            ).length;
          } else if (conversation.type === "order") {
            let noOfOrders = 0;
            let totalPriceOfOrder = 0;
            conversation.order?.items?.forEach(item => {
              noOfOrders = noOfOrders + Number(item.quantity);
              totalPriceOfOrder = totalPriceOfOrder + item.quantity * Number(item.price);
            });
            conversation.order["noOfOrders"] = noOfOrders;
            conversation.order["totalPriceOfOrder"] = totalPriceOfOrder;
          }
        }
      });
      this.transcriptArr = tempArr.concat(this.transcriptArr);
    } else {
      if (!this.dateLabels.includes("Today")) {
        this.transcriptArr.splice(this.transcriptArr.length, 0, { date: "Today", _id: "date" + Date.now(), type: "conversationDate" });
        this.dateLabels.push("Today");
      }
      this.transcriptArr.push(this.transcriptDataNewBatch);
    }
    setTimeout(() => {
      this.dateLabel.emit(document.getElementById("date0"));
      let container = document.getElementById("chat-area");
      this.isShowSticky = container?.scrollHeight > container?.offsetHeight;
    });
  }

  trackByFn(index, item) {
    return item._id;
  }

  createTagMap() {
    this.tags.forEach(tag => {
      this.tagMapping[tag._id] = tag.tag;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.tags?.currentValue?.length) {
        this.tags = changes.tags.currentValue;
        this.createTagMap();
      }
      if (changes.transcriptDataNewBatch?.currentValue) {
        this.getConversationDate();
      }
      this.detector.detectChanges();
    }
  }

  generateHyperLinkInText(text) {
    if (!text?.trim()) return "";
    return anchorme({
      input: text,
      options: {
        attributes: {
          target: "_blank",
          class: "a-link",
        },
      },
    });
  }

  getBotIcon() {
    if (localStorage.getItem("BotIconBase64")) {
      this.botIcon = localStorage.getItem("BotIconBase64");
    } else if (localStorage.getItem("BotIcon")) {
      this.botIcon = localStorage.getItem("BotIcon");
    } else {
      this.botIcon = base64Image.botFaceImage;
    }
  }

  updateIndexOfCarousel(index, type, el, display?) {
    if (type === "prev") {
      el.moveLeft();
    } else if (type === "next") {
      el.moveRight();
    }
  }

  addressMessageFillDetails(template, message) {
    if (message.prefilledValues)
      this.addressMessageOBJ = {
        name: message.prefilledValues?.name || null,
        phone_number: message.prefilledValues?.phone_number || null,
        in_pin_code: message.prefilledValues?.in_pin_code || null,
        house_number: message.prefilledValues?.house_number || null,
        floor_number: message.prefilledValues?.floor_number || null,
        tower_number: message.prefilledValues?.tower_number || null,
        building_name: message.prefilledValues?.building_name || null,
        address: message.prefilledValues?.address || null,
        landmark_area: message.prefilledValues?.landmark_area || null,
        city: message.prefilledValues?.city || null,
        state: message.prefilledValues?.state || null,
      };
    if (message.saved_addresses) {
      this.saved_addresses = message.saved_addresses;
      this.showSavedAddresses = true;
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: "Provide address", template },
      height: "auto",
      width: "28vw",
      autoFocus: false,
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.showSavedAddresses = false;
      this.addressMessageOBJ = {
        [AddressMessageFieldType.NAME]: "",
        [AddressMessageFieldType.PHONE_NUMBER]: "",
        [AddressMessageFieldType.IN_PIN_CODE]: "",
        [AddressMessageFieldType.HOUSE_NUMBER]: "",
        [AddressMessageFieldType.FLOOR_NUMBER]: "",
        [AddressMessageFieldType.TOWER_NUMBER]: "",
        [AddressMessageFieldType.BUILDING_NAME]: "",
        [AddressMessageFieldType.ADDRESS]: "",
        [AddressMessageFieldType.LANDMARK_AREA]: "",
        [AddressMessageFieldType.CITY]: "",
        [AddressMessageFieldType.STATE]: "",
      };
    });
  }

  openImagePreview(template, imageUrl) {
    this.currentImage = imageUrl;
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: "Preview", template, isShowSave: false, imageUrl },
      height: "auto",
      width: "auto",
    });
  }

  showListItem(template, actions) {
    this.listData = actions;
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: actions.button_text, template },
      height: "auto",
      width: "30vw",
    });
  }

  showCatalogueItems(template, productData, type) {
    this.productData = productData;
    this.catalogueType = type;
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: this.catalogueType === "order" ? "Your Cart" : "Shop With Us", template },
      height: "auto",
      width: this.productData.isDynamicProduct ? "30vw" : "35vw",
    });
  }

  showWhatsappFlowDetails(template, whatsappFlowData) {
    this.WhatsappFlowDetails = whatsappFlowData;
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: "Form", template },
      height: "auto",
      width: "30vw",
    });
  }

  showOrderPaymentDetails(template, orderPaymentData) {
    this.orderPaymentData = orderPaymentData;
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: "Order Details", template, type: "orderPaymentDetails" },
      width: "25vw",
      height: "70vh",
    });
  }

  opneCTAUrl(url: string) {
    window.open(url, "_blank");
  }
}
