import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UsersService } from "../services/users.service";
import { TagsService } from "../services/tags.service";
import { BotService } from "../services/bot.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CommonService } from "../services/common.service";
import { TagType } from "../utils/model/customField";

declare var $: any;

@Component({
  selector: "app-user-transcript",
  templateUrl: "./user-transcript.component.html",
  styleUrls: ["./user-transcript.component.scss"],
})
export class UserTranscriptComponent implements OnInit {
  componentName = "user-transcript";

  isLoading = true;
  show: any = true;
  tags = [];
  selectedUser;
  userId;
  data;
  isScrollDone = false;
  loading = false;
  lastLoadedPage;
  otherFi: any;
  userExperienceFi: any;

  botIcon;
  tagForm;
  dialogRef;
  navigation = {
    isCustom: false,
    navigation: [],
  };
  scheduleMessageName = "User Detail";
  topLabel;
  dateLabels: any = [];
  transcriptDataNewBatch;
  constructor(
    private _userService: UsersService,
    protected route: ActivatedRoute,
    private _tagsService: TagsService,
    private _botService: BotService,
    private commonService: CommonService
  ) {
    this.tagForm = new UntypedFormGroup({
      tag: new UntypedFormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    if (this.route.snapshot.params.id) {
      this.userId = this.route.snapshot.params.id;
      this.showUser(this.route.snapshot.params.id);
      this.getTags();
    }
  }

  showUser(userId) {
    this.show = true;
    this.isLoading = true;
    this._userService
      .getUserDetailsById(userId)
      .then((res: any) => {
        this.selectedUser = res;
        this.lastLoadedPage = res.totalPages;
        this.selectedUser["firstSeen"] = "";
        this.selectedUser["lastSeen"] = "";
        let FI = [];
        this.otherFi = [];
        this.userExperienceFi = [];
        const customFieldsKey = Object.keys(this.selectedUser.customFields);
        for (const customField of customFieldsKey) {
          let currentFI = this.selectedUser.customFields[customField];
          if (customField === "first_interacted_at") {
            this.selectedUser.firstSeen = currentFI;
          } else if (customField === "last_interacted_at") {
            this.selectedUser.lastSeen = currentFI;
          } else if (customField === "profile_pic") {
            if (currentFI) {
              this.selectedUser.profile = currentFI;
            } else {
              this.selectedUser.profile = "../../../../assets/images/demo_person.jpg";
            }
          }
        }

        if (localStorage.getItem("BotIcon")) {
          this.botIcon = localStorage.getItem("BotIcon");
        } else {
          this.botIcon = "../../../assets/images/Bot-face.svg";
        }
        try {
          this.loading = false;
          this.data = {};
          this.data["selectedUser"] = {};
          this.data["selectedUser"] = this.selectedUser;
          this.data.selectedUser.transcript = [];
          this.getUserTranscript(true);
        } catch (e) {
          this.commonService.errorHandler(e, `${this.componentName}:showUser:getUserTranscript`, false);
        }
      })
      .catch(err => {
        this.commonService.errorHandler(err, `${this.componentName}:showUser:getUserDetailsById`, true);
      });
  }

  getTags() {
    this._tagsService
      .getAllTagsList(this._botService.getBot(), TagType.USER)
      .then((result: any) => {
        if (result) {
          this.tags.length = 0;
          this.tags = result.tags;
        } else {
          this.tags = [];
        }
        this.removeLoader();
      })
      .catch(err => {
        this.commonService.errorHandler(err, `${this.componentName}:getTags:getAllTagsList`, true);
        this.removeLoader();
      });
  }

  removeLoader() {
    this.isLoading = false;
    this.show = false;
  }

  onScrollUp() {
    if (this.lastLoadedPage) {
      this.getUserTranscript(false);
    }
  }

  getUserTranscript(flag) {
    if (this.lastLoadedPage) {
      this._userService
        .getShowUserDetailsById(this.userId, {
          page: this.lastLoadedPage,
          channel: this.data.selectedUser?.botChannel,
          botId: this.data.selectedUser?.botId,
        })
        .then((res: any) => {
          this.lastLoadedPage--;
          this.transcriptDataNewBatch = res.docs;
          this.data.selectedUser.transcript = res.docs.concat(this.data.selectedUser.transcript);
          if (res["docs"].length < 50 && this.lastLoadedPage) {
            this.getUserTranscript(flag);
            return;
          }
          if (flag) {
            this.isScrollDone = false;
            setTimeout(() => {
              this.chatomateScroll(false);
            }, 100);
          }
          this.removeLoader();
        })
        .catch(err => {
          this.commonService.errorHandler(err, `${this.componentName}:getUserTranscript:getShowUserDetailsById`, true);
        });
    }
  }

  chatomateScroll(isMsg) {
    const container = document.getElementById("chat-area");
    if (container) {
      if (container.scrollHeight > container.offsetHeight) {
        if (!isMsg) {
          this.setSmoothScroll("unset");
          this.isScrollDone = false;
        }
        container.scrollTop = container.scrollHeight;
        setTimeout(() => {
          this.setScroll(true);
          this.setSmoothScroll("smooth");
        }, 100);
      } else {
        // if(!isMsg) {
        this.setSmoothScroll("smooth");
        this.setScroll(true);
        // }
      }
    }
  }
  onScroll() {
    let chatArea = document.getElementById("chat-area");
    let currentLabel = null;
    this.dateLabels.forEach((dateLabel, di) => {
      // @ts-ignore
      if (chatArea.scrollTop >= dateLabel.offsetTop) {
        currentLabel = dateLabel;
      }
      if (currentLabel) {
        this.topLabel.style.opacity = "1";
        this.topLabel.innerText = currentLabel.innerText;
      } else {
        this.topLabel.style.opacity = "0";
      }
    });
  }

  setSmoothScroll(value) {
    $(".chat-container").css("scroll-behavior", value);
  }

  setScroll(value) {
    this.isScrollDone = value;
  }
  changeLabel(eve: any) {
    this.topLabel = eve;
    this.dateLabels = document.querySelectorAll(".conversation-date") as any;
  }
}
