import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { environment } from "src/environments/environment";
import { CHANNEL_NAMES, SCHEDULE_MESSAGE_ALLOW_CHANNELS, TEMPLATE_ALLOW_CHANNELS } from "../utils/model/channels";
import { IAPIResponse } from "../utils/model/common";
import { IPlatform } from "../utils/model/platform";
import { Urls } from "../utils/urls";
import { BotService } from "./bot.service";
import { ChannelService } from "./channel.service";

@Injectable()
export class SettingService {
  constructor(
    private http: HttpClient,
    private channelService: ChannelService,
    private readonly botService: BotService
  ) {}
  EnableSetting = [];
  EnableChannels = [];
  isNoBot = true;
  isInitialized = true;
  isKnowledgeBaseEnable = false;
  isBotEnabled = true;
  isGenesysEnable = false;
  isTriggerEnable = false;
  triggerAvailable = [];
  isNLPEnabled = false;
  isHandOffEnabled = false;
  availableCampaignChannels = [];
  botList = [];
  integrations = [];
  timezone = "";
  bdkVersion = "";
  preview;
  webChat;
  availableChannels = [];
  isTemplateAvailable: boolean;
  allSettings = [
    {
      icon: "assessment",
      id: "analytics",
      name: "Analytics",
      routerLink: "analytics",
    },
    {
      icon: "ballot",
      id: "flow_builder",
      name: "Flow Builder",
      routerLink: "builder",
    },
    {
      icon: "schedule_send",
      id: "schedule_message",
      name: "Schedule Message",
      routerLink: "schedule-message",
    },
    {
      icon: "campaign",
      id: "campaign",
      name: "Campaign",
      routerLink: "campaign",
    },
    {
      icon: "portrait",
      id: "user_profiling",
      name: "Customers",
      routerLink: "users",
    },
    {
      icon: "headset_mic",
      id: "live_chat",
      name: "Live Chat",
      routerLink: "hand-off",
      showBadge: true,
    },
    {
      icon: "code",
      id: "integration",
      name: "Integration",
      routerLink: "integration",
    },
    {
      icon: "flash_on",
      id: "automation",
      name: "Automation",
      routerLink: "automation",
    },
    {
      icon: "category",
      id: "fields",
      name: "Fields",
      routerLink: "fields",
    },
    {
      icon: "memory",
      id: "nlp",
      name: "NLP",
      routerLink: "nlp",
    },
    {
      icon: "description",
      id: "template",
      name: "Template",
      routerLink: "template",
    },
    {
      icon: "settings",
      id: "settings",
      name: "Settings",
      routerLink: "settings",
    },
    {
      isShowImage: true,
      id: "instagram",
      name: "Instagram",
      routerLink: "instagram",
    },
    {
      id: "bot_preview",
      isNotShowInMenu: true,
      name: "Bot Preview",
      routerLink: "bot-preview",
    },
    {
      id: "manage_profile",
      isNotShowInMenu: true,
      name: "Manage Profile",
      routerLink: "manage-profile",
    },
  ];
  settingMenuPosition = environment.settingMenuPosition;
  privacyAndPolicy = "https://www.kevit.io/privacy-policy";
  termAndCondition = "https://www.kevit.io/terms-and-condition";
  platformTitle = "Chatomate";
  whiteLabelData;
  whiteLabelDataEvent = new Subject();
  isCampaignAvailable: boolean;
  public botListChanged = new Subject<any>();
  isFlowBuilderEnable: boolean;
  whatsappBSPMessageQuality;
  whatsappBSPAccountStatusUpdates;
  isWhatsappAccountErrorMessage = false;
  isWhatsappBSPChannel = false;
  isCampaignEnabled = new Subject<any>();
  isCampaignWidgetEnabled = new Subject<any>();
  isWhatsappBSPEnabled = new Subject<boolean>();
  currentWhatsappChannel = null;
  orderEnabledChannel = false;
  isCreditLimitReached = false;
  instagramUserDetails: {
    username: string;
    userProfileImageUrl: string;
  };
  isCallingAvailable = false;
  isCallingEnabled = false;
  botMessageLimit;
  botPlan;

  resetFlag() {
    this.isInitialized = false;
  }

  isNlpEnabled() {
    return this.isNLPEnabled;
  }

  setModules(bot) {
    this.isHandOffEnabled = bot.module.handoff;
    this.isNLPEnabled = false;
    this.EnableChannels = bot.platforms;
    this.availableChannels = bot.availableChannel;
    this.availableCampaignChannels = bot.availableCampaignChannels;
    this.isKnowledgeBaseEnable = bot.isKnowledgeBaseEnable;
    this.isBotEnabled = bot.isBotEnabled;
    this.isCreditLimitReached = bot?.creditLimitReached;
    this.timezone = bot.timezone;
    this.isGenesysEnable = bot.isGenesysEnabled;
    let isScheduleEnable = false;
    this.isFlowBuilderEnable = false;
    this.bdkVersion = bot.bdkVersion || "v2";
    this.preview = bot.preview;
    this.webChat = bot.webChat;
    this.integrations = bot.integrations || [];
    this.isTemplateAvailable = false;
    this.isCampaignAvailable = false;
    this.isCampaignEnabled.next(false);
    this.whatsappBSPMessageQuality = null;
    this.isWhatsappAccountErrorMessage = false;
    this.isCallingAvailable = false;
    this.isCallingEnabled = false;
    this.instagramUserDetails = bot.instagramMessaging;
    this.isWhatsappBSPChannel = this.EnableChannels?.includes(CHANNEL_NAMES.WHATSAPP_BSP);
    SCHEDULE_MESSAGE_ALLOW_CHANNELS.every(channelKey => {
      isScheduleEnable = this.EnableChannels.includes(CHANNEL_NAMES[channelKey]);
      return !isScheduleEnable;
    });
    TEMPLATE_ALLOW_CHANNELS.every(channelName => {
      this.isTemplateAvailable = this.EnableChannels.includes(channelName);
      return !this.isTemplateAvailable;
    });

    if (bot.triggerAvailable && bot.triggerAvailable.length && bot.triggerAvailable.length > 0) {
      this.triggerAvailable = bot.triggerAvailable;
      this.isTriggerEnable = true;
    } else {
      this.isTriggerEnable = false;
    }

    this.EnableSetting.length = 0;

    this.EnableSetting.push(this.allSettings[0]);
    if (bot.module.flow && this.EnableChannels.length) {
      this.isNoBot = false;
      this.EnableSetting.push(this.allSettings[1]);
      this.isFlowBuilderEnable = true;
    }
    if (bot.module.scheduleMessages && isScheduleEnable) {
      this.isNoBot = false;
      this.EnableSetting.push(this.allSettings[2]);
    }
    if (bot.module.campaign && this.availableCampaignChannels.length) {
      this.EnableSetting.push(this.allSettings[3]);
      this.isCampaignAvailable = true;
      this.isCampaignEnabled.next(true);
    }
    if (bot.module.userProfiling && this.EnableChannels.length) {
      if (!bot.handoffActions?.chatHistoryByAgent || !this.botService.reviewerOrSupervisor.includes(this.botService.getRole())) {
        this.isNoBot = false;
        this.EnableSetting.push(this.allSettings[4]);
      }
    }
    if (bot.module.handoff) {
      this.EnableSetting.push(this.allSettings[5]);
    }

    if (bot.module.apiTool && this.EnableChannels.length) {
      this.isNoBot = false;
      this.EnableSetting.push(this.allSettings[6]);
    }
    this.isNoBot = false;
    if (this.EnableChannels.length) {
      this.EnableSetting.push(this.allSettings[7]);
    }
    if (bot.module.fi) {
      this.isNoBot = false;
      this.EnableSetting.push(this.allSettings[8]);
    }
    if (bot.module.NLP && this.EnableChannels.length) {
      this.isNLPEnabled = true;
      this.isNoBot = false;
      this.EnableSetting.push(this.allSettings[9]);
    }
    if (bot.module.calling) {
      this.isCallingAvailable = true;
      if (bot.handoffCallDesign.isCallingEnabled) {
        this.isCallingEnabled = true;
      }
    }
    if (this.EnableChannels.length) {
      if (this.isTemplateAvailable) {
        this.EnableSetting.push(this.allSettings[10]);
      }
    }
    if (this.EnableChannels.includes("instagramMessaging") && bot.module.instagram) {
      this.EnableSetting.push(this.allSettings[12]);
    }
    this.EnableSetting.push(this.allSettings[11]);
    this.EnableSetting.push(this.allSettings[13]);
    this.isInitialized = true;
    if (!this.EnableChannels.length) {
      if (
        environment.envServerName === "ttl" ||
        (environment.envServerName === "chatomate" && this.availableChannels.includes(CHANNEL_NAMES.WHATSAPP_BSP))
      ) {
        this.EnableSetting = [this.allSettings[11]];
      } else {
        this.EnableSetting.length = 0;
      }
    }
    if (Object.keys(this.settingMenuPosition).length) {
      this.sortMenu();
    }
    if (this.isWhatsappBSPChannel && this.isBotEnabled) {
      this.getWhatsappBspMonitorSignals();
    }
  }

  async getWhatsappBspMonitorSignals() {
    try {
      const whatsappBspMonitorSignalsDetail = await this.channelService.getWhatsappBspMonitorSignals();
      this.whatsappBSPMessageQuality = whatsappBspMonitorSignalsDetail?.qualityRating;
      this.whatsappBSPAccountStatusUpdates = whatsappBspMonitorSignalsDetail?.accountStatusUpdates;
      this.isWhatsappAccountErrorMessage = true;
    } catch (err) {
      console.log(err);
    }
  }

  sortMenu() {
    this.EnableSetting = this.EnableSetting.sort((settingObject_A, settingObject_B) => {
      if (this.settingMenuPosition[settingObject_A.id] < this.settingMenuPosition[settingObject_B.id]) return -1;
      if (this.settingMenuPosition[settingObject_A.id] > this.settingMenuPosition[settingObject_B.id]) return 1;
      return 0;
    });
  }

  getPlatformDetail(origin): Promise<IAPIResponse<IPlatform>> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.platform}getPlatform?origin=${origin}`).subscribe(
        data => {
          this.whiteLabelData = data;
          this.whiteLabelDataEvent.next(true);
          resolve(data as IAPIResponse<IPlatform>);
        },
        err => {
          this.whiteLabelDataEvent.next(false);
          reject(err);
        }
      );
    });
  }
}
