import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Urls } from "../utils/urls";
import { BotService } from "./bot.service";
import { CommonService } from "./common.service";

@Injectable()
export class UsecaseMappingService {
  constructor(
    private http: HttpClient,
    private botService: BotService,
    public commonService: CommonService
  ) {}
  getUsecaseList(isPaginated, skip?, limit?, search?) {
    const queryObj = {
      skip,
      limit,
      filter_param: encodeURIComponent(search?.trim() ?? ""),
      isPaginated,
    };
    const httpParams = this.commonService.httpParamsPatcher(queryObj);
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.baseUrl + "bot/" + this.botService.getBot() + "/usecase-mapping/", { params: httpParams }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getUsecaseCount(searchValue) {
    const httpParams = this.commonService.httpParamsPatcher({
      filter_param: encodeURIComponent(searchValue?.trim() ?? ""),
    });
    return new Promise((resolve, reject) => {
      this.http.get(Urls.baseUrl + "bot/" + this.botService.getBot() + "/usecase-mapping/count", { params: httpParams }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  addUsecase(object) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.baseUrl + "bot/" + this.botService.getBot() + "/usecase-mapping", object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  editUsecase(id, object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.baseUrl + "bot/" + this.botService.getBot() + "/usecase-mapping/" + id, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  deleteUsecase(Id) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.baseUrl + "bot/" + this.botService.getBot() + "/usecase-mapping/" + Id).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
