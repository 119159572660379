<mat-form-field appearance="fill" floatLabel="never" class="input select">
  <mat-select
    matInput
    [disabled]="isSelectDisable"
    [placeholder]="placeholder"
    [(ngModel)]="selectedTag"
    (selectionChange)="tagSelectChange.emit($event)"
    (closed)="searchText = ''">
    <mat-form-field appearance="fill" floatLabel="never" class="input search-option sticky-search">
      <input matInput MatInputModule placeholder="Search" [(ngModel)]="searchText" class="t-w-full" />
      <div class="search-icon-wrapper">
        <mat-icon>search</mat-icon>
      </div>
    </mat-form-field>
    <mat-option class="t-hidden"></mat-option>
    <mat-optgroup *ngFor="let tagType of allowTagTypes" class="sticky">
      <mat-icon> {{ tagType === tagTypes.AGENT ? "support_agent" : "groups" }} </mat-icon>
      <label>{{ tagType | titlecase }} Tags</label>
      <ng-container *ngFor="let tagObject of allTags | tagSearch: searchText">
        <mat-option *ngIf="tagObject.type === tagType" [value]="tagObject._id">
          <span class="ml-10">{{ tagObject.tag }}</span>
        </mat-option>
      </ng-container>
    </mat-optgroup>
    <mat-option *ngIf="isAllowCreateNewTag" (click)="addNewTag.emit()" class="sticky-add-tag">
      <div class="add-tag">
        <mat-icon>add</mat-icon>
        Add Tag
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
