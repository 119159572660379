import { Component, Input } from "@angular/core";

@Component({
  selector: "app-messenger-image",
  templateUrl: "./messenger-image.component.html",
  styleUrls: ["../messenger.common-bot.component.scss"],
})
export class MessengerImageComponent {
  @Input() url: string;
}
