import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TagType } from "../../utils/model/customField";

@Component({
  selector: "app-tag-select",
  templateUrl: "./tag-select.component.html",
  styleUrls: ["./tag-select.component.scss"],
})
export class TagSelectComponent {
  @Input() isSelectDisable = false;
  @Input() placeholder = "Select Tag";
  @Input() isAllowCreateNewTag = false;
  @Input() selectedTag;
  @Input() allTags;
  @Input() allowTagTypes = [TagType.USER];
  @Input() isFromSendTemplate = false;
  @Output() addNewTag = new EventEmitter<any>();
  @Output() tagSelectChange = new EventEmitter<any>();
  searchText = "";
  tagTypes = TagType;

  getTagName(selectedTag) {
    return this.allTags.find(tag => tag._id === selectedTag)?.tag;
  }

  clearSelection(event) {
    event.stopPropagation();
    this.selectedTag = "";
    this.tagSelectChange.emit({ value: "" });
  }
}
