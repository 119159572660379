import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-messenger-carousal",
  templateUrl: "./messenger-carousal.component.html",
  styleUrls: ["../messenger.common-bot.component.scss"],
})
export class MessengerCarousalComponent {
  @Input() carouselDetails: any;
  @Output() buttonClickedEmitter = new EventEmitter<any>();
  title = "angularowlslider";
  customOptions: any = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 10,
    navText: ["←", "→"],
    responsive: {
      0: {
        items: 2,
      },
    },
    nav: true,
  };

  buttonClicked(value) {
    if (value.type === "openUrl") {
      window.open(value.value, "_blank");
    } else if (value.type === "openApp") {
      window.open(value.value, "_blank");
    } else {
      this.buttonClickedEmitter.emit(value);
    }
  }
}
