import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DragScrollModule } from "ngx-drag-scroll";
import { LoaderComponent } from "../common-components/loader/loader.component";
import { MaterialModule } from "../material.module";
import { NumberDirective } from "./number-only.directive";
import { SafeHtmlPipe } from "./pipe/safe-html.pipe";
import { LinesLoaderComponent } from "../common-components/lines-loader/lines-loader.component";
import { NumberShorterPipe } from "./pipe/number-shorter.pipe";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { NgApexchartsModule } from "ng-apexcharts";
import { SkeletonRectLoaderComponent } from "../common-components/skeleton-rect-loader/skeleton-rect-loader.component";
import { CustomFieldSearchPipe } from "./pipe/customfield-search.pipe";

@NgModule({
  declarations: [
    LoaderComponent,
    SafeHtmlPipe,
    NumberDirective,
    LinesLoaderComponent,
    SkeletonRectLoaderComponent,
    NumberShorterPipe,
    CustomFieldSearchPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    DragScrollModule,
    DragDropModule,
    NgxDaterangepickerMd.forRoot(),
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    LoaderComponent,
    CommonModule,
    RouterModule,
    MaterialModule,
    DragScrollModule,
    SafeHtmlPipe,
    NumberDirective,
    LinesLoaderComponent,
    SkeletonRectLoaderComponent,
    DragDropModule,
    NumberShorterPipe,
    NgxDaterangepickerMd,
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFieldSearchPipe,
  ],
})
export class AnalyticsCommonModule {}
