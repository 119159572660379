import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { IAPIResponse } from "../utils/model/common";
import { Urls } from "../utils/urls";
import { CommonService } from "./common.service";

@Injectable()
export class UsersService {
  public tagActionPerformed = new Subject<any>();
  constructor(
    private http: HttpClient,
    public commonService: CommonService
  ) {}

  importCompleted = new Subject();
  userFilterConditionArray = [];
  isAllConditionsSelected = true;
  customFieldValues = {};
  channels = [];
  isFromUserDetailsPage = false;
  getUserList(page, limit, obj, download, sort?): any {
    return new Promise((resolve, reject) => {
      return this.http
        .post(Urls.userProfiling + "?skip=" + page + "&limit=" + limit + "&download=" + download + "&sort=" + sort, obj)
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getUserDetailsById(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.users + "/" + id + "?limit=50").subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getShowUserDetailsById(botUserId, queryParams) {
    return new Promise((resolve, reject) => {
      return this.http
        .get(
          `${Urls.users}/${botUserId}/transcript?botId=${queryParams.botId}&channel=${queryParams.channel}&page=${queryParams.page}&limit=50`
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getSelectedUserTranscript(botUserId, queryParams) {
    return this.http.get(
      `${Urls.users}/${botUserId}/botUserTranscript?botId=${queryParams.botId}&channel=${queryParams.channel}&lastTranscriptId=${queryParams.lastTranscriptId}&limit=30`
    );
  }
  getBotFis(id, channels) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.users + "/useFI/" + id, channels).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getWordCount(userId, word): Promise<IAPIResponse<{ count: number }>> {
    const httpParams = this.commonService.httpParamsPatcher({ searchParam: encodeURIComponent(word?.trim() ?? "") });
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.users + `/${userId}/` + `transcript/search`, { params: httpParams }).subscribe(
        data => {
          resolve(data as IAPIResponse<{ count: number }>);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  uploadImportUserFile(botId, fileData): Observable<any> {
    return this.http.post(`${Urls.userProfiling}/${botId}/upload`, fileData, { reportProgress: true, observe: "events" });
  }

  importMultipleUser(botId, reqPayload) {
    return this.http.post(`${Urls.userProfiling}/${botId}/importUserData`, reqPayload);
  }

  getUsersStringTypeValue(botId, customFieldObject, currentPage, customFieldLimit) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(`${Urls.users}/${botId}/custom-field/suggestions?page=${currentPage}&limit=${customFieldLimit}`, customFieldObject)
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getTotalCount(userConfiguraton) {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.userProfiling}/count`, userConfiguraton).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getOptedOutCustomers(botId, params) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.bot}${botId}/customers/optout`, { params }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  optInCustomer(botId, payload) {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.bot}${botId}/customers/optin`, payload).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
