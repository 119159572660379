<app-messenger-icon></app-messenger-icon>

<section class="messenger-carousal">
  <div class="messenger-carousal-inner">
    <div style="text-align: center">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let card of carouselDetails">
          <div class="card">
            <div class="card-header" *ngIf="card.image || (card.content?.images)[0]">
              <img [src]="card.image ? card.image : card.content.images[0].url" class="img-fluid" alt="" />
            </div>
            <div class="card-body">
              <div class="card-title">
                <h4>
                  {{ card.title ? card.title : card.content.title }}
                </h4>
                <p>
                  {{ card.subTitle ? card.subTitle : card.content.subtitle }}
                </p>
              </div>
            </div>
            <div *ngIf="card.buttons">
              <div *ngFor="let button of card.buttons" class="card-buttons" (click)="buttonClicked(button)">
                {{ button.title }}
              </div>
            </div>
            <div *ngIf="card.content?.buttons">
              <div *ngFor="let button of card.content.buttons" class="card-buttons" (click)="buttonClicked(button)">
                {{ button.title }}
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>
