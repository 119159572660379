import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_TYPE } from "../utils/enums/bot.enum";
import { Urls } from "../utils/urls";
import { BotService } from "./bot.service";

@Injectable({
  providedIn: "root",
})
export class ChannelService {
  constructor(
    private http: HttpClient,
    private readonly botService: BotService
  ) {}

  setChannel(channel) {
    localStorage.setItem("selectedChannel", channel);
  }

  getChannel() {
    return localStorage.getItem("selectedChannel");
  }

  getChannelSpecificConfigurationData() {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/channel-specific-info/${this.botService.getBot()}`)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  setWebChat(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/webchat/${this.botService.getBot()}`, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  exportWebChatJson(id) {
    return this.http.get(
      `${Urls.org}${this.botService.getSelectedOrganization()}/bots/webchat/schedule-message/${this.botService.getBot()}`,
      {
        responseType: "blob",
      }
    );
  }

  updateTelegram(data) {
    return new Promise((resolve, reject) => {
      this.http.put(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/telegram/${this.botService.getBot()}`, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateWorkflow(data) {
    return new Promise((resolve, reject) => {
      this.http.put(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/workflow/${this.botService.getBot()}`, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateFacebookBot(data) {
    return new Promise((resolve, reject) => {
      this.http.put(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/fb/${this.botService.getBot()}`, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getCurrentFacebookAccessToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/fbDetails/${this.botService.getBot()}`).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getPageList() {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/page-list/${this.botService.getBot()}`).subscribe(
        (pages: []) => {
          resolve(pages);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateBotDetails(data) {
    return new Promise((resolve, reject) => {
      this.http.put(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/${this.botService.getBot()}`, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  addRemoveWhitelistDomain(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/fb/${this.botService.getBot()}/whitelistUrl`, data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  updateInstagram(data): Promise<{ message: string; status: string }> {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/instagram-messaging/${this.botService.getBot()}`, data)
        .subscribe(
          res => {
            resolve(res as { message: string; status: string });
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getInstagramBusinessList(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http
        .get(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/instagram-messaging/account-list/${this.botService.getBot()}`)
        .subscribe(
          (pages: any) => {
            resolve(pages);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  setWhatsappBspChannel(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(`${Urls.org}${this.botService.getSelectedOrganization()}/bots/whatsappBSP/${this.botService.getBot()}`, data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getWhatsappBspAccountInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.channelSettings}whatsappBSP/${this.botService.getBot()}`).subscribe(
        (pages: any) => {
          resolve(pages);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getWhatsappBspMonitorSignals(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.channelSettings}whatsappBSP/monitorSignals/${this.botService.getBot()}`).subscribe(
        (pages: any) => {
          resolve(pages);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getWhatsappBspProfileDetails(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.channelSettings}whatsappBSP/profile/${this.botService.getBot()}`).subscribe(
        (pages: any) => {
          resolve(pages);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setWhatsappBspProfileDetails(data): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.channelSettings}whatsappBSP/profile/${this.botService.getBot()}`, data).subscribe(
        (pages: any) => {
          resolve(pages);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  uploadBusinessProfileImage(data): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.channelSettings}whatsappBSP/profilePhoto/${this.botService.getBot()}`, data).subscribe(
        (pages: any) => {
          resolve(pages);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getAppName(): Observable<object> {
    return this.http.get<{ appName: APP_TYPE }>(`${Urls.channelSettings}whatsappBSP/appName/${this.botService.getBot()}`);
  }

  getWhatsappBspData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${Urls.channelSettings}/whatsappBSP/accountInfo/${this.botService.getBot()}`).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  // Embedded signup process v2 routes
  configureBusinessNumber(data): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.channelSettings}whatsappBSP/whatsApp-number-setup/${this.botService.getBot()}`, data).subscribe(
        (data: any) => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  disconnectPhoneNumber(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.delete(`${Urls.channelSettings}whatsappBSP/disconnect-whatsapp-number/${this.botService.getBot()}`).subscribe(
        (data: any) => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
