<app-messenger-icon></app-messenger-icon>
<section class="messenger-text t-mb-2.5">
  <div class="messenger-text-inner">
    <p *ngIf="!isLink">
      {{ text }}
    </p>
    <a *ngIf="isLink" [href]="url" target="_blank">
      {{ url }}
    </a>
  </div>
</section>
