import { Component } from "@angular/core";
import { svgImage } from "../../utils/consts/svg.const";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-insufficient-access",
  templateUrl: "./insufficient-access.component.html",
  styleUrls: ["./insufficient-access.component.scss"],
})
export class InsufficientAccessComponent {
  svgImage = svgImage;
  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router
  ) {}

  logout() {
    this.authService
      .logout()
      .then(res => {
        this.router.navigate(["login"]);
      })
      .catch(err => {});
  }
}
