<app-messenger-icon></app-messenger-icon>
<section class="messenger-card">
  <div class="messenger-card-inner">
    <div class="card">
      <div class="card-header" *ngIf="cardDetails.content.images">
        <img [src]="(cardDetails.content?.images)[0]?.url" class="img-fluid" alt="" />
      </div>
      <div class="card-body">
        <div class="card-title">
          <h4>
            {{ cardDetails.content.title }}
          </h4>
          <p>
            {{ cardDetails.content.subTitle }}
          </p>
        </div>
      </div>
      <div *ngFor="let button of cardDetails.content.buttons" class="card-buttons" (click)="buttonClicked(button)">
        {{ button.title }}
      </div>
    </div>
  </div>
</section>
