import { environment } from "../../../environments/environment";

export class GoogleAuthConst {
  public static readonly GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?`;
  public static AUTH_URL = `${GoogleAuthConst.GOOGLE_AUTH_URL}client_id=${environment.googleAuth.clientId}&response_type=code&access_type=offline&prompt=consent`;
  public static TYPES = {
    SUCCESS: "success",
    ERROR: "error",
    FAILURE: "failure",
  };
  public static GOOGLE_AUTH_CONFIG = {
    sheetScope: "email openid " + "https://www.googleapis.com/auth/userinfo.email " + "https://www.googleapis.com/auth/spreadsheets",

    calendarScope: "email openid https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email",
  };
}
