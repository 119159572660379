export enum ChannelsData {
  whatsapp = "whatsapp", // - karix
  facebook = "facebook",
  whatsappBusiness = "whatsappBusiness",
  whatsappNetCore = "whatsappNetCore",
  webchat = "webchat",
  telegram = "telegram",
  webchatColor = "#2198FA",
  webchatBackground = "#2198FA",
  whatsappColor = "#095E54",
  whatsappBackground = "#095E54",
  facebookColor = "#2198FA",
  facebookBackground = "linear-gradient(90deg, #0088FF 0%, #A033FF 50%, #FF5C87 100%)",
  RCSBusinessMessages = "RCSBusinessMessages",
  INSTAGRAM = "instagramMessaging",
}

export const CHANNEL_NAMES = {
  WEBCHAT: "webchat",
  FACEBOOK: "facebook",
  WHATSAPP: "whatsapp",
  WHATSAPP_BUSINESS: "whatsappBusiness",
  WHATSAPP_NET_CORE: "whatsappNetCore",
  TELEGRAM: "telegram",
  RCS_BUSINESS_MESSAGES: "RCSBusinessMessages",
  WORKFLOW: "workflow",
  INSTAGRAM: "instagramMessaging",
  WHATSAPP_KALEYRA: "whatsappKaleyra",
  SOCKET_IO: "socketIO",
  WHATSAPP_AIRTEL: "whatsappAirtel",
  WHATSAPP_ACL: "whatsappACL",
  WHATSAPP_CLOUD: "whatsappCloud",
  WHATSAPP_INFOBIP: "whatsappInfobip",
  WHATSAPP_BSP: "whatsappBSP",
} as const;

export const CHANNELS_ARR = [
  CHANNEL_NAMES.WEBCHAT,
  CHANNEL_NAMES.FACEBOOK,
  CHANNEL_NAMES.WHATSAPP,
  CHANNEL_NAMES.WHATSAPP_BUSINESS,
  CHANNEL_NAMES.WHATSAPP_NET_CORE,
  CHANNEL_NAMES.WORKFLOW,
  CHANNEL_NAMES.RCS_BUSINESS_MESSAGES,
  CHANNEL_NAMES.TELEGRAM,
  CHANNEL_NAMES.INSTAGRAM,
  CHANNEL_NAMES.WHATSAPP_KALEYRA,
  CHANNEL_NAMES.SOCKET_IO,
  CHANNEL_NAMES.WHATSAPP_AIRTEL,
  CHANNEL_NAMES.WHATSAPP_ACL,
  CHANNEL_NAMES.WHATSAPP_CLOUD,
  CHANNEL_NAMES.WHATSAPP_INFOBIP,
  CHANNEL_NAMES.WHATSAPP_BSP,
] as const;

export const WHATSAPP_CHANNELS_ARR = [
  CHANNEL_NAMES.WHATSAPP,
  CHANNEL_NAMES.WHATSAPP_BUSINESS,
  CHANNEL_NAMES.WHATSAPP_NET_CORE,
  CHANNEL_NAMES.WHATSAPP_KALEYRA,
  CHANNEL_NAMES.WHATSAPP_AIRTEL,
  CHANNEL_NAMES.WHATSAPP_ACL,
  CHANNEL_NAMES.WHATSAPP_CLOUD,
  CHANNEL_NAMES.WHATSAPP_INFOBIP,
  CHANNEL_NAMES.WHATSAPP_BSP,
] as const;

const WHATSAPP_CHANNELS_KEYS_ARR = [
  "WHATSAPP",
  "WHATSAPP_ACL",
  "WHATSAPP_AIRTEL",
  "WHATSAPP_BSP",
  "WHATSAPP_BUSINESS",
  "WHATSAPP_CLOUD",
  "WHATSAPP_INFOBIP",
  "WHATSAPP_KALEYRA",
  "WHATSAPP_NET_CORE",
];

export const CHANNEL_DISPLAY_NAMES = {
  WORKFLOW: "Work Flow",
  RCS_BUSINESS_MESSAGES: "RCS Business Messaging",
  TELEGRAM: "Telegram",
  WHATSAPP_NET_CORE: "WhatsApp",
  WHATSAPP_BUSINESS: "WhatsApp",
  WHATSAPP: "WhatsApp",
  FACEBOOK: "Facebook Messenger",
  WEBCHAT: "Web Widget",
  INSTAGRAM: "Instagram",
  WHATSAPP_KALEYRA: "WhatsApp",
  SOCKET_IO: "Mobile",
  WHATSAPP_AIRTEL: "WhatsApp",
  WHATSAPP_ACL: "WhatsApp",
  WHATSAPP_CLOUD: "WhatsApp",
  WHATSAPP_INFOBIP: "WhatsApp",
  WHATSAPP_BSP: "WhatsApp",
} as const;

export const CHANNEL_DISPLAY_ICONS = {
  WORKFLOW: "assets/images/channels/workflow.svg",
  RCS_BUSINESS_MESSAGES: "assets/images/channels/rich-communication-services.svg",
  TELEGRAM: "assets/images/channels/telegram.svg",
  WHATSAPP_NET_CORE: "assets/images/channels/whatsappNetCore.svg",
  WHATSAPP_BUSINESS: "assets/images/channels/whatsappBusiness.svg",
  WHATSAPP: "assets/images/channels/whatsapp.svg",
  FACEBOOK: "assets/images/channels/facebook.svg",
  MESSENGER: "assets/images/channels/messanger.svg",
  WEBCHAT: "assets/images/channels/wechat.svg",
  INSTAGRAM: "assets/images/channels/instagram.svg",
  WHATSAPP_KALEYRA: "assets/images/channels/whatsapp.svg",
  SOCKET_IO: "assets/images/channels/socket_io.svg",
  WHATSAPP_AIRTEL: "assets/images/channels/whatsapp.svg",
  WHATSAPP_ACL: "assets/images/channels/whatsapp.svg",
  WHATSAPP_CLOUD: "assets/images/channels/whatsapp.svg",
  WHATSAPP_BSP: "assets/images/channels/whatsapp.svg",
};

export const CHANNELS_DISPLAY_NAME_ARR = [
  CHANNEL_DISPLAY_NAMES.WEBCHAT,
  CHANNEL_DISPLAY_NAMES.FACEBOOK,
  CHANNEL_DISPLAY_NAMES.WHATSAPP,
  CHANNEL_DISPLAY_NAMES.WHATSAPP_BUSINESS,
  CHANNEL_DISPLAY_NAMES.WHATSAPP_NET_CORE,
  CHANNEL_DISPLAY_NAMES.WORKFLOW,
  CHANNEL_DISPLAY_NAMES.RCS_BUSINESS_MESSAGES,
  CHANNEL_DISPLAY_NAMES.TELEGRAM,
  CHANNEL_DISPLAY_NAMES.INSTAGRAM,
  CHANNEL_DISPLAY_NAMES.WHATSAPP_KALEYRA,
  CHANNEL_DISPLAY_NAMES.SOCKET_IO,
  CHANNEL_DISPLAY_NAMES.WHATSAPP_AIRTEL,
  CHANNEL_DISPLAY_NAMES.WHATSAPP_ACL,
  CHANNEL_DISPLAY_NAMES.WHATSAPP_CLOUD,
  CHANNEL_DISPLAY_NAMES.WHATSAPP_INFOBIP,
  CHANNEL_DISPLAY_NAMES.WHATSAPP_BSP,
] as const;

export type CHANNELS_DISPLAY_TYPE = (typeof CHANNELS_ARR)[number];
export type CHANNELS_TYPE = (typeof CHANNELS_ARR)[number];

/*
  MODULE SPECIFIC AVAILABLE CHANNELS LIST
*/

// HERE WE STORE CHANNEL_KEY TO GET BOTH VALUE AND DISPLAY VALUE
export const SCHEDULE_MESSAGE_ALLOW_CHANNELS: string[] = [...WHATSAPP_CHANNELS_KEYS_ARR, "TELEGRAM"];

export const GROWTHTOOL_ALLOW_CHANNELS: string[] = ["WEBCHAT", "FACEBOOK", "INSTAGRAM", ...WHATSAPP_CHANNELS_KEYS_ARR];

// HERE WE STORE CHANNEL_VALUE FOR CHECK AVAILABLE OR NOT
export const TEMPLATE_ALLOW_CHANNELS: string[] = [CHANNEL_NAMES.WHATSAPP_BSP];

export const CATALOG_ALLOW_CHANNELS: string[] = [
  CHANNEL_NAMES.WHATSAPP_BSP,
  CHANNEL_NAMES.WHATSAPP_CLOUD,
  CHANNEL_NAMES.WHATSAPP_KALEYRA,
  CHANNEL_NAMES.WHATSAPP_NET_CORE,
];

export const FORM_ALLOWED_CHANNELS: string[] = ["WHATSAPP_BSP", "WEBCHAT"];

export const ORDER_PAYMENT_ALLOWED_CHANNELS: string[] = [CHANNEL_NAMES.WHATSAPP_BSP, CHANNEL_NAMES.WHATSAPP_NET_CORE];

export const channelWithDisplayName = {
  [CHANNEL_NAMES.WHATSAPP_BUSINESS]: CHANNEL_DISPLAY_NAMES.WHATSAPP_BUSINESS,
  [CHANNEL_NAMES.FACEBOOK]: CHANNEL_DISPLAY_NAMES.FACEBOOK,
  [CHANNEL_NAMES.WEBCHAT]: CHANNEL_DISPLAY_NAMES.WEBCHAT,
  [CHANNEL_NAMES.WHATSAPP]: CHANNEL_DISPLAY_NAMES.WHATSAPP,
  [CHANNEL_NAMES.WHATSAPP_NET_CORE]: CHANNEL_DISPLAY_NAMES.WHATSAPP_NET_CORE,
  [CHANNEL_NAMES.TELEGRAM]: CHANNEL_DISPLAY_NAMES.TELEGRAM,
  [CHANNEL_NAMES.RCS_BUSINESS_MESSAGES]: CHANNEL_DISPLAY_NAMES.RCS_BUSINESS_MESSAGES,
  [CHANNEL_NAMES.INSTAGRAM]: CHANNEL_DISPLAY_NAMES.INSTAGRAM,
  [CHANNEL_NAMES.WHATSAPP_KALEYRA]: CHANNEL_DISPLAY_NAMES.WHATSAPP_KALEYRA,
  [CHANNEL_NAMES.WHATSAPP_AIRTEL]: CHANNEL_DISPLAY_NAMES.WHATSAPP_AIRTEL,
  [CHANNEL_NAMES.SOCKET_IO]: CHANNEL_DISPLAY_NAMES.SOCKET_IO,
  [CHANNEL_NAMES.WHATSAPP_ACL]: CHANNEL_DISPLAY_NAMES.WHATSAPP_ACL,
  [CHANNEL_NAMES.WHATSAPP_CLOUD]: CHANNEL_DISPLAY_NAMES.WHATSAPP_CLOUD,
  [CHANNEL_NAMES.WHATSAPP_INFOBIP]: CHANNEL_DISPLAY_NAMES.WHATSAPP_INFOBIP,
  [CHANNEL_NAMES.WHATSAPP_BSP]: CHANNEL_DISPLAY_NAMES.WHATSAPP_BSP,
};

export const getChannelIconsByName = {
  [CHANNEL_DISPLAY_NAMES.FACEBOOK]: "assets/images/channels/facebook.svg",
  [CHANNEL_DISPLAY_NAMES.WEBCHAT]: "assets/images/channels/wechat.svg",
  [CHANNEL_DISPLAY_NAMES.WHATSAPP]: "assets/images/channels/whatsapp.svg",
  [CHANNEL_DISPLAY_NAMES.TELEGRAM]: "assets/images/channels/telegram.svg",
  [CHANNEL_DISPLAY_NAMES.RCS_BUSINESS_MESSAGES]: "assets/images/channels/rich-communication-services.svg",
  [CHANNEL_DISPLAY_NAMES.INSTAGRAM]: "assets/images/channels/instagram.svg",
  [CHANNEL_DISPLAY_NAMES.SOCKET_IO]: "assets/images/channels/socket_io.svg",
};
