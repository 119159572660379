import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BotService } from "../../../services/bot.service";
import { NotifyService } from "../../../services/noyify.service";
import { TagsService } from "../../../services/tags.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../../../common-components/dialog/dialog.component";
import { CHANNEL_DISPLAY_NAMES, CHANNEL_NAMES } from "../../../utils/model/channels";
import { CommonService } from "../../../services/common.service";
import { UsersService } from "src/app/services/users.service";
import { AuthService } from "src/app/services/auth.service";
import { GENERAL_NAME } from "src/app/utils/consts/regex";
import { TagType } from "../../../utils/model/customField";

@Component({
  selector: "app-user-profiling",
  templateUrl: "./user-profiling.component.html",
  styleUrls: ["./user-profiling.component.scss"],
})
export class UserProfilingComponent implements OnInit, OnChanges {
  componentName = "user-profiling";

  @Input() selectedUser;
  @Input() parentComponent;
  @Input() tags = [];
  @Input() source;
  @Input() tagType;
  @Input() type;
  @Output() newTagChange = new EventEmitter<any>();
  isAllAccess = this._botService.checkAllAccess();
  isPartnerOrCST = this._botService.checkPartnerOrCST();
  isTagRemovable = true;
  isLoading = true;
  tagName = null;
  show: any = true;
  userFi: any = [];
  dialogRef;
  createDialogRef;
  userFiArray = [
    "name",
    "first_name",
    "last_name",
    "locale",
    "timezone",
    "gender",
    "contactNumber",
    "ipAddress",
    "browser",
    "device",
    "country",
    "city",
    "region",
    "latitude",
    "longitude",
    "metroCode",
    "transcriptUrl",
  ];
  nlpFiArray = ["lastIntent", "user_prefer_language", "lastQuery", "entity1", "entity2", "entity3", "entity4", "entity5"];
  userExperienceArray = [
    "emotion",
    "mood",
    "openness",
    "neuroticism",
    "satisfaction",
    "extroversion",
    "agreeableness",
    "conscientiousness",
  ];
  nlpFi: any = [];
  otherFi: any = [];
  userExperienceFi: any = [];
  userName;
  selectedTag;
  description;
  allowTagTypes = [TagType.USER];
  otherSelectedTags = [];
  allowsTags = [];
  tagTypes = TagType;
  constructor(
    protected route: ActivatedRoute,
    public _botService: BotService,
    private _notificationService: NotifyService,
    private _tagsService: TagsService,
    private dialog: MatDialog,
    public commonService: CommonService,
    private _userService: UsersService,
    private readonly _authService: AuthService,
    private readonly _router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selectedUser && changes.selectedUser.currentValue) {
      this.selectedUser = JSON.parse(JSON.stringify(this.selectedUser));
      this.getTags();
    }
  }

  ngOnInit(): void {
    this.getData();
    this.setOtherTags();
    this.isTagRemovable = this.parentComponent !== "transcript";
    this.userName = this.showUserName();
  }

  setOtherTags() {
    this.otherSelectedTags = [];
    this.allowsTags = [];
    if (this.type === TagType.AGENT) {
      this.allowTagTypes = [TagType.AGENT];
    }
    this.selectedUser?.tags?.forEach(tag => {
      if (tag.type !== this.type) {
        this.otherSelectedTags.push(tag);
      }
    });
    this.tags?.forEach(tag => {
      if (tag.type === this.type) {
        this.allowsTags.push(tag);
      }
    });
  }

  getData() {
    const customFieldsKey = Object.keys(this.selectedUser.customFields);
    for (let i = 0; i < customFieldsKey.length; i++) {
      const currentFI = this.selectedUser.customFields[customFieldsKey[i]];
      if (customFieldsKey[i] === "first_interacted_at") {
        this.selectedUser.firstSeen = currentFI;
      } else if (customFieldsKey[i] === "last_interacted_at") {
        this.selectedUser.lastSeen = currentFI;
      }
      if (customFieldsKey[i] === "profile_pic") {
        if (currentFI) {
          this.selectedUser.profile = currentFI;
        } else {
          this.selectedUser.profile = "../../../../assets/images/demo_person.jpg";
        }
      }
      if (
        customFieldsKey[i] !== "profile_pic" &&
        customFieldsKey[i] !== "first_interacted_at" &&
        customFieldsKey[i] !== "last_interacted_at"
      ) {
        if (this.userFiArray.includes(customFieldsKey[i])) {
          this.userFi.push({
            name: customFieldsKey[i],
            value: currentFI,
          });
        } else if (this.nlpFiArray.includes(customFieldsKey[i])) {
          this.nlpFi.push({
            name: customFieldsKey[i],
            value: currentFI,
          });
        } else if (this.userExperienceArray.includes(customFieldsKey[i])) {
          this.userExperienceFi.push({
            name: customFieldsKey[i],
            value: currentFI,
          });
        } else {
          this.otherFi.push({
            name: customFieldsKey[i],
            value: currentFI,
          });
        }
      }
    }
  }
  openDialog(templateRef, type?, subType?): void {
    if (type === "actionTag") {
      this.dialogRef = this.dialog.open(DialogComponent, {
        panelClass: "addUser",
        data: { title: "Add Tag", template: templateRef },
        width: "600px",
      });
    } else if (type === "createTag") {
      this.selectedTag = "";
      this.description = "";
      this.createDialogRef = this.dialog.open(DialogComponent, {
        panelClass: "addUser",
        data: { title: `Create ${this.type === TagType.USER ? "User" : "Agent"} Tag`, template: templateRef },
        width: "600px",
      });
      this.createDialogRef.afterClosed().subscribe(result => {
        this.tagName = "";
        this.description = "";
      });
    }
  }

  showUserName() {
    let name = "";
    if (this.selectedUser.customFields) {
      const fullName = this.selectedUser.customFields.name;
      const fName = this.selectedUser.customFields.first_name;
      const lName = this.selectedUser.customFields.last_name;
      const username = this.selectedUser.customFields.username;
      if (fullName) {
        name = fullName;
      } else if (fName || lName) {
        if (fName) {
          name = name + fName;
        }
        if (lName) {
          name = name + lName;
        }
      } else if (username) {
        name = username;
      } else {
        name = this.getNameFromChannel(this.selectedUser.botChannel);
      }
    }
    return name;
  }

  getNameFromChannel(channelName): string {
    let channelNames = {};
    Object.keys(CHANNEL_NAMES).forEach(channel => {
      channelNames[CHANNEL_NAMES[channel]] = CHANNEL_DISPLAY_NAMES[channel];
    });
    return channelNames[channelName] + " User";
  }

  onTagSelect(isRemove, index?) {
    const object: any = { source: this.source, tagType: this.tagType };
    object.botUsers = [];
    if (!this.selectedUser.tags) {
      this.selectedUser.tags = [];
    }
    object.botUsers[0] = this.parentComponent !== "handoff" ? this.route.snapshot.params.id : this.selectedUser?._id;
    const user = this._authService.getUser();
    const transcriptObject = {
      from: "Bot",
      type: "tag",
      isConfigMessage: true,
      isFromAgent: false,
      isFromGenesys: false,
      isPreviewUser: false,
      createdAt: new Date().toISOString(),
      tag: {
        actionPerformedByUserName: user.role === "SA" ? "Admin" : `${user?.name.first} ${user?.name.last}`,
        source: this.parentComponent !== "handoff" ? "userProfile" : "liveChat",
        tagType: this.tagType,
        timestamp: new Date().toISOString(),
      },
    };
    if (isRemove) {
      object.removeTags = [];
      object.removeTags[0] = transcriptObject.tag["tagId"] = this.selectedUser.tags[index]._id;
      object.tagName = transcriptObject.tag["tagName"] = this.selectedUser.tags[index].tag;
      transcriptObject.tag["action"] = "tagRemoved";
      transcriptObject.tag["stateBeforeAction"] = "existed";
    } else {
      object.addTags = [];
      object.addTags[0] = transcriptObject.tag["tagId"] = this.selectedTag;
      const tagIndex = this.tags.findIndex(tag => tag._id === this.selectedTag);
      if (tagIndex !== -1) {
        object.tagName = transcriptObject.tag["tagName"] = this.tags[tagIndex].tag;
      }
      transcriptObject.tag["action"] = "tagAdded";
      transcriptObject.tag["stateBeforeAction"] = "notExisted";
    }
    let flag = true;
    if (!isRemove) {
      this.selectedUser.tags.forEach(tag => {
        if (tag._id === this.selectedTag) {
          flag = false;
        }
      });
    }
    if (flag) {
      this._tagsService
        .assignTags(this._botService.getBot(), object)
        .then((data: any) => {
          this._userService.tagActionPerformed.next(transcriptObject);
          if (isRemove) {
            this.selectedUser.tags.splice(index, 1);
            this.newTagChange.emit({
              tag: name,
              type: "remove",
              _id: object.removeTags[0],
            });
            this._notificationService.sendNotification("success", "Tag removed successfully");
          } else {
            let name = "";
            this.tags.forEach(tag => {
              if (tag._id === this.selectedTag) {
                name = tag.tag;
              }
            });
            this.selectedUser.tags.push({
              tag: name,
              _id: object.addTags[0],
              type: this.type,
            });
            this.newTagChange.emit({
              tag: name,
              type: "add",
              _id: object.addTags[0],
            });
            this._notificationService.sendNotification("success", "Tag added successfully");
          }
          this.selectedTag = "";
          this.dialog.closeAll();
        })
        .catch(err => {
          this.commonService.errorHandler(err, `${this.componentName}:onTagSelect:assignTags`, true);
          this.removeLoader();
        });
    } else {
      this._notificationService.sendNotification("error", "Tag is already assigned");
    }
  }
  removeLoader() {
    this.isLoading = false;
    this.show = false;
  }
  createTag() {
    if (this.tagName && this.tagName.trim() !== "" && this.type && this.tagName.match(GENERAL_NAME)) {
      this._tagsService
        .addTag(this._botService.getBot(), { tag: this.tagName.trim(), type: this?.type, description: this.description || null })
        .then((data: any) => {
          if (data._id) {
            this.tags.push({
              tag: this.tagName,
              type: this.type,
              _id: data._id,
            });
            this.allowsTags.push({
              tag: this.tagName,
              type: this.type,
              _id: data._id,
            });
            this.newTagChange.emit({
              tags: this.tags,
              type: "create",
            });
          }
          this.createDialogRef.close();
          this._notificationService.sendNotification("success", "Tag created successfully");
        })
        .catch(err => {
          this.commonService.errorHandler(err, `${this.componentName}:createTag:addTag`, true);
        });
    } else if (!this.tagName.value) {
      this._notificationService.sendNotification("error", "Please Enter Tag Name");
    } else if (this.tagName.value.trim() === "") {
      this._notificationService.sendNotification("error", "Please Enter Valid Tag Name");
    } else if (!this.tagName.match(GENERAL_NAME)) {
      this._notificationService.sendNotification(
        "error",
        "You have entered wrong Name. Special characters are not allowed except _ and -. Don't add numbers or special characters at starting position."
      );
    }
  }

  getTags() {
    const tempTags = [];
    if (this.parentComponent === "handoff") {
      this.tags?.forEach(t => {
        this.selectedUser?.tags?.forEach(tag => {
          if (!tag._id) {
            if (tag === t._id) {
              tempTags.push(t);
            }
          }
        });
      });
      this.selectedUser.tags = JSON.parse(JSON.stringify(tempTags));
      this.removeLoader();
    }
  }
}
