<div>
  <div class="preview-part">
    <!-- MAIN CHAT PART | START -->
    <div class="whatsapp-chat t-w-full" id="conversation">
      <ng-container *ngFor="let message of messages; trackBy: trackById">
        <ng-container *ngIf="message.from === 'bot'">
          <div class="bot-chat t-flex t-items-center" *ngIf="message.type === 'text'">
            <div class="chat">
              <p [innerHTML]="generateHyperLinkInText(message.text)"></p>
            </div>
          </div>
          <div class="bot-chat t-flex t-items-center" *ngIf="message.type === 'datePickerLink'">
            <div class="chat link">
              <a [href]="message.url" target="_blank">{{ message.url }}</a>
            </div>
          </div>
          <div class="bot-message-media--parent" *ngIf="message.type === 'image'">
            <div style="width: 100%">
              <img [src]="message.url" alt="image" />
            </div>
            <div class="t-mt-[5px]" *ngIf="message.caption">
              {{ message.caption }}
            </div>
          </div>
          <div class="bot-message-media--parent" *ngIf="message.type === 'video'">
            <div style="width: 100%">
              <video controls>
                <source [src]="message.url" type="video/mp4" />
                <source [src]="message.url" type="video/webm" />
                <source [src]="message.url" type="video/ogg" />
              </video>
            </div>
            <div class="t-mt-[5px]" *ngIf="message.caption">
              {{ message.caption }}
            </div>
          </div>

          <div class="bot-message-media--parent" *ngIf="message.type === 'audio'">
            <div style="width: 100%">
              <audio controls>
                <source [src]="message.url" type="audio/mpeg" />
              </audio>
            </div>
            <div class="t-mt-[5px]" *ngIf="message.caption">
              {{ message.caption }}
            </div>
          </div>
          <div class="bot-message-doc--parent" *ngIf="message.type === 'file'">
            <a class="t-flex t-items-center" [href]="message.url" target="_blank">
              <img src="assets/images/{{ message.docType }}.svg" alt="document" />
              <div class="t-ml-[5px]">
                {{ message.name ? message.name + "." + message.docType : message.docType }}
              </div>
            </a>
            <div *ngIf="message.caption" class="t-mt-[5px]">{{ message.caption }}</div>
          </div>
          <div class="bot-message-media--parent" *ngIf="message.type === 'location'">
            <a class="t-flex t-items-center t-justify-center" [href]="message.url" target="_blank">
              <img src="assets/images/googlemaps.jpg" alt="location" />
            </a>
            <div *ngIf="message.location.label" class="t-ml-[5px] t-mr-[5px]">
              <a
                [href]="
                  'https://www.google.com/maps/search/?api=1&query=' +
                  message.location.label +
                  '/@' +
                  message.location.latitude +
                  ',' +
                  message.location.longitude
                "
                target="_blank"
                class="location-label"
                >{{ message.location.label }}</a
              >
            </div>
            <p *ngIf="message.location.address" class="location-body t-pl-[5px] t-pr-[5px]">
              {{ message.location.address }}
            </p>
          </div>
          <div *ngIf="message.type === 'locationRequest'">
            <div class="bot-chat t-flex t-items-center">
              <div class="chat">
                <p>{{ message.locationRequest?.body }}</p>
                <div class="horizontal-line t-mb-[5px] t-mt-[5px]"></div>
                <div
                  class="t-flex t-justify-center t-mt-2.5 t-mb-[5px] color-primary t-items-center"
                  (click)="getCoordinatesForLocationRequest()">
                  <mat-icon>location_on</mat-icon>
                  <span class="t-ml-[5px]">Send Location</span>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="message.type === 'addressMessage'">
            <div class="bot-chat t-flex t-items-center">
              <div class="chat">
                <div class="t-flex t-flex-col reply-div-width">
                  <span
                    class="t-text-lg header-font-weight t-font-medium"
                    *ngIf="message[message.type]?.header?.type === 'text'"
                    [innerHTML]="message[message.type]?.header?.text"></span>
                  <img *ngIf="message[message.type]?.header?.type === 'image'" [src]="message[message.type]?.header?.url" alt="image" />
                  <video *ngIf="message[message.type]?.header?.type === 'video'" controls>
                    <source [src]="message[message.type]?.header?.url" type="video/mp4" />
                    <source [src]="message[message.type]?.header?.url" type="video/webm" />
                    <source [src]="message[message.type]?.header?.url" type="video/ogg" />
                    <track label="English" kind="captions" />
                  </video>
                  <a
                    class="t-flex t-items-center reply-document"
                    [href]="message[message.type]?.header?.url"
                    target="_blank"
                    *ngIf="message[message.type]?.header?.type === 'document' && message[message.type]?.header?.url">
                    <img
                      src="assets/images/{{ commonService.documentTypeMap[message[message.type].header.url.split('.').pop()] }}"
                      alt="reply-header-image" />
                    <div class="t-ml-[5px]" [matTooltip]="message[message.type].header.url.split('/').pop()">
                      {{ message[message.type].header.url.split("/").pop() }}
                    </div>
                  </a>
                  <p class="t-mt-[5px] body-font-weight t-text-base" [innerHTML]="message[message.type]?.body"></p>
                  <p
                    class="t-mt-[5px] footer-text t-text-xs"
                    *ngIf="message[message.type]?.footer"
                    [innerHTML]="message[message.type]?.footer"></p>
                  <div class="horizontal-line t-mb-[5px] t-mt-[5px]"></div>
                  <div
                    class="t-flex t-justify-center t-mt-[5px] t-mb-[5px]"
                    (click)="message.isTheAddressSent ? false : addressMessageFillDetails(addressMessageFormModal, message)"
                    [ngStyle]="{
                      cursor: message.isTheAddressSent ? 'not-allowed' : 'pointer',
                      color: message.isTheAddressSent ? '#4e4f5040' : '#2198fa',
                    }">
                    <span class="t-ml-[5px] dot t-font-bold">Provide Address</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="bot-chat" *ngIf="message.type === 'contact'">
            <div class="chat preview-contact t-flex">
              <mat-icon class="t-mt-[5px]">perm_contact_calendar</mat-icon>
              <div class="t-flex t-flex-col t-ml-2.5">
                <p>{{ message.contactName }}</p>
                <p>{{ message.contactNumber }}</p>
              </div>
            </div>
          </div>
          <div class="bot-chat t-flex t-items-center" *ngIf="message.type === 'list'">
            <div class="chat">
              <div class="list">
                <p *ngIf="message.list?.header?.type === 'text'">{{ message.list?.header?.text }}</p>
                <p>{{ message.list?.body }}</p>
                <p *ngIf="message.list?.footer">{{ message.list?.footer }}</p>
              </div>
              <div class="list-title" (click)="showListItem(listTemplate, message.list.actions)">
                <mat-icon class="t-mr-[5px]">format_list_bulleted</mat-icon>
                {{ message.list.actions.button_text }}
              </div>
            </div>
          </div>
          <div class="bot-chat t-items-center" *ngIf="message.type === 'reply'">
            <div class="chat">
              <div class="list">
                <p *ngIf="message.reply?.header?.type === 'text'">{{ message.reply?.header?.text }}</p>
                <img *ngIf="message.reply?.header?.type === 'image'" [src]="message.reply?.header?.url" alt="image" />
                <video *ngIf="message.reply?.header?.type === 'video'" controls>
                  <source [src]="message.reply?.header?.url" type="video/mp4" />
                  <source [src]="message.reply?.header?.url" type="video/webm" />
                  <source [src]="message.reply?.header?.url" type="video/ogg" />
                </video>
                <a
                  class="t-flex t-items-center reply-document"
                  [href]="message.reply?.header?.url"
                  target="_blank"
                  *ngIf="message.reply?.header?.type === 'document'">
                  <img
                    src="assets/images/{{ commonService.documentTypeMap[message[message.type].header.url.split('.').pop()] }}"
                    alt="reply-header" />
                  <div class="t-ml-[5px]">
                    {{ message.reply?.header?.url.split("/")[message.reply?.header?.url.split("/").length - 1] }}
                  </div>
                </a>
                <p>{{ message.reply?.body }}</p>
                <p *ngIf="message.reply?.footer">{{ message.reply?.footer }}</p>
              </div>
            </div>
            <ng-container *ngFor="let replyButton of message.reply.actions.buttons">
              <div
                *ngIf="message.reply.actions.buttonType === FlowStepReplyButtonType.REPLY"
                class="chat chat-button"
                [ngClass]="{ 'button-clicked': replyButton.clicked }"
                (click)="handleReplyButton(replyButton)">
                <mat-icon class="t-mr-[5px]">reply</mat-icon><span>{{ replyButton.title }}</span>
              </div>
              <div
                *ngIf="
                  message.reply.actions.buttonType === FlowStepReplyButtonType.CTA_URL ||
                  message.reply.actions.buttonType === FlowStepReplyButtonType.MULTIPLE_ATTACHMENT
                "
                class="chat chat-button"
                (click)="opneCTAUrl(replyButton.url)">
                <mat-icon class="t-mr-[5px]">open_in_new</mat-icon><span>{{ replyButton.title }}</span>
              </div>
            </ng-container>
          </div>
          <div class="bot-chat" *ngIf="message.type === 'productMulti'">
            <div class="chat multiple-product-box">
              <div class="t-flex t-flex-col">
                <div class="t-mb-2.5 t-flex t-flex-col">
                  <div class="t-flex product-header">
                    <img
                      *ngIf="((message.productMulti?.action?.sections)[0]?.items)[0]?.image"
                      [src]="((message.productMulti?.action?.sections)[0]?.items)[0]?.image"
                      alt="header-image"
                      class="product-header-image" />
                    <div *ngIf="!((message.productMulti?.action?.sections)[0]?.items)[0]?.image" class="t-flex no-image-div">
                      <img class="image-common" src="assets/images/catalogue_image.svg" alt="header-image" />
                    </div>
                    <div class="t-w-[75%] t-flex t-flex-col t-ml-5">
                      <span class="t-text-xl t-mb-[5px]">{{ message.productMulti?.header?.text }}</span>
                      <p class="t-text-sm t-font-light">{{ message.productMulti.action.itemsForMultiproduct }} items</p>
                    </div>
                  </div>
                  <span class="t-mt-[5px]" *ngIf="message.productMulti?.body">
                    {{ message.productMulti?.body }}
                  </span>
                  <span class="t-mt-[5px] t-text-sm" *ngIf="message.productMulti?.footer">
                    {{ message.productMulti?.footer }}
                  </span>
                </div>
                <hr />
                <div
                  class="chat-button t-cursor-pointer t-outline-none"
                  (click)="showCatalogueItems(catalogueProductList, message.productMulti, message.type, message.multiProduct_id)">
                  Start Shopping
                </div>
                <span class="t-flex t-justify-end t-text-sm"> {{ message.createdAt | date: "shortTime" }}</span>
              </div>
            </div>
          </div>
          <div class="bot-chat" *ngIf="message.type === 'productSingle'">
            <div class="chat single-product-box">
              <div class="t-mb-2.5 t-flex t-flex-col">
                <div class="product-header">
                  <img *ngIf="message.productSingle?.action?.image" [src]="message.productSingle.action.image" alt="singleproductimage" />
                  <p *ngIf="message.productSingle?.action?.title" class="t-text-sm t-font-semibold t-ml-2.5">
                    {{ message.productSingle.action.title }}
                  </p>
                  <p *ngIf="message.productSingle?.action?.price" class="t-text-sm t-ml-2.5 t-mt-[5px] t-mb-[5px]">
                    {{ message.productSingle.action.price }}
                  </p>
                </div>
                <span class="t-mt-[5px]" *ngIf="message.productSingle?.body">
                  {{ message.productSingle?.body }}
                </span>
                <span class="t-mt-[5px] t-text-sm" *ngIf="message.productSingle?.footer">
                  {{ message.productSingle?.footer }}
                </span>
              </div>
              <hr />
              <div
                class="chat-button t-cursor-pointer t-outline-none"
                (click)="showCatalogueItems(catalogueProductList, message.productSingle, message.type, message.singleProduct_id)">
                View
              </div>
              <span class="t-flex t-justify-end t-text-sm"> {{ message.createdAt | date: "shortTime" }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="message.from === 'user'">
          <div class="user-chat t-flex t-items-center" *ngIf="message.type === 'text'">
            <div class="chat">
              <p>{{ message.text }}</p>
            </div>
          </div>
          <div class="user-chat t-flex" *ngIf="message.type === 'order'">
            <div class="chat product-box-user">
              <div class="t-flex t-flex-col">
                <div class="t-mb-2.5 t-flex t-items-center product-header-user">
                  <img
                    *ngIf="(message.order?.items)[0].image"
                    [src]="(message.order?.items)[0].image"
                    alt="header-image"
                    class="product-header-image-user" />
                  <img
                    *ngIf="!(message.order?.items)[0].image"
                    class="image-common"
                    src="assets/images/catalogue_image.svg"
                    alt="header-image" />
                  <div class="t-w-[75%] t-ml-5 t-flex t-flex-col">
                    <div class="t-flex t-items-center t-mb-[5px]">
                      <mat-icon class="t-mr-[5px]">shopping_cart_checkout</mat-icon>
                      <span class="t-text-xl t-font-semibold">{{ message.order?.itemsForOrder }} Items</span>
                    </div>
                    <p *ngIf="message.order?.totalPriceOfOrder > 0" class="t-text-sm">
                      {{ (message.order?.items)[0].currency }} {{ message.order?.totalPriceOfOrder }} (estimated total)
                    </p>
                  </div>
                </div>
                <hr />
                <div class="t-mb-[5px] chat-button t-cursor-pointer t-outline-none" (click)="showCart(orderItem, message.order)">
                  View sent cart
                </div>
                <span class="t-flex t-justify-end t-text-sm"> {{ message.createdAt | date: "shortTime" }}</span>
              </div>
            </div>
          </div>
          <div class="user-chat t-flex" *ngIf="message.type === 'location'">
            <div class="chat user-sent-location">
              <a
                class="t-flex t-items-center t-justify-center"
                [href]="'https://www.google.com/maps/search/?api=1&query=' + message.location.latitude + ',' + message.location.longitude"
                target="_blank">
                <img src="assets/images/googlemaps.jpg" alt="location" />
              </a>
              <div *ngIf="message.location.label" class="t-ml-[5px] t-mr-[5px] t-mt-2.5"></div>
              <a
                [href]="'https://www.google.com/maps/search/?api=1&query=' + message.location.latitude + ',' + message.location.longitude"
                target="_blank">
                <p *ngIf="message.location.address" class="location-body t-pl-[5px] t-pr-[5px] t-mt-2.5">
                  {{ message.location.address }}
                </p>
              </a>
            </div>
          </div>
          <ng-container *ngIf="message.type === 'addressMessageResponse'">
            <div class="user-chat t-flex t-items-center">
              <div class="chat">
                <p [innerHTML]="message.addressMessageResponse?.body"></p>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="message.type === 'attachment'">
            <div class="user-chat t-flex">
              <div class="bot-message-media--parent-user" *ngIf="message.mediaType === 'image'">
                <div style="width: 100%">
                  <img [src]="message.url" alt="image" />
                </div>
              </div>
              <div class="bot-message-media--parent-user" *ngIf="message.mediaType === 'video'">
                <div style="width: 100%">
                  <video controls>
                    <source [src]="message.url" type="video/mp4" />
                    <source [src]="message.url" type="video/webm" />
                    <source [src]="message.url" type="video/ogg" />
                  </video>
                </div>
              </div>
              <div class="bot-message-media--parent-user" *ngIf="message.mediaType === 'audio'">
                <div style="width: 100%">
                  <audio controls>
                    <source [src]="message.url" type="audio/mpeg" />
                  </audio>
                </div>
              </div>
              <div class="bot-message-doc--parent-user" *ngIf="message.mediaType === 'doc'">
                <a class="t-flex t-items-center" [href]="message.url" target="_blank">
                  <img src="assets/images/{{ message.docType }}.svg" alt="document" />
                  <div class="ml-5">
                    {{ message.name }}
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <!-- MAIN CHAT PART | END -->
  </div>
  <div class="message-part">
    <!-- <div class="t-flex t-items-center t-justify-center"> -->
    <div class="message-input">
      <input
        #newMessage
        type="text"
        class="input ml-10 t-w-full"
        placeholder="Type message"
        (keyup.enter)="sendMessage(newMessage.value, newMessage)" />
      <mat-icon (click)="multipleFileUplodPreview(multipleUpload)">attach_file</mat-icon>
    </div>
    <mat-icon (click)="sendMessage(newMessage.value, newMessage)" class="material-icons-outlined send">send</mat-icon>
    <!-- </div> -->
  </div>
</div>

<!--List Template -->
<ng-template #listTemplate>
  <mat-radio-group [(ngModel)]="listData.selectedValue">
    <div class="list-option-main-div" *ngFor="let listItem of listData.sections">
      <div class="title">{{ listItem.title | titlecase }}</div>
      <div class="list-option">
        <ng-container *ngFor="let option of listItem.row">
          <div class="t-w-full t-flex t-items-center t-justify-between option">
            <div (click)="setSelected(option)">
              <div class="t-w-full option-title">{{ option.title }}</div>
              <div class="t-flex t-w-full option-desc" *ngIf="option.description">{{ option.description }}</div>
            </div>
            <mat-radio-button
              class="radio radio-secondary radio-normal"
              (click)="setSelected(option)"
              [value]="option.id"
              label="abc"></mat-radio-button>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-radio-group>
  <mat-dialog-actions class="button-wrapper">
    <button mat-flat-button color="primary" class="btn primary-btn t-mx-auto" (click)="handleListSend()">Send</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #orderItem>
  <div class="product-main-div t-p-[5px]">
    <div class="scrollable-div t-pr-2.5">
      <div class="product-div t-w-full t-mt-2.5 t-mb-2.5" *ngFor="let item of orderedItem.items">
        <div class="image-div">
          <img class="t-h-full" [src]="item.image ? item.image : 'assets/images/catalogue_image.svg'" alt="product-image" />
        </div>
        <div class="t-flex t-justify-between t-w-full">
          <div class="t-h-full title-main-div">
            <span *ngIf="item.title" class="product-title" [matTooltip]="item.title">{{ item.title }}</span>
            <span *ngIf="!item.title">Product Id: {{ item.retailerId }}</span>
            <p class="subtitle-cart-order subtitle-common dot" [matTooltip]="item.subTitle">{{ item.subTitle }}</p>
          </div>
          <div class="t-flex t-flex-col t-justify-between t-items-end t-m-2.5">
            <span class="t-mr-[5px]">Quantity: {{ item.quantity }}</span>
            <div class="t-text-base">
              {{ item.totalPriceOfItem ? orderedItem.items[0].currency + " " + item.totalPriceOfItem : "—" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-footer-div t-mt-2.5 t-flex t-justify-between t-p-2.5">
      <div class="t-flex t-flex-col t-w-[75%]">
        <span>{{ orderedItem.itemsForOrder }} Items</span>
        <p class="t-pt-2.5">The business will confirm your order and total price, including any tax, fees and discounts</p>
      </div>
      <span *ngIf="orderedItem.totalPriceOfOrder">{{ orderedItem.items[0].currency }} {{ orderedItem.totalPriceOfOrder }}</span>
    </div>
  </div>
</ng-template>

<ng-template #viewCart>
  <div class="product-main-div t-p-[5px]">
    <div class="scrollable-div t-pr-2.5">
      <div class="product-div t-w-full t-mt-2.5 t-mb-2.5" *ngFor="let item of cartItem; let itemIndex = index">
        <div class="image-div">
          <img class="t-h-full" [src]="item.image ? item.image : 'assets/images/catalogue_image.svg'" alt="product-image" />
        </div>
        <div class="t-flex t-justify-between t-w-full">
          <div class="t-h-full title-main-div">
            <span *ngIf="item?.title" class="product-title" [matTooltip]="item.title">{{ item.title }}</span>
            <span *ngIf="!item.title">Product Id: {{ item.retailerId }}</span>
            <p class="subtitle-cart-order subtitle-common dot" [matTooltip]="item.subTitle">{{ item.subTitle }}</p>
          </div>
          <div class="t-flex t-flex-col t-justify-between t-items-end t-m-2.5 t-text-base">
            <div class="t-flex t-items-center">
              <ng-container *ngIf="productQuntity[item.retailerId]">
                <button mat-icon-button class="common-icon-div icon-button" (click)="decrementValue(itemIndex)">
                  <mat-icon>remove</mat-icon>
                </button>

                <span class="t-ml-2.5 t-mr-2.5">{{ productQuntity[item.retailerId] }}</span>
              </ng-container>
              <button mat-icon-button class="common-icon-div icon-button" (click)="incrementValue(itemIndex)">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div>{{ item.totalPriceOfItem ? item.currency + " " + item.totalPriceOfItem : "—" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-footer-div t-mt-2.5 t-flex t-justify-between t-p-2.5">
      <div class="t-flex t-flex-col t-w-[75%]">
        <span>{{ cartItem.length }} Items</span>
        <p class="t-pt-2.5">The business will confirm your order and total price, including any tax, fees and discounts</p>
      </div>
      <span *ngIf="totalPriceOfOrder">{{ cartItem[0]?.currency }} {{ totalPriceOfOrder }}</span>
    </div>
    <div class="t-mt-5 t-flex t-justify-end">
      <button mat-flat-button color="primary" class="common-button" [disabled]="!cartItem.length" (click)="sendToBusiness()">
        Send To Business
      </button>
    </div>
  </div>
</ng-template>

<ng-template #catalogueProductList>
  <div class="product-main-div t-p-[5px]">
    <div class="scrollable-div t-pr-2.5">
      <ng-container *ngIf="!isSingleProduct">
        <ng-container *ngFor="let section of productData.action?.sections; let sectionIndex = index">
          <div class="section-header-div t-mt-2.5">
            <span class="t-ml-2.5 t-font-semibold">{{ section.title }}</span>
          </div>
          <ng-container>
            <div class="product-div t-w-full t-mt-2.5 t-mb-2.5" *ngFor="let item of section.items; let itemIndex = index">
              <img [src]="item.image ? item.image : 'assets/images/catalogue_image.svg'" class="t-h-full" alt="product-image" />
              <div class="t-flex t-justify-between t-w-full">
                <div class="t-h-full title-main-div">
                  <span *ngIf="item?.title" class="product-title" [matTooltip]="item.title">{{ item?.title }}</span>
                  <span *ngIf="!item.title">Product Id: {{ item.retailerId }}</span>
                  <p class="subtitle-single-multi subtitle-common dot" [matTooltip]="item.subTitle">{{ item?.subTitle }}</p>
                </div>

                <div class="t-flex t-flex-col t-justify-between t-items-end t-m-2.5 t-text-base">
                  <div class="t-flex t-items-center">
                    <ng-container *ngIf="productQuntity[item.retailerId]">
                      <button mat-icon-button class="common-icon-div icon-button" (click)="decrementValue(itemIndex, sectionIndex)">
                        <mat-icon>remove</mat-icon>
                      </button>
                      <span class="t-ml-2.5 t-mr-2.5">{{ productQuntity[item.retailerId] }}</span>
                    </ng-container>
                    <button mat-icon-button class="common-icon-div icon-button" (click)="incrementValue(itemIndex, sectionIndex)">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                  <div>
                    <span>{{ item.price?.trim() ? item.currency : "" }} {{ item.priceWithoutSymbol }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isSingleProduct">
        <div class="product-div t-w-full t-mt-2.5">
          <img
            [src]="productDataForSingle.action.image ? productDataForSingle.action.image : 'assets/images/catalogue_image.svg'"
            class="t-h-full"
            alt="product-image" />
          <div class="t-flex t-justify-between t-w-full">
            <div class="t-h-full title-main-div">
              <span *ngIf="productDataForSingle.action.title" class="product-title" [matTooltip]="productDataForSingle.action.title">{{
                productDataForSingle.action.title
              }}</span>
              <span *ngIf="!productDataForSingle.action.title">Product Id: {{ productDataForSingle.action.retailerId }}</span>
              <p class="subtitle-single-multi subtitle-common dot" [matTooltip]="productDataForSingle.action.subTitle">
                {{ productDataForSingle.action.subTitle }}
              </p>
            </div>

            <div class="t-flex t-flex-col t-justify-between t-items-end t-m-2.5 t-text-base">
              <div class="t-flex t-items-center">
                <ng-container *ngIf="productQuntity[productDataForSingle.action.retailerId]">
                  <button mat-icon-button class="common-icon-div icon-button" (click)="decrementValue()">
                    <mat-icon>remove</mat-icon>
                  </button>
                  <span class="t-ml-2.5 t-mr-2.5">{{ productQuntity[productDataForSingle.action.retailerId] }}</span>
                </ng-container>
                <button mat-icon-button class="common-icon-div icon-button" (click)="incrementValue()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
              <div>
                <span
                  >{{ productDataForSingle.action.price?.trim() ? productDataForSingle.action.currency : "" }}
                  {{ productDataForSingle.action.priceWithoutSymbol }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <mat-dialog-actions class="t-mt-5 t-flex t-justify-end">
    <button mat-flat-button color="primary" class="common-button" [disabled]="!cartItem.length" (click)="showCart(viewCart)">
      View cart ({{ cartItem.length }})
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #addressMessageFormModal>
  <ng-container *ngIf="showSavedAddresses && saved_addresses.length">
    <div
      class="t-flex t-items-center t-justify-start t-mt-2.5 p-15 add-an-address t-cursor-pointer t-outline-none"
      (click)="showSavedAddresses = !showSavedAddresses; selectedSavedAddress = null">
      <mat-icon color="primary">add_circle_outline</mat-icon>
      <span class="t-ml-2.5 color-primary">Add an address</span>
    </div>

    <div class="saved-address-div">
      <p class="t-text-lg t-mt-2.5 t-mb-[5px] t-font-semibold">Select Address</p>
      <div class="horizontal-line"></div>
      <div class="saved-addresses-list">
        <mat-radio-group #radioAddressGroup="matRadioGroup" [(ngModel)]="selectedSavedAddress">
          <div class="t-mt-2.5 saved-address-block p-15 t-mb-2.5" *ngFor="let address of saved_addresses; index as savedAddressIndex">
            <div class="t-flex t-items-center t-justify-between">
              <mat-radio-button class="radio" [value]="address">
                <div class="t-flex t-flex-col t-ml-2.5 t-w-full">
                  <span class="t-text-base t-font-bold saved-address">{{ address.value.name }}</span>
                  <span class="t-text-sm saved-address">{{ address.value.phone_number }}</span>
                  <span class="t-text-sm t-mt-2.5 saved-address">
                    {{ address.value.house_number ? address.value.house_number + ", " : "" }}
                    {{ address.value.floor_number ? address.value.floor_number + ", " : "" }}
                    {{ address.value.tower_number ? address.value.tower_number + ", " : "" }}
                    {{ address.value.building_name ? address.value.building_name + ", " : "" }}
                    {{ address.value.address ? address.value.address + ", " : "" }}
                    {{ address.value.landmark_area ? address.value.landmark_area + ", " : "" }}
                    {{ address.value.city ? address.value.city + ", " : "" }}
                    {{ address.value.state ? address.value.state : "" }}
                    {{ address.value.in_pin_code ? " - " + address.value.in_pin_code : "" }}
                  </span>
                </div>
              </mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <div class="button-wrapper">
      <button
        type="button"
        mat-flat-button
        class="common-button send-address-button t-w-full t-cursor-pointer t-outline-none t-p-2.5 t-mt-2.5"
        [disabled]="!radioAddressGroup.selected"
        (click)="sendAddressMessage(selectedSavedAddress, true)">
        Send Address
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!showSavedAddresses || !saved_addresses.length">
    <div
      class="t-flex t-items-center t-justify-start t-cursor-pointer t-outline-none t-mb-[5px] color-primary"
      *ngIf="saved_addresses.length"
      (click)="showSavedAddresses = !showSavedAddresses">
      <mat-icon>arrow_back</mat-icon><span class="t-text-sm t-ml-[5px]">Back</span>
    </div>

    <div class="content-align-col">
      <div class="address-form-div">
        <form [formGroup]="addressMessageForm" class="t-pt-[5px]">
          <div class="content-align-col">
            <div class="content-align-col">
              <span class="t-text-lg t-mb-[5px] color-grey t-font-semibold">Contact Details</span>
              <div class="input-wrapper">
                <label for="name"><span class="t-text-sm">Name</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input matInput autocomplete="off" type="text" formControlName="name" placeholder="Enter name" />
                </mat-form-field>
              </div>
              <div class="input-wrapper">
                <label for="phone_number"><span class="t-text-sm">Phone Number</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input
                    matInput
                    autocomplete="off"
                    type="text"
                    minlength="10"
                    formControlName="phone_number"
                    placeholder="Enter phone number"
                    (paste)="handleMobileNumberPaste($event)" />
                </mat-form-field>
              </div>
            </div>

            <div class="content-align-col">
              <span class="t-text-lg color-grey t-font-semibold">Address Details</span>
              <div class="input-wrapper">
                <label for="in_pin_code"><span class="t-text-sm">PIN Code</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input
                    matInput
                    autocomplete="off"
                    type="text"
                    maxlength="6"
                    minlength="6"
                    pattern="\d+"
                    formControlName="in_pin_code"
                    placeholder="Enter PIN code" />
                </mat-form-field>
              </div>

              <div class="input-wrapper">
                <label for="address"><span class="t-text-sm">Address</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input matInput autocomplete="off" type="text" formControlName="address" placeholder="Enter address" />
                </mat-form-field>
              </div>

              <div class="input-wrapper">
                <label for="landmark_area"><span class="t-text-sm">Landmark / Area</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input matInput autocomplete="off" type="text" formControlName="landmark_area" placeholder="Enter landmark / area" />
                </mat-form-field>
              </div>

              <div class="input-wrapper">
                <label for="house_number"><span class="t-text-sm">Flat / House Number</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input
                    matInput
                    autocomplete="off"
                    type="text"
                    formControlName="house_number"
                    placeholder="Enter flat / house number (optional)" />
                </mat-form-field>
              </div>

              <div class="input-wrapper">
                <label for="floor_number"><span class="t-text-sm">Floor Number</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input
                    matInput
                    autocomplete="off"
                    type="text"
                    formControlName="floor_number"
                    placeholder="Enter floor number (optional)" />
                </mat-form-field>
              </div>

              <div class="input-wrapper">
                <label for="tower_number"><span class="t-text-sm">Tower Number</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input
                    matInput
                    autocomplete="off"
                    type="text"
                    formControlName="tower_number"
                    placeholder="Enter tower number (optional)" />
                </mat-form-field>
              </div>

              <div class="input-wrapper">
                <label for="building_name"><span class="t-text-sm">Building / Apartment Name</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input
                    matInput
                    autocomplete="off"
                    type="text"
                    formControlName="building_name"
                    placeholder="Enter building / apartment name (optional)" />
                </mat-form-field>
              </div>

              <div class="input-wrapper">
                <label for="city"><span class="t-text-sm">City</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input matInput autocomplete="off" type="text" formControlName="city" placeholder="Enter city" />
                </mat-form-field>
              </div>

              <div class="input-wrapper">
                <label for="state"><span class="t-text-sm">State</span></label>
                <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
                  <input matInput autocomplete="off" type="text" formControlName="state" placeholder="Enter state" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="button-wrapper send-address-button-div">
        <button
          type="button"
          mat-flat-button
          class="btn primary-btn btn-full send-address-button"
          [disabled]="!addressMessageForm.valid"
          (click)="sendAddressMessage(addressMessageForm, false)">
          Send Address
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #multipleUpload>
  <div class="multiple-file-select-modal">
    <div class="inside-modal-div">
      <div
        class="upload-file-box"
        (click)="selectMultipleFile.click()"
        [ngClass]="{ 'blur-screen': isMultipleFilesUploading }"
        (drop)="onFilesSelected($event, 'dataTransfer')"
        (dragover)="$event.preventDefault()"
        (dragenter)="$event.preventDefault()">
        <mat-icon class="material-symbols-outlined">cloud_upload</mat-icon>
        <div>Select or drop files</div>
        <div class="t-hidden">
          <input
            #selectMultipleFile
            type="file"
            [accept]="ChannelFileTypeValidation[selectedChannel].join(',')"
            (change)="onFilesSelected($event, 'target')"
            multiple />
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedMediaFiles.length">
      <div class="input-wrapper">
        <label class="uploaded-file-count">
          {{
            isMultipleFilesUploading
              ? "Sending- " + totalUploadedFiles + "/" + totalSelectedFiles
              : "Files selected - " + totalSelectedFiles
          }}
        </label>
        <div class="selected-file-container">
          <ng-container *ngFor="let file of selectedMediaFiles; index as fileIndex">
            <div class="selected-file">
              <div class="file-details">
                <ng-container *ngIf="file.status !== MediaUploadStatus.FAILED">
                  <img
                    *ngIf="[MEDIA_TYPES.DOC, MEDIA_TYPES.XLS, MEDIA_TYPES.PPT, MEDIA_TYPES.PDF].includes(file.fileType)"
                    alt="document"
                    [src]="'assets/images/' + file.fileType + '.svg'" />
                  <img *ngIf="file.fileType === MEDIA_TYPES.IMAGE" [src]="file.fileRenderedUrl" alt="image" />
                  <mat-icon color="primary" *ngIf="file.fileType === MEDIA_TYPES.AUDIO">audiotrack</mat-icon>
                  <mat-icon color="primary" *ngIf="file.fileType === MEDIA_TYPES.VIDEO">live_tv</mat-icon>
                </ng-container>

                <mat-icon *ngIf="file.status === MediaUploadStatus.FAILED">warning_amber</mat-icon>
                <span class="file-name ml-5">
                  {{ file.fileName }}
                </span>
              </div>
              <div class="file-upload-status-icon">
                <mat-icon
                  class="t-cursor-pointer"
                  (click)="removeSelectedFile(fileIndex)"
                  *ngIf="file.status === MediaUploadStatus.PENDING || file.status === MediaUploadStatus.FAILED"
                  >close</mat-icon
                >
                <mat-icon *ngIf="file.status === MediaUploadStatus.UPLOADED" class="file-upload-success-icon">check_circle</mat-icon>
              </div>
            </div>
            <span *ngIf="file.status === MediaUploadStatus.FAILED && file.errorMessage" class="invalid-file-error">{{
              file.errorMessage
            }}</span>

            <div *ngIf="file.status === MediaUploadStatus.UPLOADING">
              <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <mat-dialog-actions>
      <button
        mat-flat-button
        class="btn primary-btn hover-effect"
        color="primary"
        [disabled]="!selectedMediaFiles.length || isUploadedFileContainsError || isMultipleFilesUploading"
        (click)="uploadAndSendSelectedFilesToUser()">
        Send
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
