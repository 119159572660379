<div class="dialog">
  <div class="dialog-main-container">
    <div class="dialog-header" *ngIf="!data.isHideHeader">
      <span class="header-title">
        <img *ngIf="data.icon" [src]="data.icon" width="25" /><span [matTooltip]="data.title">{{ data.title }}</span>
      </span>
      <div class="t-flex">
        <mat-icon class="header-close t-mr-5" *ngIf="data.imageUrl" (click)="downloadImage(data.imageUrl)">get_app</mat-icon>
        <mat-icon class="header-close" (click)="closeModal()" *ngIf="!data.showHandOffPopup">close</mat-icon>
      </div>
    </div>
    <div
      class="dialog-body"
      [ngClass]="{
        'template-dialog-body': data.type === 'sampleTemplate',
        'not-select': isPartnerOrCST,
        't-h-[90%]': data.type === 'orderPaymentDetails',
        't-flex': data.type === 'campaignSummary',
      }">
      <ng-template #componentTemplate></ng-template>
      <ng-template [ngIf]="data.template" [ngTemplateOutlet]="!data.isDelete ? data.template : deleteTemplate"></ng-template>
    </div>
  </div>
  <mat-dialog-actions align="end" *ngIf="data.showHandOffPopup">
    <button mat-stroked-button color="primary" class="primary-btn btn t-ml-2.5" [mat-dialog-close]="true" mat-dialog-close>Ok</button>
  </mat-dialog-actions>
  <ng-template #deleteTemplate>
    <div class="delete-text">
      Are you sure you want to delete
      <b>{{ data.delete.text }} ?</b>
    </div>
    <div class="list-delete" *ngIf="data.delete.list && data.delete.list.length !== 0">
      <ng-container *ngFor="let listItem of data.delete.list; index as lIndex">
        <ng-container *ngIf="listItem.listData.length && listItem.listData.length > 1">
          <p>{{ listItem.name }} :</p>
          <div class="single-delete-item" *ngFor="let intent of listItem.listData; index as iIndex">
            {{ +iIndex + 1 }}. {{ intent.name }}
          </div>
        </ng-container>
      </ng-container>
    </div>
    <mat-dialog-actions align="end">
      <button mat-stroked-button color="accent" class="btn secondary-btn hover-effect" [mat-dialog-close]="false" mat-dialog-close>
        Cancel
      </button>
      <button [mat-dialog-close]="true" cdkFocusInitial mat-flat-button color="primary" class="btn primary-btn hover-effect">Delete</button>
    </mat-dialog-actions>
  </ng-template>
</div>
