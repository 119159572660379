export type TCustomFiledType = "date" | "string" | "number" | "dateAndTime" | "boolean" | "array" | "json";
export interface IDisplayInProfileField {
  _id: string;
  field: string;
  type: TCustomFiledType;
}

export enum TagType {
  USER = "user",
  AGENT = "agent",
}
