import { Component, OnInit } from "@angular/core";

import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-messenger-icon",
  templateUrl: "./messenger-icon.component.html",
  styleUrls: ["../messenger.common-bot.component.scss"],
})
export class MessengerIconComponent implements OnInit {
  photoUrl;

  constructor(public _authService: AuthService) {}

  ngOnInit(): void {
    this.photoUrl =
      this._authService.getUser() && this._authService.getUser().profileImage
        ? this._authService.getUser().profileImage
        : "../../../../../assets/images/Bot-face.svg";
  }
}
