import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { SettingService } from "src/app/services/setting.service";
import { NULLISH_STRING_VALUE } from "../../services/common.service";
import { GoogleAuthConst } from "../../utils/consts/googleAuth.const";
import { IGoogleAuthCallback } from "../../utils/model/common";

@Component({
  selector: "app-google-callback",
  templateUrl: "./google-callback.component.html",
  styleUrls: ["./google-callback.component.scss"],
})
export class GoogleCallbackComponent implements OnInit {
  isLoading = false;

  constructor(
    readonly activeRoute: ActivatedRoute,
    readonly router: Router,
    private readonly _settingService: SettingService
  ) {}

  ngOnInit(): void {
    this.handleSlackCallback(this.activeRoute.snapshot.queryParams);
  }

  handleSlackCallback(queryParams: any): void {
    const appPanelUrl = this._settingService.whiteLabelData.data.appPanelUrl;
    const parentWindowsData: IGoogleAuthCallback = {
      type: GoogleAuthConst.TYPES.FAILURE,
      data: {
        code: "",
        scope: "",
      },
    };
    const parent = window.opener;
    if (!NULLISH_STRING_VALUE.includes(queryParams.code) && !NULLISH_STRING_VALUE.includes(queryParams.state)) {
      try {
        this.isLoading = true;
        const state = JSON.parse(queryParams.state);
        if (state.securityCode) {
          parentWindowsData.type = GoogleAuthConst.TYPES.SUCCESS;
          parentWindowsData.data = {
            code: this.activeRoute.snapshot.queryParams.code,
            scope: queryParams.scope,
            state,
          };
        } else {
          parentWindowsData.type = GoogleAuthConst.TYPES.ERROR;
        }
        if (parent) {
          parent.postMessage(parentWindowsData, appPanelUrl);
        }
        this.isLoading = false;
        window.close();
      } catch (e) {
        parentWindowsData.type = GoogleAuthConst.TYPES.ERROR;
        if (parent) {
          parent.postMessage(parentWindowsData, appPanelUrl);
        }
        this.isLoading = false;
        console.error("Error in decode JSON");
        window.close();
      }
    } else if (this.activeRoute.snapshot.queryParams.error) {
      this.isLoading = false;
      parentWindowsData.type = GoogleAuthConst.TYPES.FAILURE;
      if (parent) {
        parent.postMessage(parentWindowsData, appPanelUrl);
      }
      window.close();
    }
  }
}
