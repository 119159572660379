import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { catchError, takeUntil, tap } from "rxjs/operators";

import { UuidGenerator } from "../../../bot-builder/bot-helper-classes/UUID.generator";
import { DialogComponent } from "../../../common-components/dialog/dialog.component";
import { CommonService } from "../../../services/common.service";
import { AddressMessageFieldType, FlowStepReplyButtonType } from "../../../utils/consts/flow-step.const";

declare let DirectLine;
import { PreviewSocketService } from "../../../services/preview-socket.service";
import AwesomePhonenumber from "awesome-phonenumber";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import anchorme from "anchorme";
import { ISelectedMediaFiles, MediaUploadStatus } from "src/app/utils/model/handoff";
import {
  ChannelFileTypeValidation,
  MEDIA_TYPES,
  MIME_TYPE_MAPPING,
  getMediaTypeFromMimeType,
} from "src/app/utils/consts/channelSupportedMediaTypes";
import { FlowValidationHelper } from "src/app/bot-builder/bot-helper-classes/flow.validation.helper";
import { HandOffV2Service } from "src/app/services/hand-off-v2.service";
import { HttpEventType } from "@angular/common/http";

@Component({
  selector: "app-whatsapp-preview",
  templateUrl: "./whatsapp-preview.component.html",
  styleUrls: ["./whatsapp-preview.component.scss"],
})
export class WhatsappPreviewComponent implements OnInit, OnDestroy {
  componentName = "whatsapp-preview";

  @Input() botId: string;
  @Input() selectedChannel: string;
  @Input() previewAuth;

  @Output() changepreviewUserDynamicData = new EventEmitter<any>();

  addressMessageForm: UntypedFormGroup;
  directLine;
  fromId = "";
  messages = [];
  UUID = new UuidGenerator();
  isConversationStarted = false;
  showSavedAddresses: boolean = false;
  saved_addresses = [];
  selectedSavedAddress = null;
  dialogRef;
  private unsubscribe: Subject<void> = new Subject();
  listData;
  firstMessageSent = false;
  FlowStepReplyButtonType = FlowStepReplyButtonType;
  accessToken = "";
  unique_idCount = 0;
  manageSingleAndMultiProduct = {};
  productQuntity = {};
  productData;
  productDataForSingle;
  cartItem = [];
  orderedItem;
  totalPriceOfOrder = 0;
  isSingleProduct: boolean;
  sendBusiness = {
    _default: {
      type: "order",
      order: {
        catalogId: "",
        items: [],
      },
    },
  };
  selectedMediaFiles: Array<ISelectedMediaFiles> = [];
  totalSelectedFiles = 0;
  totalUploadedFiles = 0;
  validations = new FlowValidationHelper().validations;
  mimeTypeObject = {};
  isUploadedFileContainsError = false;
  isMultipleFilesUploading: boolean;
  transcriptDataNewBatch;
  private multipleFileUploadCallSubject: Subject<void> = new Subject();
  MEDIA_TYPES = MEDIA_TYPES;
  MediaUploadStatus = MediaUploadStatus;
  ChannelFileTypeValidation = ChannelFileTypeValidation;
  docType = ["xls", "doc", "ppt", "pdf"];
  isUploadSocketConnected = false;

  constructor(
    public dialog: MatDialog,
    private previewSocketService: PreviewSocketService,
    public readonly commonService: CommonService
  ) {}

  ngOnInit(): void {
    Object.keys(MIME_TYPE_MAPPING).forEach(typeKey => {
      MIME_TYPE_MAPPING[typeKey].forEach(mimeType => {
        this.mimeTypeObject[mimeType] = typeKey;
      });
    });
    this.getPreviewAccessToken().catch(() => {});
  }

  async getPreviewAccessToken() {
    try {
      const response = await this.previewSocketService.generateAccessTokenForPreviewChannel(this.botId, this.previewAuth.appSecret);
      this.accessToken = response.accessToken;
    } catch (err) {
      this.commonService.errorHandler(err, `${this.componentName}:getPreviewAccessToken`, true);
    }
  }

  setNewFromId() {
    const chars = "0123456789";
    const stringLength = 10;
    let randomstring = "";
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    this.fromId = `preview-${this.selectedChannel}-+91${randomstring}`;
  }

  initializePreviewSocket(message) {
    this.setNewFromId();
    this.previewSocketService.previewSocketCityConnection(this.botId, this.fromId, this.accessToken, this.selectedChannel);
    this.previewSocketService.botOutputEvent();
    const contactNumber = this.fromId.split("-")[2];
    const result = new AwesomePhonenumber(contactNumber);
    const country = result.getRegionCode();
    const sendMessageObject = {
      text: message,
      data: {
        _default: {
          type: "text",
          text: message,
        },
      },
      extra: {
        customFields: {
          contactNumber,
          country,
          locale: "en-US",
        },
      },
    };
    this.firstMessageSent = true;
    this.sendMessageToBot(sendMessageObject, true);
  }

  sendMessageToBot(object, isFirst?: boolean) {
    this.previewSocketService.sendUserMessageToBot(object);
    if (isFirst) {
      this.activityMonitor();
    }
  }

  activityMonitor() {
    this.isConversationStarted = true;
    this.previewSocketService
      .botOutputListener()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(activity => {
        activity = JSON.parse(activity);
        if (activity._default) {
          switch (activity._default.type) {
            case "mcq":
              if (activity._default.mcq?.style === "dynamicMcq") {
                const dynamicChoices: Array<{
                  value: string;
                  synonyms: Array<string>;
                  input_mapping: any;
                  is_split_message: boolean;
                  extra_attachment_type?: "image" | "video";
                  attachment_url?: string;
                }> = activity._default.mcq.choices;
                let replyIndex = 0;
                const reply: any = {};
                const replies = [];
                reply.content = {};
                reply.content.text = `${activity._default.mcq.text}\n\n`;
                replies.push({ ...reply });
                dynamicChoices.forEach((choiceItem, itemIndex) => {
                  if (choiceItem.is_split_message) {
                    replyIndex += 1;
                    reply.content = {};
                    reply.content.text = `${choiceItem.value}\n`;
                    replies[replyIndex] = { ...reply };
                  } else if (choiceItem.extra_attachment_type) {
                    replyIndex += 1;
                    reply.content = {};
                    reply.content.media = {};
                    reply.content.media.url = choiceItem.attachment_url;
                    reply.content.media.type = choiceItem.extra_attachment_type;
                    reply.content.media.caption = choiceItem.value;
                    replies[replyIndex] = { ...reply };
                    if (
                      dynamicChoices[itemIndex + 1] &&
                      !dynamicChoices[itemIndex + 1].extra_attachment_type &&
                      !dynamicChoices[itemIndex + 1].is_split_message
                    ) {
                      replyIndex += 1;
                    }
                  } else if (replies[replyIndex]) {
                    replies[replyIndex].content.text = replies[replyIndex].content.text.concat(`${choiceItem.value}\n`);
                  } else {
                    reply.content = {};
                    reply.content.text = `${choiceItem.value}\n`;
                    replies[replyIndex] = { ...reply };
                  }
                });
                this.handleDynamicMcq(replies);
              } else if (activity._default.mcq?.style === "list") {
                this.addTextMessageInBot(activity._default.mcq.text, "bot");
              }
              break;
            case "list":
            case "dynamicList":
              this.messages.push({
                from: "bot",
                type: "list",
                list: activity._default[activity._default.type],
                _id: this.UUID.UUID(),
              });
              this.scrollConversation();
              break;
            case "reply":
            case "dynamicReply":
              this.messages.push({
                from: "bot",
                type: "reply",
                reply: activity._default[activity._default.type],
                _id: this.UUID.UUID(),
              });
              this.scrollConversation();
              break;
            case "text":
              this.addTextMessageInBot(activity._default.text.text, "bot");
              break;
            case "question":
              if (activity._default.question?.buttons && activity._default.question?.buttons?.length > 0) {
                this.messages.push({
                  from: "bot",
                  type: "reply",
                  reply: {
                    body: activity._default.question.text,
                    actions: {
                      buttons: activity._default.question.buttons,
                      buttonType: "reply",
                    },
                  },
                  _id: this.UUID.UUID(),
                });
                this.scrollConversation();
              } else {
                this.addTextMessageInBot(activity._default.question.text, "bot");
              }
              break;
            case "media":
              if (
                activity._default.media.mediaType === "image" ||
                activity._default.media.mediaType === "video" ||
                activity._default.media.mediaType === "audio"
              ) {
                this.addMediaInBot(
                  activity._default.media.link,
                  "bot",
                  activity._default.media.isCaptionAttached ? activity._default.media.caption : "",
                  activity._default.media.mediaType
                );
              } else if (
                activity._default.media.mediaType === "pdf" ||
                activity._default.media.mediaType === "doc" ||
                activity._default.media.mediaType === "ppt" ||
                activity._default.media.mediaType === "xls"
              ) {
                this.addDocumentsInBot(
                  activity._default.media.link,
                  "bot",
                  activity._default.media.caption,
                  activity._default.media.mediaType,
                  activity._default.media.filename ? activity._default.media.filename : ""
                );
              }
              break;
            case "location":
              this.addLocationCard(activity._default.location);
              break;
            case "locationRequest":
              this.messages.push({
                from: "bot",
                type: "locationRequest",
                locationRequest: activity._default.locationRequest,
                _id: this.UUID.UUID(),
              });
              break;
            case "addressMessage":
              this.messages.push({
                from: "bot",
                type: "addressMessage",
                addressMessage: activity._default.addressMessage,
                isTheAddressSent: false,
                _id: this.UUID.UUID(),
              });
              this.scrollConversation();
              break;
            case "contact":
              this.addContactCard(activity._default.contact);
              break;
            case "productMulti":
              activity._default.from = "bot";
              this.unique_idCount++;
              activity._default.multiProduct_id = this.unique_idCount;
              activity._default.productMulti.action.itemsForMultiproduct = 0;
              activity._default.productMulti.action.sections?.forEach(section => {
                activity._default.productMulti.action.itemsForMultiproduct += section.items.length;
                section.items?.forEach(item => {
                  item.priceWithoutSymbol = item.price?.trim() ? parseFloat(item.price?.replace(/,/g, "")) : "—";
                });
              });
              this.messages.push(activity._default);
              this.scrollConversation();
              break;
            case "productSingle":
              activity._default.from = "bot";
              this.unique_idCount++;
              activity._default.singleProduct_id = this.unique_idCount;
              activity._default.productSingle.action.priceWithoutSymbol = activity._default.productSingle.action.price?.trim()
                ? parseFloat(activity._default.productSingle.action.price?.replace(/,/g, ""))
                : "—";
              this.messages.push(activity._default);
              this.scrollConversation();
              break;
            case "event":
              this.changepreviewUserDynamicData.emit(activity._default.event);
              break;
            // if (activity.attachment && activity.attachment.type === "template") {
            //   this.addTextMessageInBot(activity.attachment.payload.text, "bot");
            //   this.addDatePickerLink(activity.attachment.payload.buttons[0].url, "bot");
            // }
          }
        }
      });
  }

  handleDynamicMcq(replies) {
    replies.forEach(res => {
      if (res.content && res.content.text) {
        this.addTextMessageInBot(res.content.text, "bot");
      } else if (res.content && res.content.media) {
        this.addMediaInBot(
          res.content.media.url,
          "bot",
          res.content.media.caption ? res.content.media.caption : "",
          res.content.media.type
        );
      }
    });
  }
  addDatePickerLink(url: string, from: string) {
    this.messages.push({
      from,
      type: "datePickerLink",
      url,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addDocumentsInBot(url: string, from: string, caption: string, docType: string, name: string) {
    this.messages.push({
      from,
      type: "file",
      docType,
      url,
      name,
      caption,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addMediaInBot(url: string, from: string, caption: string, type: string) {
    this.messages.push({
      from,
      type,
      url,
      caption,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addLocationCard(location: any) {
    const url = "https://www.google.com/maps/place/" + location.label + "/@" + location.latitude + "," + location.longitude + ",13z";
    this.messages.push({
      from: "bot",
      type: "location",
      location,
      url,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  addContactCard(contact) {
    this.messages.push({
      from: "bot",
      type: "contact",
      contactName: contact.vcard ? contact.vcard : contact.firstName + " " + contact.lastName,
      contactNumber: contact.phoneNumber,
    });
    this.scrollConversation();
  }
  addTextMessageInBot(text: string, from: string) {
    this.messages.push({
      from,
      type: "text",
      text,
      _id: this.UUID.UUID(),
    });
    this.scrollConversation();
  }

  getCoordinatesForLocationRequest() {
    navigator.geolocation.getCurrentPosition(position => {
      const location = {
        latitude: position.coords.latitude.toString(),
        longitude: position.coords.longitude.toString(),
        name: "",
        address: `Latitude: ${position.coords.latitude}
        Longitude: ${position.coords.longitude}`,
      };
      this.messages.push({
        from: "user",
        type: "location",
        location,
        _id: this.UUID.UUID(),
      });
      this.handleSendLocationRequest(location);
    });
  }

  sendMessage(message: string, elementRef: HTMLInputElement) {
    if (this.fromId.length === 0) {
      this.setNewFromId();
    }
    const contactNumber = this.fromId.split("-")[2];
    const result = new AwesomePhonenumber(contactNumber);
    const country = result.getRegionCode();
    if (!this.firstMessageSent && message.trim()) {
      this.initializePreviewSocket(message);
      this.addTextMessageInBot(message.trim(), "user");
      elementRef.value = "";
    } else if (message.trim()) {
      const sendMessageObject = {
        text: message.trim(),
        extra: {
          customFields: {
            contactNumber,
            country,
            locale: "en-US",
          },
        },
        data: {
          _default: {
            type: "text",
            text: message.trim(),
          },
        },
      };
      this.addTextMessageInBot(message.trim(), "user");
      elementRef.value = "";
      this.sendMessageToBot(sendMessageObject);
    }
  }

  clearChat() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isConversationStarted = false;
    this.directLine = null;
    const box = document.getElementById("conversation");
    if (box) {
      this.messages = [];
    }
    const container = document.getElementById("whatsapp_bot");
    if (container) {
      container.style.display = "none";
    }
  }

  scrollConversation() {
    const box = document.getElementById("conversation");
    setTimeout(() => {
      box.scrollTop = box.scrollHeight;
    }, 100);
  }

  trackById(index: number, message: any): string {
    return message._id;
  }

  ngOnDestroy() {
    this.clearChat();
    this.previewSocketService.removeAllListener();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  showListItem(template, actions) {
    this.listData = actions;
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: actions.button_text, template },
      height: "auto",
      width: "30vw",
    });
  }

  handleListSend() {
    this.dialog.closeAll();
    const description = this.listData.selected.description ? this.listData.selected.description : "";
    const title = this.listData.selected.title ? this.listData.selected.title : "";
    this.addTextMessageInBot(`${title}\n${description}`, "user");
    const sendMessageObject = {
      text: this.listData.selectedValue.trim(),
      data: {
        _default: {
          type: "text",
          text: this.listData.selectedValue.trim(),
        },
      },
      extra: {
        payload: {
          title: `${title}\n${description}`,
        },
      },
    };
    this.sendMessageToBot(sendMessageObject);
  }

  handleReplyButton(replyButton) {
    if (!replyButton.clicked) {
      this.addTextMessageInBot(replyButton.title, "user");
      replyButton.clicked = true;
      let text;
      if (replyButton.id) {
        text = replyButton.id.trim();
      } else if (replyButton.value) {
        text = replyButton.value?.trim();
      }
      const sendMessageObject = {
        text,
        data: {
          _default: {
            type: "text",
            text,
          },
        },
        extra: {
          payload: {
            title: replyButton.title,
          },
        },
      };
      this.sendMessageToBot(sendMessageObject);
    }
  }

  handleSendLocationRequest(location?) {
    this.sendMessageToBot({
      text: location.address,
      data: {
        _default: {
          type: "location",
          location,
        },
      },
    });
  }

  setSelected(selected) {
    this.listData.selectedValue = selected.id;
    this.listData.selected = selected;
  }

  showCatalogueItems(template, productData, type, product_id) {
    this.isSingleProduct = false;
    if (!this.manageSingleAndMultiProduct.hasOwnProperty(product_id)) {
      this.manageSingleAndMultiProduct[product_id] = JSON.parse(JSON.stringify(productData));
    }
    if (type == "productSingle") {
      this.isSingleProduct = true;
      this.productDataForSingle = this.manageSingleAndMultiProduct[product_id];
      if (!this.productQuntity[this.productDataForSingle.action.retailerId]) {
        this.productQuntity[this.productDataForSingle.action.retailerId] = 0;
      }
    } else {
      this.productData = this.manageSingleAndMultiProduct[product_id];
      this.productData.action.sections.forEach(section => {
        section.items.forEach(item => {
          if (!this.productQuntity[item.retailerId]) {
            this.productQuntity[item.retailerId] = 0;
          }
        });
      });
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: "Shop with us", template },
      height: "auto",
      width: "35vw",
    });
  }

  showCart(template, cartData?) {
    this.totalPriceOfOrder = 0;
    if (cartData) {
      this.orderedItem = cartData;
      this.dialogRef = this.dialog.open(DialogComponent, {
        panelClass: "addUser",
        data: { title: "View cart", template },
        height: "auto",
        width: "35vw",
      });
    } else {
      this.dialogRef.close();
      this.dialogRef = this.dialog.open(DialogComponent, {
        panelClass: "addUser",
        data: { title: "View cart", template },
        height: "auto",
        width: "35vw",
      });
      this.cartItem.forEach(item => {
        if (item.price?.trim()) {
          item.totalPriceOfItem = parseFloat(this.productQuntity[item.retailerId]) * parseFloat(item.price?.replace(/,/g, ""));
          this.totalPriceOfOrder += item.totalPriceOfItem;
        }
      });
    }
  }

  handleMobileNumberPaste(event) {
    const pastedText = event.clipboardData.getData("text");
    const phoneNumberPattern = /^((\+\d{1,3}(-)?)|0)?[0-9]{10}$/;
    if (!phoneNumberPattern.test(pastedText)) {
      event.preventDefault();
    }
  }

  addressMessageFillDetails(template, message) {
    const prefilledValues = message?.addressMessage?.prefilledValues;
    this.addressMessageForm = new UntypedFormGroup({
      [AddressMessageFieldType.NAME]: new UntypedFormControl(prefilledValues?.name || null, [Validators.required, this.noWhiteSpace]),
      [AddressMessageFieldType.PHONE_NUMBER]: new UntypedFormControl(prefilledValues?.phone_number || null, [
        Validators.required,
        Validators.pattern("^((\\+\\d{1,3}(-)?)|0)?[0-9]{10}$"),
        this.noWhiteSpace,
      ]),
      [AddressMessageFieldType.IN_PIN_CODE]: new UntypedFormControl(prefilledValues?.in_pin_code || null, [
        Validators.required,
        this.noWhiteSpace,
      ]),
      [AddressMessageFieldType.HOUSE_NUMBER]: new UntypedFormControl(prefilledValues?.house_number || null, []),
      [AddressMessageFieldType.FLOOR_NUMBER]: new UntypedFormControl(prefilledValues?.floor_number || null, []),
      [AddressMessageFieldType.TOWER_NUMBER]: new UntypedFormControl(prefilledValues?.tower_number || null, []),
      [AddressMessageFieldType.BUILDING_NAME]: new UntypedFormControl(prefilledValues?.building_name || null, []),
      [AddressMessageFieldType.ADDRESS]: new UntypedFormControl(prefilledValues?.address || null, [Validators.required, this.noWhiteSpace]),
      [AddressMessageFieldType.LANDMARK_AREA]: new UntypedFormControl(prefilledValues?.landmark_area || null, [
        Validators.required,
        this.noWhiteSpace,
      ]),
      [AddressMessageFieldType.CITY]: new UntypedFormControl(prefilledValues?.city || null, [Validators.required, this.noWhiteSpace]),
      [AddressMessageFieldType.STATE]: new UntypedFormControl(prefilledValues?.state || null, [Validators.required, this.noWhiteSpace]),
    });
    if (message.addressMessage?.saved_addresses) {
      this.saved_addresses = message.addressMessage?.saved_addresses;
      this.showSavedAddresses = true;
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: "Provide address", template },
      height: "auto",
      width: "28vw",
      autoFocus: false,
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.showSavedAddresses = false;
      this.selectedSavedAddress = null;
      this.addressMessageForm.reset();
    });
  }

  noWhiteSpace(control: UntypedFormControl): { [s: string]: boolean } | null {
    if (control.value != null && control.value.trim() === "") {
      return { noWhiteSpace: true };
    }
    return null;
  }

  sendAddressMessage(formValues, savedAddress) {
    const addressMessageResponseForm = {
      name: formValues.value.name,
      phone_number: formValues.value.phone_number,
      in_pin_code: formValues.value.in_pin_code,
      house_number: formValues.value.house_number,
      floor_number: formValues.value.floor_number,
      tower_number: formValues.value.tower_number,
      building_name: formValues.value.building_name,
      address: formValues.value.address,
      landmark_area: formValues.value.landmark_area,
      city: formValues.value.city,
      state: formValues.value.state,
    };
    let addressMessageResponseFormString = Object.values(addressMessageResponseForm)
      .filter(value => !!value)
      .map((value, index) => (index < 2 ? (index === 0 ? `<strong>${value}</strong>,\n` : `${value},\n`) : `${value}, `))
      .join("");

    addressMessageResponseFormString = addressMessageResponseFormString.trimRight().replace(/,$/, "");
    this.dialogRef.close();
    this.messages[this.messages.length - 1].isTheAddressSent = true;
    this.messages.push({
      from: "user",
      type: "addressMessageResponse",
      addressMessageResponse: {
        body: addressMessageResponseFormString,
      },
      _id: this.UUID.UUID(),
    });
    let response = "";
    if (savedAddress) response = JSON.stringify({ saved_address_id: formValues.id, values: addressMessageResponseForm });
    else response = JSON.stringify({ values: addressMessageResponseForm });
    this.sendMessageToBot({
      text: addressMessageResponseFormString,
      data: {
        _default: {
          type: "addressMessageResponse",
          addressMessageResponse: {
            response_json: response,
            name: "address_message",
          },
        },
      },
    });
  }

  incrementValue(itemIndex?, sectionIndex?) {
    let specificItem;
    if (sectionIndex == null && itemIndex >= 0) {
      this.productQuntity[this.cartItem[itemIndex].retailerId]++;
      if (this.cartItem[itemIndex]?.price?.trim()) {
        const priceWithoutDots = parseFloat(this.cartItem[itemIndex]?.price?.replace(/,/g, ""));
        this.cartItem[itemIndex].totalPriceOfItem += priceWithoutDots;
        this.totalPriceOfOrder += priceWithoutDots;
      }
      return;
    } else if (itemIndex >= 0 && sectionIndex >= 0) {
      specificItem = this.productData.action.sections[sectionIndex].items[itemIndex];
    } else {
      specificItem = this.productDataForSingle.action;
    }
    this.productQuntity[specificItem.retailerId]++;
    if (!this.cartItem.find(item => item.retailerId == specificItem.retailerId)) {
      this.cartItem.push(specificItem);
    }
  }

  decrementValue(itemIndex?, sectionIndex?) {
    let specificItem;
    if (sectionIndex == null && itemIndex >= 0) {
      this.productQuntity[this.cartItem[itemIndex].retailerId]--;
      if (this.cartItem[itemIndex]?.price?.trim()) {
        const priceWithoutDots = parseFloat(this.cartItem[itemIndex]?.price?.replace(/,/g, ""));
        this.cartItem[itemIndex].totalPriceOfItem -= priceWithoutDots;
        this.totalPriceOfOrder -= priceWithoutDots;
      }
      if (!this.productQuntity[this.cartItem[itemIndex].retailerId]) {
        this.cartItem.splice(itemIndex, 1);
      }
      return;
    } else if (itemIndex >= 0 && sectionIndex >= 0) {
      specificItem = this.productData.action.sections[sectionIndex].items[itemIndex];
    } else {
      specificItem = this.productDataForSingle.action;
    }
    this.productQuntity[specificItem.retailerId]--;
    if (!this.productQuntity[specificItem.retailerId]) {
      this.cartItem.splice(
        this.cartItem.findIndex(item => item.retailerId == specificItem.retailerId),
        1
      );
    }
  }

  sendToBusiness() {
    const finalCartItemArray = this.cartItem.map(item => ({
      retailerId: item.retailerId,
      quantity: this.productQuntity[item.retailerId].toString(),
      price: item.price?.trim() ? parseFloat(item.price?.replace(/,/g, "")) : undefined,
      currency: item.currency,
    }));
    this.sendBusiness._default.order.items = finalCartItemArray;
    this.sendBusiness._default.order.catalogId = this.productDataForSingle
      ? this.productDataForSingle.action.catalogId
      : this.productData.action.catalogId;
    this.sendMessageToBot({
      data: this.sendBusiness,
    });
    this.dialogRef.close();
    this.sentOrderDisplay();
    this.scrollConversation();
    this.productData = null;
    this.productDataForSingle = null;
    this.manageSingleAndMultiProduct = {};
    this.productQuntity = {};
    this.cartItem = [];
  }

  sentOrderDisplay() {
    this.totalPriceOfOrder = 0;
    this.cartItem.forEach(item => {
      item.quantity = this.productQuntity[item.retailerId];
      this.totalPriceOfOrder += item.totalPriceOfItem;
    });
    let orderfinal = {
      from: "user",
      type: "order",
      order: {
        items: this.cartItem,
        itemsForOrder: this.cartItem.length,
        totalPriceOfOrder: this.totalPriceOfOrder,
      },
    };
    this.messages.push(orderfinal);
  }

  opneCTAUrl(url: string) {
    window.open(url, "_blank");
  }

  generateHyperLinkInText(text) {
    if (!text?.trim()) return "";
    return anchorme({
      input: text,
      options: {
        attributes: {
          target: "_blank",
          class: "a-link",
        },
      },
    });
  }

  multipleFileUplodPreview(multipleUpload) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "addUser",
      data: { title: "Upload Attachment", template: multipleUpload },
      height: "auto",
      width: "35vw",
    });
  }

  onFilesSelected(event, uploadType) {
    const channelId = this.selectedChannel;
    const selectedFiles = event[uploadType]?.files;
    event.preventDefault();
    if (this.selectedMediaFiles.length + selectedFiles?.length > 10) {
      this.commonService.errorMessage("You can add upto 10 files!");
      return;
    }
    if (selectedFiles?.length) {
      this.totalSelectedFiles = this.selectedMediaFiles.length + selectedFiles?.length;
      for (const file of selectedFiles) {
        const fileObject = {
          fileName: file.name,
          fileUrl: "",
          status: MediaUploadStatus.PENDING,
          progress: 0,
          errorMessage: "",
          formData: file,
          fileType: "",
          fileRenderedUrl: "",
        };
        const mimeType = file.type;
        const invalidFileMessage = "File format not supported!";
        if (file.size > this.validations[channelId][this.mimeTypeObject[mimeType]]?.size * 1048576) {
          fileObject.errorMessage = `File size is too big. Maximum ${
            this.validations[channelId][this.mimeTypeObject[mimeType]]?.size
          } MB allowed.`;
          fileObject.status = MediaUploadStatus.FAILED;
        } else if (!ChannelFileTypeValidation[channelId]?.includes(mimeType)) {
          fileObject.errorMessage = invalidFileMessage;
          fileObject.status = MediaUploadStatus.FAILED;
        } else {
          let fileType;
          try {
            fileType = getMediaTypeFromMimeType(mimeType);
            if (!fileType) {
              fileObject.errorMessage = invalidFileMessage;
              fileObject.status = MediaUploadStatus.FAILED;
            }
            fileObject.fileType = fileType;
          } catch (err) {
            fileObject.errorMessage = invalidFileMessage;
            fileObject.status = MediaUploadStatus.FAILED;
          }
        }
        if (fileObject.fileType === MEDIA_TYPES.IMAGE) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            fileObject.fileRenderedUrl = fileReader.result as string;
          };
          fileReader.readAsDataURL(file);
        }
        this.selectedMediaFiles.push(fileObject);
      }
    }
    this.checkFilesContainsErrorOrNot();
  }

  checkFilesContainsErrorOrNot() {
    this.isUploadedFileContainsError = this.selectedMediaFiles.some(file => file.status === MediaUploadStatus.FAILED);
  }

  async uploadAndSendSelectedFilesToUser() {
    if (this.selectedMediaFiles?.length && !this.isUploadedFileContainsError && !this.isMultipleFilesUploading) {
      this.isMultipleFilesUploading = true;
      const observables: Observable<any>[] = [];
      for (const file of this.selectedMediaFiles) {
        observables.push(this.uploadAndSendFile(file));
      }
      forkJoin(
        observables.map(obs$ =>
          obs$.pipe(
            catchError(error => {
              console.error("An error occurred in one of the observables:", error);
              return of(null);
            })
          )
        )
      ).subscribe(
        () => {
          this.dialogRef.close();
          this.resetFileUploadData();
        },
        error => {
          console.error("Error occurred while uploading multiple files:", error);
        }
      );
    }
  }

  resetFileUploadData() {
    this.selectedMediaFiles = [];
    this.isMultipleFilesUploading = false;
    this.isUploadedFileContainsError = false;
    this.totalUploadedFiles = this.totalSelectedFiles = 0;
  }

  uploadAndSendFile(file): Observable<any> {
    const formData = new FormData();
    formData.append("file", file.formData, file.fileName);
    this.transcriptDataNewBatch = [];
    if (!this.firstMessageSent && !this.isUploadSocketConnected) {
      this.setNewFromId();
      this.previewSocketService.previewSocketCityConnection(this.botId, this.fromId, this.accessToken, this.selectedChannel);
      this.isUploadSocketConnected = true;
      this.previewSocketService.botOutputEvent();
    }
    return this.previewSocketService.uploadPreviewMedia(formData, this.botId, this.accessToken).pipe(
      takeUntil(this.multipleFileUploadCallSubject),
      tap(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            file.progress = Math.round((100 * event.loaded) / event.total);
            file.status = MediaUploadStatus.UPLOADING;
          } else if (event.type === HttpEventType.Response) {
            file.status = MediaUploadStatus.UPLOADED; // Update status based on server response
            this.totalUploadedFiles++;
            const data = {
              data: {
                _default: {
                  type: "attachment",
                  attachment: {
                    contentType: event.body.mimeType,
                    caption: "",
                    contentUrl: event.body.url,
                  },
                },
              },
            };
            if (!this.firstMessageSent) {
              const contactNumber = this.fromId.split("-")[2];
              const country = new AwesomePhonenumber(contactNumber).getRegionCode();
              this.sendMessageToBot(
                {
                  ...data,
                  extra: {
                    customFields: {
                      contactNumber,
                      country,
                      locale: "en-US",
                    },
                  },
                },
                true
              );
              this.firstMessageSent = true;
            } else {
              this.sendMessageToBot(data);
            }
            const showData = {
              from: "user",
              type: "attachment",
              name: file.fileName,
              mediaType: this.docType.includes(file.fileType) ? "doc" : file.fileType,
              url: event.body.url,
              docType: this.docType.includes(file.fileType) ? file.fileType : undefined,
            };
            this.messages.push(showData);
            this.scrollConversation();
          }
        },
        err => {
          file.status = MediaUploadStatus.FAILED; // Update status if upload fails
          file.errorMessage = err?.error?.message || "Invalid file";
          this.commonService.errorHandler(
            err,
            `${this.componentName}:uploadAndSendFile`,
            true,
            null,
            `${file.errorMessage}: ${file.fileName}`
          );
        }
      )
    );
  }
  removeSelectedFile(fileIndex) {
    this.selectedMediaFiles.splice(fileIndex, 1);
    this.totalSelectedFiles--;
    this.checkFilesContainsErrorOrNot();
  }
}
