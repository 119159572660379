import { TCustomFiledType } from "./customField";

export interface ICustomFieldCondition {
  type: string;
  field: string;
  value: any;
  fieldType: TCustomFiledType;
  autoFillValue: Array<string | boolean>;
  isOpen?: boolean;
}

export interface ILiveChatAction {
  /** used for enable/disable download chat transcript handoff feature */
  downloadChatTranscript: boolean;
  /** used for enable/disable notes handoff feature */
  notes: boolean;
  /** used for enable/disable tags handoff feature */
  tags: boolean;
  /** used for enable/disable send flow handoff feature */
  sendFlow: boolean;
  /** used for enable/disable assign to other agent handoff feature */
  assignToOtherAgent: boolean;
  /** used for enable/disable resolve chat with flow handoff feature */
  resolveChatWithFlow: boolean;
  /** used for enable/disable chat history and user profile with assigned agent feature */
  chatHistoryByAgent: boolean;

  isMaxActiveChat: boolean;
  maxActiveChat: number;
}

interface ICommonRequestObj {
  limit: number;
  page: number;
  isLoading: boolean;
}

export interface IHandoffQueryParamsAndBodyObj {
  chatWith: "chatWithBot" | "chatWithAgent";
  selectedUserId: string;
  initSelectedUser: boolean;
  search: string;
  chatWithBot: {
    chatType: "all";
    all: ICommonRequestObj;
  };
  chatWithAgent: {
    chatType: "othersRequest" | "newRequest" | "myRequest" | "visitors";
    othersRequest: ICommonRequestObj;
    newRequest: ICommonRequestObj;
    myRequest: ICommonRequestObj;
    visitors: ICommonRequestObj;
  };
}

export interface IAgentRequestTypes {
  name: "New" | "Visitors" | "Assigned" | "Others";
  value: "newRequest" | "visitors" | "myRequest" | "othersRequest";
}

export interface ISelectedUserData {
  handOffId: string;
  _id: string;
  transcript: Array<any>;
  botChannel: string;
  tags: Array<any>;
  index: number;
  name: string;
  customFields: Record<string, any>;
  action: string;
  botIcon: string;
  transcriptPage: number;
  userId: string;
  visit: string;
  agentAssigned: string;
  isTranscriptLoading: boolean;
  isTranscriptInitLoading: boolean;
  isTranscriptLoadedOnce: boolean;
  unReadMessageCount: number;
  visitorId: string;
  botId: string;
  isWaitingForSocketResponse: boolean;
  botUserId?: string;
  totalPages?: number;
  /**
   * value get from webchat loading page title
   */
  pageTitle?: string;
  /**
   * Bot Loading page
   */
  page?: string;
}

export interface IAgent {
  _id: string;
  username: string;
  email: string;
  name: string;
  isAgentOnline: boolean;
  hasLinkedGoogleCalendar: boolean;
  checked?: boolean;
}

export enum MediaUploadStatus {
  PENDING = "pending",
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  FAILED = "failed",
}

export interface ISelectedMediaFiles {
  fileName: string;
  fileUrl: string;
  fileType: string;
  progress: number;
  status: MediaUploadStatus;
  errorMessage: string;
  formData: any;
  fileRenderedUrl: string;
}
