import { Injectable } from "@angular/core";
import { ErrorMessages } from "../utils/consts/errors.const";
import { NotifyService } from "./noyify.service";
import { countryListWithFagAndName } from "../utils/consts/country.const";
import { SettingService } from "./setting.service";
import { IChannelDropDown } from "../utils/model/common";
import { CHANNEL_DISPLAY_NAMES, CHANNEL_NAMES } from "../utils/model/channels";
import { HttpParams } from "@angular/common/http";
import { publicEncrypt } from "crypto-browserify";
import { environment } from "../../environments/environment";

declare var Buffer: any;
import * as Sentry from "@sentry/angular-ivy";
import * as moment from "moment/moment";
import { TooltipComponent } from "@angular/material/tooltip";

export const NULLISH_STRING_VALUE = ["", null, undefined];

@Injectable()
export class CommonService {
  commonOperatorOfAllType = [
    {
      value: "is set",
      text: "Is Set",
    },
    {
      value: "is not set",
      text: "Is Not Set",
    },
  ];
  operators = {
    string: [
      {
        value: "equals to",
        text: "Equals To",
      },
      {
        value: "not equals to",
        text: "Not Equals To",
      },
      {
        value: "starts with",
        text: "Starts with",
      },
      {
        value: "ends with",
        text: "Ends with",
      },
      {
        value: "is of length greater than",
        text: "Is Of Length Greater Than",
      },
      {
        value: "is of length less than",
        text: "Is Of Length Less Than",
      },
      {
        value: "contains",
        text: "Contains",
      },
      {
        value: "doesn't contain",
        text: "Doesn't Contains",
      },
      ...this.commonOperatorOfAllType,
    ],
    number: [
      {
        value: "greater than",
        text: "Greater Than",
      },
      {
        value: "less than",
        text: "Less Than",
      },
      {
        value: "greater than or equals to",
        text: "Greater Than Or Equals To",
      },
      {
        value: "less than or equals to",
        text: "Less Than Or Equals To",
      },
      {
        value: "equals to",
        text: "Equals To",
      },
      {
        value: "not equals to",
        text: "Not Equals To",
      },
      ...this.commonOperatorOfAllType,
    ],
    boolean: [
      {
        value: "is",
        text: "Is",
      },
      {
        value: "is not",
        text: "Is Not",
      },
      ...this.commonOperatorOfAllType,
    ],
    date: [
      {
        value: "is at",
        text: "Is At",
      },
      {
        value: "is not at",
        text: "Is Not At",
      },
      {
        value: "is before",
        text: "Is Before",
      },
      {
        value: "is after",
        text: "Is After",
      },
      ...this.commonOperatorOfAllType,
    ],
    dateAndTime: [
      {
        value: "is at",
        text: "Is At",
      },
      {
        value: "is not at",
        text: "Is Not At",
      },
      {
        value: "is before",
        text: "Is Before",
      },
      {
        value: "is after",
        text: "Is After",
      },
      ...this.commonOperatorOfAllType,
    ],
    time: [
      {
        value: "is at",
        text: "Is At",
      },
      {
        value: "is not at",
        text: "Is Not At",
      },
      {
        value: "is before",
        text: "Is Before",
      },
      {
        value: "is after",
        text: "Is After",
      },
      ...this.commonOperatorOfAllType,
    ],
    array: [
      {
        value: "contains",
        text: "Contains",
      },
      {
        value: "doesn't contain",
        text: "Doesn't contain",
      },
      {
        value: "length equals to",
        text: "Length equals to",
      },
      {
        value: "length not equals to",
        text: "Length not equals to",
      },
      {
        value: "is of length greater than",
        text: "Is of length greater than",
      },
      {
        value: "is of length less than",
        text: "Is of length less than",
      },
      ...this.commonOperatorOfAllType,
    ],
    tag: [
      {
        value: "is set",
        text: "Is Set",
      },
      {
        value: "is not set",
        text: "Is Not Set",
      },
      {
        value: "is set on",
        text: "Is Set On",
      },
      {
        value: "is removed on",
        text: "Is Removed On",
      },
      {
        value: "is set from",
        text: "Is Set From",
      },
      {
        value: "is removed from",
        text: "Is Removed From",
      },
    ],
    interaction: [
      {
        value: "is",
        text: "Is",
      },
    ],
  };
  sourcesForCondition = [
    {
      value: "flow",
      name: "Flow",
    },
    {
      value: "userProfile",
      name: "User Profile",
    },
    {
      value: "liveChat",
      name: "Live Chat",
    },
  ];

  lengthCondition = ["is of length greater than", "is of length less than", "length equals to", "length not equals to"];

  commonCustomFeildsNotToShow = [
    "transcriptUrl",
    "last_user_input",
    "last_user_input_detailed",
    "last_handoff_failed_reason",
    "user_last_message_at",
  ];

  documentTypeMap = {
    pdf: "pdf.svg",
    ppt: "ppt.svg",
    pptx: "ppt.svg",
    doc: "doc.svg",
    docx: "doc.svg",
    xls: "xls.svg",
    xlsx: "xls.svg",
  };

  formatTextTypes = [
    {
      formatStart: "*",
      formatEnd: "*",
      matIcon: "format_bold",
      incrementNumber: 1,
    },
    {
      formatStart: "_",
      formatEnd: "_",
      matIcon: "format_italic",
      incrementNumber: 1,
    },
    {
      formatStart: "~",
      formatEnd: "~",
      matIcon: "strikethrough_s",
      incrementNumber: 1,
    },
    {
      formatStart: "```",
      formatEnd: "```",
      matIcon: "code",
      incrementNumber: 3,
    },
  ];

  URLRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/);
  EMAIL_REGEX = /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/;

  orderStatuses = [
    {
      value: "processing",
      displayValue: "Processing",
    },
    {
      value: "partially_shipped",
      displayValue: "Partially Shipped",
    },
    {
      value: "shipped",
      displayValue: "Shipped",
    },
    {
      value: "completed",
      displayValue: "Completed",
    },
    {
      value: "canceled",
      displayValue: "Canceled",
    },
  ];

  constructor(
    private notificationService: NotifyService,
    readonly settingsService: SettingService
  ) {}

  errorHandler(err: any, functionName: string, notify: boolean, fallback?: string, customError?: string): string {
    try {
      console.error(`:::${functionName}:::`, err, "::::::");
      let error = err?.error?.error_user_msg || err?.error?.error_user_title || err?.error?.message || err?.error?.description;
      const finalError = customError || error || fallback;
      if (!finalError && environment.isSentry) {
        Sentry.captureException(err, {
          extra: {
            functionName: functionName,
          },
          tags: {
            internalServerError: !error,
          },
        });
      }
      if (notify && finalError) {
        this.notificationService.sendNotification("error", finalError);
      }
      return finalError;
    } catch (err) {
      console.error(`:::errorHandler:::`, err, "::::::");
      if (environment.isSentry) {
        Sentry.captureException(err, {
          extra: {
            functionName: functionName,
          },
          tags: {
            internalServerError: JSON.stringify(err),
          },
        });
      }
      return "Internal Error";
    }
  }

  successMessage(message) {
    this.notificationService.sendNotification("success", message);
  }

  infoMessage(message) {
    this.notificationService.sendNotification("info", message);
  }

  errorMessage(message) {
    this.notificationService.sendNotification("error", message);
  }

  warningMessage(message) {
    this.notificationService.sendNotification("warning", message);
  }

  getFlag(languageCode) {
    languageCode = languageCode.toLowerCase();
    switch (languageCode) {
      case "da": {
        return "assets/images/denmark.svg";
      }
      case "en-au": {
        return "assets/images/australia.svg";
      }
      case "en-us": {
        return "assets/images/united-states.svg";
      }
      case "en-ca": {
        return "assets/images/canada.svg";
      }
      case "en-gb": {
        return "assets/images/united-kingdom.svg";
      }
      case "en-in": {
        return "assets/images/india.svg";
      }
      case "fr": {
        return "assets/images/france.svg";
      }
      case "fr-ca": {
        return "assets/images/canada.svg";
      }
      case "fr-fr": {
        return "assets/images/france.svg";
      }
      case "de": {
        return "assets/images/germany.svg";
      }
      case "hi":
      case "ta":
      case "te":
      case "pa":
      case "mr": {
        return "assets/images/india.svg";
      }
      case "nl": {
        return "assets/images/netherlands.svg";
      }
      case "id": {
        return "assets/images/indonesia.svg";
      }
      case "it": {
        return "assets/images/italy.svg";
      }
      case "ja": {
        return "assets/images/japan.svg";
      }
      case "no": {
        return "assets/images/norway.svg";
      }
      case "pl": {
        return "assets/images/poland.svg";
      }
      case "pt-br": {
        return "assets/images/brazil.svg";
      }
      case "ru": {
        return "assets/images/russia.svg";
      }
      case "es": {
        return "assets/images/spain.svg";
      }
      case "es-419": {
        return "assets/images/united-states.svg";
      }
      case "es-es": {
        return "assets/images/spain.svg";
      }
      case "sv": {
        return "assets/images/sweden.svg";
      }
      case "th": {
        return "assets/images/thailand.svg";
      }
      case "tr": {
        return "assets/images/turkey.svg";
      }
      case "uk": {
        return "assets/images/ukraine.svg";
      }
      case "en": {
        return "assets/images/united-kingdom.svg";
      }
      case "ko": {
        return "assets/images/south-korea.svg";
      }
      case "pt": {
        return "assets/images/portugal.svg";
      }
      case "zh-hk":
      case "zh-cn":
      case "zh-tw": {
        return "assets/images/china.svg";
      }
      case "ar": {
        return "assets/images/flag/arab.svg";
      }
      default: {
        return "assets/images/flag.svg";
      }
    }
  }

  generateLanguage(code) {
    switch (code.toLowerCase()) {
      case "zh-hk":
        return "Chinese - Cantonese";
      case "zh-cn":
        return "Chinese - Simplified";
      case "zh-tw":
        return "Chinese - Traditional";
      case "da":
        return "Danish";
      case "nl":
        return "Dutch";
      case "en":
        return "English";
      case "en-au":
        return "English - Australia";
      case "en-ca":
        return "English - Canada";
      case "en-gb":
        return "English - United Kingdom";
      case "en-in":
        return "English - India";
      case "en-us":
        return "English - US";
      case "fr":
        return "French";
      case "fr-ca":
        return "French - Canada";
      case "fr-fr":
        return "French - France";
      case "de":
        return "German";
      case "hi":
        return "Hindi";
      case "id":
        return "Indonesian";
      case "it":
        return "Italian";
      case "ja":
        return "Japanese";
      case "ko":
        return "Korean";
      case "no":
        return "Norwegian";
      case "pl":
        return "Polish";
      case "pt":
        return "Portuguese - Portugal";
      case "pt-br":
        return "Portuguese - Brazil";
      case "ru":
        return "Russian";
      case "es":
        return "Spanish";
      case "es-419":
        return "Spanish - Latin America";
      case "es-es":
        return "Spanish - Spain";
      case "sv":
        return "Swedish";
      case "th":
        return "Thai";
      case "tr":
        return "Turkish";
      case "uk":
        return "Ukrainian";
      case "ta":
        return "Tamil";
      case "te":
        return "Telugu";
      case "mr":
        return "Marathi";
      case "ar":
        return "Arabic";
      case "pa":
        return "Punjabi";
    }
  }

  getLanguageList() {
    return countryListWithFagAndName;
  }

  getChannelIcon(channel) {
    switch (channel) {
      case "facebook":
        return "assets/images/facebook.svg";
      case "webchat":
        return "assets/images/wechat.svg";
      case "whatsapp":
      case CHANNEL_NAMES.WHATSAPP_KALEYRA:
      case CHANNEL_NAMES.WHATSAPP_AIRTEL:
      case CHANNEL_NAMES.WHATSAPP_ACL:
      case CHANNEL_NAMES.WHATSAPP_CLOUD:
      case CHANNEL_NAMES.WHATSAPP_INFOBIP:
      case CHANNEL_NAMES.WHATSAPP_BSP:
        return "assets/images/whatsapp.svg";
      case "whatsappBusiness":
        return "assets/images/whatsappBusiness.svg";
      case "whatsappNetCore":
        return "assets/images/channels/whatsappNetCore.svg";
      case "workflow":
        return "assets/images/channels/workflow.svg";
      case "telegram":
        return "assets/images/channels/telegram.svg";
      case CHANNEL_NAMES.RCS_BUSINESS_MESSAGES:
        return "assets/images/channels/rich-communication-services.svg";
      case CHANNEL_NAMES.INSTAGRAM:
        return "assets/images/channels/instagram.svg";
      case CHANNEL_NAMES.SOCKET_IO:
        return "assets/images/channels/socket_io.svg";
      default:
        break;
    }
  }

  getChannelsDropDownList(): Array<IChannelDropDown> {
    const channels = [];
    if (this.settingsService.EnableChannels.includes("webchat")) {
      channels.push({
        value: "webchat",
        viewValue: "Web chat",
        isChecked: false,
      });
    }
    if (this.settingsService.EnableChannels.includes("facebook")) {
      channels.push({
        value: "facebook",
        viewValue: "Messenger ",
        isChecked: false,
      });
    }
    if (this.settingsService.EnableChannels.includes("whatsapp")) {
      channels.push({
        value: "whatsapp",
        viewValue: "WhatsApp",
        isChecked: false,
      });
    }
    if (this.settingsService.EnableChannels.includes("whatsappBusiness")) {
      channels.push({
        value: "whatsappBusiness",
        viewValue: "WhatsApp",
        isChecked: false,
      });
    }
    if (this.settingsService.EnableChannels.includes("whatsappNetCore")) {
      channels.push({
        value: "whatsappNetCore",
        viewValue: "WhatsApp",
        isChecked: false,
      });
    }
    if (this.settingsService.EnableChannels.includes("workflow")) {
      channels.push({
        value: "workflow",
        viewValue: "Work Flow",
        isChecked: false,
      });
    }
    if (this.settingsService.EnableChannels.includes("telegram")) {
      channels.push({
        value: "telegram",
        viewValue: "Telegram",
        isChecked: false,
      });
    }
    if (this.settingsService.EnableChannels.includes(CHANNEL_NAMES.INSTAGRAM)) {
      channels.push({
        value: CHANNEL_NAMES.INSTAGRAM,
        viewValue: CHANNEL_DISPLAY_NAMES.INSTAGRAM,
        isChecked: false,
      });
    }

    if (this.settingsService.EnableChannels.includes(CHANNEL_NAMES.WHATSAPP_KALEYRA)) {
      channels.push({
        value: CHANNEL_NAMES.WHATSAPP_KALEYRA,
        viewValue: CHANNEL_DISPLAY_NAMES.WHATSAPP_KALEYRA,
        isChecked: false,
      });
    }

    if (this.settingsService.EnableChannels.includes(CHANNEL_NAMES.WHATSAPP_AIRTEL)) {
      channels.push({
        value: CHANNEL_NAMES.WHATSAPP_AIRTEL,
        viewValue: CHANNEL_DISPLAY_NAMES.WHATSAPP_AIRTEL,
        isChecked: false,
      });
    }

    if (this.settingsService.EnableChannels.includes(CHANNEL_NAMES.WHATSAPP_ACL)) {
      channels.push({
        value: CHANNEL_NAMES.WHATSAPP_ACL,
        viewValue: CHANNEL_DISPLAY_NAMES.WHATSAPP_ACL,
        isChecked: false,
      });
    }

    if (this.settingsService.EnableChannels.includes(CHANNEL_NAMES.WHATSAPP_CLOUD)) {
      channels.push({
        value: CHANNEL_NAMES.WHATSAPP_CLOUD,
        viewValue: CHANNEL_DISPLAY_NAMES.WHATSAPP_CLOUD,
        isChecked: false,
      });
    }

    if (this.settingsService.EnableChannels.includes(CHANNEL_NAMES.WHATSAPP_INFOBIP)) {
      channels.push({
        value: CHANNEL_NAMES.WHATSAPP_INFOBIP,
        viewValue: CHANNEL_DISPLAY_NAMES.WHATSAPP_INFOBIP,
        isChecked: false,
      });
    }

    if (this.settingsService.EnableChannels.includes(CHANNEL_NAMES.WHATSAPP_BSP)) {
      channels.push({
        value: CHANNEL_NAMES.WHATSAPP_BSP,
        viewValue: CHANNEL_DISPLAY_NAMES.WHATSAPP_BSP,
        isChecked: false,
      });
    }

    if (this.settingsService.EnableChannels.includes(CHANNEL_NAMES.SOCKET_IO)) {
      channels.push({
        value: CHANNEL_NAMES.SOCKET_IO,
        viewValue: CHANNEL_DISPLAY_NAMES.SOCKET_IO,
        isChecked: false,
      });
    }
    return channels;
  }

  getChannelNames() {
    return {
      facebook: "Facebook",
      webchat: "Web",
      whatsapp: "WhatsApp",
      whatsappBusiness: "WhatsApp",
      whatsappNetCore: "WhatsApp",
      workflow: "Work Flow",
      telegram: "Telegram",
      [CHANNEL_NAMES.RCS_BUSINESS_MESSAGES]: CHANNEL_DISPLAY_NAMES.RCS_BUSINESS_MESSAGES,
      [CHANNEL_NAMES.INSTAGRAM]: CHANNEL_DISPLAY_NAMES.INSTAGRAM,
      [CHANNEL_NAMES.WHATSAPP_KALEYRA]: CHANNEL_DISPLAY_NAMES.WHATSAPP_KALEYRA,
      [CHANNEL_NAMES.SOCKET_IO]: CHANNEL_DISPLAY_NAMES.SOCKET_IO,
      [CHANNEL_NAMES.WHATSAPP_AIRTEL]: CHANNEL_DISPLAY_NAMES.WHATSAPP_AIRTEL,
      [CHANNEL_NAMES.WHATSAPP_ACL]: CHANNEL_DISPLAY_NAMES.WHATSAPP_ACL,
      [CHANNEL_NAMES.WHATSAPP_CLOUD]: CHANNEL_DISPLAY_NAMES.WHATSAPP_CLOUD,
      [CHANNEL_NAMES.WHATSAPP_INFOBIP]: CHANNEL_DISPLAY_NAMES.WHATSAPP_INFOBIP,
      [CHANNEL_NAMES.WHATSAPP_BSP]: CHANNEL_DISPLAY_NAMES.WHATSAPP_BSP,
    };
  }

  restrictInput(event) {
    const pattern = /[0-9\-.\ ]/;
    if (event.keyCode === 43 || event.keyCode === 101 || event.key === "+") {
      event.preventDefault();
    }

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    if ((event.keyCode === 46 || event.key === ".") && event.target.value.toString().includes(".")) {
      event.preventDefault();
    }
    if ((event.keyCode === 45 || event.ey === "-") && event.target.value.toString().includes("-")) {
      event.preventDefault();
    }
  }

  restrictSpecialCharacter(event, keyName, isCopyEvent?) {
    if (event?.key?.match(/[^a-zA-Z0-9_]/)) {
      this.notificationService.sendNotification("error", `${keyName} allows alpha-numeric and underscore`);
      event.preventDefault();
    } else if (isCopyEvent && event?.match(/[^a-zA-Z0-9_]/)) {
      this.notificationService.sendNotification("error", `${keyName} allows alpha-numeric and underscore`);
      return true;
    }
  }

  httpParamsPatcher(paramObj) {
    let httpParams = new HttpParams();
    const params = Object.entries(paramObj);
    params.map(([key, value]) => {
      httpParams = httpParams.append(key, value as string);
    });
    return httpParams;
  }

  validateConditions(conditionArray) {
    const selectOperatorMessage = `Please select operator for condition `;
    const selectValueMessage = `Please add value to compare for condition `;
    if (conditionArray.length) {
      const isValid = conditionArray.every((conditionObject, conditionIndex) => {
        if (!conditionObject.flexibleIndicator?.trim() && !conditionObject.customFieldOrTag?.trim()) {
          this.errorMessage(`Please select custom field or tag for condition  ${conditionIndex + 1}`);
          return false;
        } else if (conditionObject.type === "interaction" && (!conditionObject.startDate || !conditionObject.endDate)) {
          this.errorMessage(`${selectValueMessage} ${conditionIndex + 1}`);
          return false;
        } else if (conditionObject.flexibleIndicator !== "tag" && conditionObject.customFieldOrTag !== "tag") {
          if (!conditionObject.operator?.trim()) {
            this.errorMessage(`${selectOperatorMessage} ${conditionIndex + 1}`);
            return false;
          } else if (
            conditionObject.valueToCompare === "" &&
            conditionObject.flexibleIndicator !== "interaction" &&
            conditionObject.operator !== "is set" &&
            conditionObject.operator !== "is not set"
          ) {
            this.errorMessage(`${selectValueMessage} ${conditionIndex + 1}`);
            return false;
          }
        } else if (conditionObject.flexibleIndicator === "tag" || conditionObject.customFieldOrTag === "tag") {
          if (!conditionObject.valueToCompare) {
            this.errorMessage(`Please select tag value for condition ${conditionIndex + 1}`);
            return false;
          } else if (!conditionObject.operator?.trim()) {
            this.errorMessage(`${selectOperatorMessage} ${conditionIndex + 1}`);
            return false;
          } else if (
            (conditionObject.operator === "is set from" || conditionObject.operator === "is removed from") &&
            !conditionObject.source
          ) {
            this.errorMessage(`Please select source to compare for condition ${conditionIndex + 1}`);
            return false;
          } else if (
            (conditionObject.operator === "is set on" || conditionObject.operator === "is removed on") &&
            (!conditionObject.startDate || !conditionObject.endDate)
          ) {
            this.errorMessage(`Please select start date and end date to compare for condition ${conditionIndex + 1}`);
            return false;
          }
        } else if (conditionObject.operator != "is set" && conditionObject.operator != "is not set") {
          if ((conditionObject.type === "string" || conditionObject.type === "array") && !conditionObject.valueToCompare?.trim().length) {
            this.errorMessage(`${selectValueMessage} ${conditionIndex + 1}`);
            return false;
          } else if (
            (conditionObject.type !== "string" &&
              conditionObject.type !== "array" &&
              conditionObject.type !== "number" &&
              typeof conditionObject.valueToCompare !== "boolean" &&
              !conditionObject.valueToCompare &&
              conditionObject.type != "interaction") ||
            (conditionObject.type === "number" && conditionObject.valueToCompare !== 0 && !conditionObject.valueToCompare)
          ) {
            this.errorMessage(`${selectValueMessage} ${conditionIndex + 1}`);
            return false;
          }
        }
        return true;
      });
      if (!isValid) {
        return false;
      }
    }
    return true;
  }

  setStartDate(event, conditionObject) {
    let startDate;
    if (event.value) {
      startDate = moment(conditionObject.startDate).startOf("day");
      conditionObject.startDate = new Date(startDate).toISOString();
    }
  }

  setEndDate(event, conditionObject) {
    let endDate;
    if (event.value) {
      endDate = moment(conditionObject.endDate).endOf("day");
      conditionObject.endDate = new Date(endDate).toISOString();
    }
  }

  convertConditions(conditionArray, connditionMatchType) {
    let convertedConditions = [];
    if (connditionMatchType) {
      convertedConditions[0] = [];
      conditionArray.forEach(conditionObject => {
        convertedConditions[0].push(conditionObject);
      });
    } else {
      conditionArray.forEach((conditionObject, conditionIndex) => {
        convertedConditions[conditionIndex] = [];
        convertedConditions[conditionIndex].push(conditionObject);
      });
    }
    return convertedConditions;
  }

  resizePage() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 10);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 30);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 50);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 70);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 90);
  }

  encryptPasswordWithPublicKey(inputText) {
    const publicKeyBuffer = Buffer.from(environment.publicKey, "base64");
    const textBuffer = Buffer.from(inputText);
    const encrypted = publicEncrypt(publicKeyBuffer, textBuffer);
    return encrypted.toString("base64");
  }

  formatText(text, inputText, formatTextType) {
    let iCaretPos = 0;
    if (inputText.selectionStart === inputText.selectionEnd) {
      iCaretPos = inputText.selectionStart;
      text = [text.slice(0, iCaretPos), formatTextType.formatStart, formatTextType.formatEnd, text.slice(iCaretPos)].join("");
    } else {
      if (inputText.selectionStart || inputText.selectionStart == "0") {
        iCaretPos = inputText.selectionDirection == "backward" ? inputText.selectionStart : inputText.selectionEnd;
      }
      text = [
        text.slice(0, inputText.selectionStart),
        formatTextType.formatStart,
        text.slice(inputText.selectionStart, inputText.selectionEnd),
        formatTextType.formatEnd,
        text.slice(inputText.selectionEnd),
      ].join("");
    }
    inputText.focus();
    setTimeout(() => {
      inputText.selectionEnd = iCaretPos + formatTextType.incrementNumber;
    }, 10);
    return text;
  }

  addEmoji(text, textElement, emoji, incrementNumebr) {
    let iCaretPos = 0;
    if (textElement.nativeElement.selectionStart || textElement.nativeElement.selectionStart == "0") {
      iCaretPos =
        textElement.nativeElement.selectionStart == "backward"
          ? textElement.nativeElement.selectionStart
          : textElement.nativeElement.selectionEnd;
    }
    text = [text.slice(0, iCaretPos), emoji.native, text.slice(iCaretPos)].join("");
    textElement.nativeElement.focus();
    setTimeout(() => {
      textElement.nativeElement.selectionEnd = iCaretPos + incrementNumebr;
    }, 10);
    return text;
  }

  convertTooltipToHtml() {
    Object.defineProperty(TooltipComponent.prototype, "message", {
      set(toolTipConent: any) {
        const toolTipElement = document.querySelectorAll(".mat-tooltip");
        if (toolTipElement) {
          toolTipElement[toolTipElement.length - 1].innerHTML = toolTipConent;
        }
      },
    });
  }

  checkImageValidity(imageUrl: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let img = new Image();
      img.onload = () => resolve(true); // Image loaded successfully
      img.onerror = () => resolve(false); // Image failed to load
      img.src = imageUrl; // Start loading the image
    });
  }
}
