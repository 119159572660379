import { CHANNEL_NAMES } from "../../utils/model/channels";

export class FlowValidationHelper {
  commonWhatsappText = {
    textLength: 4096,
    text: true,
  };
  commonWhatsappMedia = {
    image: {
      size: 5,
      type: ".jpg,.jpeg,.png",
    },
    video: {
      size: 16,
      type: "video/mp4",
    },
    audio: {
      size: 16,
      type: "audio/aac,audio/mp4,audio/amr,audio/mpeg,audio/ogg",
    },
    pdf: {
      size: 100,
    },
    doc: {
      size: 100,
    },
    ppt: {
      size: 100,
    },
    xls: {
      size: 100,
    },
  };
  max16MBWhatsappMedia = {
    image: {
      size: 5,
      type: ".jpg,.jpeg,.png",
    },
    video: {
      size: 16,
      type: "video/mp4",
    },
    audio: {
      size: 16,
      type: "audio/aac,audio/mp4,audio/amr,audio/mpeg,audio/ogg",
    },
    pdf: {
      size: 16,
    },
    doc: {
      size: 16,
    },
    ppt: {
      size: 16,
    },
    xls: {
      size: 16,
    },
  };
  replyValidation = {
    headerLength: 60,
    bodyLength: 1024,
    footerLength: 60,
    buttonLength: 20,
    button: {
      text: true,
      textLength: 20,
      minLength: 1,
      maxLength: 3,
    },
    image: {
      size: 5,
      type: ".jpg,.jpeg,.png",
    },
    pdfFile: {
      size: 100,
    },
    docFile: {
      size: 100,
    },
    xlsFile: {
      size: 100,
    },
    pptFile: {
      size: 100,
    },
    video: {
      size: 16,
      type: "video/mp4, video/3gpp",
    },
  };
  listValidation = {
    headerLength: 60,
    bodyLength: 1024,
    footerLength: 60,
    buttonLength: 20,
    section: {
      titleLength: 24,
    },
    item: {
      titleLength: 24,
      descriptionLength: 72,
    },
  };
  documentNameAndCaption = {
    caption: 1024,
    filename: 240,
  };
  commonWhatsappQuestionAndMCQ = {
    question: {
      text: true,
      textLength: 1600,
      button: {
        textLength: 20,
        maxLength: 3,
      },
    },
    mcq: {
      text: true,
      textLength: 640,
      textReLength: 640,
      replyTextLength: 20,
      maxLength: "noLimit",
    },
  };
  commonWhatsappCatalog = {
    product: {
      header: 60,
      body: 1024,
      rePromptBody: 1024,
      footer: 60,
      section: 24,
    },
    dynamicProduct: {
      header: 60,
      body: 1024,
      rePromptBody: 1024,
      footer: 60,
      section: 24,
    },
  };
  locationRequestValidations = {
    bodyLength: 1024,
  };

  validations = {
    facebook: {
      text: {
        text: true,
        textLength: 640,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      question: {
        text: true,
        textLength: 640,
      },
      card: {
        title: true,
        titleLength: 80,
        image: true,
        subTitle: true,
        subTitleLength: 80,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      carousels: {
        minLength: 2,
        maxLength: 10,
        title: true,
        titleLength: 80,
        subTitleLength: 80,
        image: true,
        subTitle: true,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      mcq: {
        text: true,
        textLength: 640,
        textReLength: 640,
        replyTextLength: 20,
        maxLength: "noLimit",
      },
      image: {
        size: 25,
        type: "image/*",
      },
      video: {
        size: 25,
        type: "video/*",
      },
      audio: {
        size: 25,
        type: "audio/*",
      },
      pdf: {
        size: 5,
      },
      delay: {
        multi: false,
        typing: true,
      },
    },
    webchat: {
      text: {
        text: true,
        textLength: 640,
        button: {
          text: true,
          textLength: 50,
          minLength: 1,
          maxLength: 15,
          redirectUrl: true,
        },
      },
      mcq: {
        text: true,
        textLength: 640,
        textReLength: 640,
        replyTextLength: 20,
        maxLength: "noLimit",
      },
      question: {
        text: true,
        textLength: 640,
      },
      card: {
        title: true,
        titleLength: 80,
        image: true,
        subTitle: true,
        subTitleLength: 80,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      carousels: {
        minLength: 2,
        maxLength: 10,
        title: true,
        titleLength: 80,
        subTitleLength: 80,
        image: true,
        subTitle: true,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      image: {
        size: 25,
        type: "image/*",
      },
      video: {
        size: 25,
        type: "video/*",
      },
      audio: {
        size: 25,
        type: "audio/*",
      },
      pdf: {
        size: 5,
      },
      doc: {
        size: 5,
      },
      ppt: {
        size: 5,
      },
      xls: {
        size: 5,
      },
      delay: {
        multi: false,
        typing: true,
      },
    },
    whatsapp: {
      text: this.commonWhatsappText,
      question: {
        text: true,
        textLength: 1600,
      },
      mcq: {
        text: true,
        textLength: 640,
        replyTextLength: 20,
        maxLength: "noLimit",
        textReLength: 640,
      },
      list: this.listValidation,
      reply: this.replyValidation,
      ...this.commonWhatsappMedia,
      ...this.documentNameAndCaption,
      delay: {
        multi: false,
        typing: false,
      },
    },
    whatsappBusiness: {
      text: this.commonWhatsappText,
      ...this.commonWhatsappQuestionAndMCQ,
      list: this.listValidation,
      reply: this.replyValidation,
      ...this.commonWhatsappMedia,
      ...this.documentNameAndCaption,
      delay: {
        multi: false,
        typing: false,
      },
    },
    [CHANNEL_NAMES.WHATSAPP_NET_CORE]: {
      text: this.commonWhatsappText,
      ...this.commonWhatsappQuestionAndMCQ,
      list: this.listValidation,
      reply: this.replyValidation,
      whatsappFlow: this.replyValidation,
      ...this.max16MBWhatsappMedia,
      ...this.documentNameAndCaption,
      ...this.commonWhatsappCatalog,
      delay: {
        multi: false,
        typing: false,
      },
      orderPaymentMessage: this.replyValidation,
      orderStatus: {
        bodyLength: 1024,
        footerLength: 60,
        orderStatusDescriptionLength: 120,
      },
    },
    telegram: {
      text: {
        text: true,
        textLength: 4096,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      question: {
        text: true,
        textLength: 4096,
      },
      card: {
        title: true,
        titleLength: 80,
        image: true,
        subTitle: true,
        subTitleLength: 640,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      carousels: {
        minLength: 2,
        maxLength: 10,
        title: true,
        titleLength: 80,
        subTitleLength: 640,
        image: true,
        subTitle: true,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      mcq: {
        text: true,
        textLength: 640,
        textReLength: 640,
        replyTextLength: 20,
        maxLength: "noLimit",
      },
      image: {
        size: 5,
        type: "image/*",
      },
      video: {
        size: 20,
        type: "video/*",
      },
      audio: {
        size: 20,
        type: "audio/*",
      },
      pdf: {
        size: 20,
      },
      doc: {
        size: 15,
      },
      ppt: {
        size: 15,
      },
      xls: {
        size: 15,
      },
      ...this.documentNameAndCaption,
      delay: {
        multi: true,
        typing: true,
      },
    },
    [CHANNEL_NAMES.RCS_BUSINESS_MESSAGES]: {
      text: {
        text: true,
        textLength: "noLimit",
        button: {
          text: true,
          textLength: 25,
          minLength: 1,
          maxLength: 11,
          redirectUrl: true,
        },
      },
      question: {
        text: true,
        textLength: "noLimit",
      },
      card: {
        title: true,
        titleLength: 200,
        image: true,
        subTitle: true,
        subTitleLength: 2000,
        button: {
          text: true,
          textLength: 25,
          minLength: 1,
          maxLength: 4,
          redirectUrl: true,
        },
      },
      carousels: {
        minLength: 2,
        maxLength: 10,
        title: true,
        titleLength: 200,
        subTitleLength: 2000,
        image: true,
        subTitle: true,
        button: {
          text: true,
          textLength: 25,
          minLength: 1,
          maxLength: 4,
          redirectUrl: true,
        },
      },
      mcq: {
        text: true,
        textLength: "noLimit",
        textReLength: "noLimit",
        replyTextLength: 20,
        maxLength: 11,
      },
      image: {
        size: 100,
        type: "image/*",
      },
      video: {
        size: 100,
        type: "video/*",
      },
      delay: {
        multi: false,
        typing: false,
      },
    },
    [CHANNEL_NAMES.INSTAGRAM]: {
      text: {
        text: true,
        textLength: 1000,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      question: {
        text: true,
        textLength: 1000,
      },
      card: {
        title: true,
        titleLength: 80,
        image: true,
        subTitle: true,
        subTitleLength: 80,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      carousels: {
        minLength: 2,
        maxLength: 10,
        title: true,
        titleLength: 80,
        subTitleLength: 80,
        image: true,
        subTitle: true,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      mcq: {
        text: true,
        textLength: 1000,
        textReLength: 1000,
        replyTextLength: 20,
        maxLength: 13,
      },
      image: {
        size: 8,
        type: "image/*",
      },
      delay: {
        multi: false,
        typing: false,
      },
    },
    [CHANNEL_NAMES.WHATSAPP_KALEYRA]: {
      text: this.commonWhatsappText,
      ...this.commonWhatsappQuestionAndMCQ,
      list: this.listValidation,
      reply: this.replyValidation,
      image: {
        size: 5,
        type: ".jpg,.jpeg,.png",
      },
      video: {
        size: 16,
        type: "video/mp4",
      },
      audio: {
        size: 16,
        type: "audio/aac,audio/mp4,audio/amr,audio/mpeg,audio/ogg",
      },
      pdf: {
        size: 64,
      },
      doc: {
        size: 64,
      },
      ppt: {
        size: 64,
      },
      xls: {
        size: 64,
      },
      ...this.documentNameAndCaption,
      ...this.commonWhatsappCatalog,
      delay: {
        multi: false,
        typing: false,
      },
    },
    [CHANNEL_NAMES.SOCKET_IO]: {
      text: {
        text: true,
        textLength: 640,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      mcq: {
        text: true,
        textLength: 640,
        textReLength: 640,
        replyTextLength: 20,
        maxLength: "noLimit",
      },
      question: {
        text: true,
        textLength: 640,
      },
      card: {
        title: true,
        titleLength: 80,
        image: true,
        subTitle: true,
        subTitleLength: 80,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      carousels: {
        minLength: 2,
        maxLength: 10,
        title: true,
        titleLength: 80,
        subTitleLength: 80,
        image: true,
        subTitle: true,
        button: {
          text: true,
          textLength: 20,
          minLength: 1,
          maxLength: 3,
          redirectUrl: true,
        },
      },
      image: {
        size: 25,
        type: "image/*",
      },
      video: {
        size: 25,
        type: "video/*",
      },
      audio: {
        size: 25,
        type: "audio/*",
      },
      pdf: {
        size: 5,
      },
      doc: {
        size: 5,
      },
      ppt: {
        size: 5,
      },
      xls: {
        size: 5,
      },
      ...this.documentNameAndCaption,
      delay: {
        multi: false,
        typing: true,
      },
    },
    [CHANNEL_NAMES.WHATSAPP_AIRTEL]: {
      text: this.commonWhatsappText,
      ...this.commonWhatsappQuestionAndMCQ,
      list: this.listValidation,
      reply: this.replyValidation,
      ...this.max16MBWhatsappMedia,
      ...this.documentNameAndCaption,
      csv: {
        size: 16,
      },
      delay: {
        multi: false,
        typing: false,
      },
    },
    [CHANNEL_NAMES.WHATSAPP_ACL]: {
      text: this.commonWhatsappText,
      ...this.commonWhatsappQuestionAndMCQ,
      list: this.listValidation,
      reply: this.replyValidation,
      ...this.commonWhatsappMedia,
      ...this.documentNameAndCaption,
      delay: {
        multi: false,
        typing: false,
      },
    },
    [CHANNEL_NAMES.WHATSAPP_CLOUD]: {
      text: this.commonWhatsappText,
      ...this.commonWhatsappQuestionAndMCQ,
      list: this.listValidation,
      whatsappFlow: this.replyValidation,
      reply: this.replyValidation,
      ...this.commonWhatsappMedia,
      ...this.documentNameAndCaption,
      csv: {
        size: 100,
      },
      ...this.commonWhatsappCatalog,
      delay: {
        multi: false,
        typing: false,
      },
      locationRequest: this.locationRequestValidations,
      addressMessage: this.replyValidation,
    },
    [CHANNEL_NAMES.WHATSAPP_BSP]: {
      text: this.commonWhatsappText,
      ...this.commonWhatsappQuestionAndMCQ,
      list: this.listValidation,
      reply: this.replyValidation,
      whatsappFlow: this.replyValidation,
      ...this.commonWhatsappMedia,
      ...this.documentNameAndCaption,
      csv: {
        size: 100,
      },
      ...this.commonWhatsappCatalog,
      delay: {
        multi: false,
        typing: false,
      },
      orderPaymentMessage: this.replyValidation,
      orderStatus: {
        bodyLength: 1024,
        footerLength: 60,
        orderStatusDescriptionLength: 120,
      },
      locationRequest: this.locationRequestValidations,
      addressMessage: this.replyValidation,
      zohoDesk: {
        subject: 255,
        description: 65535,
      },
    },
    [CHANNEL_NAMES.WHATSAPP_INFOBIP]: {
      text: this.commonWhatsappText,
      ...this.commonWhatsappQuestionAndMCQ,
      list: this.listValidation,
      reply: this.replyValidation,
      ...this.commonWhatsappMedia,
      ...this.documentNameAndCaption,
      delay: {
        multi: false,
        typing: false,
      },
    },
  };
}
