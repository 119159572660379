import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { BotPreviewComponent } from "./common-components/bot-preview/bot-preview.component";
import { LoginComponent } from "./common-components/login/login.component";
import { UserTranscriptComponent } from "./user-transcript/user-transcript.component";
import { AuthGuard } from "./utils/guards/auth.guard";
import { InsufficientAccessComponent } from "./common-components/insufficient-access/insufficient-access.component";
import { GoogleCallbackComponent } from "./common-components/google-callback/google-callback.component";
import { environment } from "src/environments/environment";

type PathMatch = "full" | "prefix" | undefined;

export const routes: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full" as PathMatch,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "forgot",
    component: LoginComponent,
  },
  {
    path: "two-factor",
    component: LoginComponent,
  },
  {
    path: "reset/:id",
    component: LoginComponent,
  },
  {
    path: "set-password/:id",
    component: LoginComponent,
  },
  {
    path: "user-transcript/:id",
    component: UserTranscriptComponent,
  },
  {
    path: "bot-preview/:id",
    component: BotPreviewComponent,
  },
  {
    path: "insufficient-access",
    component: InsufficientAccessComponent,
  },
  {
    path: "google/callback",
    component: GoogleCallbackComponent,
  },
  ...(environment.themeEnabled
    ? [
        {
          path: "theme",
          loadChildren: () => import("./theme/theme.module").then(m => m.ThemeModule),
        },
      ]
    : []),
  {
    path: "**",
    redirectTo: "dashboard",
    pathMatch: "full" as PathMatch,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
