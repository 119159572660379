import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-messenger-quick-reply",
  templateUrl: "./messenger-quick-reply.component.html",
  styleUrls: ["../messenger.common-bot.component.scss"],
})
export class MessengerQuickReplyComponent {
  @Input() qrDetails: any;
  @Output() buttonClickedEmitter = new EventEmitter<any>();

  buttonClicked(value) {
    this.buttonClickedEmitter.emit(value);
  }
}
