import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthService } from "../../services/auth.service";
import { Urls } from "../urls";
import { BotService } from "src/app/services/bot.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private _botService: BotService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !(
        request.url.includes("botPreviewLink/auth") ||
        request.url.includes("changeForgotPassword") ||
        request.url.includes("set-password") ||
        request.url.includes("preview/attachment")
      )
    ) {
      request = request.clone({
        setHeaders: {
          authorization: this.authService.iKk1k_4KxG8Q37tkFfM46() || "",
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authService.logout().catch(() => {});
              if (request.url !== Urls.login) {
                this._botService.clearCurrentSession();
                location.href = "";
              }
            }
          }
        }
      )
    );
  }
}
