<ng-container *ngIf="data">
  <div class="example-viewport">
    <div *ngFor="let message of transcriptArr; index as i; trackBy: trackByFn" [id]="'message-' + message._id">
      <!--Message from bot-->
      <ng-container *ngIf="message.type === 'conversationDate'">
        <div class="t-flex t-items-center t-justify-center">
          <div
            class="conversation-date1 sticky"
            id="date0"
            *ngIf="i === 0"
            [ngStyle]="{
              top: parentComponent === 'handoff' ? '160px' : '115px',
              opacity: isShowSticky ? '1' : '0',
            }">
            {{ transcriptArr[0].date === "Today" ? transcriptArr[0].date : (transcriptArr[0].date | date) }}
          </div>
          <div class="conversation-date">
            {{ message.date === "Today" ? message.date : (message.date | date) }}
          </div>
        </div>
      </ng-container>
      <!--Message from user-->
      <ng-container *ngIf="message.from === 'User' && !message.isConfigMessage">
        <div class="t-w-full t-flex t-justify-start t-pt-[5px] t-pr-5" [ngClass]="{ 't-pb-5': i === transcriptArr.length - 1 }">
          <div class="t-flex t-justify-start chat-send-msg-main t-pl-5">
            <div class="chat-send-profile">
              <img height="30px" width="30px" [src]="sanitizer.bypassSecurityTrustResourceUrl(profilePic)" alt="profile-pic" />
            </div>
            <!--  message types start-->
            <!--  send type - text-->
            <ng-container *ngIf="message.type === 'text' && !message.text.isRating">
              <div class="user-bubble" [id]="'element' + message._id">
                <div class="user-bubble-agent-msg t-flex-col">
                  <div class="t-flex">
                    <span [innerHTML]="generateHyperLinkInText(message?.text?.message)"></span>
                    <mat-icon *ngIf="message?.text?.isWhatsappFlowResponse" class="t-text-[22px] t-ml-[5px]">check_circle_outline</mat-icon>
                  </div>
                  <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - rating-->
            <ng-container *ngIf="message.type === 'text' && message.text.isRating">
              <div class="user-bubble" [id]="'element' + message._id">
                <div class="user-bubble-agent-msg" *ngIf="!message.text.isNoRating">
                  <span class="t-flex t-mr-[5px]" *ngFor="let star of message.text.activeStars">
                    <mat-icon *ngIf="star === true">star</mat-icon>
                    <mat-icon *ngIf="star === false">star_outline</mat-icon>
                  </span>
                  <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
                <div class="user-bubble-agent-msg" *ngIf="message.text.isNoRating">
                  <span> Not Now </span>
                  <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - rating-->
            <ng-container *ngIf="message.type === 'attachment'">
              <div
                class="bot-bubble bot-bubble-image"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'"
                *ngIf="
                  message.attachment.contentType &&
                  (message.attachment.contentType.split('/')[0] === 'video' || VIDEO_TYPES.includes(message.attachment.contentType))
                ">
                <div class="bot-bubble-agent-img user__background" [id]="'element' + message._id">
                  <div class="bot-bubble-agent-image-caption">
                    <video class="bot-bubble-agent-video" controls *ngIf="message?.attachment?.contentUrl">
                      <source [src]="message?.attachment?.contentUrl" type="video/mp4" />
                      <source [src]="message?.attachment?.contentUrl" type="video/webm" />
                      <source [src]="message?.attachment?.contentUrl" type="video/ogg" />
                    </video>
                  </div>
                  <div>
                    <div class="caption" *ngIf="message.isCaptionAttached" [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                    <span class="user-bubble-agent-time">
                      {{ message.createdAt | date: "shortTime" }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="message.type === 'attachment'">
              <div
                *ngIf="message.attachment.contentType && message.attachment.contentType.split('/')[0] === 'image'"
                class="bot-bubble bot-bubble-img background-none"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div
                  class="bot-bubble bot-bubble-image background-none"
                  [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                  <div class="bot-bubble-agent-img user__background" [id]="'element' + message._id">
                    <div class="bot-bubble-agent-image-caption">
                      <img
                        class="bot-bubble-agent-image"
                        (click)="openImagePreview(imagePreview, message.attachment.contentUrl)"
                        *ngIf="message?.attachment?.contentUrl"
                        [src]="message?.attachment?.contentUrl"
                        alt="image" />
                    </div>
                    <div>
                      <div
                        class="caption"
                        *ngIf="message.isCaptionAttached"
                        [id]="'element' + message._id"
                        [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                      <span class="user-bubble-agent-time color-video-audio">{{ message.createdAt | date: "shortTime" }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="message.type === 'attachment'">
              <div
                *ngIf="message.attachment.contentType && message.attachment.contentType.split('/')[0] === 'audio'"
                class="user-bubble bot-bubble-audio background-none t-relative">
                <div class="t-flex" [id]="'element' + message._id">
                  <audio class="audio" controls *ngIf="message.attachment.contentUrl">
                    <source [src]="message?.attachment?.contentUrl" type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>

                  <span class="bot-bubble-agent-time user-time-user">
                    {{ message.createdAt | date: "shortTime" }}
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="message.type === 'attachment'">
              <div class="user-bubble" *ngIf="message.attachment.contentType && message.attachment.contentType.split('/')[1] === 'pdf'">
                <div class="user-bubble-message-text">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <img alt="document" [src]="'../../../assets/images/pdf.svg'" />
                      <span>{{ message.attachment.contentUrl.split("/")[message.attachment.contentUrl.split("/").length - 1] }}</span>
                    </div>
                    <a target="_blank" download [href]="message.attachment.contentUrl" style="outline: none">
                      <mat-icon class="download" *ngIf="message.attachment.contentUrl">cloud_download </mat-icon>
                    </a>
                  </div>
                  <div>
                    <div
                      class="caption-css"
                      *ngIf="message.isCaptionAttached"
                      [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                    <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                      >{{ message.createdAt | date: "shortTime" }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="message.type === 'attachment'">
              <div
                class="user-bubble"
                [id]="'element' + message._id"
                *ngIf="
                  message.attachment.contentType &&
                  (EXCEL_TYPE.includes(message.attachment.contentType) ||
                    message.attachment.contentType.indexOf('.sheet') !== -1 ||
                    message.attachment.contentType.split('/')[1] === 'csv')
                ">
                <div class="user-bubble-message-text">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <img alt="document" [src]="'../../../assets/images/xls.svg'" />
                      <span>{{
                        message.attachment.contentUrl.split("/")[message.attachment.contentUrl.split("/").length - 1].split(".")[0]
                      }}</span>
                    </div>
                    <a target="_blank" download [href]="message.attachment.contentUrl" style="outline: none">
                      <mat-icon class="download" *ngIf="message.attachment.contentUrl">cloud_download </mat-icon>
                    </a>
                  </div>
                  <div>
                    <div
                      class="caption-css"
                      *ngIf="message.isCaptionAttached"
                      [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                    <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                      >{{ message.createdAt | date: "shortTime" }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="message.type === 'attachment'">
              <div
                class="user-bubble"
                [id]="'element' + message._id"
                *ngIf="
                  message.attachment.contentType &&
                  (message.attachment.contentType.indexOf('.document') !== -1 || DOC_TYPE.includes(message.attachment.contentType))
                ">
                <div class="user-bubble-message-text">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <img alt="document" [src]="'../../../assets/images/doc.svg'" />
                      <span>{{
                        message.attachment.contentUrl.split("/")[message.attachment.contentUrl.split("/").length - 1].split(".")[0]
                      }}</span>
                    </div>
                    <a target="_blank" download [href]="message.attachment.contentUrl" style="outline: none">
                      <mat-icon class="download" *ngIf="message.attachment.contentUrl">cloud_download </mat-icon>
                    </a>
                  </div>
                  <div>
                    <div
                      class="caption-css"
                      *ngIf="message.isCaptionAttached"
                      [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                    <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                      >{{ message.createdAt | date: "shortTime" }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="message.type === 'attachment'">
              <div
                class="user-bubble"
                [id]="'element' + message._id"
                *ngIf="
                  message.attachment.contentType &&
                  (message.attachment.contentType.indexOf('/vnd.ms-powerpoint') !== -1 ||
                    message.attachment.contentType.indexOf('/vnd.openxmlformats-officedocument.presentationml') !== -1 ||
                    PPT_TYPE.includes(message.attachment.contentType))
                ">
                <div class="user-bubble-message-text">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <img alt="document" [src]="'../../../assets/images/ppt.svg'" />
                      <span>{{
                        message.attachment.contentUrl.split("/")[message.attachment.contentUrl.split("/").length - 1].split(".")[0]
                      }}</span>
                    </div>
                    <a target="_blank" download [href]="message.attachment.contentUrl" style="outline: none">
                      <mat-icon class="download" *ngIf="message.attachment.contentUrl">cloud_download </mat-icon>
                    </a>
                  </div>
                  <div>
                    <div
                      class="caption-css"
                      *ngIf="message.isCaptionAttached"
                      [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                    <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                      >{{ message.createdAt | date: "shortTime" }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--  receive type multiple attachment - start -->
            <ng-container *ngIf="message.type === 'multipleAttachment'">
              <div class="user-bubble" [id]="'element' + message._id">
                <div class="user-bubble-agent-msg t-flex t-flex-col">
                  <ng-container *ngFor="let attachment of message.multipleAttachment; index as attachmentIndex">
                    <div class="bot-bubble-agent-doc mb-10 p-10 bg-white">
                      <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                        <img
                          *ngIf="[MEDIA_TYPES.DOC, MEDIA_TYPES.XLS, MEDIA_TYPES.PPT, MEDIA_TYPES.PDF].includes(attachment.contentType)"
                          alt="document"
                          [src]="'assets/images/' + attachment.contentType + '.svg'" />
                        <mat-icon *ngIf="attachment.contentType === MEDIA_TYPES.IMAGE" class="t-flex t-items-center" color="primary">
                          image
                        </mat-icon>
                        <mat-icon *ngIf="attachment.contentType === MEDIA_TYPES.VIDEO" class="t-flex t-items-center" color="primary">
                          live_tv
                        </mat-icon>
                        <mat-icon *ngIf="attachment.contentType === MEDIA_TYPES.AUDIO" class="t-flex t-items-center" color="primary">
                          audiotrack
                        </mat-icon>
                        <span>{{ attachment.caption }}</span>
                      </div>
                      <a target="_blank" download [href]="attachment.contentUrl" matTooltip="Click to download">
                        <mat-icon class="download color-primary t-flex t-items-center" *ngIf="attachment.contentUrl">
                          cloud_download
                        </mat-icon>
                      </a>
                    </div>
                  </ng-container>
                  <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type multiple attachment - end -->
            <!--  receive type other attachment - start -->
            <ng-container *ngIf="message.type === 'attachment'">
              <div
                class="user-bubble"
                [id]="'element' + message._id"
                *ngIf="message.attachment.contentType && !ALLOWED_TYPES.includes(message.attachment.contentType)">
                <div class="user-bubble-message-text">
                  <div class="bot-bubble-agent-doc">
                    <div class="d-flex align-center bot-bubble-agent-doc-detail">
                      <img alt="document" [src]="'../../../assets/images/other.svg'" />
                      <span>{{
                        message.attachment.contentUrl.split("/")[message.attachment.contentUrl.split("/").length - 1].split(".")[0]
                      }}</span>
                    </div>
                    <a target="_blank" download [href]="message.attachment.contentUrl" style="outline: none" matTooltip="Click to download">
                      <mat-icon class="download" *ngIf="message.attachment.contentUrl">cloud_download </mat-icon>
                    </a>
                  </div>
                  <div>
                    <div
                      class="caption-css"
                      *ngIf="message.isCaptionAttached"
                      [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                    <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                      >{{ message.createdAt | date: "shortTime" }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--  receive type other attachment - end -->
            <!-- receive type - Order -->
            <ng-container *ngIf="message.type === 'order'">
              <div class="bot-bubble" [id]="'element' + message._id" class="order-main-div user-bubble">
                <div class="user-order-msg t-flex t-flex-col">
                  <div class="t-mb-2.5 t-flex t-flex-col">
                    <div class="t-flex t-w-full order-header-div">
                      <div class="header-image">
                        <mat-icon>local_mall</mat-icon>
                      </div>
                      <div class="t-w-[75%] header-text-transcript t-font-semibold t-flex t-flex-col">
                        <div class="t-flex t-mb-[5px]">
                          <mat-icon class="t-mr-[5px]">shopping_cart_checkout</mat-icon>
                          <span>{{ message.order?.noOfOrders }} Items</span>
                        </div>
                        <p>{{ (message.order?.items)[0].currency }} {{ message.order?.totalPriceOfOrder }} (estimated total)</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list-title t-cursor-pointer t-outline-none order-footer-title t-mb-[5px]"
                    (click)="showCatalogueItems(catalogueProductList, message.order, message.type)">
                    <mat-icon class="t-mr-[5px]">format_list_bulleted</mat-icon>
                    View sent cart
                  </div>
                  <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!-- receive type - Order -->
            <!--  message types end-->
            <ng-container *ngIf="message.type === 'form'">
              <div class="user-bubble-agent-msg">
                <span> Form Submitted </span>
                <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
              </div>
            </ng-container>
            <ng-container *ngIf="message.type === 'location'">
              <div class="user-bubble location-div" [id]="'element' + message._id">
                <div class="user-bubble-message-text">
                  <a
                    [href]="
                      message.location.label
                        ? 'https://www.google.com/maps/search/?api=1&query=' +
                          message.location.label +
                          '/@' +
                          message.location.latitude +
                          ',' +
                          message.location.longitude
                        : 'https://www.google.com/maps/search/?api=1&query=' + message.location.latitude + ',' + message.location.longitude
                    "
                    target="_blank"
                    ><img class="location-image" src="assets/images/live-chat/location.svg" alt="location"
                  /></a>

                  <div [id]="'element' + message._id" class="location-info-div">
                    <div class="t-ml-[5px] t-mr-[5px]">
                      <a
                        [href]="
                          message.location.label
                            ? 'https://www.google.com/maps/search/?api=1&query=' +
                              message.location.label +
                              '/@' +
                              message.location.latitude +
                              ',' +
                              message.location.longitude
                            : 'https://www.google.com/maps/search/?api=1&query=' +
                              message.location.latitude +
                              ',' +
                              message.location.longitude
                        "
                        target="_blank"
                        class="location-label"
                        >{{
                          message.location.label ||
                            "https://www.google.com/maps/search/?api=1&query=" +
                              message.location.latitude +
                              "," +
                              message.location.longitude
                        }}</a
                      >
                    </div>
                    <p *ngIf="message.location.address" class="location-body location-address">
                      {{ message.location.address }}
                    </p>
                    <span class="user-bubble-agent-time color-video-audio">{{ message.createdAt | date: "shortTime" }} </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- receive type - Address Message Response - start -->
            <ng-container *ngIf="message.type === 'addressMessageResponse'">
              <div class="user-bubble t-flex-col">
                <div class="t-w-full user-bubble-agent-msg t-flex-col">
                  <span class="t-mb-[5px]" [innerHTML]="generateHyperLinkInText(message[message.type]?.body)"></span>
                  <span class="user-bubble-agent-time color-video-audio">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
              </div>
            </ng-container>
            <!-- receive type - Address Message Response - end  -->
            <ng-container *ngIf="message.type === 'contact'">
              <div class="user-bubble" [id]="'element' + message._id">
                <div class="user-bubble-agent-msg t-block">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <div>
                        <mat-icon>perm_contact_calendar</mat-icon>
                      </div>
                      <div>
                        <div>
                          {{ message.contact.vcard ? message.contact.vcard : message.contact.firstName + " " + message.contact.lastName }}
                        </div>
                        <div>{{ message.contact.phoneNumber }}</div>
                      </div>
                    </div>
                  </div>
                  <span class="bot-bubble-agent-time contact-time">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="message.from === 'User' && message.isConfigMessage">
        <div
          *ngIf="message.type !== 'paymentStatus'"
          class="t-w-full bot-detail-text"
          [ngClass]="{ 't-pb-5': i === transcriptArr.length - 1 }">
          <div [innerHTML]="message.text.message"></div>
          <span class="user-bubble-agent-time f-10">{{ message.createdAt | date: "shortTime" }} </span>
        </div>
        <div
          *ngIf="message.type === 'paymentStatus'"
          class="t-w-full bot-detail-text t-flex t-items-center"
          [ngClass]="{ 't-pb-5': i === transcriptArr.length - 1 }">
          <mat-icon
            class="t-mr-[5px] payment-status-icon"
            [ngClass]="{
              'payment-success': message.paymentStatus?.status === 'success',
              'payment-failed': message.paymentStatus?.status === 'failed',
            }"
            >{{ paymentStatusMapping[message.paymentStatus?.status]?.icon }}</mat-icon
          >
          <div class="t-mr-[5px] t-text-xs t-font-medium">{{ paymentStatusMapping[message.paymentStatus?.status]?.message }}</div>
          <span class="t-text-xs">Order - {{ message.paymentStatus?.referenceId }}</span>
        </div>
      </ng-container>
      <!--Message from bot-->
      <ng-container *ngIf="message.from === 'Bot' || message.from === 'Agent'">
        <div
          class="t-w-full t-flex t-justify-end t-pt-2.5 t-pl-5"
          *ngIf="!message.isConfigMessage"
          [ngClass]="{ 't-pb-5': i === transcriptArr.length - 1 }">
          <div class="t-flex t-justify-end align-items-start chat-send-msg-main" style="padding-right: 20px">
            <!--  receive type - text-->
            <ng-container *ngIf="message.type === 'text' && !message.text.buttons.length">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-bubble-agent-msg t-flex t-flex-col">
                  <span [innerHTML]="generateHyperLinkInText(message?.text?.message)"></span>
                  <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - text-->
            <!--  receive type - text with buttons-->
            <ng-container *ngIf="message?.type === 'text' && message.text?.buttons && message.text.buttons?.length !== 0">
              <div class="receive-msg-card common-display common-display-user-detail">
                <div
                  class="bot-bubble"
                  [id]="'element' + message._id"
                  [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'"
                  style="border-radius: 10px 0 0px 0px !important">
                  <div class="bot-bubble-agent-msg">
                    <span class="user-bubble-agent-msg-text" [innerHTML]="generateHyperLinkInText(message?.text?.message)"></span>
                  </div>
                </div>
                <div class="receive-msg-buttons">
                  <button
                    class="primary-btn btn receive-msg-button"
                    *ngFor="let button of message?.text?.buttons; index as bI"
                    mat-flat-button
                    color="primary">
                    {{ button }}
                  </button>
                </div>
              </div>
            </ng-container>
            <!--  receive type - text with buttons-->
            <!--  receive type - image-->
            <ng-container *ngIf="message.type === 'image'">
              <div [ngClass]="message.isCaptionAttached ? 'receive-msg-card common-display-user-detail' : 'bot-bubble bot-bubble-image'">
                <div class="bot-bubble-agent-img">
                  <img
                    class="bot-bubble-agent-image"
                    [src]="message?.image?.url"
                    (click)="openImagePreview(imagePreview, message.image.url)"
                    *ngIf="message?.image?.url"
                    [ngStyle]="{
                      border: message.isCaptionAttached ? 'none' : '',
                    }"
                    alt="image" />
                  <div [id]="'element' + message._id">
                    <div *ngIf="message.isCaptionAttached" class="caption-css">
                      <span [innerHTML]="generateHyperLinkInText(message?.caption)"> </span>
                      <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
                    </div>
                    <span *ngIf="!message.isCaptionAttached" class="user-bubble-agent-time color-video-audio"
                      >{{ message.createdAt | date: "shortTime" }}
                    </span>
                  </div>
                  <ng-container *ngIf="message.isUploaded !== undefined">
                    <div *ngIf="!message.isUploaded">
                      <mat-progress-spinner class="example-margin" color="primary" diameter="50" mode="indeterminate">
                      </mat-progress-spinner>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <!--  receive type - image-->
            <!--  receive type - location-->
            <ng-container *ngIf="message.type === 'location'">
              <div class="bot-bubble bot-background bot-location location-div" [id]="'element' + message._id">
                <div>
                  <a
                    [href]="
                      message.location.label
                        ? 'https://www.google.com/maps/search/?api=1&query=' +
                          message.location.label +
                          '/@' +
                          message.location.latitude +
                          ',' +
                          message.location.longitude
                        : 'https://www.google.com/maps/search/?api=1&query=' + message.location.latitude + ',' + message.location.longitude
                    "
                    target="_blank"
                    ><img class="location-image" src="assets/images/live-chat/location.svg" alt="location"
                  /></a>

                  <div [id]="'element' + message._id" class="location-info-div">
                    <div *ngIf="message.location.label" class="t-ml-[5px] t-mr-[5px]">
                      <a
                        [href]="
                          'https://www.google.com/maps/search/?api=1&query=' +
                          message.location.label +
                          '/@' +
                          message.location.latitude +
                          ',' +
                          message.location.longitude
                        "
                        target="_blank"
                        class="bot-location-label"
                        >{{ message.location.label }}</a
                      >
                    </div>
                    <p *ngIf="message.location.address" class="location-address t-pl-[5px] t-pr-[5px]">
                      {{ message.location.address }}
                    </p>
                    <span class="user-bubble-agent-time color-video-audio">{{ message.createdAt | date: "shortTime" }} </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--  receive type - location-->
            <!--  receive type - video-->
            <ng-container *ngIf="message.type === 'video'">
              <div [ngClass]="message.isCaptionAttached ? 'receive-msg-card common-display-user-detail' : 'bot-bubble bot-bubble-image'">
                <div class="bot-bubble-agent-img">
                  <video class="bot-bubble-agent-image" controls *ngIf="message?.video?.url" preload="none">
                    <source [src]="message?.video?.url" type="video/mp4" />
                    <source [src]="message?.video?.url" type="video/webm" />
                    <source [src]="message?.video?.url" type="video/ogg" />
                  </video>
                  <div [id]="'element' + message._id" *ngIf="message.isCaptionAttached" class="caption-css">
                    <span [innerHTML]="generateHyperLinkInText(message?.caption)"> </span>
                    <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
                  </div>
                  <span *ngIf="!message.isCaptionAttached" class="user-bubble-agent-time color-video-audio">
                    {{ message.createdAt | date: "shortTime" }}
                  </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - video-->
            <!--  receive type - audio-->
            <ng-container *ngIf="message.type === 'audio'">
              <div class="bot-bubble bot-bubble-image" [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-bubble-agent-img t-relative">
                  <audio class="audio-preview bot-bubble-agent-audio audio" controls *ngIf="message?.audio?.url">
                    <source [src]="message?.audio?.url" type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  <span class="bot-bubble-agent-time color-video-audio audio-time">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - audio-->
            <!--  receive type - card-->
            <ng-container *ngIf="message.type === 'card'">
              <div class="receive-msg-card common-display-user-detail">
                <img *ngIf="message?.card?.image_url?.url" [src]="message?.card?.image_url?.url" alt="card-image" />
                <div [id]="'element' + message._id">
                  <p class="receive-msg-title" [innerHTML]="generateHyperLinkInText(message?.card?.title)"></p>
                  <p class="receive-msg-subTitle" [innerHTML]="generateHyperLinkInText(message?.card?.subtitle)"></p>
                  <ng-container *ngIf="message.card && message.card.buttons && message.card.buttons.length !== 0">
                    <div class="receive-msg-buttons">
                      <div
                        class="receive-msg-button"
                        *ngFor="let button of message.card.buttons; index as bI"
                        mat-flat-button
                        color="primary">
                        {{ button }}
                      </div>
                    </div>
                  </ng-container>
                  <span class="bot-bubble-agent-time t-pb-[5px] t-pr-[5px] color-video-audio"
                    >{{ message.createdAt | date: "shortTime" }}
                  </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - card-->
            <!--  receive type - carousel-->
            <ng-container *ngIf="message.type === 'carousel'">
              <div style="width: 90%; min-width: 210px; max-width: 330px" class="t-flex t-items-center">
                <div class="carousel-navigation">
                  <mat-icon aria-hidden="false" aria-label="left" (click)="updateIndexOfCarousel(i, 'prev', customCarousel)"
                    >chevron_left
                  </mat-icon>
                </div>
                <drag-scroll #customCarousel style="width: 100%" class="drag-preview" [drag-disabled]="true" [drag-scroll-disabled]="true">
                  <div
                    drag-scroll-item
                    *ngFor="let car of message.carousel; let cIndex = index"
                    id="childCarousel{{ cIndex }}"
                    class="carousel-flex-div t-w-full">
                    <div class="receive-msg-carousal-card common-display-user-detail" style="width: 92%; margin-bottom: 10px">
                      <img *ngIf="car.image_url" [src]="car.image_url" alt="carousel-image" />
                      <div [id]="'element' + message._id">
                        <div>
                          <p class="receive-msg-title" [innerHTML]="generateHyperLinkInText(car.title)"></p>
                        </div>
                        <div>
                          <p class="receive-msg-subTitle" [innerHTML]="generateHyperLinkInText(car.subtitle)"></p>
                        </div>
                        <div>
                          <span class="bot-bubble-agent-time t-pb-[5px] t-pr-[5px] color-video-audio"
                            >{{ message.createdAt | date: "shortTime" }}
                          </span>
                        </div>
                        <ng-container *ngIf="car.buttons && car.buttons.length !== 0">
                          <div class="receive-msg-buttons" style="display: grid">
                            <button *ngFor="let button of car.buttons; index as bI" mat-flat-button style="margin-top: 5px" color="primary">
                              {{ button }}
                            </button>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </drag-scroll>
                <div class="carousel-navigation">
                  <mat-icon aria-hidden="false" aria-label="right" (click)="updateIndexOfCarousel(i, 'next', customCarousel)"
                    >chevron_right
                  </mat-icon>
                </div>
              </div>
            </ng-container>
            <!--  receive type - carousel-->
            <!--  receive type - pdf-->
            <ng-container *ngIf="message.type === 'pdf'">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-bubble-agent-msg">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <img alt="pdf" src="assets/images/pdf.svg" />
                      <span *ngIf="message.pdf?.url">{{ message.filename || message.pdf.url.split("/").pop() }}</span>
                    </div>
                    <a target="_blank" download [href]="message.pdf.url" style="outline: none" matTooltip="Click to download">
                      <mat-icon class="download" [ngClass]="{ 'agent-download-icon': message.isFromAgent }" *ngIf="message.pdf.url"
                        >cloud_download
                      </mat-icon>
                    </a>
                  </div>
                  <div class="doc__caption" *ngIf="message.isCaptionAttached" [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                  <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                    >{{ message.createdAt | date: "shortTime" }}
                  </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - pdf-->
            <!--  receive type - doc-->
            <ng-container *ngIf="message.type === 'doc'">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-bubble-agent-msg">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <img alt="document" src="assets/images/doc.svg" />
                      <span *ngIf="message.doc?.url">{{ message.filename || message.doc.url.split("/").pop() }}</span>
                    </div>
                    <a target="_blank" download [href]="message.doc.url" style="outline: none" matTooltip="Click to download">
                      <mat-icon class="download" [ngClass]="{ 'agent-download-icon': message.isFromAgent }" *ngIf="message.doc.url"
                        >cloud_download
                      </mat-icon>
                    </a>
                  </div>
                  <div *ngIf="message.isCaptionAttached" [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                  <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                    >{{ message.createdAt | date: "shortTime" }}
                  </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - doc-->
            <!--  receive type - excel-->
            <ng-container *ngIf="message.type === 'xls'">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-bubble-agent-msg">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <img alt="excel" src="assets/images/xls.svg" />
                      <span *ngIf="message.xls?.url">{{ message.filename || message.xls.url.split("/").pop() }}</span>
                    </div>
                    <a target="_blank" download [href]="message.xls.url" style="outline: none" matTooltip="Click to download">
                      <mat-icon class="download" [ngClass]="{ 'agent-download-icon': message.isFromAgent }" *ngIf="message.xls.url"
                        >cloud_download
                      </mat-icon>
                    </a>
                  </div>
                  <div *ngIf="message.isCaptionAttached" [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                  <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                    >{{ message.createdAt | date: "shortTime" }}
                  </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - excel-->
            <!--  receive type - ppt-->
            <ng-container *ngIf="message.type === 'ppt'">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-bubble-agent-msg">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <img alt="ppt" src="assets/images/ppt.svg" />
                      <span *ngIf="message.ppt?.url">{{ message.filename || message.ppt.url.split("/").pop() }}</span>
                    </div>
                    <a target="_blank" download [href]="message.ppt.url" style="outline: none" matTooltip="Click to download">
                      <mat-icon class="download" [ngClass]="{ 'agent-download-icon': message.isFromAgent }" *ngIf="message.ppt.url"
                        >cloud_download
                      </mat-icon>
                    </a>
                  </div>
                  <div *ngIf="message.isCaptionAttached" [innerHTML]="generateHyperLinkInText(message?.caption)"></div>
                  <span class="bot-bubble-agent-time" style="bottom: 1px !important; right: 27px !important"
                    >{{ message.createdAt | date: "shortTime" }}
                  </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - ppt-->
            <!--  receive type - Contact-->
            <ng-container *ngIf="message.type === 'contact'">
              <div class="bot-bubble bot-background" [id]="'element' + message._id">
                <div class="bot-bubble-agent-msg">
                  <div class="bot-bubble-agent-doc">
                    <div class="t-flex t-items-center bot-bubble-agent-doc-detail">
                      <div>
                        <mat-icon>perm_contact_calendar</mat-icon>
                      </div>
                      <div>
                        <div>
                          {{ message.contact.vcard ? message.contact.vcard : message.contact.firstName + " " + message.contact.lastName }}
                        </div>
                        <div>{{ message.contact.phoneNumber }}</div>
                      </div>
                    </div>
                  </div>
                  <span class="bot-bubble-agent-time contact-time">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - Contact-->
            <!--  receive type - DynamicList-->
            <ng-container *ngIf="message.type === 'list' || message.type === 'dynamicList'">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-bubble-agent-msg t-flex t-flex-col">
                  <div class="t-mb-2.5 t-flex t-flex-col">
                    <span
                      *ngIf="message[message.type]?.header?.type === 'text'"
                      [innerHTML]="generateHyperLinkInText(message[message.type]?.header?.text)">
                    </span>
                    <span class="t-mt-[5px]" [innerHTML]="generateHyperLinkInText(message[message.type]?.body)"> </span>
                    <span
                      class="t-mt-[5px]"
                      *ngIf="message[message.type]?.footer"
                      [innerHTML]="generateHyperLinkInText(message[message.type]?.footer)">
                    </span>
                  </div>
                  <div
                    class="list-title t-cursor-pointer t-outline-none t-mb-[5px]"
                    (click)="showListItem(listTemplate, message[message.type].actions)">
                    <mat-icon class="t-mr-[5px]">format_list_bulleted</mat-icon>
                    {{ message[message.type].actions.button_text }}
                  </div>
                  <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - DynamicList-->
            <!--  receive type - dynamicReply | whatsappFlow | addressMessage - START-->
            <ng-container
              *ngIf="
                message.type === 'reply' ||
                message.type === 'dynamicReply' ||
                message.type === 'whatsappFlow' ||
                message.type === 'addressMessage'
              ">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-bubble-agent-msg t-flex t-flex-col">
                  <div class="t-mb-2.5 t-flex t-flex-col reply-div-width">
                    <span
                      class="t-text-lg header-font-weight t-font-medium"
                      *ngIf="message[message.type]?.header?.type === 'text'"
                      [innerHTML]="generateHyperLinkInText(message[message.type]?.header?.text)"></span>
                    <img *ngIf="message[message.type]?.header?.type === 'image'" [src]="message[message.type]?.header?.url" alt="image" />
                    <video *ngIf="message[message.type]?.header?.type === 'video'" controls>
                      <source [src]="message[message.type]?.header?.url" type="video/mp4" />
                      <source [src]="message[message.type]?.header?.url" type="video/webm" />
                      <source [src]="message[message.type]?.header?.url" type="video/ogg" />
                      <track label="English" kind="captions" />
                    </video>
                    <a
                      class="t-flex t-items-center reply-document"
                      [href]="message[message.type]?.header?.url"
                      target="_blank"
                      *ngIf="message[message.type]?.header?.type === 'document' && message[message.type]?.header?.url">
                      <img
                        src="assets/images/{{ commonService.documentTypeMap[message[message.type].header.url.split('.').pop()] }}"
                        alt="reply-header-image" />
                      <div class="t-ml-[5px]" [matTooltip]="message[message.type].header.url.split('/').pop()">
                        {{ message[message.type].header.url.split("/").pop() }}
                      </div>
                    </a>
                    <p
                      class="t-mt-[5px] body-font-weight t-text-base"
                      [innerHTML]="generateHyperLinkInText(message[message.type]?.body)"></p>
                    <p
                      class="t-mt-[5px] footer-text t-text-xs"
                      *ngIf="message[message.type]?.footer"
                      [innerHTML]="generateHyperLinkInText(message[message.type]?.footer)"></p>
                  </div>
                  <ng-container *ngIf="message.type === 'addressMessage'">
                    <div class="horizontal-line t-mb-[5px] t-mt-[5px]"></div>
                    <div
                      class="t-flex t-justify-center t-mt-[5px] t-mb-[5px] t-cursor-pointer t-outline-none"
                      (click)="addressMessageFillDetails(addressMessageFormModal, message.addressMessage)">
                      <span class="t-ml-[5px] dot t-font-bold">Provide Address</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="message.type === 'reply' || message.type === 'dynamicReply'">
                    <ng-container *ngFor="let replyButton of message[message.type].actions.buttons">
                      <div class="chat-button" *ngIf="message[message.type].actions.buttonType === FlowStepReplyButtonType.REPLY">
                        <mat-icon class="t-mr-[5px]">reply</mat-icon>
                        <span>{{ replyButton.title }}</span>
                      </div>
                      <div
                        class="chat-button mb-5 t-cursor-pointer"
                        *ngIf="message[message.type].actions.buttonType !== FlowStepReplyButtonType.REPLY"
                        (click)="opneCTAUrl(replyButton.url)">
                        <mat-icon class="mr-5">open_in_new</mat-icon>
                        <span>{{ replyButton.title }}</span>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="message.type === 'whatsappFlow'">
                    <div
                      class="list-title t-mb-[5px]"
                      (click)="
                        channel !== CHANNEL_NAMES.WHATSAPP_NET_CORE
                          ? showWhatsappFlowDetails(whatsappFlowDetails, message.whatsappFlow)
                          : false
                      "
                      [ngClass]="{ 't-cursor-pointer t-outline-none': channel !== CHANNEL_NAMES.WHATSAPP_NET_CORE }">
                      <mat-icon class="t-mr-[5px] material-icons-round" style="margin-top: 2px" aria-hidden="false">list_alt</mat-icon>
                      {{ message[message.type].actionButton?.text }}
                    </div>
                  </ng-container>
                  <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!--  receive type - dynamicReply | whatsappFlow | addressMessage - END -->

            <!-- receive type - Order Details Message- start -->
            <ng-container *ngIf="message.type === 'orderPaymentMessage'">
              <div class="bot-bubble bot-background-new-theme" [id]="'element' + message._id">
                <div class="t-relative t-w-full t-flex t-flex-col order-payment-main">
                  <div class="t-flex t-flex-col order-payment-message">
                    <span class="t-text-xs t-mb-[5px] order-details-title">Order Details</span>
                    <img
                      class="order-payment-header-image"
                      *ngIf="message[message.type]?.header?.type === 'image'"
                      [src]="message[message.type]?.header?.url"
                      alt="image" />
                    <video *ngIf="message[message.type]?.header?.type === 'video'" controls>
                      <source [src]="message[message.type]?.header?.url" type="video/mp4" />
                      <source [src]="message[message.type]?.header?.url" type="video/webm" />
                      <source [src]="message[message.type]?.header?.url" type="video/ogg" />
                      <track label="English" kind="captions" />
                    </video>
                    <a
                      class="t-flex t-items-center order-payment-document"
                      [href]="message[message.type]?.header?.url"
                      target="_blank"
                      *ngIf="message[message.type]?.header?.type === 'document' && message[message.type]?.header?.url">
                      <img
                        src="assets/images/{{ commonService.documentTypeMap[message[message.type].header.url.split('.').pop()] }}"
                        alt="reply-header-image" />
                      <div class="t-ml-[5px] t-text-sm t-w-full dot" [matTooltip]="message[message.type].header.url.split('/').pop()">
                        {{ message[message.type].header.url.split("/").pop() }}
                      </div>
                    </a>
                    <div class="order-headline-info">
                      <span class="headline-small-text">Order #{{ message[message.type]?.action?.referenceId }}</span>
                      <div class="dashed-line"></div>
                      <div class="headline-item t-mt-[5px] dot t-w-full">{{ message[message.type]?.action?.items?.[0]?.name }}</div>
                      <div class="headline-small-text headline-item-quantity">
                        {{ message[message.type]?.action?.items?.[0]?.quantity }} items
                      </div>
                      <div class="dashed-line"></div>
                      <div class="t-flex t-justify-between t-mt-[5px] headline-item">
                        <span>Total</span
                        ><span
                          >{{ message[message.type]?.action?.currency }}
                          {{ message[message.type]?.action?.totalAmount?.value / message[message.type]?.action?.totalAmount?.offset }}</span
                        >
                      </div>
                    </div>
                    <div class="t-mt-[5px] order-payment-description">
                      <span
                        class="t-text-sm t-font-semibold t-w-full header-text"
                        *ngIf="message[message.type]?.header?.type === 'text'"
                        [innerHTML]="generateHyperLinkInText(message[message.type]?.header?.text)"></span>
                      <p class="t-mt-[5px] t-text-sm" [innerHTML]="generateHyperLinkInText(message[message.type]?.body)"></p>
                      <span
                        class="t-mt-[5px] footer-text t-text-xs dot t-w-full"
                        *ngIf="message[message.type]?.footer"
                        [innerHTML]="generateHyperLinkInText(message[message.type]?.footer)"></span>
                    </div>
                  </div>
                  <div class="horizontal-line t-mb-2.5"></div>
                  <div
                    class="order-payment-button t-cursor-pointer t-outline-none"
                    (click)="showOrderPaymentDetails(orderPaymentDetails, message[message.type]?.action)">
                    Review and pay
                  </div>
                  <span class="user-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!-- receive type - Order Details Message- end -->

            <!-- receive type - Order Details Message- start -->
            <ng-container *ngIf="message.type === 'orderStatus'">
              <div class="bot-bubble bot-background-new-theme" [id]="'element' + message._id">
                <div class="t-relative t-w-full t-flex t-flex-col order-status-main-div">
                  <span class="order-status-title t-text-xs t-mb-[5px]">Order Status - {{ message[message.type]?.referenceId }}</span>
                  <div class="horizontal-line t-mb-2.5"></div>
                  <span class="order-status t-text-sm t-font-semibold"
                    >Status: Order {{ orderStatusesObj[message[message.type]?.order?.status] }}</span
                  >

                  <span class="t-mt-[5px] t-text-sm body-text" [innerHTML]="generateHyperLinkInText(message[message.type]?.body)"></span>
                  <span
                    class="t-mt-[5px] footer-text t-text-xs dot t-w-full"
                    *ngIf="message[message.type]?.footer"
                    [innerHTML]="generateHyperLinkInText(message[message.type]?.footer)"></span>
                  <span class="user-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!-- receive type - Order Details Message- end -->

            <!-- receive type - Location Request - Start -->
            <ng-container *ngIf="message.type === 'locationRequest'">
              <div class="bot-bubble bot-background bot-location-request-div t-flex-col">
                <div class="t-w-full t-flex bot-location-request-body-div t-flex-col">
                  <span class="t-mb-[5px]" [innerHTML]="generateHyperLinkInText(message[message.type]?.body)"></span>

                  <div class="horizontal-line t-mb-[5px] t-mt-[5px]"></div>
                  <div class="t-flex t-justify-center t-mt-[5px] t-mb-[5px]">
                    <mat-icon>location_on</mat-icon>
                    <span class="t-ml-[5px] dot">Send Location</span>
                  </div>
                  <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!-- receive type - Location Request - End -->

            <!-- RECEIVE-TYPE-TEMPLATE | START -->
            <div *ngIf="message.type === 'template'" class="bot-bubble-template">
              <div class="bot-bubble-agent-template" [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="t-flex t-flex-col">
                  <div *ngIf="message.template?.name" class="template-name">
                    <span
                      class="dot t-text-xs t-w-[90%] template-name d-flex align-center"
                      [ngClass]="{ 'd-flex align-center': message.isTemplateSentFromFlow }">
                      <mat-icon *ngIf="message.isTemplateSentFromFlow" color="primary" class="mr-10">account_tree</mat-icon>
                      <span class="template-name-inner">
                        Template
                        <span *ngIf="message.template?.body" [matTooltip]="message.template?.name"> - {{ message.template?.name }}</span>
                      </span>
                    </span>
                    <ng-container *ngIf="!message.template?.body">
                      <hr />
                      <span class="dot t-mt-[5px] t-w-[90%]" [matTooltip]="message.template?.name">Name: {{ message.template?.name }}</span>
                    </ng-container>
                  </div>
                  <div
                    class="template-header-with-body"
                    *ngIf="message.template?.header || message.template?.body || message.template?.footer">
                    <div *ngIf="message.template?.header?.type === 'IMAGE'" class="t-w-full">
                      <img [src]="message.template?.header?.url" alt="Header image" class="t-w-full template-image" />
                    </div>
                    <video *ngIf="message.template?.header?.type === 'VIDEO'" controls class="template-video bot-bubble-agent-video">
                      <source [src]="message.template?.header?.url" type="video/mp4" />
                      <source [src]="message.template?.header?.url" type="video/webm" />
                      <source [src]="message.template?.header?.url" type="video/ogg" />
                      <track label="English" kind="captions" />
                    </video>
                    <ng-container *ngIf="message.template?.header?.type === 'DOCUMENT'">
                      <div class="template-document-header t-flex t-items-center t-w-full">
                        <div class="t-flex t-items-center t-w-[80%] t-mr-2.5">
                          <img alt="document" [src]="'../../../assets/images/pdf.svg'" />
                          <p class="dot t-ml-2.5">
                            {{ message.template?.header?.url.split("/")[message.template?.header?.url.split("/").length - 1] }}
                          </p>
                        </div>
                        <a target="_blank" download [href]="message.template?.header?.url" style="outline: none">
                          <mat-icon class="download" color="primary" *ngIf="message.template?.header?.url">cloud_download </mat-icon>
                        </a>
                      </div>
                    </ng-container>
                    <div *ngIf="message.template?.header?.type === 'LOCATION'" class="t-w-full">
                      <a
                        [href]="
                          message.template?.header?.location?.name
                            ? 'https://www.google.com/maps/search/?api=1&query=' +
                              message.template?.header?.location?.name +
                              '/@' +
                              message.template?.header?.location?.latitude +
                              ',' +
                              message.template?.header?.location?.longitude
                            : 'https://www.google.com/maps/search/?api=1&query=' +
                              message.template?.header?.location?.latitude +
                              ',' +
                              message.template?.header?.location?.longitude
                        "
                        target="_blank">
                        <div class="t-w-full location-div location-image-div">
                          <img class="t-w-full location-image" src="assets/images/live-chat/location.svg" alt="location" />
                        </div>
                        <div class="location-details t-p-[5px]">
                          <p *ngIf="message.template?.header?.location?.name" class="t-text-xs t-font-bold">
                            {{ message.template?.header?.location?.name }}
                          </p>
                          <p *ngIf="message.template?.header?.location?.address" class="f-10">
                            {{ message.template?.header?.location?.address }}
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="template-header-body-footer">
                      <span
                        *ngIf="message.template?.header?.type === 'TEXT'"
                        [innerHTML]="generateHyperLinkInText(message.template?.header?.text)"
                        class="t-font-semibold template-text-header t-mb-[5px]"></span>
                      <p
                        *ngIf="message.template?.body"
                        [innerHTML]="generateHyperLinkInText(message.template?.body)"
                        class="template-body"></p>
                      <p
                        *ngIf="message.template?.footer"
                        [innerHTML]="generateHyperLinkInText(message.template?.footer)"
                        class="t-mt-[5px] template-footer"></p>
                    </div>
                  </div>

                  <div *ngIf="message.template?.buttons?.length" class="t-mt-2.5">
                    <div
                      *ngFor="let replyButton of message.template.buttons"
                      class="t-flex t-justify-center t-items-center t-mt-[5px] t-gap-[5px] button-template-div"
                      [ngClass]="{ 't-cursor-pointer': replyButton.type === 'FLOW' }">
                      <mat-icon *ngIf="replyButton.type === 'QUICK_REPLY'">reply</mat-icon>
                      <mat-icon *ngIf="replyButton.type === 'URL'">open_in_new </mat-icon>
                      <mat-icon *ngIf="replyButton.type === 'PHONE_NUMBER'">call</mat-icon>
                      <mat-icon *ngIf="replyButton.type === 'COPY_CODE'">content_copy</mat-icon>
                      <span class="ml-5 dot" *ngIf="replyButton.type !== 'FLOW'">{{ replyButton.text }}</span>
                      <div *ngIf="replyButton.type === 'FLOW'" (click)="showWhatsappFlowDetails(whatsappFlowDetails, replyButton.flowName)">
                        <span class="t-ml-[5px] dot">{{ replyButton.text }}</span>
                      </div>
                    </div>
                  </div>
                  <span class="user-bubble-agent-time t-mt-[5px]"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
              <ng-container *ngIf="message.template?.carousel?.length">
                <div class="template-carousel-div">
                  <div class="card-arrow-left" (click)="cardContainer.moveLeft()" *ngIf="cardContainer.currIndex !== 0">
                    <mat-icon class="carousel-chevron-icon">chevron_left</mat-icon>
                  </div>
                  <drag-scroll
                    [drag-disabled]="true"
                    [drag-scroll-disabled]="true"
                    [snap-duration]="140"
                    class="carousel-cards-container t-flex flex-row mt-5"
                    #cardContainer>
                    <div
                      class="t-w-full carousel-card-holder"
                      drag-scroll-item
                      *ngFor="let card of message.template.carousel; index as cardIndex">
                      <div class="carousel-card t-flex t-flex-col">
                        <div class="card-header-with-body" *ngIf="card?.header || card?.body">
                          <div *ngIf="card?.header?.type === 'IMAGE'" class="t-w-full">
                            <img [src]="card?.header?.url" alt="Header image" class="t-w-full template-image" />
                          </div>
                          <video *ngIf="card?.header?.type === 'VIDEO'" controls class="template-card-video bot-bubble-agent-video">
                            <source [src]="card?.header?.url" type="video/mp4" />
                            <source [src]="card?.header?.url" type="video/webm" />
                            <source [src]="card?.header?.url" type="video/ogg" />
                            <track label="English" kind="captions" />
                          </video>
                          <div *ngIf="card?.body" class="template-card-body" [innerHTML]="generateHyperLinkInText(card?.body)"></div>
                        </div>
                        <div *ngIf="card?.buttons?.length" class="mt-10">
                          <div *ngFor="let button of card.buttons" class="t-flex just-center t-items-center mt-5 button-template-div">
                            <mat-icon>{{ _templateService.carouselButtonIconMap[button.type] }}</mat-icon>
                            <span class="ml-5 dot">{{ button.text }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </drag-scroll>
                  <div
                    class="card-arrow-right"
                    *ngIf="cardContainer.currIndex !== message.template?.carousel?.length - 1"
                    (click)="cardContainer.moveRight()">
                    <mat-icon class="carousel-chevron-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- RECEIVE-TYPE-TEMPLATE | START -->

            <!-- receive type - SingleProduct -->
            <ng-container *ngIf="message.type === 'productSingle'">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-product-msg t-flex t-flex-col">
                  <div class="t-mb-2.5 t-flex t-flex-col">
                    <img *ngIf="message.productSingle?.action?.image" [src]="message.productSingle.action.image" alt="product-image" />
                    <div *ngIf="!message.productSingle?.action?.image" class="no-image-div">
                      <img class="image-common" src="assets/images/catalogue_image.svg" alt="product-image" />
                    </div>
                    <span
                      class="t-mt-[5px] catalogue-body"
                      *ngIf="message.productSingle?.body"
                      [innerHTML]="generateHyperLinkInText(message.productSingle?.body)">
                    </span>
                    <span
                      class="t-mt-[5px] t-flex footer"
                      *ngIf="message.productSingle?.footer"
                      [innerHTML]="generateHyperLinkInText(message.productSingle?.footer)">
                    </span>
                  </div>
                  <div
                    class="list-title t-cursor-pointer t-outline-none t-mb-[5px]"
                    (click)="showCatalogueItems(catalogueProductList, message.productSingle, message.type)">
                    <mat-icon class="t-mr-[5px]">format_list_bulleted</mat-icon>
                    View
                  </div>
                  <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!-- receive type - SingleProduct -->

            <!-- receive type - MultiProduct -->
            <ng-container *ngIf="message.type === 'productMulti'">
              <div
                class="bot-bubble"
                [id]="'element' + message._id"
                [ngClass]="message.isFromAgent ? 'agent-background' : 'bot-background'">
                <div class="bot-multi-product-msg t-flex t-flex-col">
                  <div class="t-mb-2.5 t-flex t-flex-col">
                    <div class="t-flex t-w-full multi-header-div">
                      <div class="header-image"><mat-icon>shopping_cart</mat-icon></div>
                      <div class="t-w-[75%] header-text-transcript t-font-semibold t-flex t-flex-col">
                        <span [innerHTML]="generateHyperLinkInText(message.productMulti?.header?.text)"></span>
                        <p>{{ message.productMulti?.noOfMultiProducts }} items</p>
                      </div>
                    </div>
                    <span
                      class="t-mt-[5px] catalogue-body"
                      *ngIf="message.productMulti?.body"
                      [innerHTML]="generateHyperLinkInText(message.productMulti?.body)">
                    </span>
                    <span
                      class="t-mt-[5px] t-flex footer"
                      *ngIf="message.productMulti?.footer"
                      [innerHTML]="generateHyperLinkInText(message.productMulti?.footer)">
                    </span>
                  </div>
                  <div
                    class="list-title t-cursor-pointer t-outline-none t-mb-[5px]"
                    (click)="showCatalogueItems(catalogueProductList, message.productMulti, message.type)">
                    <mat-icon class="t-mr-[5px]">format_list_bulleted</mat-icon>
                    Start Shopping
                  </div>
                  <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
                </div>
              </div>
            </ng-container>
            <!-- receive type - MultiProduct -->

            <!-- message-end  -->
            <div class="chat-send-profile" *ngIf="message.type !== 'typing'">
              <img
                height="30px"
                width="30px"
                [src]="sanitizer.bypassSecurityTrustResourceUrl(botIcon)"
                *ngIf="!message.isFromAgent"
                alt="bot-icon" />
              <img
                height="30px"
                width="30px"
                [src]="sanitizer.bypassSecurityTrustResourceUrl(agentPic)"
                *ngIf="message.isFromAgent"
                alt="bot-icon" />
            </div>
          </div>
        </div>
        <div
          *ngIf="message.isConfigMessage && message.type === 'text'"
          class="t-w-full bot-detail-text"
          [ngClass]="{ 't-pb-5': i === transcriptArr.length - 1 }">
          {{ message.text.message }}
          <span class="user-bubble-agent-time f-10 d-flex">{{ message.createdAt | date: "shortTime" }} </span>
        </div>
        <div *ngIf="message.isConfigMessage && message.type === 'tag'" class="text-center t-w-full config-dashed-line">
          <div class="bot-config-details">
            <div class="bot-config-details-inner">
              {{ tagActionMapping[message.tag.action + "." + message.tag.stateBeforeAction] }}
              <span class="tag-name">{{ tagMapping[message.tag.tagId] ? tagMapping[message.tag.tagId] : message.tag.tagName }}</span>
            </div>
            <mat-icon
              class="material-icons-outlined info-icon t-flex"
              [popper]="configToolTip"
              popperTrigger="hover"
              popperPlacement="top"
              [popperPreventOverflow]="false"
              [popperPositionFixed]="true"
              [popperStyles]="{ 'text-align': 'left' }"
              popperApplyClass="warning-popper"
              popperApplyArrowClass="warning-arrow"
              [popperHideOnMouseLeave]="false"
              aria-hidden="false"
              (mouseover)="commonService.resizePage()"
              >info
            </mat-icon>
            <span class="user-bubble-agent-time f-10">{{ message.tag.timestamp | date: "shortTime" }} </span>
            <popper-content #configToolTip>
              <div>Source: {{ sourceMapping[message.tag.source] }}</div>
              <div>
                <span *ngIf="message.tag.source !== 'flow'">Agent: {{ message.tag.actionPerformedByUserName }} </span
                ><span *ngIf="message.tag.source === 'flow'"
                  >Flow:
                  {{
                    flowListMapping[message.tag.flowId]
                      ? flowListMapping[message.tag.flowId]
                      : channel === CHANNEL_NAMES.WHATSAPP_BSP
                        ? workflowChannelFlowListMapping[message.tag.flowId]
                          ? workflowChannelFlowListMapping[message.tag.flowId]
                          : message.tag.flowName + " (Deleted)"
                        : message.tag.flowName + " (Deleted)"
                  }}</span
                >
              </div>
              <div>Type: {{ tagTypeMapping[message.tag.tagType] }}</div>
              <div *ngIf="message.tag.stateBeforeAction === 'notExisted' && message.tag.action === 'tagRemoved'">
                (This tag was either removed or did not exist.)
              </div>
              <div *ngIf="!tagMapping[message.tag.tagId]">(This tag is deleted.)</div>
            </popper-content>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #imagePreview>
  <div class="image-preview-main">
    <img alt="preview-image" [src]="currentImage" />
  </div>
</ng-template>

<!--List Template -->
<ng-template #listTemplate>
  <mat-radio-group [(ngModel)]="listData.selectedValue">
    <div class="list-option-main-div" *ngFor="let listItem of listData.sections">
      <div class="title">{{ listItem.title | titlecase }}</div>
      <div class="list-option">
        <ng-container *ngFor="let option of listItem.row">
          <div class="t-w-full t-flex t-items-center t-justify-between option">
            <div>
              <div class="t-w-full option-title">{{ option.title | titlecase }}</div>
              <div class="t-flex t-w-full option-desc">{{ option.description }}</div>
            </div>
            <mat-radio-button class="radio" [value]="option.id" [disabled]="true"></mat-radio-button>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-radio-group>
</ng-template>

<ng-template #catalogueProductList>
  <div class="product-main-div">
    <ng-container *ngIf="catalogueType === 'productSingle'">
      <div class="product-div t-w-full" [ngClass]="{ 'iproduct-image': productData.isDynamicProduct }">
        <div>
          <img class="t-h-full" *ngIf="productData.action?.image" [src]="productData.action?.image" alt="product-image" />
          <div *ngIf="!productData.action?.image" class="t-h-full image-none">
            <img src="assets/images/catalogue_image.svg" alt="product-image" />
          </div>
        </div>
        <div class="t-flex t-justify-between t-w-full">
          <div class="t-h-full title-main-div">
            <span *ngIf="productData.action?.title">{{ productData.action?.title }}</span>
            <span *ngIf="!productData.action.title">Product Id: {{ productData.action.retailerId }}</span>
            <p class="subtitle">{{ productData.action?.subTitle }}</p>
          </div>
          <div class="t-flex t-flex-col t-justify-end t-m-2.5 price-div">
            <div>{{ productData.action?.price }}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="catalogueType === 'productMulti'">
      <ng-container *ngFor="let section of productData.action?.sections">
        <div class="section-header-div t-mt-2.5">
          <span class="t-ml-2.5 t-font-semibold">{{ section.title }}</span>
        </div>
        <ng-container>
          <div
            class="product-div t-w-full t-mt-2.5"
            *ngFor="let item of section.items"
            [ngClass]="{ 'iproduct-image': productData.isDynamicProduct }">
            <div>
              <img class="t-h-full" *ngIf="item?.image" [src]="item?.image" alt="product-image" />
              <div *ngIf="!item?.image" class="t-h-full image-none">
                <img src="assets/images/catalogue_image.svg" alt="product-image" />
              </div>
            </div>
            <div class="t-flex t-justify-between t-w-full">
              <div class="t-h-full title-main-div">
                <span *ngIf="item?.title">{{ item?.title }}</span>
                <span *ngIf="!item.title">Product Id: {{ item.retailerId }}</span>
                <p class="subtitle">{{ item?.subTitle }}</p>
              </div>
              <div class="t-flex t-flex-col t-justify-end t-m-2.5 price-div">
                <div>{{ item?.price }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="catalogueType === 'order'">
      <div class="product-div t-w-full t-mt-2.5" *ngFor="let item of productData?.items">
        <div>
          <img class="t-h-full" *ngIf="item?.image" [src]="item?.image" alt="product-image" />
          <div *ngIf="!item?.image" class="t-h-full image-none">
            <img src="assets/images/catalogue_image.svg" alt="product-image" />
          </div>
        </div>
        <div class="t-flex t-justify-between t-w-full">
          <div class="t-h-full title-main-div">
            <span *ngIf="item?.title">{{ item?.title }}</span>
            <span *ngIf="!item.title">Product Id: {{ item.retailerId }}</span>
            <p class="subtitle">{{ item?.subTitle }}</p>
          </div>
          <div class="t-flex t-flex-col t-justify-between t-m-2.5 price-div">
            <span class="t-mr-[5px]">Qty: {{ item?.quantity }}</span>
            <div>{{ item?.price }}</div>
          </div>
        </div>
      </div>
      <div class="section-footer-div t-mt-2.5 t-flex t-justify-between t-items-center t-p-5">
        <span>{{ productData.noOfOrders }} Items</span>
        <span>{{ (productData?.items)[0].currency }} {{ productData.totalPriceOfOrder }}(estimated)</span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #whatsappFlowDetails>
  <div class="t-w-full t-p-5">
    <div class="t-flex t-justify-center t-items-center t-flex-col">
      <mat-icon class="material-icons-round whatsapp-flow-image color-primary" aria-hidden="false">list_alt</mat-icon>
      <p class="text-center t-text-[22px]">{{ WhatsappFlowDetails.whatsappFlowName ?? WhatsappFlowDetails }}</p>
    </div>
  </div>
</ng-template>

<ng-template #orderPaymentDetails>
  <div class="t-w-full t-h-full order-details-template-main">
    <div class="order-details-top">
      <div class="order-details-items-main">
        <div class="order-details-header t-mt-[5px]">
          <div class="order-business-icon t-flex t-justify-center t-items-center">
            <mat-icon>storefront</mat-icon>
          </div>
          <span class="t-text-base t-font-semibold">Whatsapp Business</span>
          <span class="order-id t-text-xs">Order #{{ orderPaymentData?.referenceId }}</span>
          <div class="t-flex t-justify-center order-details-status t-items-center t-mt-[5px]">
            <mat-icon class="t-mr-[5px]">timelapse</mat-icon><span>Order pending</span>
          </div>
        </div>
        <div class="dashed-line t-mt-2.5"></div>
        <div class="order-item-main-div t-mt-2.5">
          <div *ngFor="let item of orderPaymentData?.items" class="order-item t-flex t-mt-2.5 t-w-full">
            <div class="t-flex t-w-full">
              <div class="item-image-div t-flex t-justify-center t-items-center">
                <mat-icon>image_outline</mat-icon>
              </div>
              <div class="t-ml-2.5 item-text-div t-flex t-justify-center t-flex-col">
                <div class="dot t-w-full t-text-sm t-font-semibold t-flex t-justify-between">
                  <div class="dot t-w-[70%]">
                    {{ item.name }}<span *ngIf="!item.name">Retailer Id: {{ item.retailerId }}</span>
                  </div>
                  <div class="t-text-xs t-font-semibold">
                    {{ orderPaymentData.currency }} {{ item.amount?.value / item.amount?.offset }}
                  </div>
                </div>
                <div class="item-quantity t-text-xs">Quantity {{ item.quantity }}</div>
              </div>
            </div>
          </div>
          <div class="dashed-line t-mt-2.5"></div>
          <div class="bottom-amount-and-text-div t-mt-5">
            Subtotal <span>{{ orderPaymentData.currency }} {{ orderPaymentData.subtotal?.value / orderPaymentData.subtotal?.offset }}</span>
          </div>
          <div *ngIf="orderPaymentData?.shipping?.value" class="bottom-amount-and-text-div t-mt-[5px]">
            Shipping {{ orderPaymentData.shipping?.description ? "(" + orderPaymentData.shipping?.description + ")" : "" }}
            <span>{{ orderPaymentData.currency }} {{ orderPaymentData.shipping?.value / orderPaymentData.shipping?.offset }}</span>
          </div>
          <div *ngIf="orderPaymentData?.tax?.value" class="bottom-amount-and-text-div t-mt-[5px]">
            Tax {{ orderPaymentData.tax?.description ? "(" + orderPaymentData.tax?.description + ")" : "" }}
            <span>{{ orderPaymentData.currency }} {{ orderPaymentData.tax?.value / orderPaymentData.tax?.offset }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-details-bottom t-p-2.5">
      <div class="t-flex t-justify-between t-text-sm t-font-semibold">
        Total <span>{{ orderPaymentData.currency }} {{ orderPaymentData.totalAmount?.value / orderPaymentData.totalAmount?.offset }}</span>
      </div>
      <div class="t-mt-[15px] t-flex t-justify-center t-items-center continue-button t-font-semibold">Continue</div>
    </div>
  </div>
</ng-template>

<ng-template #addressMessageFormModal>
  <ng-container *ngIf="showSavedAddresses && saved_addresses.length">
    <div
      class="t-flex t-items-center t-justify-start t-mt-2.5 p-15 add-an-address t-cursor-pointer t-outline-none"
      (click)="showSavedAddresses = !showSavedAddresses">
      <mat-icon color="primary">add_circle_outline</mat-icon>
      <span class="t-ml-2.5 color-primary">Add an address</span>
    </div>

    <div class="saved-address-div">
      <p class="t-text-lg t-mt-2.5 t-mb-[5px] t-font-semibold">Select Address</p>
      <div class="horizontal-line t-mb-[5px]"></div>
      <div class="saved-addresses-list">
        <mat-radio-group>
          <div class="t-mt-2.5 saved-address-block p-15 t-mb-2.5" *ngFor="let address of saved_addresses; index as savedAddressIndex">
            <div class="t-flex t-items-center t-justify-between">
              <mat-radio-button class="radio" [value]="address">
                <div class="t-flex t-flex-col t-ml-2.5 t-w-full">
                  <span class="t-text-base t-font-bold saved-address">{{ address.value.name }}</span>
                  <span class="t-text-sm saved-address">{{ address.value.phone_number }}</span>
                  <span class="t-text-sm t-mt-2.5 saved-address t-pr-2.5">
                    {{ address.value.house_number ? address.value.house_number + ", " : "" }}
                    {{ address.value.floor_number ? address.value.floor_number + ", " : "" }}
                    {{ address.value.tower_number ? address.value.tower_number + ", " : "" }}
                    {{ address.value.building_name ? address.value.building_name + ", " : "" }}
                    {{ address.value.address ? address.value.address + ", " : "" }}
                    {{ address.value.landmark_area ? address.value.landmark_area + ", " : "" }}
                    {{ address.value.city ? address.value.city + ", " : "" }}
                    {{ address.value.state ? address.value.state : "" }}
                    {{ address.value.in_pin_code ? " - " + address.value.in_pin_code : "" }}
                  </span>
                </div>
              </mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showSavedAddresses || !saved_addresses.length">
    <div
      class="t-flex t-items-center t-justify-start t-cursor-pointer t-outline-none t-mb-[5px] color-primary"
      *ngIf="saved_addresses.length"
      (click)="showSavedAddresses = !showSavedAddresses">
      <mat-icon>arrow_back</mat-icon><span class="t-text-sm t-ml-[5px]">Back</span>
    </div>
    <div class="address-form-div">
      <div class="t-pt-[5px]">
        <div>
          <span class="t-text-lg t-mb-[5px] color-grey t-font-semibold">Contact Details</span>
          <p class="t-mt-[5px]"></p>
          <div class="input-wrapper">
            <label for="name" class="t-mt-[15px]"><span class="t-text-sm">Name</span></label>
            <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
              <input matInput readonly type="text" [(ngModel)]="addressMessageOBJ.name" />
            </mat-form-field>
          </div>
          <label for="phone_number"><span class="t-text-sm">Phone Number</span></label>
          <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
            <input matInput readonly type="text" [(ngModel)]="addressMessageOBJ.phone_number" />
          </mat-form-field>
        </div>
        <div class="t-pt-[5px]">
          <span class="t-text-lg t-mb-2.5 color-grey t-font-semibold">Address Details</span>
          <p class="t-mt-[5px]"></p>
          <div *ngFor="let addressMessageField of addressMessageFields">
            <label for="address">
              <span class="t-text-sm">{{ addressMessageField.label }}</span>
            </label>
            <mat-form-field floatLabel="never" appearance="fill" class="input input-gray">
              <input matInput readonly type="text" [(ngModel)]="addressMessageOBJ[addressMessageField.value]" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="button-wrapper">
    <button type="button" mat-flat-button class="btn primary-btn btn-full t-mt-[10px]">Send Address</button>
  </div>
</ng-template>
