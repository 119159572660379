import { BotService } from "./../../services/bot.service";
import { Component, ComponentFactoryResolver, ComponentRef, Inject, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { saveAs } from "file-saver";

class DialogOverviewExampleDialog {}

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnInit {
  @ViewChild("componentTemplate", { read: ViewContainerRef, static: true })
  componentTemplate: ViewContainerRef;
  componentRef: ComponentRef<any>;
  isPartnerOrCST = this._botService.checkPartnerOrCST();

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private resolver: ComponentFactoryResolver,
    private readonly _botService: BotService
  ) {}

  ngOnInit() {
    if (this.data.component !== undefined) {
      this.componentRef = this.componentTemplate.createComponent(this.resolver.resolveComponentFactory(this.data.component));
      this.componentRef.instance.data = this.data.data;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  downloadImage(url) {
    saveAs(url);
  }
}
