import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";

import { IAPIResponse } from "../utils/model/common";
import { Urls } from "../utils/urls";
import { BotService } from "./bot.service";
import { CommonService } from "./common.service";
import { NotifyService } from "./noyify.service";

@Injectable()
export class FlowBuilderService {
  serviceName = "flow-builder";

  constructor(
    private http: HttpClient,
    private botService: BotService,
    private commonService: CommonService,
    private router: Router,
    public _notificationService: NotifyService
  ) {}

  private subject = new Subject<any>();
  saving = new Subject<any>();
  removeEndPoint = new Subject<any>();
  addEndPoint = new Subject<any>();
  revalidateEndPoint = new Subject<any>();
  deleteEndPoint = new Subject<any>();
  saveCounter = new Subject<any>();
  changeWhatsappFlow = new Subject<any>();
  isConfirmPopUpDisabled = new Subject<boolean>();
  generateNodeForTemplate = new EventEmitter<any>();
  removeNodeForTemplate = new EventEmitter<any>();
  isFetchingDetails = new BehaviorSubject<boolean>(false);

  get isFetchingDetails$() {
    return this.isFetchingDetails.asObservable();
  }
  getFlowSubFolderNavList() {
    return JSON.parse(localStorage.getItem("FlowSubFolderNavList"));
  }

  setFlowSubFolderNavList(list) {
    localStorage.setItem("FlowSubFolderNavList", JSON.stringify(list));
  }

  getFidS() {
    return JSON.parse(localStorage.getItem("fid"));
  }

  setFids(value) {
    localStorage.setItem("fid", JSON.stringify(value));
  }

  getFlowFolderList(skip, limit, search, botIds, type, channel, folderId?) {
    let httpParams;
    const queryObj = {
      limit,
      skip,
      filter_param: encodeURIComponent(search?.trim() ?? ""),
      botId: botIds ? botIds.join(",") : "",
      channel,
      type,
    };
    if (folderId) {
      queryObj["folderId"] = folderId;
      httpParams = this.commonService.httpParamsPatcher(queryObj);
    }
    httpParams = this.commonService.httpParamsPatcher(queryObj);
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flowFolder, { params: httpParams }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getFlowsCount(search, botIds, channel, type, floderId?) {
    let httpParams;
    const queryObj = {
      filter_param: encodeURIComponent(search?.trim() ?? ""),
      botId: botIds ? botIds : "",
      channel,
      type,
    };
    if (floderId) {
      queryObj["folderId"] = floderId;
      httpParams = this.commonService.httpParamsPatcher(queryObj);
    }
    httpParams = this.commonService.httpParamsPatcher(queryObj);
    return new Promise((resolve, reject) => {
      return this.http
        .get(`${Urls.flowFolder}count`, {
          params: httpParams,
        })
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllFlowFolderList(botIds, type, channel, folderId?) {
    let url;
    if (folderId) {
      url =
        Urls.flowFolder +
        "?getAllFlow=" +
        true +
        (botIds ? "&botId=" + botIds.join(",") : "") +
        "&type=" +
        type +
        "&channel=" +
        channel +
        "&folderId=" +
        folderId;
    } else {
      url =
        Urls.flowFolder + "?getAllFlow=" + true + (botIds ? "&botId=" + botIds.join(",") : "") + "&type=" + type + "&channel=" + channel;
    }
    return new Promise((resolve, reject) => {
      return this.http.get(url).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  createFlowFolder(object) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flowFolder, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getNameForFolders(ids, botId): any {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flowFolder + "by-ids?botId=" + botId, { folderIds: ids }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  moveFlowFolder(object, folderId) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flowFolder + folderId, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  deleteFlowFolder(folderId, isDelete) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.flowFolder + folderId + "?dryRun=" + isDelete).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getFlowBuilderById(id, isPreview): any {
    let url;
    if (isPreview) {
      url = Urls.flow_builder + id + "/publish/flow";
    } else {
      url = Urls.flow_builder + id;
    }
    return new Promise((resolve, reject) => {
      return this.http.get(url).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  addFlow(object) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateFlow(id, object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + id, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  copyFlow(object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + "copy/flow", object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  deleteMultipleFlow(object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + "delete/flow", object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  moveMultipleFlow(object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + "move/flow", object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  publishFlow(id) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + id + "/publish/flow", {}).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  restorePublishFlow(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + id + "/restore/publish/flow").subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  deleteFlow(id): any {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.flow_builder + id).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  deleteMessage(id) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.floW_messages + "/" + id).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getAllFlowList(botId, type): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + "all/" + type + "?botId=" + botId).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getChannelFlowList(botId, channel, type): Promise<any> {
    const currentBotId = botId ? botId : this.botService.getBot();
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + "all/" + type + "?botId=" + currentBotId + "&channel=" + channel).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getAllListOfFunction(botId): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + "operations?botId=" + botId).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /*new methods for block*/

  saveBlock(id, object): any {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder + id + "/block", object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  copyBlock(id, object): any {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + id + "/copy/block", object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  updateBlock(id, blockId, object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + id + "/block/" + blockId, object).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  deleteBlock(id, blockId) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.flow_builder + id + "/block/" + blockId).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  saveBlockStep(botId, blockId, obj): any {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder + botId + "/block/" + blockId + "/step", obj).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  deleteBlockStep(id, blockId, stepId) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.flow_builder + id + "/block/" + blockId + "/step/" + stepId).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  updateBlockStep(id, obj) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + "step/" + id, obj).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (this.handleFlowNotFoundError(err)) {
            reject(err);
          }
        }
      );
    });
  }

  setDefaultStartingFlowAndMessage(obj) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.bdk_common, obj).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  deleteDefaultStartingFlowAndMessage(botId, channel, isDefaultFlow) {
    return new Promise((resolve, reject) => {
      return this.http.delete(`${Urls.bdk_common}${botId}/${channel}?isDefaultFlow=${isDefaultFlow}`).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getDefaultStartingFlowAndMessage(botId): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.bdk_common + botId).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  uploadMediaOrDocument(data, mediaTypePath, channel): any {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.flow_builder}${mediaTypePath}?botId=${this.botService.getBot()}&channel=${channel}`, data).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getIcons() {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + "all/icon").subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getGenesysQueueList(botId) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.baseUrl}genesys/queue/${botId}?page=1&limit=50`).subscribe(
        queueListResponse => {
          resolve(queueListResponse);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  exportFlow(flowId): Promise<IAPIResponse<any>> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.bot}${this.botService.getBot()}${Urls.flowBuilder}export/${flowId}`).subscribe(
        res => {
          resolve(res as IAPIResponse<any>);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  restoreFlow(data): Promise<IAPIResponse<any>> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.bot}${this.botService.getBot()}${Urls.flowBuilder}restore`, data).subscribe(
        res => {
          resolve(res as IAPIResponse<any>);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getProducts(botId, queryParams) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.baseUrl}catalog/${botId}/getProducts${queryParams}`).subscribe(
        productResponse => {
          resolve(productResponse);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  handleFlowNotFoundError(err) {
    if (err.status === 404 && err?.error?.error_code === "REQUESTED_FLOW_NOT_FOUND") {
      this.commonService.errorHandler(err, `${this.serviceName}:handleFlowNotFoundError`, true);
      this.router.navigate(["/dashboard/builder"]).catch(() => {});
      return false;
    }
    return true;
  }

  getListOfZohoDeskDepartments() {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.bot}${this.botService.getBot()}${Urls.integration}zoho-desk/getDepartments`).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
